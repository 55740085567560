import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from "@angular/core";


import { ActivatedRoute, Router } from "@angular/router";



import { HCPService } from "../hcp.service";

import { HCPClass } from "../../models/hcpclass.model";
import { DisplayModes } from "../../../models/enums";
import { HCP } from "../../../models/lookups/hcp.model";
import { Specialty } from "../../../models/lookups/speciality.model";
import { AlertService, MessageSeverity } from "../../../services/alert.service";
import { LookupsService } from "../../../services/lookups.service";
import { AppTranslationService } from "../../../services/app-translation.service";



@Component({
  selector: "crm-add-hcp",
  templateUrl: "./add-hcp.component.html",
  styleUrls: ["./add-hcp.component.css"]
})
export class AddHCPComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  hcp: HCP = new HCP();
  specialities: Specialty[] = [];
  hCPClasses: HCPClass[] = [];

  searchText: string = "";
  //hcpId: number;

  @ViewChild("specialityId")
  private specialityId;

  @ViewChild("specialitiesSelector")
  private specialitiesSelector;

  //@ViewChild("hcpClassesSelector")
  //private hcpClassesSelector;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() hcpId: number;
  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private hcpService: HCPService,
    private translateService: AppTranslationService,
    private router: Router) {
    
  }

  ngOnInit() {
    if (this.hcpId != undefined)
      this.getHCPDetails(this.hcpId);
    this.getSpecialities();
    //this.getHCPClasses();
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      if (this.hcp.specialityId)
        this.hcp.specialityId = Number(this.hcp.specialityId);
      this.hcpService.SaveHCP(this.hcp).subscribe((result): void => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.hcp.id === 0)
          this.closeEvent.emit(true);
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  getSpecialities() {
    this.displayMode = DisplayModes.Loading;
    this.hcpService.GetAllSpecialities("").subscribe(response => {
      const results = response;
      this.specialities = results as Specialty[];
      this.displayMode = DisplayModes.Success;
      setTimeout(() => this.specialitiesSelector.refresh());
    }, () => {
      this.displayMode = DisplayModes.Error;
    });
  }


  back() {
    this.closeEvent.emit(true);
  }

  getHCPDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.hcpService.GetHCPById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.hcp = response as HCP;
    }, () => {
      this.displayMode = DisplayModes.Error;

    });
  }

  getHCPClasses() {
    this.displayMode = DisplayModes.Loading;
    this.hcpService.GetAllHCPClasses("").subscribe(response => {
      const results = response;
      this.hCPClasses = results as HCPClass[];
      this.displayMode = DisplayModes.Success;
      //setTimeout(() => this.hcpClassesSelector.refresh());
    }, () => {
      this.displayMode = DisplayModes.Error;
    });
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }
}
