import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { LocalStoreManager } from '../services/local-store-manager.service';

import { DBkeys } from '../services/db-Keys';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const localStorage = this.inj.get(LocalStoreManager);
      request = request.clone({
        url: request.url,
        headers: request.headers.set('Authorization', `Bearer ${localStorage.getData(DBkeys.ACCESS_TOKEN)}`)
      });
    
   
    return next.handle(request);
  }
}
