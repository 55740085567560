import { BusiniessUnitSpeciality } from "./businiess-unit-speciality";
import { BusinessUnitType } from "../../lookups/models/business-unit-type";


export class BusinessUnit {

  constructor(_active: boolean) {
    this.active = _active;
  }
  id: number;
  name: string;
  active: boolean;
  parentId: number;
  createdBy: string;
  creationDate: Date;
  lastModifiedBy: string;
  lastModificationDate: Date;
  buisnessUnitSpecialities: BusiniessUnitSpeciality[];
  businessUnitSpecialities: BusiniessUnitSpeciality[];
  parentBusinessUnit: BusinessUnit;
  businessUnitType: BusinessUnitType;
  businessUnitTypeId: number;
  businessUnitTypeName: string;
  parentName: string;
  endDate?: Date;
}


export class BusinessUnitTree {
  data: BusinessUnit;
  children: BusinessUnitTree[];
}
