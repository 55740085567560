import { Component, ViewChild, OnInit } from '@angular/core';
import { Role } from '../../../../models/role.model';
import { Permission } from '../../../../models/permission.model';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { AccountService } from '../../../../services/account.service';
import { DisplayModes } from '../../../../models/enums';
import { Utilities } from '../../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService } from '../../../../settings/components/settings/setting.service';
import { ConfigurationService } from '../../../../services/configuration.service';

@Component({
  selector: 'role-editor',
  templateUrl: './role-editor.component.html',
  styleUrls: ['./role-editor.component.css']
})
export class RoleEditorComponent implements OnInit {

  private isNewRole = false;
  private isSaving: boolean;
  private roleId: number;
  private roles: Role[]=[];
  private showValidationErrors: boolean = true;
  private displayMode: DisplayModes = DisplayModes.Success;
  private editingRoleName: string;
  private roleEdit: Role = new Role();
  private allPermissions: Permission[] = [];
  private selectedValues: { [key: string]: boolean; } = {};

  public formResetToggle = true;
  public logo = require("../../../../assets/images/demo/Icon_Roles Management.png");
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;


  applyingPharmaciesModule: string;
  @ViewChild('f')
  private form;
  constructor(private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly settingService: SettingService,
    private configurations: ConfigurationService  ) {
  }
  ngOnInit() {
    this.loadAllRoles();
    this.loadData();

    this.route.params.subscribe(params => {
      this.roleId = params["roleId"];
      if (this.roleId === undefined)
        this.newRole();

      else {
        this.loadRoleData();
      }


    });

    this.settingService.getAllSettingsByURL(this.configurations.apiAddress).subscribe(result => {
      let settings = result as any[];
      this.applyingPharmaciesModule = settings.find(x => { return x.key == "applyingPharmaciesModule"; }).value;

    }, error => { console.log(error) });
  }

  private loadRoleData() {
    this.displayMode = DisplayModes.Loading;
    this.accountService.getRole(this.roleId.toString()).subscribe(response => {
      let r = response as Role
      this.editRole(r);
      this.displayMode = DisplayModes.Success;
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured whilst loading role data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.Error);
  }
  private save(form) {
    if (!form.invalid) {
      this.isSaving = true;
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("Saving changes...");

      this.roleEdit.permissions = this.getSelectedPermissions();
      
      if (this.isNewRole) {
        this.accountService.newRole(this.roleEdit).subscribe(role => this.saveSuccessHelper(role), error => this.saveFailedHelper(error));
      }
      else {
        this.accountService.updateRole(this.roleEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
      }
    }

  }
  private saveSuccessHelper(role?: Role) {


    this.isSaving = false;
    this.displayMode = DisplayModes.Success;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewRole)
      this.alertService.showMessage("Success", `Role \"${this.roleEdit.name}\" was created successfully`, MessageSeverity.Success);
    else
      this.alertService.showMessage("Success", `Changes to role \"${this.roleEdit.name}\" was saved successfully`, MessageSeverity.Success);

    if (this.isNewRole) {
      this.roleEdit = new Role();
      this.resetForm();
    }
   


    if (!this.isNewRole && this.accountService.currentUser.roles.some(r => r == this.editingRoleName))
      this.refreshLoggedInUser();




  }
  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser()
      .subscribe(user => { },
        error => {
          this.alertService.resetStickyMessage();
          this.alertService.showStickyMessage("Refresh failed", "An error occured whilst refreshing logged in user information from the server", MessageSeverity.Error, error);
        });
  }
  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.Error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

    if (this.changesFailedCallback)
      this.changesFailedCallback();
  }
  private loadAllRoles() {
    this.displayMode = DisplayModes.Loading;
    this.accountService.getRoles().subscribe(response => {
      this.roles = response as Role[];
      this.displayMode = DisplayModes.Success;
    }, error => {
        this.alertService.showStickyMessage("Load Error", `Unable to retrieve roles from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
          MessageSeverity.Error, error);
        this.displayMode = DisplayModes.Error;
    });
  }

  private cancel() {
    this.roleEdit = new Role();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage("Cancelled", "Operation cancelled by user", MessageSeverity.Default);
    this.alertService.resetStickyMessage();
    this.router.navigate(['roles-management']);

  }



  private selectAll() {
    this.allPermissions.forEach(p => this.selectedValues[p.value] = true);
  }


  private selectNone() {
    this.allPermissions.forEach(p => this.selectedValues[p.value] = false);
  }


  private toggleGroup(groupName: string) {
    let firstMemberValue: boolean;

    this.allPermissions.forEach(p => {
      if (p.groupName != groupName)
        return;

      if (firstMemberValue == null)
        firstMemberValue = this.selectedValues[p.value] == true;

      this.selectedValues[p.value] = !firstMemberValue;
    });
  }


  private getSelectedPermissions() {
    return this.allPermissions.filter(p => this.selectedValues[p.value] == true);
  }


  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }


  newRole() {
    this.isNewRole = true;
    this.showValidationErrors = true;

    this.editingRoleName = null;

    this.selectedValues = {};
    this.roleEdit = new Role();

    return this.roleEdit;
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.displayMode = DisplayModes.Loading;

    this.accountService.getPermissions()
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;

        console.log(results);
        let permissions = results;






        this.allPermissions = permissions;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.displayMode = DisplayModes.Success;

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve roles from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            MessageSeverity.Error, error);
        });
  }



  editRole(role: Role) {
    if (role) {
      this.isNewRole = false;
      this.showValidationErrors = true;

      this.editingRoleName = role.name;
      
      this.selectedValues = {};
      if (role.permissions != undefined)
        role.permissions.forEach(p => this.selectedValues[p.value] = true);
      this.roleEdit = new Role();
      Object.assign(this.roleEdit, role);

      return this.roleEdit;
    }
    else {
      return this.newRole();
    }
  }



  get canManageRoles() {
    return this.accountService.userHasPermission(Permission.manageRolesPermission)
  }

  checkPharmacyPermissions(permission) {
    let pharmacyPermissions: string[] = ['Pharmacy Permissions', 'User Pharmacy Requests Permissions', 'User Pharmacy List Permissions',
       'Pharmacy Plans Permissions', 'Pharmacy Visit Permissions','PCF Pharmacy Report']

    return pharmacyPermissions.indexOf(permission) == -1 || this.applyingPharmaciesModule == 'True';
  }
}
