import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { ActivityCostCenter } from '../../models/lookups/activity-cost-center.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityCostCenterService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  save(activityCostCenter: ActivityCostCenter) {
    return this.http.post(this.configurations.apiAddress + "/api/ActivityCostCenter/save", activityCostCenter);
  }

  search(searchText: string = undefined, businessUnitId = undefined) {
    let myParams = new HttpParams()
      .set('businessUnitId', businessUnitId == undefined ? "0" : businessUnitId)
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostCenter/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/ActivityCostCenter/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostCenter/getById/' + id);
  }

  getActivityCostCenterProducts(costCenterId: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostCenter/getActivityCostCenterProducts/' + costCenterId);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostCenter/getAll/');
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/ActivityCostCenter/downloadCSVTemplate", { responseType: "blob" });

  }

  downloadErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/ActivityCostCenter/downloadErrorFile", { responseType: "blob" });
  }

  uploadCsvTemplate(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/ActivityCostCenter/uploadCSVTemplate", formData);

  }

  updateFromCSV(formData) {
    return this.http.post(this.configurations.apiAddress + "/api/ActivityCostCenter/updateFromCSV", formData);
  }
}
