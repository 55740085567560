import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { Feedback } from '../../../models/lookups/feedback.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { FeedbackService } from '../feedback.service';

@Component({
  selector: 'crm-add-feedback',
  templateUrl: './add-feedback.component.html',
  styleUrls: ['./add-feedback.component.css']
})
export class AddFeedbackComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  feedback: Feedback = new Feedback();

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() feedbackId: number;

  constructor(private readonly feedbackService: FeedbackService,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.feedbackId != undefined)
      this.getFeedback(this.feedbackId);
  }

  getFeedback(feedbackId) {
    this.displayMode = DisplayModes.Loading;
    this.feedbackService.getById(feedbackId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.feedback = responce as Feedback;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get feedback');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.feedbackService.save(this.feedback).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }
}
