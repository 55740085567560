<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'settings.header.RolesManagement' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'settings.header.RolesManagement' | translate}}</h1>
  </div>
</div>
<form *ngIf="formResetToggle" class="form-horizontal" name="roleEditorForm" #f="ngForm" novalidate
      (ngSubmit)="save(f)">
  <div class="panel panel-default widget-support-tickets">
    <div class="panel-heading">
      <span class="panel-title">{{'settings.header.RolesManagement' | translate}} {{'Data' | translate}}</span>
    </div>

    <div class="panel-body padding-sm-hr">
      <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
        <div class="row">
          <div class="col-sm-3">
            <div [ngClass]="{'has-success': (roleName.dirty || roleName.touched || f.submitted)  && roleName.valid, 'has-error' : (roleName.dirty || roleName.touched || f.submitted)  && roleName.invalid}">
              <input [disabled]="!canManageRoles" type="text" id="roleName" name="roleName" placeholder="Enter role name" class="form-control" [(ngModel)]="roleEdit.name" #roleName="ngModel" required minlength="2" maxlength="200" />
              <div *ngIf="(roleName.dirty || roleName.touched || f.submitted)  && roleName.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <input [disabled]="!canManageRoles" type="text" id="roleDescription" name="roleDescription" placeholder="Enter role description" class="form-control" [(ngModel)]="roleEdit.description" />
          </div>
          <div class="col-sm-3">
            <div class="checkbox user-enabled">
              <label>
                <input type="checkbox" name="showInReport" [(ngModel)]="roleEdit.showInReport">
                {{'roles.editor.ShowInReport' | translate}}
              </label>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="checkbox user-enabled">
              <label>
                <input type="checkbox" name="hasMobileView" [(ngModel)]="roleEdit.hasMobileView">
                {{'Has Mobile View' | translate}}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span *ngIf="showValidationErrors && f.submitted && !roleName.valid" class="errorMessage roleErrorMessage">
              {{'roles.editor.RoleNameRequired' | translate}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="panel panel-success widget-support-tickets">
    <div class="panel-heading">
      <span class="panel-title">{{'settings.header.RolesManagement' | translate}} {{'Permission' | translate}}</span>
    </div>
    <div class="panel-body padding-sm-hr">
      <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
        <div class="row" *ngIf="canManageRoles" style="padding-left: 1%;">
          <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="selectAll()">
            <span class="margin-right-7 filter-span">Select All</span>
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="selectNone()">
            <span class="margin-right-7 filter-span">Select None</span>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row well well-sm permissionsRow">
              <ng-template ngFor let-permissionGroup [ngForOf]="allPermissions | groupBy: 'groupName'" let-i="index">
                <div class="form-group col-sm-6 permissionsColumn"
                     *ngIf="checkPharmacyPermissions(permissionGroup.key)">
                  <label class="col-md-5 control-label" for="checkboxes" (click)="toggleGroup(permissionGroup.key)">{{permissionGroup.key}}</label>
                  <div class="col-md-7">
                    <div class="checkbox" *ngFor="let permission of permissionGroup.value">
                      <label tooltip="{{permission.description}}" for="checkboxes-{{permission.value}}" *ngIf="checkPharmacyPermissions(permission.name)">
                        <input [disabled]="!canManageRoles" name="checkboxes-{{permission.value}}" id="checkboxes-{{permission.value}}" type="checkbox" [(ngModel)]="selectedValues[permission.value]">
                        {{permission.name}}
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="(i + 1) % 2 === 0" class="clearfix"></div>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" *ngIf="canManageRoles" [disabled]="isSaving" (click)="cancel()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-cancel" type="button" *ngIf="!canManageRoles" (click)="cancel()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" *ngIf="canManageRoles" [disabled]="isSaving">{{'Save' | translate}}</button>

        </div>
      </div>
    </div>
  </div>

</form>


