
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList, ElementRef, Inject, ViewChild } from "@angular/core";
import { Router, NavigationStart } from '@angular/router';
import { ToastyService, ToastyConfig, ToastOptions, ToastData } from 'ng2-toasty';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from '../services/alert.service';
import { NotificationService } from "../services/notification.service";
import { AppTranslationService } from "../services/app-translation.service";
import { AccountService } from '../services/account.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';

import { LoginComponent } from "../components/login/login.component";
import { DOCUMENT } from "@angular/platform-browser";
import Default = MessageSeverity.Default;
import Default1 = MessageSeverity.Default;
import Default2 = MessageSeverity.Default;
import Info = MessageSeverity.Info;
import Info1 = MessageSeverity.Info;
import Success = MessageSeverity.Success;
import Error = MessageSeverity.Error;
import Error1 = MessageSeverity.Error;
import Error2 = MessageSeverity.Error;
import Warn = MessageSeverity.Warn;
import Warn1 = MessageSeverity.Warn;
import Warn2 = MessageSeverity.Warn;
import Wait = MessageSeverity.Wait;
import Alert = DialogType.Alert;
import Confirm = DialogType.Confirm;
import Prompt = DialogType.Prompt;
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

var alertify: any = require('../assets/scripts/alertify.js');

@Component({
  selector: "app-root",
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  isAppLoaded: boolean;
  isUserLoggedIn = false;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;

  appTitle = "CRM";

  appLogo = require("../assets/images/pixel-admin/main-navbar-logo.png");
  stickyToasties: number[] = [];
  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;
  loginModal: ModalDirective;
  loginControl: LoginComponent;

  //Idle variables
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  public modalRef: BsModalRef;
  @ViewChild('idleModal') idleModal: ModalDirective;


  private profilePic = require("../assets/images/demo/avatars/1.jpg");
  public logo = require("../assets/images/pixel-admin/main-navbar-logo.png");

  get notificationsTitle() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    if (this.newNotificationCount)
      return `${gT("app.Notifications")} (${this.newNotificationCount} ${gT("app.New")})`;
    else
      return gT("app.Notifications");
  }

  constructor(@Inject(DOCUMENT) private document: Document,
    private storageManager: LocalStoreManager,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private configurations: ConfigurationService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive) {
    storageManager.initialiseStorageSyncListener();
    translationService.addLanguages(["en"]);
    translationService.setDefaultLanguage('en');


    this.toastyConfig.theme = 'bootstrap';
    this.toastyConfig.position = this.configurations.language == 'ar' ? 'top-left' : 'top-right';
    this.toastyConfig.limit = 100;
    this.toastyConfig.showClose = true;
    this.appTitleService.appName = this.appTitle;


    // sets an idle timeout of 30 minutes.
    idle.setIdle(1800);
    // sets a timeout period of 20 seconds. after 30 Minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(20);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.idleLogout();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      this.idleModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  hideIdleModal(): void {
    this.idleModal.hide();
  }

  stay() {
    this.idleModal.hide();
    this.reset();
  }



  onActivate(event) {
    window.scroll(0, 0);

  }

  ngAfterViewInit() {


  }

  onLoginModalShown() {
    this.alertService.showStickyMessage(this.translationService.getTranslation("SessionExpired"), "Your Session has expired. Please log in again", Info);
  }

  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again to renew your session", Warn);
    }
  }

  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }

  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 1000);
    setTimeout(() => this.removePrebootScreen = true, 1500);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();


        if (!this.authService.isSessionExpired) {
          this.alertService.showMessage("Login", `Welcome back ${this.userName}!`, Default);
          this.authService.intervalRefrechToken();
        }
        else {
          this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", Warn1);
          this.logout();
        }
      }
    }, 2000);


    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    //this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;


      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
        this.idle.watch();
        this.timedOut = false;
        this.document.body.classList.remove('page-signin');
      }
      else {
        this.unsubscribeNotifications();
        this.idle.stop();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage("Session Ended!", "", Default1);
          this.logout();

        }
      }, 500);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = (event as NavigationStart).url;

        if (url !== url.toLowerCase()) {
          this.router.navigateByUrl((event as NavigationStart).url.toLowerCase());
        }
      }
    });


    if (this.authService.currentUser && this.authService.currentUser.profilePicFileId) {
      let interval = setInterval(()=> {
        if (this.configurations.apiAddress) {
          this.profilePic = this.configurations.apiAddress + '/' + this.authService.currentUser.profilePicFileId;
          clearInterval(interval);
        }
        }, 5000);
    }
  }

  ngOnDestroy() {
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription)
      this.notificationsLoadingSubscription.unsubscribe();
  }

  initNotificationsLoading() {

    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailurs = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
        error => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailurs++ < 20)
            setTimeout(() => this.initNotificationsLoading(), 5000);
          else
            this.alertService.showStickyMessage("Load Error", "Loading new notifications from the server failed!", Error);
        });
  }

  markNotificationsAsRead() {

    let recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (let n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage("Notification Error", "Marking read notifications failed", Error1);

          });
    }
  }

  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || "OK",
        cancel: dialog.cancelLabel || "Cancel"
      }
    });

    switch (dialog.type) {
      case Alert:
        alertify.alert(dialog.message);

        break
      case Confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          });

        break;
      case Prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            }
            else {
              if (dialog.cancelCallback)
                dialog.cancelCallback();
            }
          }, dialog.defaultValue);

        break;
    }
  }

  showToast(message: AlertMessage, isSticky: boolean) {

    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastyService.clear(id);
      }

      return;
    }

    let toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      timeout: isSticky ? 0 : 4000
    };


    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        let index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }


    switch (message.severity) {
      case Default2: this.toastyService.default(toastOptions); break
      case Info1: this.toastyService.info(toastOptions); break;
      case Success: this.toastyService.success(toastOptions); break;
      case Error2: this.toastyService.error(toastOptions); break
      case Warn2: this.toastyService.warning(toastOptions); break;
      case Wait: this.toastyService.wait(toastOptions); break;
    }
  }

  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  idleLogout() {

    this.idleModal.hide();
    this.logout();

  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }




}
