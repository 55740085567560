
import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Role } from '../../../models/role.model';
import { Permission } from '../../../models/permission.model';
import { AlertService, MessageSeverity, DialogType } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AccountService } from '../../../services/account.service';
import { Utilities } from '../../../services/utilities';
import Error = MessageSeverity.Error;
import Error1 = MessageSeverity.Error;
import Confirm = DialogType.Confirm;
import { RoleEditorComponent } from './role-editor/role-editor.component';
import { Router } from '@angular/router';
import { DisplayModes } from '../../../models/enums';
import { AppConfigService } from '../../../app.config';

@Component({
  selector: 'roles-management',
  templateUrl: './roles-management.component.html',
  styleUrls: ['./roles-management.component.css']
})
export class RolesManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: Role[] = [];
  rowsCache: Role[] = [];
  allPermissions: Permission[] = [];
  editedRole: Role;
  sourceRole: Role;
  editingRoleName: { name: string };
  loadingIndicator: boolean;
  searchText: string;
  pageSize: number = 10;
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = require("../../../assets/images/demo/Icon_Roles Management.png");

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('roleEditor')
  roleEditor: RoleEditorComponent;

  selectedRoleId;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private readonly alertService: AlertService,
    private readonly translationService: AppTranslationService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly accountService: AccountService) {
  }


  ngOnInit() {

    let gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      
      { field: 'name', header: gT('roles.management.Name')},
      { field: 'description', header: gT('roles.management.Description') },
      { field: 'usersCount', header: gT('roles.management.Users') }
     
    ];
    this.pageSize = this.appConfig.settings.pageSize;
    this.loadData();
  }





  ngAfterViewInit() {

    this.roleEditor.changesSavedCallback = () => {
      this.addNewRoleToList();
      this.editorModal.hide();
    };

    this.roleEditor.changesCancelledCallback = () => {
      this.editedRole = null;
      this.sourceRole = null;
      this.editorModal.hide();
    };
  }


  addNewRoleToList() {
    if (this.sourceRole) {
      Object.assign(this.sourceRole, this.editedRole);

      let sourceIndex = this.rowsCache.indexOf(this.sourceRole, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);

      sourceIndex = this.rows.indexOf(this.sourceRole, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);

      this.editedRole = null;
      this.sourceRole = null;
    }
    else {
      let role = new Role();
      Object.assign(role, this.editedRole);
      this.editedRole = null;

      let maxIndex = 0;
      for (let r of this.rowsCache) {
        if ((<any>r).index > maxIndex)
          maxIndex = (<any>r).index;
      }

      (<any>role).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, role);
      this.rows.splice(0, 0, role);
      this.rows = [...this.rows];
    }
  }




  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.displayMode = DisplayModes.Loading;

    this.accountService.getRolesAndPermissions()
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.displayMode = DisplayModes.Success;

        let roles = results[0];
        let permissions = results[1];

        roles.forEach((role, index, roles) => {
          (<any>role).index = index + 1;
        });


        this.rowsCache = [...roles];
        this.rows = roles;

        this.allPermissions = permissions;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.displayMode = DisplayModes.Error;

          this.alertService.showStickyMessage("Load Error", `Unable to retrieve roles from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
            Error, error);
        });
  }


  onSearchChanged() {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(this.searchText, false, r.name, r.description));
  }


  onEditorModalHidden() {
    this.editingRoleName = null;
    this.roleEditor.resetForm(true);
  }


  newRole() {
    this.editingRoleName = null;
    this.sourceRole = null;
    this.editedRole = this.roleEditor.newRole();
    //this.editorModal.show();
    this.router.navigate(['role-editor']);
  }


  editRole(row: Role) {
  
    //this.editingRoleName = { name: row.name };
    //this.sourceRole = row;
    //this.editedRole = this.roleEditor.editRole(row, this.allPermissions);
    //this.editorModal.show();
    this.router.navigate(['role-editor', row.id]);

  }

  deleteRole(row: Role) {
    this.selectedRoleId = row;
    this.deleteModal.show();
    //this.alertService.showDialog('Are you sure you want to delete the \"' + row.name + '\" role?', Confirm, () => this.deleteRoleHelper(row));
  }


  deleteRoleHelper() {
    this.hideDeleteModal();
    this.alertService.startLoadingMessage("Deleting...");
    this.loadingIndicator = true;

    this.accountService.deleteRole(this.selectedRoleId)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== this.selectedRoleId)
        this.rows = this.rows.filter(item => item !== this.selectedRoleId)
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.displayMode = DisplayModes.Success;
          this.alertService.showStickyMessage("Delete Error", `An error occured whilst deleting the role.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
            Error1, error);
        });
  }


  get canManageRoles() {
    return this.accountService.userHasPermission(Permission.manageRolesPermission)
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
