import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../../../services/account.service';
import { HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../../../../models/enums';

@Component({
  selector: 'crm-provision-users',
  templateUrl: './provision-users.component.html',
  styleUrls: ['./provision-users.component.css']
})
export class ProvisionUsersComponent implements OnInit {
  progress: number=0;
  message: string;
  templateUrl: string;
  displayMode: DisplayModes = DisplayModes.Success;
  @ViewChild("provisionModal")
  provisionModal: ModalDirective;
  constructor(private readonly accountService: AccountService) { }

  ngOnInit() {
  }

  downloadTemplate() {
    this.accountService.downloadCsvTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "UsersTemplate.csv";
      anchor.href = url;
      anchor.click();
      //window.open(url);
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.displayMode = DisplayModes.Loading;
    this.accountService.uploadCsv(formData).subscribe(response => {
      debugger;
      let result = response as any;
      this.templateUrl = result.item2 as string;
      this.displayMode = DisplayModes.Success;
    });
  }

  provisionUsers() {
    this.displayMode = DisplayModes.Loading;
    this.accountService.provisionUsers(this.templateUrl).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        let result = event.body as any;

        if (result) {
          if (result.item1 == true)
            this.message = `Provisioned ${result.item2} of ${result.item3} Users`;
          this.displayMode = DisplayModes.Success;
        }
      }
      
      
    }, err => {
        this.displayMode = DisplayModes.Error;
    });
}


}
