import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Job } from '../models/job.model';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Injectable({
  providedIn: 'root'
})
export class JobService extends EndpointFactory{
  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService,
    injector: Injector) {
    super(http, configurations, injector);
  }


  searchJobs(searchText: string, parentId: number, pageNumber: number) {
    let myParams = new HttpParams()

      .set('searchText', searchText == undefined ? "" : searchText)
      .set('parentId', parentId == undefined ? "0" : parentId.toString())
      .set("pageNumber", pageNumber.toString());
    return this.http.get(`${this.configurations.apiAddress}/api/jobs/search`, { params: myParams });
  }

  saveJob(job: Job) {
    return this.http.post(`${this.configurations.apiAddress}/api/jobs/save`, job);
  }
  getAllJobs() {
    return this.http.get(`${this.configurations.apiAddress}/api/jobs/getAllJobs`);
  }
  getJobById(id: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/jobs/getJobById/${id}`);
  }
  deleteJob(id: number) {
    return this.http.post(`${this.configurations.apiAddress}/api/jobs/deleteJob/${id}`,null);
  }
}
