<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Activity Cost Center' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Activity Cost Center' | translate}}</h1>
  </div>
</div>


<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Activity Cost Center Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-3">
          <input type="text"
                 name="activityName"
                 [(ngModel)]="searchText"
                 placeholder="{{'clm.name' |translate}}"
                 #activityName="ngModel"
                 autocomplete="off"
                 class="form-control" />
        </div>
        <div class="col-sm-3" *ngIf="bussinesUnits && bussinesUnits.length>1">
          <span class="ui-fluid">
            <p-dropdown [options]="bussinesUnits"
                        name="bussinesUn" #bussinesUn="ngModel"
                        styleClass="wid100"
                        placeholder="Bussiness Units"
                        emptyFilterMessage="No results found"
                        showClear="true"
                        [(ngModel)]="bussinesUnit"
                        [virtualScroll]="true" itemSize="10"
                        [filter]="true" optionLabel="name"></p-dropdown>

          </span>
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="getActivityCostCenters()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-8 cursor-pointer" *ngIf="canManageActivityCostCenterPermission" (click)="gotoNew()">
          <span class="margin-right-7 create-new-span">Create New Cost Center</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-activity-cost-center *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [activityCostCenterId]="selectedActivityCostCenterId"></crm-add-activity-cost-center>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Activity Cost Center Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="activityCostCenters" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th style="text-align:center" [pSortableColumn]="name" class="table-edit">
                  {{'clm.name'| translate}}
                  <p-sortIcon [field]="name" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>

                <th width="15%" style="text-align:center">Amount</th>
                <th width="15%" style="text-align:center">Reserved</th>
                <th width="15%" style="text-align:center">Consumed</th>
                <th width="10%" style="text-align:center" *ngIf="canManageActivityCostCenterPermission">Edit</th>
                <th width="10%" style="text-align:center" *ngIf="canManageActivityCostCenterPermission">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-activity>
              <tr *ngIf="activityCostCenters.length>0 && displayMode==2">
                <td>{{ activity.name }}</td>
                <td>{{ activity.amount }}</td>
                <td>{{ activity.reserved }}</td>
                <td>{{ activity.consumed }}</td>
                <td *ngIf="canManageActivityCostCenterPermission">
                  <a class="" href="javascript:;" (click)="edit(activity.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td *ngIf="canManageActivityCostCenterPermission"><a href="javascript:;" (click)="confirmDelete(activity)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="activityCostCenters.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="canManageActivityCostCenterPermission">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2 cursor-pointer left-15" (click)="showUpdatenModal()">
    <span class="margin-right-7 edit-profile-span">Update Sheet</span>
    <i class="fa fa-upload" aria-hidden="true"></i>
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>


<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <crm-provision-cost-center [update]="updateModel"></crm-provision-cost-center>
    </div>
  </div>
</div>
