

export class Cycle  {

  
  constructor(_active: boolean) {
    this.active = _active;
    this.id = 0;
  }
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  expectedVisits: number;
  pmVisits: number;
  amVisits: number;
  objectives: string;
  active: boolean;
  
}
