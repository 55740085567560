<div class="row">
  <div class="col-sm-6">
    <span class="ui-fluid">
      <p-dropdown [options]="targetTypes"
                  styleClass="wid100"
                  placeholder="{{'Target type' | translate}}"
                  emptyFilterMessage="No results found"
                  [showClear]="false"
                  [(ngModel)]="targetType"
                  [filter]="false" optionLabel="name"></p-dropdown>

    </span>
  </div>
  <div class="col-sm-6">
    <span class="ui-fluid">
      <p-dropdown [options]="years"
                  styleClass="wid100"
                  placeholder="{{'Year' | translate}}"
                  emptyFilterMessage="No results found"
                  [showClear]="false"
                  [(ngModel)]="year"
                  [filter]="false" optionLabel="name"></p-dropdown>

    </span>
  </div>
</div>




<ul id="uidemo-tabs-default-demo" class="nav nav-tabs">
  <li class="active">
    <a href="#home" data-toggle="tab">{{'DownloadTemplate' |translate}}</a>
  </li>
  <li class="">
    <a href="#profile" data-toggle="tab">{{'UploadTemplate' |translate}}</a>
  </li>
</ul>

<div class="tab-content tab-content-bordered">
  <div class="tab-pane fade active in" id="home">
    <div class="image-upload cursor-pointer margin-top-5 margin-left-44" (click)="downloadTemplate()">
      <label for="file-download">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </label>
    </div>

  </div>
  <div class="tab-pane fade " id="profile">
    <div class="image-upload cursor-pointer margin-top-5 margin-left-44">
      <label for="file-upload_1">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </label>

      <input id="file-upload_1" #file type="file" (change)="upload(file.files)" />
    </div>

    <br />
    <!--<span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
    {{progress}}%
  </span>

  <span style="font-weight:bold;color:green;" *ngIf="message">
    {{message}}
  </span>-->

    <span style="font-weight:bold;color:blue;" *ngIf="message&&loading">
      {{message}}
    </span>

    <span style="font-weight:bold;color:red;" *ngIf="message&&error&&!loading">
      {{message}}
    </span>

    <span style="font-weight:bold;color:green;" *ngIf="message&&!error&&!loading">
      {{message}}
    </span>



  </div>

</div>

