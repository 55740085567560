import { Component, OnInit, ViewChild } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { HCPClassService } from './hcpClass.service';
import { DisplayModes } from '../../models/enums';
import { AppConfigService } from '../../app.config';
import { hcpClass } from '../../models/lookups/hcpClass.model';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'crm-hcp-class',
  templateUrl: './hcpClass.component.html',
  styleUrls: ['./hcpClass.component.css']
})
export class hcpClassComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  hcpClasses: hcpClass[] = [];
  hcpClassId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = require("../../assets/images/demo/HCP Class.png");
  @ViewChild('filterModal')
  filterModal: ModalDirective;
  @ViewChild('editModal')
  editModal: ModalDirective;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  addNew: boolean = false;
  editMode: boolean = false;
  selectedHcpClassId;
  constructor(private alertService: AlertService,
    private hcpClassService: HCPClassService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchHCPClass();
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'visitsPerPlan', header: 'Visits Per Plan' }
    ];
  }
  new() {
    this.router.navigate(['add-hcp-class']);
  }
  searchHCPClass() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.hcpClassService.Search(this.searchText).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Array<any>;
      console.log(result);
      this.hcpClasses = result;
      this.searchText = "";
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve item types from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchHCPClass();
  }

  gotoEdit(hcpClassId) {
    this.selectedHcpClassId = hcpClassId;
    this.editModal.show();
    this.editMode = true;
  }

  confirmDelete(hcpClassId) {
    this.selectedHcpClassId = hcpClassId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteHCPClass(hcpClassId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  deleteHCPClass() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.hcpClassService.Delete(this.selectedHcpClassId).subscribe(result => {
      this.displayMode = DisplayModes.Success;
      this.searchHCPClass();
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  openFilterModal() {
    this.filterModal.show();
  }

  searchFilterModal() {
    this.filterModal.hide();
    this.searchHCPClass();
  }

  hideFilterModal() {
    this.searchText = "";
    this.filterModal.hide();
  }

  showAddNew() {
    this.addNew = true;
  }

  closeAdding(event) {
    this.addNew = false;
    this.editMode = false;
    this.editModal.hide();
    if (event) {
      this.searchHCPClass();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}

