import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ItemService } from './item.service';

import { ProvisionItemsComponent } from "./provision-items/provision-items.component";
import { Router } from '@angular/router';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { Item } from '../../models/lookups/item.model';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { ItemPriceType } from '../../enums/ItemPriceType';


@Component({
  selector: 'crm-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  columns: any[] = [];
  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  displayMode: DisplayModes = DisplayModes.Success;
  itemPriceType = ItemPriceType;
  items: Item[] = [];
  public logo = require("../../../app/assets/images/demo/Icon_Items.png");

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Items List :',
    useBom: true,
    noDownload: false,
    headers: ["ID", "Name", "BrandName", "PublicPrice", "XFactorPrice", "TenderPrice", "ExportPrice", "AveragePrice", "Category", "ItemFormat"]
  };

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("provisionItems")
  provisionItems: ProvisionItemsComponent;

  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  addNew: boolean = false;
  selectedItemId;
  selectedPriceType;
  itemPriceMode: boolean = false;
  itemBusinessunitMode: boolean = false;
  constructor(private alertService: AlertService,
    private itemService: ItemService,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) {
  }

  ngOnInit() {
    this.searchItems();

    this.columns = [

      { field: 'name', header: 'Name' },
      { field: 'brandName', header: 'Brand Name' },
      { field: 'publicPrice', header: 'Public Price' },
      { field: 'xFactorPrice', header: 'XFactor Price' },
      { field: 'tenderPrice', header: 'Tender Price' },
      { field: 'exportPrice', header: 'Export Price' },
      { field: 'averagePrice', header: 'Average Price' },
      { field: 'itemFormatName', header: 'Item Format' },
      { field: 'itemTypeName', header: 'Item Category' },
      { field: 'businessUnitName', header: 'Business Unit' },
      { field: 'isActive', header: 'Active' }
    ];

  }

  searchItems() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.itemService.Search(this.search.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      let result = response as Page;
      this.items = result.items;
      this.items.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      })

      //this.totalItems = result.totalItems;

    }, err => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchItems();
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['add-item']);
  }


  gotoEdit(itemId) {
    this.selectedItemId = itemId;
    this.addNew = true;
  }

  confirmDeleteItem(itemId) {
    this.selectedItemId = itemId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(itemId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemService.Delete(this.selectedItemId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);

      this.searchItems();
    }, err => {
      this.displayMode = DisplayModes.Success;

      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }

  downloadCSV() {
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    const csvData = this.items.map(o => {
      return {
        id: o.id, name: o.name, brandName: o.itemBrandName, publicPrice: o.publicPrice, xFactorPrice: o.xFactorPrice,
        tenderPrice: o.tenderPrice, exportPrice: o.exportPrice, averagePrice: o.averagePrice, itemType: o.itemTypeName, itemFormat: o.itemFormatName
      };

    });
    new AngularCsv(csvData, "ItemsList", this.csvOptions);
  }

  showProvisionModal() {
    this.provisionModal.show();
  }

  onProvisionModalHidden() {

  }

  closeAdding(event) {
    this.selectedItemId = undefined;
    this.addNew = false;
    this.itemPriceMode = false;
    this.itemBusinessunitMode = false;
    if (event) {
      this.searchItems();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  getItemPrices(item, priceType) {
    this.selectedItemId = item.id;
    this.selectedPriceType = priceType;
    this.itemPriceMode = true;
  }

  getBusinessUnit(item, priceType) {
    this.selectedItemId = item.id;
    this.itemBusinessunitMode = true;
  }

}
