<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'User Jobs' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-9">
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="newUserJob()">
          <span class="margin-right-7 create-new-span">Create New Job</span>
          <i class="fa fa-plus-circle"></i>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-12">

          <p-table [value]="userJobs" [responsive]="true">
            <ng-template pTemplate="header">
              <tr>
                <th [pSortableColumn]="'userName'" class="table-edit">
                  User Name
                  <p-sortIcon [field]="'userName'" class="table-edit"></p-sortIcon>

                </th>
                <th [pSortableColumn]="'jobName'" class="table-edit">
                  Job
                  <p-sortIcon [field]="'jobName'"></p-sortIcon>

                </th>
                <th [pSortableColumn]="'startDate'" class="table-edit">
                  Start Date
                  <p-sortIcon [field]="'startDate'"></p-sortIcon>
                </th>
                <th class="table-edit">End Date</th>
                <th class="table-edit">Is Current</th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-job>
              <tr *ngIf="userJobs.length>0 && displayMode===2">
                <td>
                  {{job.userName}}
                </td>
                <td>
                  {{job.jobName}}
                </td>
                <td>{{job.startDate |date :'dd-MM-yyy'}}</td>
                <td>{{job.endDate |date :'dd-MM-yyy'}}</td>
                <td>
                  <span *ngIf="job.isCurrent" class="fa fa-2x fa-check-circle"></span>
                  <span *ngIf="!job.isCurrent" class="fa fa-2x fa-times-circle"></span>
                </td>
                <td>
                  <a class="" href="javascript:;" (click)="editJob(job)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDeleteUserJob(job.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="userJobs.length===0 && displayMode===2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>

                  </div>

                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
        <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;

      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="displayMode==3">
  <div class="col-sm-12">
    <div class="alert alert-danger alert-dark">
      <button type="button" class="close" data-dismiss="alert">×</button>
      {{'ServerError' |translate}}.
    </div>
  </div>
</div>


<div class="modal fade" bsModal #deleteJobModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteUserJob()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-sizes-1" bsModal #userJobModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'User Job' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="userJobModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <crm-save-user-job #userJobEditor [userId]="userId"></crm-save-user-job>
    </div>
  </div>
</div>
