
<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Cycles' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Cycles' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title">{{'pageHeader.cyclesBUSpeciality' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">

        <div class="row padding-sm-vr">
          <div class="col-sm-6">

            <label class="control-label">{{'Cycle' | translate}} :</label>&nbsp;
            <label class="label label-primary">{{cycle.name}}</label>

          </div>

        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <label class="control-label">{{'Business Unit' | translate}} </label>
            <span class="ui-fluid">
              <p-dropdown [options]="businessUnits"
                          styleClass="wid100"
                          placeholder=" {{'BusinessUnit'| translate}}"
                          emptyFilterMessage="No results found"
                          showClear="true"
                          name="businessUnitId" #businessUnitId="ngModel"
                          [(ngModel)]="selectedBusinessUnit" baseZIndex="9999"
                          [filter]="true" optionLabel="name"></p-dropdown>
            </span>
          </div>
          <div style="padding-top:25px;" class="col-sm-6">
            <div class="pull-right">
              <button class="btn btn-labeled btn-success" *ngIf="selectedBusinessUnit!==null" type="button" (click)="addBusinessUnit()"><span class="btn-label icon fa fa-plus"></span>{{'Add' | translate}}</button>
            </div>
          </div>
        </div>

        <div *ngFor="let item of cycleBusinessUnits">
          <div class="row padding-sm-vr">
            <div class="col-sm-6">

              <label class="control-label">{{'BusinessUnit' | translate}} :</label>&nbsp;
              <label class="label label-primary">{{item.businessUnitName}}</label>

            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div [ngClass]="{'has-success': (cycleBuSpecialities.dirty || cycleBuSpecialities.touched || f.submitted)  && cycleBuSpecialities.valid, 'has-error' : (cycleBuSpecialities.dirty || cycleBuSpecialities.touched || f.submitted)  && cycleBuSpecialities.invalid}">
                <label class="control-label">{{'Business Unit Specialities' | translate}} </label>
                <ng-multiselect-dropdown style="z-index:9999;" [placeholder]="'Business Unit Specialities'"
                                         [data]="item.specialities"
                                         name="cycleBuSpecialities"
                                         #cycleBuSpecialities="ngModel"
                                         [(ngModel)]="item.selectedSpecialities"
                                         [settings]="dropdownSettings">
                </ng-multiselect-dropdown>


                <div *ngIf="(cycleBuSpecialities.dirty || cycleBuSpecialities.touched || f.submitted)  && cycleBuSpecialities.invalid" class="jquery-validate-error help-block">
                  {{'RequiredField' | translate}}
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div [ngClass]="{'has-success': (cycleBricks.dirty || cycleBricks.touched || f.submitted)  && cycleBricks.valid, 'has-error' : (cycleBricks.dirty || cycleBricks.touched || f.submitted)  && cycleBricks.invalid}">
                <label class="control-label">{{'Business Unit Bricks' | translate}} </label>
                <ng-multiselect-dropdown [placeholder]="'Cycle Bricks'"
                                         [data]="bricks"
                                         name="cycleBricks"
                                         #cycleBricks="ngModel"
                                         [(ngModel)]="item.bricks"
                                         [settings]="dropdownSettings">
                </ng-multiselect-dropdown>


                <div *ngIf="(cycleBricks.dirty || cycleBricks.touched || f.submitted)  && cycleBricks.invalid" class="jquery-validate-error help-block">
                  {{'RequiredField' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Back' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" [disabled]="isSaving || isLoading">{{'Save' | translate}}</button>

        </div>
      </form>
    </div>
  </div>
</div>


<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>


