import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class WorkingDaysService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {

  }

  Save(id: number) {
    return this.http.post(this.configurations.apiAddress + "/api/WorkingDay/save/", id);
  }

  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/WorkingDay/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/WorkingDay/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/WorkingDay/getById/' + id);
  }

  AmCheck(id: number) {   
    return this.http.get(this.configurations.apiAddress + '/api/WorkingDay/AmCheck/' + id);
  }

  PmCheck(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/WorkingDay/PmCheck/' + id);
  }

}
