import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class OrganizationJobStructureService {
  constructor(private http: HttpClient,
    private configurations: ConfigurationService) {
  }

  GetAllJobs() {
    return this.http.get(this.configurations.apiAddress + "/api/Jobs/getAllJobs");
  }

  GetAllBussinessUnit() {
    return this.http.get(this.configurations.apiAddress + "/api/BusinessUnit/getAll");
  }

  GetAllOrgJobStructure() {
    return this.http.get(this.configurations.apiAddress + "/api/OrganizationJobStructure/getAll");
  }

  Save(item) {
    return this.http.post(this.configurations.apiAddress + "/api/OrganizationJobStructure/save", item);
  }

  Search(searchText: string, directManager:string, pageNumber: number) {
    debugger;
    let myParams = new HttpParams()
      .set('searchText', searchText === undefined ? "" : searchText)
      .set('directManager', directManager === undefined ? "" : directManager)

      .set("pageNumber", pageNumber.toString());
    return this.http.get(`${this.configurations.apiAddress}/api/OrganizationJobStructure/search`, { params: myParams });
  }

  getAllTree(searchText: string, directManager: string, pageNumber: number) {
    debugger;
    let myParams = new HttpParams()
      .set('searchText', searchText === undefined ? "" : searchText)
      .set('directManager', directManager === undefined ? "" : directManager)

      .set("pageNumber", pageNumber.toString());
    return this.http.get(`${this.configurations.apiAddress}/api/OrganizationJobStructure/getAllTree`, { params: myParams });
  }

  getChilds(parentId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/OrganizationJobStructure/getChilds/${parentId}`);

  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/OrganizationJobStructure/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/OrganizationJobStructure/getById/' + id);
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/OrganizationJobStructure/downloadExcelTemplate", { responseType: "blob" });
  }
  provisionFromxcel(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/OrganizationJobStructure/provisionFromExcel`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  updateFromExcel(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/OrganizationJobStructure/updateFromExcel`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  saveOrgJobStrBusinessunits(jobId: number, businessUnitDtos) {
    return this.http.post(this.configurations.apiAddress + `/api/OrganizationJobStructure/saveOrgJobStrBusinessunits/${jobId}`, businessUnitDtos);
  }

  getOrgJobStrBusinessunits(jobId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/OrganizationJobStructure/getOrgJobStrBusinessunits/${jobId}`);
  }


  getOrganizationJobStructureBusinessUnitDates(organizationJobStructureId) {
    return this.http.get(this.configurations.apiAddress + '/api/OrganizationJobStructure/getOrganizationJobStructureBusinessUnitDates/' + organizationJobStructureId);
  }

  deleteOrganizationJobStructureBusinessUnitDates(organizationJobStructureBusinessUnitDatesId: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/OrganizationJobStructure/deleteOrganizationJobStructureBusinessUnitDates/' + organizationJobStructureBusinessUnitDatesId);
  }

  saveOrganizationJobStructureBusinessUnitDates(organizationJobStructureBusinessUnitDates) {
    return this.http.post(this.configurations.apiAddress + "/api/OrganizationJobStructure/saveOrganizationJobStructureBusinessUnitDates", organizationJobStructureBusinessUnitDates);
  }

  getOrganizationJobStructureTargetDates(organizationJobStructureId, targetType) {
    return this.http.get(this.configurations.apiAddress + '/api/OrganizationJobStructure/getOrganizationJobStructureTargetDates/' + organizationJobStructureId + '/' + targetType);
  }

  deleteOrganizationJobStructureTargetDates(organizationJobStructureTargetDatesId: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/OrganizationJobStructure/deleteOrganizationJobStructureTargetDates/' + organizationJobStructureTargetDatesId);
  }

  saveOrganizationJobStructureTargetDates(organizationJobStructureTargetDates) {
    return this.http.post(this.configurations.apiAddress + "/api/OrganizationJobStructure/saveOrganizationJobStructureTargetDates", organizationJobStructureTargetDates);
  }

  downloadCSVTargetDateTemplate(targetType, year): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/OrganizationJobStructure/downloadCSVTargetDateTemplate/" + targetType + "/" + year, { responseType: "blob" });
  }

  //provisionCSVTargetDateTemplate(formData, targetType, year) {
  //  const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/OrganizationJobStructure/provisionCSVTargetDateTemplate?targetType=` + targetType + `&year=` + year, formData, {
  //    reportProgress: true,
  //  });
  //  return this.http.request(uploadReq);
  //}

  provisionCSVTargetDateTemplate(formData, targetType, year) {
    return this.http.post(this.configurations.apiAddress + "/api/OrganizationJobStructure/provisionCSVTargetDateTemplate?targetType=" + targetType + `&year=` + year, formData);

  }

}
