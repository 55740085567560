import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AppConsts } from '../shared/app-consts';
import { PlatformLocation } from '@angular/common';


@Injectable()
export class AppConfigService {


  constructor(private http: HttpClient,
    private _platformLocation: PlatformLocation) { }


  public get settings() {
    return { pageSize: AppConsts.pageSize, apiUrl: AppConsts.apiUrl };
  }

}
