<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Jobs' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Jobs' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Jobs Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="name"
                 [(ngModel)]="search.searchText"
                 placeholder="{{'Name' |translate}}"
                 #name="ngModel"
                 class="form-control" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="searchJobs()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-8 cursor-pointer" (click)="goToNew()">
          <span class="margin-right-7 create-new-span">Create New Job</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-save-job *ngIf="addNew" (closeEvent)="closeAdding($event)" [jobId]="selectedJobId"></crm-save-job>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Jobs Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <table class='table  table-bordered'>
            <thead style="background-color: #e0dede94; ">
              <tr>
                <!--<th>{{'Code' | translate}}</th>-->
                <th class="table-edit" style="text-align:center" width="40%">{{'Name' | translate}}</th>
                <th class="table-edit" style="text-align:center">{{'Parent' | translate}}</th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </thead>
            <tbody *ngIf="jobs.length>0 && displayMode==2">
              <tr *ngFor="let item of jobs">
                <!--<td>{{ item.id }}</td>-->
                <td width="40%">{{ item.name }}</td>
                <td>{{ item.parentJobName }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(item.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDeleteJob(item)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>

            </tbody>
            <tbody *ngIf="jobs.length==0 && displayMode==2">
              <tr>
                <td colspan="4">{{'NoDataFound' | translate}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="displayMode==1">
              <tr>
                <td colspan="3">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>

                  </div>

                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
      <div class="row" *ngIf="totalItems>pageSize">
        <div class="col-sm-12">
          <pagination [totalItems]="totalItems"
                      [itemsPerPage]="pageSize"
                      [(ngModel)]="currentPage"
                      (pageChanged)="pageChanged($event)"
                      [boundaryLinks]="true"
                      [rotate]="false"
                      previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteJob()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
