import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { HelperMethodsService } from '../../../services/helper-methods.service';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { OrganizationJobStructureService } from '../organization-job-structure.service';

@Component({
  selector: 'crm-save-org-job-str-business-unit-dates',
  templateUrl: './save-org-job-str-business-unit-dates.component.html',
  styleUrls: ['./save-org-job-str-business-unit-dates.component.css']
})
export class SaveOrgJobStrBusinessUnitDatesComponent implements OnInit {

  businessUnits: BusinessUnit[] = [];
  orgJobStrBusinessUnit: any = {};
  displayMode: DisplayModes = DisplayModes.Success;
  startDate: any;
  endDate: any;
  public changesCallback: () => void;

  constructor(private readonly organizationJobStructureService: OrganizationJobStructureService,
    private readonly alertService: AlertService, private readonly businessUnitService: BusinessUnitService,
    private readonly helperMethods: HelperMethodsService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getBusinessUnits();
  }

  close() {
    this.changesCallback();
  }

  new(orgJobStrId: number) {
    this.orgJobStrBusinessUnit = {};
    this.startDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    };
    this.endDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 10
    };
    this.orgJobStrBusinessUnit.organizationJobStructureId = orgJobStrId;
    this.orgJobStrBusinessUnit.isCurrent = false;
  }

  edit(orgJobStrBusinessUnit) {
    this.orgJobStrBusinessUnit = orgJobStrBusinessUnit;
    let sDate = new Date(this.orgJobStrBusinessUnit.startDate);
    this.startDate = {
      year: sDate.getFullYear(),
      month: sDate.getMonth() + 1,
      day: sDate.getDate()
    };

    if (orgJobStrBusinessUnit.businessUnitId)
      this.orgJobStrBusinessUnit.businessUnit = this.businessUnits.find(x => x.id == orgJobStrBusinessUnit.businessUnitId);

    if (this.orgJobStrBusinessUnit.endDate !== null) {
      this.endDate = {
        year: new Date(this.orgJobStrBusinessUnit.endDate).getFullYear(),
        month: new Date(this.orgJobStrBusinessUnit.endDate).getMonth() + 1,
        day: new Date(this.orgJobStrBusinessUnit.endDate).getDate()
      }
    }

  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

      this.orgJobStrBusinessUnit.startDate = this.helperMethods.convertDateToUTC(new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day + 1));

      if (this.endDate !== undefined)
        this.orgJobStrBusinessUnit.endDate = this.helperMethods.convertDateToUTC(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day + 1));

      if (this.orgJobStrBusinessUnit.businessUnit)
        this.orgJobStrBusinessUnit.businessUnitId = this.orgJobStrBusinessUnit.businessUnit.id;

      this.organizationJobStructureService.saveOrganizationJobStructureBusinessUnitDates(this.orgJobStrBusinessUnit).subscribe(response => {
        this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
        this.changesCallback();
      }, () => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showMessage("", this.translateService.getTranslation("FialedToSave"), MessageSeverity.Error);
        this.changesCallback();
      });
    }
  }

  getBusinessUnits() {
    this.businessUnitService.getAllBusinessUnits().subscribe(response => {
      this.businessUnits = response as BusinessUnit[];
    }, error => { });
  }


}
