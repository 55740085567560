import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from '../../../services/alert.service';
import { OrganizationJobStructureService } from '../organization-job-structure.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'crm-provision-organization-job-structure',
  templateUrl: './provision-organization-job-structure.component.html',
  styleUrls: ['./provision-organization-job-structure.component.css']
})
export class ProvisionOrganizationJobStructureComponent implements OnInit {
  progress: number;
  message: string;
  @Input() update;

  constructor(private alertService: AlertService,
    private orgJobStrService: OrganizationJobStructureService,
    private translateService: AppTranslationService,
    private router: Router) { }
  ngOnInit() {
  }
  downloadTemplate() {
    this.orgJobStrService.downloadCsvTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "OrganizationJobStructure.csv";
      anchor.href = url;
      anchor.click();
      //window.open(url);
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }
  upload(files) {
    if (files.length === 0)
      return;
    const formData = new FormData();
    for (let file of files)
      formData.append(file.name, file);
    if (this.update) {
      this.orgJobStrService.updateFromExcel(formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = "uploaded success";
          //this.provisionModal.hide();
        }
      });
    }
    else {
      this.orgJobStrService.provisionFromxcel(formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = "uploaded success";
          //this.provisionModal.hide();
        }
      });
    }
  }
}
