<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!userId">Create {{'settings.header.UsersManagements' | translate}}</span>
    <span class="panel-title" *ngIf="userId">Edit {{'settings.header.UsersManagements' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!userId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (jobId.dirty || jobId.touched || f.submitted)  && jobId.valid, 'has-error' : (jobId.dirty || jobId.touched || f.submitted)  && jobId.invalid}">
              <label class="control-label" for="jobTitle-{{uniqueId}}">{{'users.editor.JobTitle' | translate}}</label>
              <span class="ui-fluid">
                <p-dropdown [options]="jobs"
                            styleClass="wid100"
                            placeholder="User Job"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [disabled]="!isNewUser"
                            name="jobId" #jobId="ngModel"
                            required="required" [(ngModel)]="userEdit.job"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(jobId.dirty || jobId.touched || f.submitted)  && jobId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


          <div class="col-sm-6">
            <div class="" [ngClass]="{'has-success': (userName.dirty || userName.touched || f.submitted)  && userName.valid, 'has-error' : (userName.dirty || userName.touched || f.submitted)  && userName.invalid}">

              <label class="control-label" for="userName-{{uniqueId}}">{{'users.editor.UserName' | translate}}</label>

              <input type="text" attr.id="userName-{{uniqueId}}" name="userName" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter user name"
                     class="form-control" [(ngModel)]="userEdit.userName" #userName="ngModel" required minlength="2" maxlength="200" />


              <div *ngIf="(userName.dirty || userName.touched || f.submitted)  && userName.invalid" class="jquery-validate-error help-block">
                {{'users.editor.UserNameRequired' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">

            <div>
              <label class="control-label" for="hrCode-{{uniqueId}}">{{'Hr Code' | translate}}</label>

              <input type="text" attr.id="hrCode-{{uniqueId}}" name="hrCode" placeholder="Enter hr code" class="form-control" [(ngModel)]="userEdit.hrCode" />
            </div>
          </div>



          <div class="col-sm-6">

            <div class="input-group date">
              <label class="control-label" for="hiringDate-{{uniqueId}}">{{'Hiring Date' | translate}}</label>
              <input class="form-control" placeholder="yyyy-mm-dd"
                     name="hiringDate" hiringDate="ngModel"
                     [(ngModel)]="hiringdate" ngbDatepicker #d="ngbDatepicker">
              <span class="input-group-btn">
                <button class="btn" (click)="d.toggle()" type="button" style="margin-top:27px;">
                  <i class="fa fa-calendar"></i>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">

            <div [ngClass]="{'has-success': (email.dirty || email.touched || f.submitted)  && email.valid, 'has-error' : (email.dirty || email.touched || f.submitted)  && email.invalid}">

              <label class="control-label" for="email-{{uniqueId}}">{{'users.editor.Email' | translate}}</label>

              <input type="text" attr.id="email-{{uniqueId}}" name="email" placeholder="Enter email address" class="form-control" [(ngModel)]="userEdit.email" #email="ngModel"
                     required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />

              <span *ngIf="(email.dirty || email.touched || f.submitted)  && email.invalid && email.errors?.pattern" class="jquery-validate-error help-block">
                {{'users.editor.InvalidEmail' | translate}}
              </span>

              <div *ngIf="(email.dirty || email.touched || f.submitted)  && email.errors?.required" class="jquery-validate-error help-block">
                {{'users.editor.EmailRequired' | translate}}
              </div>
            </div>
          </div>


          <div *ngIf="canAssignRoles" class="col-sm-6">

            <div [ngClass]="{'has-success': (roles.dirty || roles.touched || f.submitted) && roles.valid, 'has-error' : (roles.dirty || roles.touched || f.submitted)  && roles.invalid}">

              <label class="control-label" for="roles-user-info">{{'users.editor.Roles' | translate}}</label>
              <ng-multiselect-dropdown [placeholder]="'User Roles'"
                                       [data]="allRoles"
                                       name="roles"
                                       #roles="ngModel"
                                       [(ngModel)]="userEdit.roles"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>
              <div *ngIf="(roles.touched || f.submitted)  && roles.errors?.required" class="jquery-validate-error help-block">
                {{'users.editor.RoleRequired' | translate}}
              </div>

            </div>
          </div>


        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">

            <div>
              <label class="control-label" for="fullName-{{uniqueId}}">{{'users.editor.FullName' | translate}}</label>

              <input type="text" attr.id="fullName-{{uniqueId}}" name="fullName" placeholder="Enter full name" class="form-control" [(ngModel)]="userEdit.fullName" />
            </div>
          </div>



          <div class="col-sm-6">

            <div>
              <label class="control-label" for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber' | translate}}</label>

              <input type="text" attr.id="phoneNumber-{{uniqueId}}" name="phoneNumber" placeholder="Enter phone number" class="form-control" [(ngModel)]="userEdit.phoneNumber" />
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-12">
            <div class="col-sm-5">
              <div class="pull-left">
                <div class="checkbox user-enabled">
                  <label>
                    <input type="checkbox" name="isEnabled" [(ngModel)]="userEdit.isEnabled">
                    {{'users.editor.Enabled' | translate}}
                  </label>
                </div>
                <button *ngIf="userEdit.isLockedOut" type="button" (click)="unlockUser()" class="btn btn-warning unblock-user" [disabled]="isSaving"><i class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
              </div>
            </div>

          </div>

        </div>
        <div class ="row padding-sm-vr">
          <div class="col-sm-12">
            <div class="col-sm-5">
              <div class="pull-left">
                <div class="checkbox user-enabled">
                  <label>
                    <input type="checkbox" name="isGpsEnabled" [(ngModel)]="userEdit.isGpsEnabled">
                    {{'GPS Enabled' | translate}}
                  </label>
                </div>
              </div>
            </div>

          </div>
    </div>
    <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
      <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
      <button class="btn  btn-save" type="submit" [disabled]="isSaving">{{'Save' | translate}}</button>

    </div>
    </form>
  </div>
</div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>
