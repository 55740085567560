<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">Add {{'Bricks' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">

      <div class="row padding-sm-vr">
        <div class="col-sm-6">
          <label class="control-label">{{'SearchText' | translate}} </label>
          <input type="text"
                 name="searchText"
                 [(ngModel)]="searchText"
                 (input)="searchBricks()"
                 placeholder="{{'SearchText' |translate}}"
                 #name="ngModel"
                 class="form-control" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="bricks" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize" [resizableColumns]="true">
            <ng-template pTemplate="header">
              <tr>
                <th width="10%" class="cursor-pointer"></th>
                <th  width="10%" >{{'Id' |translate}}</th>
                <th width="15%">Brick Name</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-brick>
              <tr *ngIf="bricks.length>0">
                <td>
                  <p-checkbox [(ngModel)]="selectedBricks" name="chkbr"
                              inputId="{{brick.name}}"
                              value="{{brick.id}}" label=""></p-checkbox>
                </td>
                <td>{{ brick.id }}</td>
                <td>
                  {{brick.name}}
                </td>

              </tr>
              <tr *ngIf="bricks.length==0">
                <td colspan="3">{{'NoDataFound' | translate}}</td>
              </tr>

            </ng-template>
          </p-table>
        </div>

      </div>

      <div class="panel-footer margin-left-20 margin-top-20 margin-left-30">
        <button class="btn  btn-cancel" type="button" (click)="back()">{{'Back' | translate}}</button>&nbsp;
        <button class="btn  btn-save" type="button" (click)="save()">
          {{'Save' | translate}}
        </button>
      </div>

    </div>
  </div>
</div>

