import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { holidayService } from '../holiday.service';
import { holiday } from '../../../models/lookups/holiday.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';




@Component({
  selector: 'crm-add-holiday',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.css']
})
export class AddHolidayComponent implements OnInit {
  holiday: holiday = new holiday();
  minStartDate: Date = new Date();
  //holidayid: number;
  displayMode: DisplayModes = DisplayModes.Success;
  en: any;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() holidayid: number;
  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private holidayService: holidayService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy'
    };

    if (this.holidayid != undefined)
      this.getDetails(this.holidayid);
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.holidayService.Save(this.holiday).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.holiday.id == 0)
          this.holiday = new holiday();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.holidayService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.holiday = response as holiday;
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  } 
}
