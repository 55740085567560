import { ActivityGroup } from "./activity-group.model";
import { BusinessUnit } from "./business-unit.model";
import { ItemType } from "./itemType.model";
import { Job } from "./job.model";


export class OrganizationJobStructure {
  constructor() {
    this.targetAm = 0;
    this.targetPm = 0;
    this.specificTargetAm = 0;
    this.specificTargetPm = 0;
    this.pharmacyTarget = 0;
    this.isActive = true;
  }

  id: number;
  name: string;
  businessUnitId: number;
  businessUnitName: string;
  directManagerId: number;
  directManagerName: string;
  jobId: number;
  jobName: string;
  isActive: boolean;
  targetAm: number;
  targetPm: number;
  specificTargetAm: number;
  specificTargetPm: number; 
  businessUnit: BusinessUnit;
  job: Job;
  directManager: OrganizationJobStructure;
  userName: string;
  userNameTrimmed: string;
  pharmacyTarget: number;
  applySales: boolean;
  activityGroups: OrganizationJobStructureActivityGroup[];
  itemTypes: OrganizationJobStructureItemType[];
  orgActivityGroups: ActivityGroup[];
  orgItemTypes: ItemType[];
  endDate?: Date;
  isFieldTrainer: boolean;
}

export class OrganizationJobStructureActivityGroup {
  activityGroupId: number;
}

export class OrganizationJobStructureItemType {
  itemTypeId: number;
}
