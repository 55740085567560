import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ActivityCostCenterService } from '../activity-cost-center.service';

@Component({
  selector: 'crm-provision-cost-center',
  templateUrl: './provision-cost-center.component.html',
  styleUrls: ['./provision-cost-center.component.css']
})
export class ProvisionCostCenterComponent implements OnInit {

  error: boolean;
  message: string;
  loading: boolean;
  @ViewChild("provisionModal")
  provisionModal: ModalDirective;
  @Input() update;
  constructor(private readonly activityCostCenterService: ActivityCostCenterService) { }

  ngOnInit() {
  }

  downloadCSVTemplate() {
    this.activityCostCenterService.downloadCsvTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "CostCenterTemplate.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.loading = true;
    this.error = false;
    this.message = "Loading .....";
    if (this.update) {
      this.activityCostCenterService.updateFromCSV(formData).subscribe(result => {
        this.loading = false;
        let res = result as any;
        if (res.isError) {
          this.error = true;
          this.message = "uploaded with error";
          this.downloadCSVTemplatErrorFile();
        }
        else
          this.message = "uploaded success";
      });
    }
    else {
      this.activityCostCenterService.uploadCsvTemplate(formData).subscribe(result => {
        this.loading = false;
        let res = result as any;
        if (res.isError) {
          this.error = true;
          this.message = "uploaded with error";
          this.downloadCSVTemplatErrorFile();
        }
        else
          this.message = "uploaded success";
      });
    }

  }

  downloadCSVTemplatErrorFile() {
    this.activityCostCenterService.downloadErrorFile().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "Error.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);

    },
      err => {
        console.log(err);
      });
  }

}
