import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class ActivityTypeService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  // Call a Save method from ActivityType API
  Save(itemType) {
    return this.http.post(this.configurations.apiAddress + "/api/RepActivityType/save", itemType);
  }

  // Call a Search method from ActivityType API
  Search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText);
    return this.http.get(this.configurations.apiAddress + '/api/RepActivityType/search', { params: myParams });
  }

  // Call a delete method from ActivityType API using HttpClient
  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/RepActivityType/delete/' + id);
  }

  // Call a GetById method from ActivityType API using HttpClient
  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/RepActivityType/getById/' + id);
  }

}
