import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';



import { BusinessUnitService } from './business-unit.service';


import { TreeNode } from 'primeng/api';
import { ModalDirective } from 'ngx-bootstrap';
import { ProvisionBusinessUnitsComponent } from './provision-business-units/provision-business-units.component';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { BusinessUnitType } from '../models/business-unit-type';
import { BusinessUnitTypeService } from '../services/business-unit-type.service';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppConfigService } from '../../app.config';
import { BusinessUnit, BusinessUnitTree } from '../../models/lookups/business-unit.model';
import { AppTranslationService } from '../../services/app-translation.service';


@Component({
  selector: 'crm-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.css']
})
export class BusinessUnitComponent implements OnInit {

  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 3;

  displayMode: DisplayModes = DisplayModes.Success;

  businessUnits: TreeNode[] = [];

  allBusinessUnits: BusinessUnitTree[] = [];
  businessUnitTypes: BusinessUnitType[] = [];
  businessUnitType: BusinessUnitType;
  public logo = require("../../../app/assets/images/demo/Icon_Business Units.png");

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("provisionBusinessUnits")
  provisionBusinessUnits: ProvisionBusinessUnitsComponent;

  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  addNew: boolean = false;
  manageMode: boolean = false;
  selectedBusinessUnitId;

  constructor(private readonly alertService: AlertService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly businessUnitTypeService: BusinessUnitTypeService,
    private readonly router: Router) {
  }

  ngOnInit() {
    this.getAllBusinessUnitTypes();
    this.searchBusinessUnits();
    this.pageSize = this.appConfig.settings.pageSize;
  }

  searchBusinessUnits() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    let buTypeId = 0;
    if (this.businessUnitType !== undefined && this.businessUnitType !== null)
      buTypeId = this.businessUnitType.id;

    this.businessUnitService.getAllBusinessUnitsTree(buTypeId)
      .subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        let result = response as TreeNode[];
        result.forEach(x => { x.expanded = true; x.children.forEach(y => { y.expanded = true; y.children.forEach(z => z.expanded = true) }) });
        this.businessUnits = result;
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchBusinessUnits();
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['business-unit']);
  }


  gotoEdit(businessUnitId) {
    this.selectedBusinessUnitId = businessUnitId;
    this.addNew = true;
    //this.router.navigate(['business-unit', businessUnitId]);
  }
  onProvisionModalHidden() {
    this.searchBusinessUnits();
  }
  confirmDelete(businessUnitId) {
    this.selectedBusinessUnitId = businessUnitId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(businessUnitId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.businessUnitService.Delete(this.selectedBusinessUnitId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);

      this.searchBusinessUnits();
    }, () => {
      this.displayMode = DisplayModes.Success;

      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }
  showProvisionModal() {
    this.provisionModal.show();
  }

  downloadCSV() {
    this.displayMode = DisplayModes.Loading;
    let buTypeId = 0;
    if (this.businessUnitType !== undefined && this.businessUnitType !== null)
      buTypeId = this.businessUnitType.id;
    this.businessUnitService.getAllBusinessUnitsTree(buTypeId).subscribe(response => {
      this.allBusinessUnits = response as BusinessUnitTree[];
      let bus: BusinessUnit[] = [];
      this.allBusinessUnits.forEach(x => {
        bus.push(x.data);
        if (x.children)
          x.children.forEach(y => {
            bus.push(y.data);
            if (y.children)
              y.children.forEach(z => {
                bus.push(z.data);
              })
          })
      })
      const csvData = bus.map(o => {
        return { id: o.id, name: o.name, businessUnitTypeName: o.businessUnitTypeName, parentName: o.parentName };
      });
      new AngularCsv(csvData, "BusinessUnits", this.csvOptions);
      this.displayMode = DisplayModes.Success;

    }, err => { });

  }

  getAllBusinessUnitTypes() {
    this.businessUnitTypeService.getAllTypes().subscribe(response => {
      let result = response as BusinessUnitType[];
      this.businessUnitTypes = result;
    }, err => {
      console.log(err);
    });
  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Business Units List :',
    useBom: true,
    noDownload: false,
    headers: ["ID", "Name", "Business Unit Type", "Parent Business Unit"]
  };

  manageBusinessUnitBricks(id) {
    this.selectedBusinessUnitId = id;
    this.manageMode = true;
    //this.router.navigate(["manage-business-unit-bricks", id]);
  }

  closeAdding(event) {
    this.selectedBusinessUnitId = undefined;
    this.addNew = false;
    this.manageMode = false;
    if (event) {
      this.searchBusinessUnits();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
