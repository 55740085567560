import { AttachmentFile } from "../expenses/models/attachement-file.model";
import { OrganizationJobStructure } from "./lookups/organizationJobStructure.model";

export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string, profilePicFileId?: string, roles?: string[]) {

    this.id = id;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.profilePicFileId = profilePicFileId;
  }


  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle)
      name = this.jobTitle + " " + name;

    return name;
  }


  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isGpsEnabled: boolean;
  public isLockedOut: boolean;
  public roles: string[];
  public job: OrganizationJobStructure;
  public jobId?: number;
  public jobName: string;
  public hrCode: string;
  public hiringDate: Date;
  public profilePicFileId: string;
  public profilePicFile: AttachmentFile;
  public businessunitid: number;
  public lastWebLoginDate?: Date;
  public lastMobileLoginDate?: Date;
  public businessUnitName: string;
  public jobLinkedToOtherUser: boolean;
  public businessUnitsTree: string[];
}
