import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayModes } from '../../../models/enums';
import { ItemBrand } from '../../../models/lookups/item-brand.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ItemBrandService } from '../item-brand.service';

@Component({
  selector: 'crm-add-item-brand',
  templateUrl: './add-item-brand.component.html',
  styleUrls: ['./add-item-brand.component.css']
})
export class AddItemBrandComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  itemBrand: ItemBrand = new ItemBrand();
  //itemFormatId: number;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemBrandId: number;
  constructor(private readonly itemBrandService: ItemBrandService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.itemBrandId != undefined)
      this.getItemBrand(this.itemBrandId);
  }

  getItemBrand(itemBrandId) {
    this.displayMode = DisplayModes.Loading;
    this.itemBrandService.getById(itemBrandId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.itemBrand = responce as ItemBrand;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item brand');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.itemBrandService.save(this.itemBrand).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
