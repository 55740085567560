import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../models/enums';
import { Permission } from '../models/permission.model';
import { UserMini } from '../models/user-mini.model';
import { AccountService } from '../services/account.service';
import { AlertService, MessageSeverity } from '../services/alert.service';
import { AppTranslationService } from '../services/app-translation.service';
import { UsersApiService } from '../services/users-api.service';
import { UserBricksService } from './user-bricks.service';

@Component({
  selector: 'crm-user-bricks',
  templateUrl: './user-bricks.component.html',
  styleUrls: ['./user-bricks.component.css']
})
export class UserBricksComponent implements OnInit {
  displayMode: DisplayModes;

  users: UserMini[];
  selectedUser: UserMini;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  @Output() closeEvent = new EventEmitter<boolean>();
  selectedUserBrickId;
  bricks: any[] = [];
  showAddBricks = false;
  pageSize = 10;
  logo;

  constructor(private readonly userBricksService: UserBricksService, private readonly usersService: UsersApiService,
    private readonly alertService: AlertService, private readonly translateService: AppTranslationService, private readonly accountService: AccountService) { }

  ngOnInit() {
    this.displayMode = DisplayModes.Success;
    this.getUsersByManager();
  }
  getUsersByManager() {
    this.usersService.getAllUsersMiniReport().subscribe(response => {
      this.users = response as UserMini[];
    });
  }
  back() {
    this.closeEvent.emit(false);
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  confirmDelete(userBrick) {
    this.selectedUserBrickId = userBrick.id;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.userBricksService.deleteUserBrick(this.selectedUserBrickId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", `Brick deleted.`, MessageSeverity.Success);
      this.getUserBricks();
    }, () => {
      this.displayMode = DisplayModes.Success;

      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }

  getUserBricks() {
    this.selectedUserBrickId = undefined;
    let userId;
    if (this.selectedUser)
      userId = this.selectedUser.id;
    this.userBricksService.getUserBricks(userId).subscribe(response => {
      this.bricks = response as any[];
    });
  }

  addUserBricks() {
    this.showAddBricks = true;
  }

  closeListing() {
    this.showAddBricks = false;
    this.getUserBricks();
  }

  get canAddUserBricksPermission() {
    return this.accountService.userHasPermission(Permission.addUserBricksPermission);
  }

  get canDeleteUserBricksPermission() {
    return this.accountService.userHasPermission(Permission.deleteUserBricksPermission);
  }

}
