
export enum Gender {
    None,
    Female,
    Male
}
export enum DisplayModes {
  Loading = 1,
  Success = 2,
  Error = 3,
  Saving = 4,
  Updating = 5,
  Submitting=6
}

export enum ActivityCostCenterType {
  BusinessUnitLineId = 1,
  ItemBrandId = 2,
  BusinessUnitTerritoryId = 3
}

export enum ActivityGroupType {
  Line = 1,
  Product = 2,
  Territory = 3
}
