import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class BusinessUnitTypeService {

  constructor(private readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }


  getAllTypes() {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnitType/getAllTypes`);
  }
}
