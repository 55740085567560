import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http'
import { ConfigurationService } from '../../services/configuration.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BricksService {

  constructor(private readonly http: HttpClient,
    private readonly translateService: AppTranslationService,
    private readonly configurations: ConfigurationService,
    private readonly injector: Injector,
    private readonly localStorage: LocalStoreManager) { }

  provisionBricks(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/bricks/provisionFromCsv`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  getAllBricksTree(brickLevelId?: any, sortField?: string,sortOrder?:any, pageSize?: any, pageNumber?: any) {
    let myParams = new HttpParams()
      .set('brickLevelId', brickLevelId)
      .set('sortField', sortField)
      .set('sortOrder', sortOrder)
      .set('pageSize', pageSize)
      .set('pageNumber', pageNumber);
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getAllBricksTree`, { params: myParams });
  }

  getBrickChilds(parentId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getBrickChilds/${parentId}`);

  }

  getAllBricks(query) {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getAllBricks/${query}`);
  }

  getParentBricks(brickLevelId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getParentBricks/${brickLevelId}`);
  }

  saveBrick(brick) {
    return this.http.post(`${this.configurations.apiAddress}/api/bricks/saveBrick`, brick);
  }

  getBrickDetails(brickId) {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getBrickDetails/${brickId}`);
  }

  deleteBrick(brickId) {
    return this.http.delete(`${this.configurations.apiAddress}/api/bricks/deleteBrick/${brickId}`);
  }

  getBricksWithParents() {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getBricksWithParents`);
  }
  
  getLowLevelBricks() {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getLowLevelBricks`);
  }

  downloadCsvTemplate(): Observable<Blob> {

    return this.http.get(`${this.configurations.apiAddress}/api/bricks/downloadCSVTemplate`, { responseType: "blob" });

  }

  getBricksRootLevel() {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getBricksRootLevel`);
  }

  getCurrentUserBricks(userId?: number) {
    if (userId === null || userId === undefined)
      return this.http.get(`${this.configurations.apiAddress}/api/bricks/getCurrentUserBricks`);
    else
      return this.http.get(`${this.configurations.apiAddress}/api/bricks/getCurrentUserBricks/${userId}`);
  }

  getBricksByBrickLevel(brickLevelId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/bricks/getBricksByBrickLevel/${brickLevelId}`);
  }

  //getCurrentUserBricks() {

  //  return this.http.get(`${this.configurations.apiAddress}/api/bricks/getCurrentUserBricks`);
  //}
}
