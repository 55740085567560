import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import { CycleComponent } from "./components/cycle/cycle.component";
import { AddCycleComponent } from "./components/cycle/add-cycle/add-cycle.component";
import { CycleBUSpecialityComponent } from "./components/cycle/cycle-buspeciality/cycle-buspeciality.component";
import { UsersManagementComponent } from "./components/settings/users-management/users-management.component";
import { RolesManagementComponent } from "./components/settings/roles-management/roles-management.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserJobsComponent } from "./components/settings/users-management/user-jobs/user-jobs.component";
import { UserEditorComponent } from "./components/settings/users-management/user-editor/user-editor.component";
import { RoleEditorComponent } from "./components/settings/roles-management/role-editor/role-editor.component";
import { ServerErrorComponent } from "./components/server-error/server-error.component";
import { UnAuthorizedComponent } from "./components/un-authorized/un-authorized.component";
import { CycleBricksComponent } from "./components/cycle/cycle-bricks/cycle-bricks.component";
import { UserBricksComponent } from "./user-bricks/user-bricks.component";


const routes: Routes = [
  { path: "", pathMatch: 'full', redirectTo: 'dashboard' },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  { path: "cycles", component: CycleComponent, canActivate: [AuthGuard], canLoad: [AuthGuard], data: { title: "Cycles" } },
  { path: "add-cycle", component: AddCycleComponent, canActivate: [AuthGuard], data: { title: "Add Cycle" } },
  { path: "add-cycle/:cycleId", component: AddCycleComponent, canActivate: [AuthGuard], data: { title: "Add Cycle" } },
  { path: "cycle/bu-specialities/:cycleId", component: CycleBUSpecialityComponent, canActivate: [AuthGuard], data: { title: "Cycle BU Specialities" } },
  { path: "cycle/bricks/:cycleId", component: CycleBricksComponent, canActivate: [AuthGuard], data: { title: "Cycle Bricks" } },
  { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
  { path: "users-management", component: UsersManagementComponent, canActivate: [AuthGuard], data: { title: "Users Management" } },
  { path: "roles-management", component: RolesManagementComponent, canActivate: [AuthGuard], data: { title: "Roles Management" } },
  { path: "role-editor", component: RoleEditorComponent, canActivate: [AuthGuard], data: { title: "Create Role" } },
  { path: "role-editor/:roleId", component: RoleEditorComponent, canActivate: [AuthGuard], data: { title: "Edit Role" } },
  { path: "user-profile", component: UserProfileComponent, canActivate: [AuthGuard], data: { title: "User Profile" } },
  { path: "user-editor", component: UserEditorComponent, canActivate: [AuthGuard], data: { title: "Create User" } },
  { path: "user-editor/:userId", component: UserEditorComponent, canActivate: [AuthGuard], data: { title: "Edit User" } },
  { path: "user-jobs/:userId", component: UserJobsComponent, canActivate: [AuthGuard], data: { title: "User Jobs" } },
  { path: "server-error", component: ServerErrorComponent, data: { title: "Server Error" } },
  { path: "unauthorized", component: UnAuthorizedComponent, data: { title: "UnAuthorized" } },
  { path: 'lookups', loadChildren: "app/lookups/lookups.module#LookupsModule" },
  { path: 'user-bricks', component: UserBricksComponent },
  {
    path: 'settings',
    loadChildren: 'app/settings/settings.module#SettingsModule'
  },
  {
    path: 'dashboard',
    loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'visits',
    loadChildren: 'app/visits/visits.module#VisitsModule'
  },
  {
    path: 'plans',
    loadChildren: 'app/plan/plan.module#PlanModule'
  },
  {
    path: 'rep-activities',
    loadChildren: 'app/rep-activities/rep-activities.module#RepActivitiesModule'
  },
  {
    path: 'expenses',
    loadChildren: 'app/expenses/expenses.module#ExpensesModule'
  },
  {
    path: 'transactions',
    loadChildren: 'app/transactions/transactions.module#TransactionsModule'
  },
  {
    path: 'user-hco-requests',
    loadChildren: 'app/user-hcos/user-hcos.module#UserHcosModule'
  },
  {
    path: 'user-sample-requests',
    loadChildren: 'app/sample-requests/sample-requests.module#SamplerequestsModule'
  },
  {
    path: 'vacation-credit',
    loadChildren: 'app/vacation-credit/vacation-credit.module#VacationCreditModule'
  }, {
    path: 'reports',
    loadChildren: 'app/reports/reports.module#ReportsModule'
  },
  {
    path: 'vacations',
    loadChildren: 'app/vacations/vacations.module#VacationsModule'
  },
  {
    path: 'coaching',
    loadChildren: 'app/coaching/coaching.module#CoachingModule'
  },
  {
    path: 'clm',
    loadChildren: 'app/clm/clm.module#ClmModule'
  },
  {
    path: 'distributor',
    loadChildren: 'app/distributor/distributor.module#DistributorModule'
  }, {
    path: 'message',
    loadChildren: 'app/messages/messages.module#MessagesModule'
  },
  {
    path: 'user-pharmacy-requests',
    loadChildren: 'app/user-pharmacy/user-pharmacy.module#UserPharmacyModule'
  },
  {
    path: 'pharmacy-plans',
    loadChildren: 'app/pharmacy-plan/pharmacy-plan.module#PharmacyPlanModule'
  },
  {
    path: 'pharmacy-visits',
    loadChildren: 'app/pharmacy-visit/pharmacy-visit.module#PharmacyVisitModule'
  },
  {
    path: 'incentive',
    loadChildren: 'app/incentive/incentive.module#IncentiveModule'
  },
  {
    path: 'rule',
    loadChildren: 'app/rule/rule.module#RuleModule'
  },
  {
    path: 'product-weight',
    loadChildren: 'app/product-weight/product-weights.module#ProductWeightModule'
  },
  {
    path: 'incentive-scheme',
    loadChildren: 'app/incentive-scheme/incentive-schemes.module#IncentiveSchemeModule'
  },
  {
    path: 'incentive-setting',
    loadChildren: 'app/incentive-setting/incentive-settings.module#IncentiveSettingModule'
  },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
