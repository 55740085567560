import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../models/enums';
import { AppConfigService } from '../../app.config';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../services/app-translation.service';
import { CoachingService } from './coaching.service';
import { coaching } from '../../models/lookups/coaching.model';
import { Utilities } from '../../services/utilities';
import { Page } from '../../models/page.model';
 
@Component({
  selector: 'crm-coaching',
  templateUrl: './coaching.component.html',
  styleUrls: ['./coaching.component.css']
})
export class CoachingComponent implements OnInit {
  columns: any[] = [];
  searchText: string; // get it's value from activity-type.html
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  coachings: coaching[] = [];
  coachingId: number; //to edit exisiting value

  //instance from an enum to display either sucess,loading,error or saving
  displayMode: DisplayModes = DisplayModes.Success;

  constructor(private alertService: AlertService,
    private coachingService: CoachingService,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchcoaching();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }
  new() {
    this.router.navigate(['add-coaching']);
  }
  searchcoaching() {

    // Start Loading and alert loading message
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
   
    // get a search method from activity type service and carry its result in response
    this.coachingService.Search(this.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;
      this.coachings = result.items;
      this.totalItems = result.totalItems;       
    }, error => {

      // Stop Loading and alert Error message
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;

        this.alertService.showStickyMessage("Load Error", `Unable to retrieve coachings from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }
  pageChanged(event) {
    this.currentPage = event.page;
    this.searchcoaching();
  }

  // Edit Activity Type by Id 
  gotoEdit(coachingId) {
    this.router.navigate(['add-coaching', coachingId]);
  }

  // Delete coaching by Id
  deletecoaching(coachingId) {
    this.displayMode = DisplayModes.Loading;

    // get a Delete method from activity type service and carry its result in result
    this.coachingService.Delete(coachingId).subscribe(result => {

      this.displayMode = DisplayModes.Success;
      this.searchcoaching();
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  // Confirm Delete Method
  confirmDelete(coachingId) {
    this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
      () => this.deletecoaching(coachingId),
      () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
}
