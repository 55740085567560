import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganizationType } from '../../../models/lookups/organizationType.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { OrganizationTypeService } from '../organization-type.service';
import { SettingService } from '../../../settings/components/settings/setting.service';
import { Setting } from '../../../settings/models/setting.model';

@Component({
  selector: 'crm-add-organization-type',
  templateUrl: './add-organization-type.component.html',
  styleUrls: ['./add-organization-type.component.css']
})
export class AddOrganizationTypeComponent implements OnInit {
  organizationType: OrganizationType = new OrganizationType();

  displayMode: DisplayModes = DisplayModes.Success;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() organizationTypeId: number;
  applyAmPmOrganizationType: string;
  constructor(private alertService: AlertService, private readonly settingService: SettingService,
    private route: ActivatedRoute,
    private organizationTypeService: OrganizationTypeService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.getSettings();
    if (this.organizationTypeId != undefined)
      this.getDetails(this.organizationTypeId);
  }

  getSettings() {

    this.settingService.getAllSettings().subscribe(response => {
      let settings = response as Setting[];
      this.applyAmPmOrganizationType = settings.find(x => { return x.key == "applyAmPmOrganizationType"; }).value;
      if (this.applyAmPmOrganizationType == 'True' && this.organizationTypeId == undefined)
        this.organizationType.isAm = false;
    });
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.organizationTypeService.Save(this.organizationType).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.organizationType.id == 0)
          this.organizationType = new OrganizationType();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.organizationTypeService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.organizationType = response as OrganizationType;
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }


}
