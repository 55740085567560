import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityGroupType, DisplayModes } from '../../../models/enums';
import { ActivityGroup } from '../../../models/lookups/activity-group.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ActivityGroupService } from '../activity-group.service';

@Component({
  selector: 'crm-add-activity-group',
  templateUrl: './add-activity-group.component.html',
  styleUrls: ['./add-activity-group.component.css']
})
export class AddActivityGroupComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  activityGroup: ActivityGroup = new ActivityGroup();

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() activityGroupId: number;

  //activityGroupTypes = ActivityGroupType;
  activityGroupTypeKeys: any[] = [];
  constructor(private readonly activityGroupService: ActivityGroupService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    let keys = Object.keys(ActivityGroupType).filter(f => !isNaN(Number(f)));
    for (let i = 1; i <= keys.length; i++) {
      this.activityGroupTypeKeys.push({ name: ActivityGroupType[i], value: i });
      //this.ActivityGroupTypeKeys.push({ name: ActivityGroupTypes[i], value: i });
    }
    if (this.activityGroupId != undefined)
      this.getActivityGroup(this.activityGroupId);
  }

  getActivityGroup(activityGroupId) {
    this.displayMode = DisplayModes.Loading;
    this.activityGroupService.getById(activityGroupId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.activityGroup = responce as ActivityGroup;
      this.activityGroup.typeData = this.activityGroupTypeKeys.find(y => y.value == this.activityGroup.type);
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Activity Group');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;
      if (this.activityGroup.typeData)
        this.activityGroup.type = this.activityGroup.typeData.value;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.activityGroupService.save(this.activityGroup).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
