import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { Feedback } from '../../models/lookups/feedback.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'crm-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  feedbacks: Feedback[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Representations.png");

  selectedFeedbackId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly feedbackService: FeedbackService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getFeedbacks();
  }

  getFeedbacks() {
    this.displayMode = DisplayModes.Loading;
    this.feedbackService.search(this.searchText).subscribe(responce => {
      this.feedbacks = responce as Feedback[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get feedback');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedFeedbackId = id;
    this.addNew = true;
  }

  confirmDelete(feedback) {
    this.selectedFeedbackId = feedback;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.feedbackService.delete(this.selectedFeedbackId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.feedbacks.indexOf(this.selectedFeedbackId);
      this.feedbacks.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete feedback:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageFeedbackPermission() {
    return this.accountService.userHasPermission(Permission.manageFeedbackPermission);
  }

  closeAdding(event) {
    this.selectedFeedbackId = undefined;
    this.addNew = false;
    if (event) {
      this.getFeedbacks();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }


}
