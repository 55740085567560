import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { VacationTypeService } from '../vacation-type.service';
import { VacationType } from '../../../models/lookups/vacationType.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';

@Component({
  selector: 'crm-vacation-type-add',
  templateUrl: './vacation-type-add.component.html',
  styleUrls: ['./vacation-type-add.component.css']
})
export class VacationTypeAddComponent implements OnInit {

  vacationType: VacationType = new VacationType();
  minStartDate: Date = new Date();
  //vacationTypeid: number;
  displayMode: DisplayModes = DisplayModes.Success;
  en: any;
  isVac: boolean = false;


  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() vacationTypeid: number;
  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private vacationTypeService: VacationTypeService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.isVac = this.router.url.indexOf('vacation') > -1;
    if (this.vacationTypeid != undefined)
      this.getDetails(this.vacationTypeid);
  }

  save(form) {
    this.vacationType.isVacation = this.isVac;
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.vacationTypeService.Save(this.vacationType).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.vacationType.id == 0)
          this.vacationType = new VacationType();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.vacationTypeService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.vacationType = response as VacationType;
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }
}
