import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { BusinessUnitService } from '../../business-unit/business-unit.service';

@Component({
  selector: 'crm-save-business-unit-shared-bricks',
  templateUrl: './save-business-unit-shared-bricks.component.html',
  styleUrls: ['./save-business-unit-shared-bricks.component.css']
})
export class SaveBusinessUnitSharedBricksComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() brickId: number;
  @Input() buLineId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  result;
  showError: boolean = false;
  constructor(private readonly alertService: AlertService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getBrickBusinessUnits();
  }

  getBrickBusinessUnits() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.businessUnitService.getBrickBusinessUnits(this.brickId, this.buLineId)
      .subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.result = response as any[];
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
  }

  save(form) {
    this.showError = false;
    if (this.result.reduce((sum, current) => sum + current.percent, 0) != 100) {
      this.showError = true;
    }
    else {
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.businessUnitService.saveSharedBricksPercentage(this.brickId, this.result)
        .subscribe(response => {
          this.alertService.stopLoadingMessage();
          this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
          this.displayMode = DisplayModes.Success;
          this.back();
        }, () => {
          this.alertService.stopLoadingMessage();
          this.displayMode = DisplayModes.Error;
        });
    }
  }

  back() {
    this.closeEvent.emit(false);
  }

}
