import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { OrganizationJobStructureService } from '../organization-job-structure.service';
import { SaveOrgJobStrBusinessUnitDatesComponent } from '../save-org-job-str-business-unit-dates/save-org-job-str-business-unit-dates.component';


@Component({
  selector: 'crm-org-job-str-business-unit-dates',
  templateUrl: './org-job-str-business-unit-dates.component.html',
  styleUrls: ['./org-job-str-business-unit-dates.component.css']
})
export class OrgJobStrBusinessUnitDatesComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  orgJobStrBusinessUnitDates: any[] = [];
  selectedOrgJobStrBusinessUnitDatesId;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() orgJobStrId: number;

  @ViewChild('orgJobStrBusinessUnitDatesModal')
  orgJobStrBusinessUnitDatesModal: ModalDirective;

  @ViewChild('orgJobStrBusinessUnitDatesEditor')
  orgJobStrBusinessUnitDatesEditor: SaveOrgJobStrBusinessUnitDatesComponent;

  @ViewChild('deleteOrgJobStrBusinessUnitDatesModal')
  deleteOrgJobStrBusinessUnitDatesModal: ModalDirective;

  constructor(private readonly organizationJobStructureService: OrganizationJobStructureService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getOrgJobStrBusinessUnitDates();
  }

  ngAfterViewInit() {
    this.orgJobStrBusinessUnitDatesEditor.changesCallback = () => {
      this.getOrgJobStrBusinessUnitDates();
      this.orgJobStrBusinessUnitDatesModal.hide();
    };
  }

  getOrgJobStrBusinessUnitDates() {
    this.displayMode = DisplayModes.Loading;
    this.organizationJobStructureService.getOrganizationJobStructureBusinessUnitDates(this.orgJobStrId).subscribe(response => {
      this.orgJobStrBusinessUnitDates = response as any[];
      this.displayMode = DisplayModes.Success;
    }, err => {
      console.log(err);
      this.displayMode = DisplayModes.Error;
    });
  }

  confirmDelete(orgJobStrBusinessUnitDatesId) {
    this.selectedOrgJobStrBusinessUnitDatesId = orgJobStrBusinessUnitDatesId;
    this.deleteOrgJobStrBusinessUnitDatesModal.show();
  }

  deleteOrganizationJobStructureBusinessUnitDates() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.organizationJobStructureService.deleteOrganizationJobStructureBusinessUnitDates(this.selectedOrgJobStrBusinessUnitDatesId).subscribe(() => {
      this.selectedOrgJobStrBusinessUnitDatesId = undefined;
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);
      this.getOrgJobStrBusinessUnitDates();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  hideDeleteModal() {
    this.deleteOrgJobStrBusinessUnitDatesModal.hide();
  }

  back() {
    this.closeEvent.emit(true);
  }

  addNew() {
    this.orgJobStrBusinessUnitDatesEditor.new(this.orgJobStrId);
    this.orgJobStrBusinessUnitDatesModal.show();
  }


  edit(orgJobStrBusinessUnit) {
    this.orgJobStrBusinessUnitDatesEditor.edit(orgJobStrBusinessUnit);
    this.orgJobStrBusinessUnitDatesModal.show();
  }

  onEditorModalHidden() {
    this.orgJobStrBusinessUnitDatesModal.hide();
  }



}
