import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { ItemFormat } from '../../models/lookups/itemFormat.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ItemFormatService } from './item-format.service';

@Component({
  selector: 'crm-item-format',
  templateUrl: './item-format.component.html',
  styleUrls: ['./item-format.component.css']
})
export class ItemFormatComponent implements OnInit {

  itemFormats: ItemFormat[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedItemFormatId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly itemFormatService: ItemFormatService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getItemFormats();
  }

  getItemFormats() {
    this.displayMode = DisplayModes.Loading;
    this.itemFormatService.search(this.searchText).subscribe(responce => {
      this.itemFormats = responce as ItemFormat[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item formats');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
    //this.router.navigate(['add-item-format']);
  }

  edit(id) {
    this.selectedItemFormatId = id;
    this.addNew = true;
    //this.router.navigate(['add-item-format/' + id]);
  }

  confirmDelete(itemFormat) {
    this.selectedItemFormatId = itemFormat;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("clm.DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(itemFormat),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("clm.OperationCancelled"), "", MessageSeverity.Default));
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemFormatService.delete(this.selectedItemFormatId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.itemFormats.indexOf(this.selectedItemFormatId);
      this.itemFormats.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete item fromat:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageItemFormats() {
    return this.accountService.userHasPermission(Permission.manageItemFormatsPermission);
  }

  closeAdding(event) {
    this.selectedItemFormatId = undefined;
    this.addNew = false;
    if (event) {
      this.getItemFormats();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }


}
