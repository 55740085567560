<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Organization Job Structure' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Organization Job Structure' | translate}}</h1>
  </div>
</div>


<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Organization Job Structure Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6"> </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="openFilterModal()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="goToNew()">
          <span class="margin-right-7 create-new-span">Create New Item</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-organization-job-structure *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [orgJobStrId]="selectedOrgId"></crm-add-organization-job-structure>
<crm-org-job-str-business-unit-dates *ngIf="orgJobStrBusinessunitDatesMode" (closeEvent)="closeAdding($event)" [orgJobStrId]="selectedOrgId"></crm-org-job-str-business-unit-dates>
<crm-org-job-str-target-dates *ngIf="orgJobStrTargetDatesMode" (closeEvent)="closeAdding($event)" [orgJobStrId]="selectedOrgId" [targetType]="targetType"></crm-org-job-str-target-dates>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Organization Job Structure Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-treeTable #dt [value]="orgJobStrs" [resizableColumns]="true" [paginator]="true"
                       [rows]="pageSize" [lazy]="true" (onLazyLoad)="loadTreeTableData($event)"
                       [totalRecords]="totalItems" (onNodeExpand)="onNodeExpand($event)">

            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" ttResizableColumn [ttSortableColumn]="col.field" style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-treeTableSortIcon [field]="col.field" ariaLabel="Activate to sort"
                                       ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-treeTableSortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit">BU</th>
                <!--<th width="10%" style="text-align:center" class="table-edit">AM Target</th>
                <th width="10%" style="text-align:center" class="table-edit">PM Target</th>
                <th width="10%" style="text-align:center" class="table-edit">PH Target</th>-->
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr *ngIf="orgJobStrs.length>0 && displayMode==2" [ttRow]="rowNode">
                <!--<td>
                  <strong>{{item.id}}</strong>
                </td>-->
                <td>
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <span [class.user-disabled]="!rowData.isActive">
                    <i *ngIf="!rowData.isActive" class="fa fa-exclamation"> </i>

                    {{ rowData.name }}
                  </span>
                </td>
                <td>{{ rowData.userName }}</td>

                <td>
                  <a *ngIf="rowData.businessUnitName" href="javascript:;" (click)="getBusinessUnitDates(rowData)"> {{ rowData.businessUnitName }}</a>
                  <a *ngIf="!rowData.businessUnitName" class="" href="javascript:;" (click)="getBusinessUnitDates(rowData)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>

                <td width="15%">{{ rowData.jobName }}</td>
                <td>{{ rowData.directManagerName  }}</td>

                <td>{{ rowData.directManagerJob }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="showOrgJobStrBusinessunitsModal(rowData.id)"><i class="fa fa-user-circle-o fa-lg font-size-20"></i></a>
                </td>


                <!--<td>
                  <a class="" href="javascript:;" (click)="getTargetDates(rowData,'AM')">{{ rowData.targetAm }}</a>
                </td>
                <td>
                  <a class="" href="javascript:;" (click)="getTargetDates(rowData,'PM')">{{ rowData.targetPm }}</a>
                </td>
                <td>
                  <a class="" href="javascript:;" (click)="getTargetDates(rowData,'PH')">{{ rowData.pharmacyTarget }}</a>
                </td>-->




                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(rowData.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDeleteOrgJobStr(rowData.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

              </tr>
              <tr *ngIf="orgJobStrs.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>
              </tr>
            </ng-template>

          </p-treeTable>


        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-6">
  </div>
  <div class="col-sm-2 cursor-pointer left-23" (click)="getTargetDatesProvision()">
    <span class="margin-right-7 edit-profile-span">Update Target</span>
    <i class="fa fa-upload" aria-hidden="true"></i>
  </div>
  <div class="col-sm-2 cursor-pointer left-15" (click)="showUpdatenModal()">
    <span class="margin-right-7 edit-profile-span">Update Sheet</span>
    <i class="fa fa-upload" aria-hidden="true"></i>
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Organization Job Structure Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-organization-job-structure #provisionOrgJobStructure [update]="updateModel"></crm-provision-organization-job-structure>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #provisionTargetDateModal="bs-modal" (onHidden)="onProvisionTargetDateModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Target Organization Job Structure' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionTargetDateModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-org-job-str-target-dates #provisionOrgJobStructureTargetDates></crm-provision-org-job-str-target-dates>
    </div>
  </div>
</div>



<div class="modal fade" bsModal #orgJobStrBusinessunitsModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Business Unit' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{'Business Unit' | translate}} </label>
            <ng-multiselect-dropdown [placeholder]="'Business Unit'"
                                     [data]="businessUnits"
                                     name="businessUnit"
                                     #businessUnit="ngModel"
                                     [(ngModel)]="selectedBu"
                                     [settings]="dropdownSettings"
                                     (onSelect)="onItemSelect($event)"
                                     (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideOrgJobStrBusinessunitsModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="saveOrgJobStrBu()">{{'Save' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #filterModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title">{{'Filter' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">
        <!--<div class="row">
          <div class="col-sm-6">
            <label class="control-label">Date </label>
            <div class="input-group date">
              <input type="text"
                     name="startDate"
                     #startDate="ngModel"
                     [(ngModel)]="search.filterDate"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }" class="form-control" autocomplete="off">
              <span class="input-group-btn date-label">
                <button class="btn date-btn" (click)="dpYMD.toggle()" type="button"><i class="fa fa-calendar"></i></button>
              </span>
            </div>
          </div>

        </div>-->
        <div class="row">
          <div class="col-sm-6">
            <input type="text"
                   name="searchText"
                   [(ngModel)]="search.searchText"
                   placeholder="{{'SearchText' |translate}}"
                   #name="ngModel"
                   class="form-control" />
          </div>
          <div class="col-sm-6">
            <input type="text"
                   name="searchText"
                   [(ngModel)]="search.directManager"
                   placeholder="{{'Direct Manager Search' |translate}}"
                   #name="ngModel"
                   class="form-control" />
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideFilterModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="searchFilterModal()">{{'Search' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
