import { Component, OnInit } from '@angular/core';
import { CycleService } from '../cycle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { DisplayModes } from '../../../models/enums';
import { Cycle } from '../../../models/cycle.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { CycleBrick } from '../models/cycle-brick.model';
import { TreeNode } from 'primeng/api';
import { BricksService } from '../../../settings/services/bricks.service';
import { BrickLevel } from '../../../settings/models/brick-level.model';
import { BrickLevelService } from '../../../settings/services/brick-level.service';
import { AppConfigService } from '../../../app.config';

@Component({
  selector: 'crm-cycle-bricks',
  templateUrl: './cycle-bricks.component.html',
  styleUrls: ['./cycle-bricks.component.css']
})
export class CycleBricksComponent implements OnInit {
  cycleId: number;
  cycle: Cycle = new Cycle(true);
  bricks: TreeNode[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  selectedBricks: string[] = [];
  brickLevel: BrickLevel;
  cycleBricks: CycleBrick[];
  brickLevels: BrickLevel[] = [];
  pageSize: number = 20;

  constructor(private readonly cycleService: CycleService,
    private readonly route: ActivatedRoute,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService,
    private readonly appConfig: AppConfigService,
    private readonly brickService: BricksService,
    private readonly brickLevelService: BrickLevelService,
    private readonly router: Router) {
    this.route.params.subscribe(params => {
      console.log(params)
      this.cycleId = params["cycleId"];
    });
  }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getAllBricks();
    this.getBrickLevels();
    if (this.cycleId !== undefined) {
      this.getDetails(this.cycleId);
      this.getCycleBricks();

    }
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.cycleService.getById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.cycle = response as Cycle;
    }, error => {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }

  getCycleBricks() {
    this.displayMode = DisplayModes.Loading;
    this.cycleService.getCycleBricks(this.cycleId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      let result = response as CycleBrick[];
      this.selectedBricks = result.map(x => x.brickId.toString()) as string[];

    }, error => {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }

  saveCycleBricks() {
    this.displayMode = DisplayModes.Loading;
    this.cycleService.saveCycleBricks(this.cycleId, this.selectedBricks).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
    }, error => {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }
  getAllBricks() {
    this.displayMode = DisplayModes.Loading;
    let brickLevelId = 0;
    if (this.brickLevel !== undefined && this.brickLevel !== null)
      brickLevelId = this.brickLevel.id;
    this.brickService.getAllBricksTree(brickLevelId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      let result = response;
      this.bricks = result as TreeNode[];

    }, error => {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }
  getBrickLevels() {
    this.brickLevelService.getAllBrickLevels().subscribe(response => {
      this.brickLevels = response as BrickLevel[];

    }, err => {
      console.log(err);
    });
  }
  back() {
    this.router.navigate(['cycle/bu-specialities', this.cycleId]);

  }
}
