import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { VacationTypeService } from '../vacation-type.service';
import { HttpEventType } from "@angular/common/http";
import { Router } from '@angular/router';


@Component({
  selector: 'crm-provision-vacation-type',
  templateUrl: './provision-vacation-type.component.html',
  styleUrls: ['./provision-vacation-type.component.css']
})
export class ProvisionVacationTypeComponent implements OnInit {

  progress: number;
  message: string;
  @ViewChild("provisionModal")
  provisionModal: ModalDirective;
  isVac: boolean = false;
  constructor(private router: Router, private vacationTypeService: VacationTypeService,) { }

  ngOnInit() {
    this.isVac = this.router.url.indexOf('vacation') > -1;
  }

  downloadCSVTemplate() {

    this.vacationTypeService.downloadCsvTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      if (this.isVac)
        anchor.download = "VacationTypes.csv";
      else
        anchor.download = "NonFieldTypes.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }

  upload(files) {
    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.vacationTypeService.uploadCsvTemplate(formData,this.isVac).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = "uploaded success";
      }
    });
  }


}
