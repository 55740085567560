import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Reaction } from '../../../models/lookups/Reaction';
import { ReactionsApiService } from '../../services/reactions-api.service';

@Component({
  selector: 'crm-add-reaction',
  templateUrl: './add-reaction.component.html',
  styleUrls: ['./add-reaction.component.css']
})
export class AddReactionComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  reaction: Reaction = new Reaction();
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() reactionId: number;

  constructor(private readonly reactionsApiService: ReactionsApiService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.reactionId != undefined)
      this.getReaction(this.reactionId);
  }

  getReaction(reactionId) {
    this.displayMode = DisplayModes.Loading;
    this.reactionsApiService.getById(reactionId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.reaction = responce as Reaction;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get reaction');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.reactionsApiService.save(this.reaction).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
