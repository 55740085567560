import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PharmacyWorkingDaysService } from './pharmacy-working-days.service';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'crm-pharmacy-working-days',
  templateUrl: './pharmacy-working-days.component.html',
  styleUrls: ['./pharmacy-working-days.component.css']
})
export class PharmacyWorkingDaysComponent implements OnInit {

  columns: any[] = [];
  totalItems: number = 7;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  workingDays: any[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = require("../../../app/assets/images/demo/Icon_Working Days.png");

  constructor(private alertService: AlertService,
    private pharmacyWorkingDaysService: PharmacyWorkingDaysService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.search();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }

  search() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.pharmacyWorkingDaysService.Search().subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      this.workingDays = response as any[];
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve working days from the server.`,
        MessageSeverity.Error, error);
    });
  }

  save(item) {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
    item.active = !item.active;
    this.pharmacyWorkingDaysService.Save(item).subscribe(() => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
    }, () => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

}
