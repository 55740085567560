
<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!activityTypeId">Create {{'settings.header.activityType' | translate}}</span>
    <span class="panel-title" *ngIf="activityTypeId">Edit {{'settings.header.activityType' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>

      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="activitytype.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.valid, 'has-error' : (leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.invalid}">
              <label class="control-label">{{'leadtime' | translate}} </label>
              <input type="number"
                     name="leadTime"
                     [(ngModel)]="activitytype.leadTime"
                     #leadTime="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (confirmedDeadline.dirty || confirmedDeadline.touched || f.submitted)  && confirmedDeadline.valid, 'has-error' : (confirmedDeadline.dirty || confirmedDeadline.touched || f.submitted)  && confirmedDeadline.invalid}">
              <label class="control-label">{{'Confirmed Deadline' | translate}} </label>
              <input type="number"
                     name="confirmedDeadline"
                     [(ngModel)]="activitytype.confirmedDeadline"
                     #confirmedDeadline="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(confirmedDeadline.dirty || confirmedDeadline.touched || f.submitted)  && confirmedDeadline.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6" *ngIf="activityGroups && activityGroups.length>0">
            <div [ngClass]="{'has-success': (activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.valid, 'has-error' : (activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.invalid}">

              <label class="control-label">{{'Activity Groups' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="activityGroups"
                            name="activityGrou" #activityGrou="ngModel"
                            styleClass="wid100"
                            placeholder="Activity Groups"
                            emptyFilterMessage="No results found" required="required"
                            showClear="true"
                            [(ngModel)]="activitytype.activityGroup"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>
              <div *ngIf="(activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="activityCostCenters && activityCostCenters.length>0">
            <div [ngClass]="{'has-success': (activityCostCen.dirty || activityCostCen.touched || f.submitted)  && activityCostCen.valid, 'has-error' : (activityCostCen.dirty || activityCostCen.touched || f.submitted)  && activityCostCen.invalid}">

              <label class="control-label">{{'Activity Cost Center' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Activity Cost Center'"
                                       [data]="activityCostCenters"
                                       name="activityCostCen" #activityCostCen="ngModel"
                                       [(ngModel)]="activitytype.activityCostCenter"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>

              <div *ngIf="(activityCostCen.dirty || activityCostCen.touched || f.submitted)  && activityCostCen.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6" *ngIf="activityCostTypes && activityCostTypes.length>0">
            <div [ngClass]="{'has-success': (activityCostTy.dirty || activityCostTy.touched || f.submitted)  && activityCostTy.valid, 'has-error' : (activityCostTy.dirty || activityCostTy.touched || f.submitted)  && activityCostTy.invalid}">

              <label class="control-label">{{'Activity Cost Type' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Activity Cost Type'"
                                       [data]="activityCostTypes"
                                       name="activityCostTy" #activityCostTy="ngModel"
                                       [(ngModel)]="activitytype.activityCostType"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>

              <div *ngIf="(activityCostTy.dirty || activityCostTy.touched || f.submitted)  && activityCostTy.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-xs-vr" *ngFor="let level of activitytype.activityTypeApproval; let i = index">
          <div class="col-sm-3">
            <div [ngClass]="{'has-success': (lev.dirty || lev.touched || f.submitted)  && lev.valid, 'has-error' : (lev.dirty || lev.touched || f.submitted)  && lev.invalid}">
              <label class="control-label">{{'Level' | translate}} </label>

              <input class="form-control" type="number" placeholder="{{'Level' | translate}}"
                     name="lev{{i}}" #lev="ngModel" required="required" min="1"
                     [(ngModel)]="level.stepLevel">
              <div *ngIf="(lev.dirty || lev.touched || f.submitted)  && lev.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div [ngClass]="{'has-success': (approvalAct.dirty || approvalAct.touched || f.submitted)  && approvalAct.valid, 'has-error' : (approvalAct.dirty || approvalAct.touched || f.submitted)  && approvalAct.invalid}">
              <label class="control-label">{{'Type' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="activityApprovalActorkeys"
                            placeholder="{{'Type' | translate}}"
                            emptyFilterMessage="No results found" required="required"
                            styleClass="wid100"
                            [styleClass]="form-control"
                            name="approvalAct{{i}}"
                            #approvalAct="ngModel" (onChange)="onChangeApprovalActorType(level)"
                            [(ngModel)]="level.activityApprovalActorTypeData"
                            [filter]="true" optionLabel="name">


                </p-dropdown>
              </span>
              <div *ngIf="(approvalAct.dirty || approvalAct.touched || f.submitted)  && approvalAct.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-3" *ngIf="level.showUsers">
            <div [ngClass]="{'has-success': (visitAssistants.dirty || visitAssistants.touched || f.submitted)  && visitAssistants.valid, 'has-error' : (visitAssistants.dirty || visitAssistants.touched || f.submitted)  && visitAssistants.invalid}">
              <label class="control-label">{{'Users' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="users"
                            name="visitAssistants{{i}}" #visitAssistants="ngModel"
                            styleClass="wid100" required="required"
                            placeholder="User"
                            emptyFilterMessage="No results found"
                            [(ngModel)]="level.activityApprovalActorValueData"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="fullName"></p-dropdown>

              </span> <div *ngIf="(visitAssistants.dirty || visitAssistants.touched || f.submitted)  && visitAssistants.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-3" *ngIf="level.showRoles">
            <div [ngClass]="{'has-success': (rol.dirty || rol.touched || f.submitted)  && rol.valid, 'has-error' : (rol.dirty || rol.touched || f.submitted)  && rol.invalid}">
              <label class="control-label">{{'Roles' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="roles"
                            name="rol{{i}}" #rol="ngModel"
                            styleClass="wid100" required="required"
                            placeholder="Role"
                            emptyFilterMessage="No results found"
                            [(ngModel)]="level.activityApprovalActorValueData"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span> <div *ngIf="(rol.dirty || rol.touched || f.submitted)  && rol.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-3" *ngIf="level.showJobs">
            <div [ngClass]="{'has-success': (job.dirty || job.touched || f.submitted)  && job.valid, 'has-error' : (job.dirty || job.touched || f.submitted)  && job.invalid}">
              <label class="control-label">{{'Jobs' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="jobs"
                            name="job{{i}}" #job="ngModel"
                            styleClass="wid100" required="required"
                            placeholder="Job"
                            emptyFilterMessage="No results found"
                            [(ngModel)]="level.activityApprovalActorValueData"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span> <div *ngIf="(job.dirty || job.touched || f.submitted)  && job.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-3 left-7 cursor-pointer" style="margin-top:30px;" (click)="removeLevel(i)">
            <span class="margin-right-7 create-new-span">
              Remove
            </span>
            <i class="fa fa-minus-circle"></i>
          </div>
        </div>
        <div class="row padding-xs-vr">
          <div class="col-sm-9"></div>
          <div class="col-sm-2 left-7 cursor-pointer" style="margin-top:30px;" (click)="addNewLevel()">
            <span class="margin-right-7 create-new-span">Add Level</span>
            <i class="fa fa-plus-circle"></i>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




