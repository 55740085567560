import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';

import { Observable } from "rxjs";
import { ConfigurationService } from '../../services/configuration.service';



@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpClient,
    private configurations: ConfigurationService) {
  }

  getAllItemTypes() {
    return this.http.get(this.configurations.apiAddress + "/api/Items/getAllItemTypes");
  }

  getAllItemFormats() {
    return this.http.get(this.configurations.apiAddress + "/api/Items/getAllItemFormats");
  }

  getAllItemBrandss() {
    return this.http.get(this.configurations.apiAddress + "/api/ItemBrand/getAll");
  }

  save(item) {
    return this.http.post(this.configurations.apiAddress + "/api/Items/save", item);
  }

  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/Items/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/Items/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/Items/getById/' + id);
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/Items/downloadCSVTemplate", { responseType: "blob" });

  }

  uploadCsvTemplate(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/Items/uploadCSVTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  getItemPrices(itemId, priceType) {
    return this.http.get(this.configurations.apiAddress + '/api/Items/getItemPrices/' + itemId + '/' + priceType);
  }

  deleteItemPrice(itemPriceId: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/Items/deleteItemPrice/' + itemPriceId);
  }

  saveItemPrice(itemPrice) {
    return this.http.post(this.configurations.apiAddress + "/api/Items/saveItemPrice", itemPrice);
  }


  getItemBusinessUnits(itemId) {
    return this.http.get(this.configurations.apiAddress + '/api/Items/getItemBusinessUnits/' + itemId);
  }

  deleteItemBusinessUnit(itemBusinessUnitId: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/Items/deleteItemBusinessUnit/' + itemBusinessUnitId);
  }

  saveItemBusinessUnit(itemBusinessUnit) {
    return this.http.post(this.configurations.apiAddress + "/api/Items/saveItemBusinessUnit", itemBusinessUnit);
  }

}
