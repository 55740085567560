import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MessageSeverity, DialogType, AlertService } from '../../../services/alert.service';
import { User } from '../../../models/user.model';
import { UserEdit } from '../../../models/user-edit.model';
import { Role } from '../../../models/role.model';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AccountService } from '../../../services/account.service';
import { UserInfoComponent } from '../../controls/user-info.component';
import { Utilities } from '../../../services/utilities';
import { Permission } from '../../../models/permission.model';
import Error = MessageSeverity.Error;
import Error1 = MessageSeverity.Error;
import Confirm = DialogType.Confirm;
import { Router } from '@angular/router';
import { UserEditorComponent } from './user-editor/user-editor.component';
import { DisplayModes } from '../../../models/enums';
import { AppConfigService } from '../../../app.config';
import { ProvisionUsersComponent } from './provision-users/provision-users.component';
import Success = MessageSeverity.Success;

@Component({
  selector: 'users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.css']
})
export class UsersManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: User[] = [];
  rowsCache: User[] = [];
  editedUser: UserEdit;
  sourceUser: UserEdit;
  searchText: string;
  pageSize: number = 0;
  editingUserName: { name: string };
  loadingIndicator: boolean;
  displayMode: DisplayModes = DisplayModes.Success;
  allRoles: Role[] = [];
  public logo = require("../../../assets/images/demo/Icon_Users Managements.png");

  @ViewChild('indexTemplate')
  indexTemplate: TemplateRef<any>;

  @ViewChild('userNameTemplate')
  userNameTemplate: TemplateRef<any>;

  @ViewChild('rolesTemplate')
  rolesTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate')
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal')
  editorModal: ModalDirective;

  @ViewChild('userEditor')
  userEditor: UserEditorComponent;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("provisionUsers")
  provisionUsers: ProvisionUsersComponent;

  displayFilter: any[] = [
    { label: 'Total', value: 1 },
    { label: 'Active', value: 2 },
    { label: 'In Active', value: 3 }];
  display = this.displayFilter.filter(x => x.value == 1)[0];

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'User List :',
    useBom: true,
    noDownload: false,
    headers: ["ID", "Name", "Business Unit", "Email", "Job", "HrCode", "HiringDate", "Last Web Login","Last Mobile Login"]

  };
  selectedUserId;
  addNew: boolean = false;
  jobMode: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private readonly alertService: AlertService,
    private readonly translationService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly accountService: AccountService,
    private readonly router: Router) {
    this.pageSize = this.appConfig.settings.pageSize;
    this.columns = [

      { field: 'userName', header: 'User Name' },
      { field: 'businessUnitName', header: 'Business Unit' },
      { field: 'jobName', header: 'Job Position' },
      { field: 'email', header: 'Email' },
      { field: 'roles', header: 'Roles' },
      { field: 'lastWebLoginDate', header: 'Last Web Login' },
      { field: 'lastMobileLoginDate', header: 'Last Mobile Login' }

    ];
    setTimeout(() => {
      this.loadData();
    });
  }


  ngOnInit() {

    let gT = (key: string) => this.translationService.getTranslation(key);



  }


  ngAfterViewInit() {


  }
  showProvisionModal() {
    this.provisionModal.show();
  }

  addNewUserToList() {
    if (this.sourceUser) {
      Object.assign(this.sourceUser, this.editedUser);

      let sourceIndex = this.rowsCache.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);

      sourceIndex = this.rows.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1)
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);

      this.editedUser = null;
      this.sourceUser = null;
    }
    else {
      let user = new User();
      Object.assign(user, this.editedUser);
      this.editedUser = null;

      let maxIndex = 0;
      for (let u of this.rowsCache) {
        if ((<any>u).index > maxIndex)
          maxIndex = (<any>u).index;
      }

      (<any>user).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, user);
      this.rows.splice(0, 0, user);
      this.rows = [...this.rows];
    }
  }

  resetPassword(userid) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.accountService.resetPassword(userid).subscribe(results => console.log("succeed"), error => console.log(error));
    this.loadData();
  }

  onProvisionModalHidden() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    if (this.canViewRoles) {
      this.accountService.getUsersAndRoles().subscribe(results => this.onDataLoadSuccessful(results[0], results[1]), error => this.onDataLoadFailed(error));
    }
    else {
      this.accountService.getUsers().subscribe(users => this.onDataLoadSuccessful(users, this.accountService.currentUser.roles.map(x => new Role(x))), error => this.onDataLoadFailed(error));
    }
  }


  onDataLoadSuccessful(users: User[], roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    users.forEach((user, index, users) => {
      (<any>user).index = index + 1;
    });

    this.rowsCache = [...users];
    this.rows = users;

    this.allRoles = roles;
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    //this.alertService.showStickyMessage("Load Error", `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
    //  Error, error);
  }


  onSearchChanged(value: string) {

    this.rows = this.rowsCache.filter(r => Utilities.searchArray(this.searchText, false, r.userName, r.fullName, r.email, r.phoneNumber, r.jobTitle, r.roles));
  }

  onChangeActiveFilter() {
    if (this.display.value == 1)
      this.rows = this.rowsCache;
    else if (this.display.value == 2)
      this.rows = this.rowsCache.filter(r => Utilities.searchArray('true', false, r.isEnabled));

    else if (this.display.value == 3)
      this.rows = this.rowsCache.filter(r => Utilities.searchArray('false', false, r.isEnabled));

  }

  onEditorModalHidden() {
    this.editingUserName = null;
    this.userEditor.resetForm(true);
  }


  newUser() {
    this.editingUserName = null;
    this.sourceUser = null;
    //this.editedUser = this.userEditor.newUser(this.allRoles);
    this.addNew = true;

    //   this.editorModal.show();
  }


  editUser(row: UserEdit) {
    //this.editingUserName = { name: row.userName };
    //this.sourceUser = row;
    //this.editedUser = this.userEditor.editUser(row, this.allRoles);
    //this.editorModal.show();

    this.selectedUserId = row.id;
    this.addNew = true;

  }


  deleteUser(row: UserEdit) {
    this.selectedUserId = row;
    this.deleteModal.show();
  }


  deleteUserHelper() {
    this.hideDeleteModal();
    this.alertService.startLoadingMessage("Deleting...");
    this.loadingIndicator = true;

    this.accountService.deleteUser(this.selectedUserId.id)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== this.selectedUserId)
        this.rows = this.rows.filter(item => item !== this.selectedUserId)
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          //this.alertService.showStickyMessage("Delete Error", `An error occured whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessage(error)}"`,
          //  Error1, error);
        });
  }

  userJobs(row: UserEdit) {
    this.selectedUserId = row.id;
    this.jobMode = true;
    //this.router.navigate(['user-jobs', row.id]);

  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission)
  }

  get canManageUsers() {
    return this.accountService.userHasPermission(Permission.manageUsersPermission);
  }

  downloadCSV() {
    const csvData = this.rows.map(o => {
      return {
        id: o.id, name: o.userName, businessUnitName: o.businessUnitName, email: o.email, jobName: o.jobName, hrCode: o.hrCode, hiringDate: o.hiringDate,
        lastWebLoginDate: o.lastWebLoginDate, lastMobileLoginDate: o.lastMobileLoginDate
      };

    });
    new AngularCsv(csvData, "UsersList", this.csvOptions);
  }

  closeAdding(event) {
    this.selectedUserId = undefined;
    this.addNew = false;
    this.jobMode = false;
    if (event) {
      this.loadData();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
