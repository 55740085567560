import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { ItemBrand } from '../../models/lookups/item-brand.model';


@Injectable({
  providedIn: 'root'
})
export class ItemBrandService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }
  save(itemBrand: ItemBrand) {
    return this.http.post(this.configurations.apiAddress + "/api/ItemBrand/save", itemBrand);
  }

  search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/ItemBrand/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/getById/' + id);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/getAll/');
  }

  getAllForVisits() {
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/getAllForVisits/');
  }

  getAllForVisitsByDate(date) {
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/getAllForVisits?date=' + date.toDateString());
  }

  getAllByBusinessUnitId(businessUnitId) {
    return this.http.get(this.configurations.apiAddress + '/api/ItemBrand/getAllByBusinessUnitId/' + businessUnitId);
  }

}
