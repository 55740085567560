import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { HelperMethodsService } from '../../../services/helper-methods.service';
import { ItemService } from '../item.service';

@Component({
  selector: 'crm-save-item-price',
  templateUrl: './save-item-price.component.html',
  styleUrls: ['./save-item-price.component.css']
})
export class SaveItemPriceComponent implements OnInit {
  itemPrice: any = {};
  displayMode: DisplayModes = DisplayModes.Success;
  startDate: any;
  endDate: any;
  public changesCallback: () => void;

  constructor(private readonly itemService: ItemService,
    private readonly alertService: AlertService,
    private readonly helperMethods: HelperMethodsService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
  }

  close() {
    this.changesCallback();
  }

  new(itemId: number, priceType: number) {
    this.itemPrice = {};
    this.startDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    };
    this.endDate = {
      year: new Date().getFullYear()+1,
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 10
    };
    this.itemPrice.itemId = itemId;
    this.itemPrice.priceType = priceType;
    this.itemPrice.isCurrent = false;
  }

  edit(itemPrice) {
    this.itemPrice = itemPrice;
    let sDate = new Date(this.itemPrice.startDate);
    this.startDate = {
      year: sDate.getFullYear(),
      month: sDate.getMonth() + 1,
      day: sDate.getDate()
    };

    if (this.itemPrice.endDate !== null) {
      this.endDate = {
        year: new Date(this.itemPrice.endDate).getFullYear(),
        month: new Date(this.itemPrice.endDate).getMonth() + 1,
        day: new Date(this.itemPrice.endDate).getDate()
      }
    }

  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      console.log(this.startDate)
      console.log(new Date(this.startDate))
      this.itemPrice.startDate = this.helperMethods.convertDateToUTC(new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day + 1));
     
      if (this.endDate !== undefined)
        this.itemPrice.endDate = this.helperMethods.convertDateToUTC(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day + 1));
      console.log(this.itemPrice)
      this.itemService.saveItemPrice(this.itemPrice).subscribe(response => {
        this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);

        this.changesCallback();
      }, () => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showMessage("", this.translateService.getTranslation("FialedToSave"), MessageSeverity.Error);
        this.changesCallback();
      });
    }
  }

}
