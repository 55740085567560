<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!activityCostCenterId">Create {{'Activity Cost Center' | translate}}</span>
    <span class="panel-title" *ngIf="activityCostCenterId">Edit {{'Activity Cost Center' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!activityCostCenterId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (activityCostCenterName.dirty || activityCostCenterName.touched || f.submitted)  && activityCostCenterName.valid, 'has-error' : (activityCostCenterName.dirty || activityCostCenterName.touched || f.submitted)  && activityCostCenterName.invalid}">
              <label class="control-label">{{'clm.name' | translate}} </label>
              <input class="form-control" placeholder="{{'clm.name' | translate}}" required
                     name="activityCostCenterName" #activityCostCenterName="ngModel"
                     [(ngModel)]="activityCostCenter.name" type="text">
              <div *ngIf="(activityCostCenterName.dirty || activityCostCenterName.touched || f.submitted)  && activityCostCenterName.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (amou.dirty || amou.touched || f.submitted)  && amou.valid, 'has-error' : (amou.dirty || amou.touched || f.submitted)  && amou.invalid}">
              <label class="control-label">{{'Budget Amount' | translate}} </label>
              <input class="form-control" placeholder="{{'Budget Amount' | translate}}" required
                     name="amou" #amou="ngModel"
                     [(ngModel)]="activityCostCenter.amount" type="number">
              <div *ngIf="(amou.dirty || amou.touched || f.submitted)  && amou.errors?.required" class="jquery-validate-error help-block">
                {{'generalValidations.requiredField' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnitsLines.dirty || businessUnitsLines.touched || f.submitted)  && businessUnitsLines.valid, 'has-error' : (businessUnitsLines.dirty || businessUnitsLines.touched || f.submitted)  && businessUnitsLines.invalid}">
              <label class="control-label">{{'Business Units' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Business Units'"
                                       [data]="businessUnitsLines"
                                       name="businessUnitsLin"
                                       #businessUnitsLin="ngModel"
                                       (onSelect)="onBuLineChanged($event,true)"
                                       (onDeSelect)="onBuLineChanged($event,false)"
                                       [(ngModel)]="buLinesSelected"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>
              <div *ngIf="(businessUnitsLines.dirty || businessUnitsLines.touched || f.submitted)  && businessUnitsLines.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (bran.dirty || bran.touched || f.submitted)  && bran.valid, 'has-error' : (bran.dirty || bran.touched || f.submitted)  && bran.invalid}">
              <label class="control-label">{{'Brands' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Brands'"
                                       [data]="itemBrands"
                                       name="bran"
                                       #bran="ngModel"
                                       (onSelect)="onBrandsChanged($event)"
                                       (onDeSelect)="onBrandsChanged($event)"
                                       [(ngModel)]="brandsSelected"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>
              <div *ngIf="(bran.dirty || bran.touched || f.submitted)  && bran.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnits.dirty || businessUnits.touched || f.submitted)  && businessUnits.valid, 'has-error' : (businessUnits.dirty || businessUnits.touched || f.submitted)  && businessUnits.invalid}">
              <label class="control-label">{{'Territory' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Territories'"
                                       [data]="businessUnits"
                                       name="businessUni"
                                       #businessUni="ngModel"
                                       (onSelect)="onBushanged($event)"
                                       (onDeSelect)="onBushanged($event)"
                                       [(ngModel)]="busSelected"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>
              <div *ngIf="(businessUnits.dirty || businessUnits.touched || f.submitted)  && businessUnits.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" [disabled]="f.invalid">{{'Save' | translate}}</button>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




