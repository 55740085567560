import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BusinessUnitBricksService } from '../business-unit-bricks.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDirective } from "ngx-bootstrap";
import { AddBusinessUnitBricksComponent } from "../add-business-unit-bricks/add-business-unit-bricks.component";
import { BusinessUnitBricks } from '../../../models/lookups/business-unit-bricks.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ProvisionBusinessUnitBricksComponent } from '../provision-business-unit-bricks/provision-business-unit-bricks.component';

@Component({
  selector: 'crm-manage-business-unit-bricks',
  templateUrl: './manage-business-unit-bricks.component.html',
  styleUrls: ['./manage-business-unit-bricks.component.css']
})

export class ManageBusinessUnitBricksComponent implements OnInit {

  //businessUnitId: number;
  businessUnitBricks: BusinessUnitBricks[] = [];
  displayMode: DisplayModes = DisplayModes.Success;

  @ViewChild("addBusinessUnitBrickModal")
  addBusinessUnitBrickModal: ModalDirective;

  @ViewChild("addBusinessUnitBrick")
  addBusinessUnitBrick: AddBusinessUnitBricksComponent;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("provisionBusinessUnitBricks")
  provisionBusinessUnitBricks: ProvisionBusinessUnitBricksComponent;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() businessUnitId: number;

  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  selectedBusinessUnitBrickId;
  endDate: any;
  constructor(private businessUnitBricksService: BusinessUnitBricksService,
    private readonly route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private readonly router: Router) {


  }

  ngOnInit() {
    if (this.businessUnitId != undefined)
      this.getBusinessUnitBricksByBusinessUnitd(this.businessUnitId);
  }

  getBusinessUnitBricksByBusinessUnitd(businessUnitId) {
    this.businessUnitBricksService.getBusinessUnitBricksByBusinessUnitd(businessUnitId).subscribe(response => {

      let results = response;
      this.businessUnitBricks = results as BusinessUnitBricks[];
      this.displayMode = DisplayModes.Success;

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  confirmDeleteHCOHCP(businessUnitId) {
    this.endDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.selectedBusinessUnitBrickId = businessUnitId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteBusinessUnitBrickById(businessUnitId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  deleteBusinessUnitBrickById() {
    this.hideDeleteModal();
    if (this.endDate !== undefined)
      this.endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 12, 0, 0);
    this.displayMode = DisplayModes.Loading;
    this.businessUnitBricksService.deleteBusinessUnitBrickById(this.selectedBusinessUnitBrickId, this.endDate).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      if (response == true) {
        this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
        this.getBusinessUnitBricksByBusinessUnitd(this.businessUnitId);
      }
      else {
        this.alertService.showMessage("Failed to delete", "This date is after satrt date or has end date already.", MessageSeverity.Error);
      }
     
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  addNewBusinessUnitBrick() {
    this.addBusinessUnitBrickModal.show();
  }

  onAddBusinessUnitBrickModalHidden() {
    this.displayMode = DisplayModes.Loading;
    this.getBusinessUnitBricksByBusinessUnitd(this.businessUnitId);
  }

  onCloseAddBusinessUnitBrickModal() {
    this.addBusinessUnitBrickModal.hide();
  }

  back() {
    this.closeEvent.emit(true);
  }

  showProvisionModal() {
    this.provisionModal.show();
  }

  onProvisionModalHidden() {

  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
