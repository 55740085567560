<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!jobId">Create {{'Job' | translate}}</span>
    <span class="panel-title" *ngIf="jobId">Edit {{'Job' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-12">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="job.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-12">
            <!--<div [ngClass]="{'has-success': (parentId.dirty || parentId.touched || f.submitted)  && parentId.valid, 'has-error' : (parentId.dirty || parentId.touched || f.submitted)  && parentId.invalid}">
        <label class="control-label">{{'ParentJob' | translate}} </label>
        <select id="parentId" name="parentId" [(ngModel)]="job.parentJobId" #parentId="ngModel" #parentsSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect
                data-live-search="true" data-actions-box="true" data-live-search-placeholder="Search..." title="Select Parent Job">
          <option *ngFor="let item of jobs"  attr.data-content="<span title='{{item.name}}'>{{item.name }}</span>" attr.value="{{item.id}}">
            {{item.name}}
          </option>
        </select>

      </div>-->
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>
