import { Component, OnInit } from '@angular/core';
import { OrganizationJobStructureService } from '../organization-job-structure.service';
import { HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'crm-provision-org-job-str-target-dates',
  templateUrl: './provision-org-job-str-target-dates.component.html',
  styleUrls: ['./provision-org-job-str-target-dates.component.css']
})

export class ProvisionOrgJobStrTargetDatesComponent implements OnInit {
  error: boolean;
  message: string;
  loading: boolean;
  targetTypes = [
    { name: 'AM' },
    { name: 'PM' },
    { name: 'PH' }
  ]
  years = [
    { name: 2020 }
  ]
  year;
  targetType = this.targetTypes[0];

  constructor(
    private orgJobStrService: OrganizationJobStructureService,

    private router: Router) { }

  ngOnInit() {
    let currentYear = new Date().getFullYear();
    for (let i = this.years[0].name + 1; i <= currentYear; i++) {
      this.years.push({ name: i });
    }
    this.years.push({ name: currentYear + 1 });
    this.year = this.years.find(x => x.name == currentYear);
  }

  downloadTemplate() {
    this.orgJobStrService.downloadCSVTargetDateTemplate(this.targetType.name, this.year.name).subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "org job str target dates.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }

  upload(files) {
    if (files.length === 0)
      return;
    const formData = new FormData();
    for (let file of files)
      formData.append(file.name, file);
    this.loading = true;
    this.error = false;
    this.message = "Loading .....";
    this.orgJobStrService.provisionCSVTargetDateTemplate(formData, this.targetType.name, this.year.name).subscribe(result => {
      this.loading = false;
      let res = result as boolean;
      if (res) {
        this.error = true;
        this.message = "error";
      }
      else
        this.message = "uploaded success";
    });

  }

}
