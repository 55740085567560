
//export const environment = {
//    production: true,
//    baseUrl: null, //Change this to the address of your backend API if different from frontend address
//  loginUrl: "/login",
    
//"ApiAddress": "http://shamys-001-site4.ftempurl.com"

//};

export const environment = {
  name:'',
  production: true,
  hmr: false,
  appConfig: 'appConfig.production.json'
};
