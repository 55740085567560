<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Shared Bricks' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Shared Bricks' | translate}}</h1>
  </div>
</div>


<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Shared Bricks Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6" *ngIf="bussinesUnits && bussinesUnits.length>1">
          <span class="ui-fluid">
            <p-dropdown [options]="bussinesUnits"
                        name="bussinesUn" #bussinesUn="ngModel"
                        styleClass="wid100"
                        placeholder="Bussiness Units"
                        emptyFilterMessage="No results found"
                        showClear="true"
                        [(ngModel)]="bussinesUnit"
                        [virtualScroll]="true" itemSize="10"
                        [filter]="true" optionLabel="name"></p-dropdown>

          </span>
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="search()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="adjustSharedBricksPercent()">
          <span class="margin-right-7 filter-span">Adjust Percentage</span>
          <i class="fa fa-recycle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-save-business-unit-shared-bricks *ngIf="editMode" (closeEvent)="closeAdding($event)" [brickId]="selectedBrickId" [buLineId]="selectedBuLineId"></crm-save-business-unit-shared-bricks>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Shared Bricks' | translate}} {{'Search Result' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="result" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align:center" class="table-edit">Line Name</th>
                <th style="text-align:center" class="table-edit">Brick Name</th>
                <th style="text-align:center" class="table-edit">Edit</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-brick>
              <tr *ngIf="result.length>0 && displayMode==2">
                <td>{{ brick.businessUnitLineName }}</td>
                <td>{{ brick.brickName }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(brick.brickId,brick.businessUnitLineId)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
              </tr>
              <tr *ngIf="result.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2">
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>
