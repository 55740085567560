
<ul class="breadcrumb breadcrumb-page">
  <div class="breadcrumb-label text-light-gray">{{'YouAreHere' | translate}}: </div>
  <li><a href="#">{{'Configuration' | translate}}</a></li>
  <li class="active"><a href="#">{{'CyclesBricks' | translate}}</a></li>
</ul>
<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'CyclesBricks' | translate}}</h1>
  </div>
</div>
<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==1">
  <div class="container text-center">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
  </div>

</div>
<div class="panel panel-success widget-support-tickets" *ngIf="displayMode==2">
  <div class="panel-heading">
    <span class="panel-title"><i class="panel-title-icon fa fa-bullhorn"></i>{{'CycleBricks' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">

    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row padding-sm-vr">
        <div class="col-sm-6">
          <div class="row padding-sm-vr ">
            <div class="col-sm-12">
              <label class="control-label">{{'Cycle' | translate}} :</label>&nbsp;
              <label class="label label-primary">{{cycle.name}}</label>
            </div>


          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <label class="control-label">{{'brickLevel' | translate}} </label><br />
            <span class="ui-fluid">
              <p-dropdown [options]="brickLevels"
                          (onChange)="getAllBricks()"
                          styleClass="wid100"
                          placeholder="Brick Level"
                          emptyFilterMessage="No results found"
                          showClear="true"
                          [styleClass]="form-control"
                          name="brickLevelId" #brickLevelId="ngModel"
                          required="required" [(ngModel)]="brickLevel"
                          [filter]="true" optionLabel="name"></p-dropdown>
            </span>


          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12">

          <p-treeTable #dt [value]="bricks" [resizableColumns]="true" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <th ttResizableColumn [ttSortableColumn]="'name'">
                  Name
                  <p-treeTableSortIcon [field]="'name'"></p-treeTableSortIcon>

                </th>
                <th ttResizableColumn>Brick Level</th>
                <th ttResizableColumn>Address</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttRow]="rowNode">
                <td>
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <!--<input type="checkbox" [(ngModel)]="rowData.isSelected" />-->
                  <p-checkbox [(ngModel)]="selectedBricks" name="chkBrick"
                              inputId="{{rowData.name}}"
                              value="{{rowData.id}}" label="{{rowData.name}}"></p-checkbox>

                </td>
                <td>{{rowData.brickLevelName}}</td>
                <td>{{rowData.address}}</td>

              </tr>
            </ng-template>
          </p-treeTable>
         
        </div>

      </div>

      <div class="panel-footer">
        <div class="row" style="float:right;">

          <div class="col-sm-12">
            <button class="btn btn-labeled btn-default" type="button" (click)="back()"><span class="btn-label icon fa fa-backward"></span>{{'Back' | translate}}</button>
            &nbsp;
            <button class="btn btn-labeled btn-success " type="button" (click)="saveCycleBricks()"> <span class="btn-label icon fa fa-save"></span>{{'Save' | translate}}</button>

            <!--<button class="btn btn-lg btn-primary wizard-next-step-btn" (click)="next()">{{'controls.Next' | translate}}</button>-->
          </div>



          

         



        </div>

        

      

      </div>



    </div>


  </div>
</div>

<div class="row" *ngIf="displayMode==3">
  <div class="col-sm-12">
    <div class="alert alert-danger alert-dark">
      <button type="button" class="close" data-dismiss="alert">×</button>
      {{'ServerError' |translate}}.
    </div>
  </div>

</div>
