import { Component, OnInit, ViewChild } from '@angular/core';
import { Vendor } from '../../models/lookups/vendor.model';
import { DisplayModes } from '../../models/enums';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { VendorService } from './vendor.service';
import { Permission } from '../../models/permission.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
  selector: 'crm-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {

  vendors: Vendor[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedVendorId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly vendorService: VendorService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getVendors();
  }

  getVendors() {
    this.displayMode = DisplayModes.Loading;
    this.vendorService.search(this.searchText).subscribe(responce => {
      this.vendors = responce as Vendor[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get vendor');
        console.log(err);
      })
  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedVendorId = id;
    this.addNew = true;
  }

  confirmDelete(vendor) {
    this.selectedVendorId = vendor;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.vendorService.delete(this.selectedVendorId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.vendors.indexOf(this.selectedVendorId);
      this.vendors.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete activity CostType:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageVendorPermission() {
    return this.accountService.userHasPermission(Permission.manageVendorPermission);
  }

  closeAdding(event) {
    this.selectedVendorId = undefined;
    this.addNew = false;
    if (event) {
      this.getVendors();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  downloadCSV() {
    const csvData = this.vendors.map(o => {
      return {
        id: o.id, name: o.name, comercialRecord: o.comercialRecord, taxCard: o.taxCard, bankName: o.bankName,
        bankAccountNo: o.bankAccountNo, branchNo: o.branchNo, addedValueRegestrationNo: o.addedValueRegestrationNo
      };
    });
    new AngularCsv(csvData, "Vendors", this.csvOptions);

  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Vendors :',
    useBom: true,
    noDownload: false,
    headers: ["Code", "Name", "Comercial Record", "Tax Card", "Bank Name", "Bank Account No.", "Branch No.", "Added Value Regestration No."]
  };

}
