import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import { ConfigurationService } from "../../services/configuration.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitBricksService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService,) {
  }

  getAllBusinessUnitLastLevel() {
    return this.http.get(this.configurations.apiAddress + "/api/businessUnit/getBusinessUnitsLastLevel");
  }

  getAllBricksLeafLevel() {
    return this.http.get(this.configurations.apiAddress + "/api/bricks/getBricksLeafLevel");
  }

  addBusinessUnitBricks(businessUnitId: number, brickIds: number[], startDate = undefined) {
    let myParams = new HttpParams()
      .set("businessUnitId", businessUnitId.toString())
      .set("brickIds", brickIds.join(', '))
      .set('startDate', startDate == undefined ? "" : startDate.toDateString())
    return this.http.get(this.configurations.apiAddress + "/api/businessUnit/addBusinessUnitBricks", { params: myParams });
  }

  getBusinessUnitBricksByBusinessUnitd(businessUnitId: number) {
    return this.http.get(this.configurations.apiAddress + "/api/businessUnit/getBusinessUnitBricksByBusinessUnitd/" + businessUnitId);
  }

  deleteBusinessUnitBrickById(id: number, endDate = undefined) {
    let myParams = new HttpParams()
      .set('endDate', endDate == undefined ? "" : endDate.toDateString());
    return this.http.delete(this.configurations.apiAddress + "/api/businessUnit/deleteBusinessUnitBrickById/" + id, { params: myParams });
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/businessUnit/downloadCSVTemplateBUB", { responseType: "blob" });
  }

  provisionBusinessUnitsBrick(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/businessUnit/UploadCSVTemplateBUB`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }
}
