import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { HelperMethodsService } from '../../../services/helper-methods.service';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { OrganizationJobStructureService } from '../organization-job-structure.service';

@Component({
  selector: 'crm-save-org-job-str-target-dates',
  templateUrl: './save-org-job-str-target-dates.component.html',
  styleUrls: ['./save-org-job-str-target-dates.component.css']
})
export class SaveOrgJobStrTargetDatesComponent implements OnInit {

  orgJobStrTarget: any = {};
  displayMode: DisplayModes = DisplayModes.Success;
  startDate: any;
  endDate: any;
  public changesCallback: () => void;

  constructor(private readonly organizationJobStructureService: OrganizationJobStructureService,
    private readonly alertService: AlertService,
    private readonly helperMethods: HelperMethodsService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
  }

  close() {
    this.changesCallback();
  }

  new(orgJobStrId: number, targetType: string) {
    this.orgJobStrTarget = {};
    this.startDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    };
    this.endDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 10
    };
    this.orgJobStrTarget.organizationJobStructureId = orgJobStrId;
    this.orgJobStrTarget.isCurrent = false;
    this.orgJobStrTarget.targetType = targetType;
  }

  edit(orgJobStrTarget) {
    this.orgJobStrTarget = orgJobStrTarget;
    let sDate = new Date(this.orgJobStrTarget.startDate);
    this.startDate = {
      year: sDate.getFullYear(),
      month: sDate.getMonth() + 1,
      day: sDate.getDate()
    };

    if (this.orgJobStrTarget.endDate !== null) {
      this.endDate = {
        year: new Date(this.orgJobStrTarget.endDate).getFullYear(),
        month: new Date(this.orgJobStrTarget.endDate).getMonth() + 1,
        day: new Date(this.orgJobStrTarget.endDate).getDate()
      }
    }

  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

      this.orgJobStrTarget.startDate = this.helperMethods.convertDateToUTC(new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day + 1));

      if (this.endDate !== undefined)
        this.orgJobStrTarget.endDate = this.helperMethods.convertDateToUTC(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day + 1));

      this.organizationJobStructureService.saveOrganizationJobStructureTargetDates(this.orgJobStrTarget).subscribe(response => {
        this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
        this.changesCallback();
      }, () => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showMessage("", this.translateService.getTranslation("FialedToSave"), MessageSeverity.Error);
        this.changesCallback();
      });
    }
  }


}
