
<ul class="breadcrumb breadcrumb-page">
  <!--<div class="breadcrumb-label text-light-gray">{{'YouAreHere' | translate}}: </div>-->
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Profile Settings' | translate}}</li>
</ul>
<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'Profile Settings' | translate}}</h1>


  </div>
</div>
<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Profile Managements' | translate}}</span>

  </div>

  <div class="panel-body padding-sm-hr">

    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">



      <div [@fadeInOut] class="row">
        <div class="col-sm-12">
          <ul bootstrapTab #tab="bootstrap-tab" class="nav nav-tabs nav-tabs-light-green" (showBSTab)="onShowTab($event)">
            <li class="active">
              <a id="profileTab" [routerLink]="[]" fragment="profile" href="#profile" data-toggle="tab"><i class="fa fa-user-circle-o fa-fw" aria-hidden="true"></i> {{'settings.tab.Profile' | translate}}</a>
            </li>
           
            <li [hidden]="!canViewUsers">
              <a id="usersTab" [routerLink]="[]" fragment="users" href="#users" data-toggle="tab"><i class="fa fa-users fa-fw" aria-hidden="true"></i> {{'settings.tab.Users' | translate}}</a>
            </li>
            <li [hidden]="!canViewRoles">
              <a id="rolesTab" [routerLink]="[]" fragment="roles" href="#roles" data-toggle="tab"><i class="fa fa-shield fa-fw" aria-hidden="true"></i> {{'settings.tab.Roles' | translate}}</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12">
          <div class="tab-content">
            <div class="tab-pane active" id="profile">
              <!--<h4>{{'settings.header.UserProfile' | translate}}</h4>
              <hr class="separator-hr" />-->
              <div [@fadeInOut] *ngIf="isProfileActivated" class="content-container">
                <user-info></user-info>
              </div>
            </div>

            <div class="tab-pane" id="preferences">
              <h4>{{'settings.header.UserPreferences' | translate}}</h4>
              <hr class="separator-hr" />
              <div [@fadeInOut] *ngIf="isPreferencesActivated" class="content-container">
                <user-preferences></user-preferences>
              </div>
            </div>

            <div class="tab-pane" id="users">
              <h4>{{'settings.header.UsersManagements' | translate}}</h4>
              <hr class="separator-hr" />
              <div [@fadeInOut] *ngIf="canViewUsers && isUsersActivated" class="content-container">
                <users-management></users-management>
              </div>
            </div>

            <div class="tab-pane" id="roles">
              <h4>{{'settings.header.RolesManagement' | translate}}</h4>
              <hr class="separator-hr" />
              <div [@fadeInOut] *ngIf="canViewRoles && isRolesActivated" class="content-container">
                <roles-management></roles-management>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
