<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Cycles' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Cycles' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Cycle Info' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="cycle.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (startDate.dirty || startDate.touched || f.submitted)  && startDate.valid, 'has-error' : (startDate.dirty || startDate.touched || f.submitted)  && startDate.invalid}">
              <label class="control-label">{{'settings.header.StartDate' | translate}} </label>

              <input type="text"
                     name="startDate"
                     [(ngModel)]="cycle.startDate"
                     #startDate="ngModel"
                     required="required"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [minDate]="minStartDate"
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }"
                     class="form-control" />

              <div *ngIf="(startDate.dirty || startDate.touched || f.submitted)  && startDate.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (endDate.dirty || endDate.touched || f.submitted)  && endDate.valid, 'has-error' : (endDate.dirty || endDate.touched || f.submitted)  && endDate.invalid}">
              <label class="control-label">{{'settings.header.EndDate' | translate}} </label>
              <input type="text"
                     name="endDate"
                     [(ngModel)]="cycle.endDate"
                     #endDate="ngModel"
                     required="required"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [minDate]="cycle.startDate"
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }"
                     class="form-control" />


              <div *ngIf="(endDate.dirty || endDate.touched || f.submitted)  && endDate.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-12">
            <div [ngClass]="{'has-success': (objectives.dirty || objectives.touched || f.submitted)  && objectives.valid, 'has-error' : (objectives.dirty || objectives.touched || f.submitted)  && objectives.invalid}">
              <label class="control-label">{{'Objectives' | translate}} </label>
              <textarea type="text"
                        name="objectives"
                        [(ngModel)]="cycle.objectives"
                        #objectives="ngModel"
                        class="form-control"></textarea>
              <div *ngIf="(objectives.dirty || objectives.touched || f.submitted)  && objectives.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (active.dirty || active.touched || f.submitted)  && active.valid, 'has-error' : (active.dirty || active.touched || f.submitted)  && active.invalid}">
              <label class="control-label">{{'Active' | translate}} </label>

              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" #active="ngModel" name="active" [(ngModel)]="cycle.active">{{'Active' | translate}}  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Back' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save & Next' | translate}}</button>
        </div>


      </form>
    </div>
  </div>

</div>


<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>

