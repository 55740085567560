
<ng2-toasty></ng2-toasty>

<div id="main-wrapper" [hidden]="!isUserLoggedIn">


  <div id="main-navbar" class="navbar navbar-inverse" role="navigation">
    <!-- Main menu toggle -->
    <button type="button" id="main-menu-toggle"><i class="navbar-icon fa fa-bars icon"></i><span class="hide-menu-text">HIDE MENU</span></button>

    <div class="navbar-inner">
      <!-- Main navbar header -->
      <div class="navbar-header">

        <!-- Logo -->
        <a routerLink="/dashboard" class="navbar-brand">
          <div>
            <img alt="" [src]="logo">
          </div>
          <!--{{appTitle}}-->
        </a>

        <!-- Main navbar toggle -->
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar-collapse"><i class="navbar-icon fa fa-bars"></i></button>

      </div>

      <div id="main-navbar-collapse" class="collapse navbar-collapse main-navbar-collapse">
        <div>


          <div class="right clearfix">
            <ul class="nav navbar-nav pull-right right-navbar-nav">

              <li class="dropdown" *ngIf="isUserLoggedIn">
                <a href="#" class="dropdown-toggle user-menu" data-toggle="dropdown">
                  <img [src]="profilePic" alt="">
                  <span>{{userName}}</span>
                </a>
                <ul class="dropdown-menu">

                  <li routerLinkActive="active"><a routerLink="/user-profile"><i class="dropdown-icon fa fa-cog"></i>&nbsp;&nbsp;{{'UserProfile' | translate}}</a></li>
                  <li class="divider"></li>
                  <li (click)="logout()"><a href="javascript:;"><i class="dropdown-icon fa fa-power-off"></i>&nbsp;&nbsp;{{'Logout' | translate}}</a></li>
                </ul>
              </li>
            </ul> <!-- / .navbar-nav -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <crm-main-menu></crm-main-menu>




  <div id="pre-bootstrap" *ngIf="!removePrebootScreen" [class.prebootShow.app-component]="!isAppLoaded" class="app-component prebootStep">
    <div class="messaging">
      <h1>
        {{'Loaded' |translate}}
      </h1>


    </div>
  </div>

  <div id="content-wrapper">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <div id="main-menu-bg"></div>



</div>


<div *ngIf="!isUserLoggedIn">
  <div>
    <crm-login></crm-login>
  </div>
</div>

<div bsModal #idleModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideIdleModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{idleState}}
        <hr />


        <div class="modal-footer">
          <div class="row" style="float:right;">

            <div class="col-sm-12">
              <button type="button" (click)="idleLogout()" class="btn btn-danger">Logout</button>
              <button type="button" (click)="stay()" class="btn btn-success">Stay</button>

            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>

