<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!itemId">Create {{'Giveaway Items' | translate}}</span>
    <span class="panel-title" *ngIf="itemId">Edit {{'Giveaway Items' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="item.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.valid, 'has-error' : (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid}">
              <label class="control-label" for="businessUnitId-{{uniqueId}}">{{'items.businessUnit' | translate}}</label>
              <span class="ui-fluid">
                <p-dropdown [options]="businessUnits"
                            styleClass="wid100"
                            placeholder="Business Unit"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="businessUnitId" #businessUnitId="ngModel"
                            required="required" [(ngModel)]="item.businessUnit"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (giveawayItemTypeId.dirty || giveawayItemTypeId.touched || f.submitted)  && giveawayItemTypeId.valid, 'has-error' : (giveawayItemTypeId.dirty || giveawayItemTypeId.touched || f.submitted)  && giveawayItemTypeId.invalid}">
              <label class="control-label" for="giveawayItemTypeId-{{uniqueId}}">{{'GiveawayItem Type' | translate}}</label>
              <span class="ui-fluid">
                <p-dropdown [options]="giveawayItemTypes"
                            styleClass="wid100"
                            placeholder="Giveaway Types"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="giveawayItemTypeId" #giveawayItemTypeId="ngModel"
                            required="required" [(ngModel)]="item.giveawayItemType"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(giveawayItemTypeId.dirty || giveawayItemTypeId.touched || f.submitted)  && giveawayItemTypeId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




