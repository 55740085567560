import { Component, EventEmitter, OnInit, Output, ViewChild, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityService } from '../speciality.service';
import { DisplayModes } from '../../../models/enums';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Specialty } from '../../../models/lookups/speciality.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { LookupsService } from '../../../services/lookups.service';

@Component({
  selector: 'crm-save-speciality',
  templateUrl: './save-speciality.component.html',
  styleUrls: ['./save-speciality.component.css']
})
export class SaveSpecialityComponent implements OnInit {

  specialty: Specialty = new Specialty(true);
  displayMode: DisplayModes = DisplayModes.Success;
  
  specialities: Specialty[] = [];
  
  searchText: string = "";
  //specialityId: number;
  @ViewChild('parentIdId')
  private parntId;

  @ViewChild('specialitiesSelector')
  private specialitiesSelector;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() specialityId: number;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private specialtyService: SpecialityService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
   this. getSpecialities();
    if (this.specialityId != undefined)
      this.getDetails(this.specialityId);
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.specialtyService.Save(this.specialty).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.specialty.id == 0)
          this.closeEvent.emit(true);
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  getSpecialities() {
    this.displayMode = DisplayModes.Loading;
    this.specialtyService.GetAllSpecialities("").subscribe(response => {
      let results = response;
      this.specialities = results as Specialty[];
      this.displayMode = DisplayModes.Success;
      setTimeout(() => this.specialitiesSelector.refresh());
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }


  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.specialtyService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.specialty = response as Specialty;
    }, err => {
      this.displayMode = DisplayModes.Error;

    });
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }

}
