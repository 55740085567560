import { BusinessUnit } from "../../models/lookups/business-unit.model";

export class GiveawayItem {
  id: number;
  name: string;
  businessUnitId: number;
  businessUnit: BusinessUnit;
  giveawayItemTypeId: number;
  giveawayItemType: any;
}
