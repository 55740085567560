import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GiveawayItem } from '../../models/giveaway-item.model';
import { GiveawayItemService } from '../../services/giveaway-item.service';
import { DisplayModes } from '../../../models/enums';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { BusinessUnitService } from '../../business-unit/business-unit.service';

@Component({
  selector: 'crm-save-giveaway-item',
  templateUrl: './save-giveaway-item.component.html',
  styleUrls: ['./save-giveaway-item.component.css']
})
export class SaveGiveawayItemComponent implements OnInit {
  item: GiveawayItem = new GiveawayItem();
  displayMode: DisplayModes;
  //itemId: number;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemId: number;
  businessUnits: BusinessUnit[] = [];
  giveawayItemTypes: any[] = [];
  constructor(private readonly giveawayItemService: GiveawayItemService,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly businessUnitService: BusinessUnitService,
    private readonly router: Router) { }

  ngOnInit() {
    this.getBusinessUnits();
    this.getGiveawayItemTypes();
    this.displayMode = DisplayModes.Success;
    if (this.itemId != undefined)
      this.getDetails(this.itemId);

  }

  save(form) {
    if (!form.invalid) {
      if (this.item.businessUnit)
        this.item.businessUnitId = this.item.businessUnit.id;

      if (this.item.giveawayItemType)
        this.item.giveawayItemTypeId = this.item.giveawayItemType.id;
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.giveawayItemService.save(this.item).subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.item.id == 0)
          this.item = new GiveawayItem();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }


  back() {
    this.closeEvent.emit(true);
  }


  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.giveawayItemService.getById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.item = response as GiveawayItem;
      if (this.item.businessUnitId && this.businessUnits && this.businessUnits.length > 0)
        this.item.businessUnit = this.businessUnits.find(x => x.id == this.item.businessUnitId)

      if (this.item.giveawayItemTypeId && this.giveawayItemTypes && this.giveawayItemTypes.length > 0)
        this.item.giveawayItemType = this.giveawayItemTypes.find(x => x.id == this.item.giveawayItemTypeId)
    }, err => {
      this.displayMode = DisplayModes.Error;

    });
  }

  getBusinessUnits() {
    this.businessUnitService.getBusinessUnitsRootLevel().subscribe(response => {
      this.businessUnits = response as BusinessUnit[];
      if (this.item.businessUnitId && this.businessUnits)
        this.item.businessUnit = this.businessUnits.find(x => x.id == this.item.businessUnitId)
    }, error => { });
  }

  getGiveawayItemTypes() {
    this.giveawayItemService.getAllGiveawayItemTypes().subscribe(response => {
      this.giveawayItemTypes = response as any[];
      if (this.item.giveawayItemTypeId && this.giveawayItemTypes && this.giveawayItemTypes.length > 0)
        this.item.giveawayItemType = this.giveawayItemTypes.find(x => x.id == this.item.giveawayItemTypeId)
    }, error => { });
  }
}
