<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title" *ngIf="!organizationTypeId">Create {{'settings.header.OrganizationType' | translate}}</span>
    <span class="panel-title" *ngIf="organizationTypeId">Edit {{'settings.header.OrganizationType' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2" novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="organizationType.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        
          <div class="col-sm-6" *ngIf="applyAmPmOrganizationType == 'True' ">
            <label class="control-label"> </label>
            <div>
              <ui-switch uncheckedLabel="PM"
                         checkedLabel="AM"
                         name="isAM"
                         #isAM="ngModel"
                         [(ngModel)]="organizationType.isAm"
                         size="large"></ui-switch>

            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div>
              <label class="control-label"> </label>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" #active="ngModel" name="specifictarget" [(ngModel)]="organizationType.hasSpecificTarget">
                    {{'Specific Target' | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




