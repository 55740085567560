import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { BusinessUnit } from '../../models/lookups/business-unit.model';
import { LookupsService } from '../../services/lookups.service';

@Component({
  selector: 'crm-business-unit-shared-bricks',
  templateUrl: './business-unit-shared-bricks.component.html',
  styleUrls: ['./business-unit-shared-bricks.component.css']
})
export class BusinessUnitSharedBricksComponent implements OnInit {
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  displayMode: DisplayModes = DisplayModes.Success;
  result: any[];
  public logo = require("../../../app/assets/images/demo/Icon_Business Units.png");
  editMode: boolean = false;
  selectedBrickId;
  selectedBuLineId;
  bussinesUnits: BusinessUnit[] = [];
  bussinesUnit: BusinessUnit;
  bussinesUnitId: number = 0;

  constructor(private readonly alertService: AlertService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly translateService: AppTranslationService, private readonly lookupsService: LookupsService,
    private readonly appConfig: AppConfigService,) { }

  ngOnInit() {
    this.search();
    this.getBusinessUnitsRootLevel();
  }

  search() {
    this.bussinesUnitId = undefined;
    if (this.bussinesUnit)
      this.bussinesUnitId = this.bussinesUnit.id;
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.businessUnitService.getListOfSharedBricks(this.bussinesUnitId)
      .subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.result = response as any[];
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
  }

  gotoEdit(brickId, businessUnitLineId) {
    this.selectedBrickId = brickId;
    this.selectedBuLineId = businessUnitLineId;
    this.editMode = true;
  }

  closeAdding(event) {
    this.selectedBrickId = undefined;
    this.selectedBuLineId = undefined;
    this.editMode = false;
    if (event) {
      this.search();
    }
  }


  downloadCSV() {
    const csvData = this.result.map(o => {
      return {
        businessUnitLineName: o.businessUnitLineName, brickName: o.brickName
      };
    });
    new AngularCsv(csvData, "Shared Bricks", this.csvOptions);

  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Shared Bricks :',
    useBom: true,
    noDownload: false,
    headers: ["Line Name", "Brick Name"]
  };

  getBusinessUnitsRootLevel() {
    this.lookupsService.getUserBusinessUnitsRootLevel().subscribe(response => {
      const results = response;
      this.bussinesUnits = results as BusinessUnit[];
    }, () => {
    });
  }

  adjustSharedBricksPercent() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Loading"));
    this.displayMode = DisplayModes.Loading;

    this.businessUnitService.adjustSharedBricksPercent()
      .subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", "Succeed", MessageSeverity.Success);
        this.displayMode = DisplayModes.Success;
      }, () => {
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", "Error", MessageSeverity.Error);
        this.displayMode = DisplayModes.Error;
      });
  }
}
