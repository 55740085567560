import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { ActivityCostType } from '../../models/lookups/activity-cost-type.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityCostTypeService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  save(activityCostType: ActivityCostType) {
    return this.http.post(this.configurations.apiAddress + "/api/ActivityCostType/save", activityCostType);
  }

  search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostType/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/ActivityCostType/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostType/getById/' + id);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostType/getAll/');
  }

  getAllForActivityType(activityTypeId) {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityCostType/getAllForActivityType/' + activityTypeId);
  }
}
