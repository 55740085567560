import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { ActivityCostCenter } from '../../models/lookups/activity-cost-center.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ActivityCostCenterService } from './activity-cost-center.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { BusinessUnit } from '../../models/lookups/business-unit.model';
import { LookupsService } from '../../services/lookups.service';

@Component({
  selector: 'crm-activity-cost-center',
  templateUrl: './activity-cost-center.component.html',
  styleUrls: ['./activity-cost-center.component.css']
})
export class ActivityCostCenterComponent implements OnInit {

  activityCostCenters: ActivityCostCenter[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedActivityCostCenterId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  @ViewChild("provisionModal")
  provisionModal: ModalDirective;
  updateModel: boolean = false;
  bussinesUnits: BusinessUnit[] = [];
  bussinesUnit: BusinessUnit;
  bussinesUnitId: number = 0;

  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService, private readonly lookupsService: LookupsService,
    private readonly activityCostCenterService: ActivityCostCenterService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getActivityCostCenters();
    this.getBusinessUnitsRootLevel();
  }

  getActivityCostCenters() {
    this.bussinesUnitId = undefined;
    if (this.bussinesUnit)
      this.bussinesUnitId = this.bussinesUnit.id;
    this.displayMode = DisplayModes.Loading;
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.activityCostCenterService.search(this.searchText, this.bussinesUnitId).subscribe(responce => {
      this.activityCostCenters = responce as ActivityCostCenter[];
      this.displayMode = DisplayModes.Success;
      this.alertService.stopLoadingMessage();
    },
      err => {
        this.displayMode = DisplayModes.Error;
        this.alertService.stopLoadingMessage();
        console.log('error in get Activity Cost Center');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedActivityCostCenterId = id;
    this.addNew = true;
  }

  confirmDelete(activityCostCenter) {
    this.selectedActivityCostCenterId = activityCostCenter;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.activityCostCenterService.delete(this.selectedActivityCostCenterId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.activityCostCenters.indexOf(this.selectedActivityCostCenterId);
      this.activityCostCenters.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete Activity Cost Center:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageActivityCostCenterPermission() {
    return this.accountService.userHasPermission(Permission.manageActivityCostCenterPermission);
  }

  closeAdding(event) {
    this.selectedActivityCostCenterId = undefined;
    this.addNew = false;
    if (event) {
      this.getActivityCostCenters();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  showProvisionModal(event) {
    this.updateModel = false;
    this.addNew = false;
    this.provisionModal.show();
  }

  onProvisionModalHidden() {
    this.getActivityCostCenters();
  }

  showUpdatenModal() {
    this.updateModel = true;
    this.provisionModal.show();
  }

  downloadCSV() {
    const csvData = this.activityCostCenters.map(o => {
      return { id: o.id, name: o.name, amount: o.amount };
    });
    new AngularCsv(csvData, "ActivityCostCenter", this.csvOptions);

  }
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'ActivityCostCenter :',
    useBom: true,
    noDownload: false,
    headers: ["Id", "Name", "Amount"]
  };

  getBusinessUnitsRootLevel() {
    this.lookupsService.getUserBusinessUnitsRootLevel().subscribe(response => {
      const results = response;
      this.bussinesUnits = results as BusinessUnit[];
    }, () => {
    });
  }
}
