<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Items' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Items' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Items Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="searchText"
                 [(ngModel)]="search.searchText"
                 placeholder="{{'settings.header.SearchText' |translate}}"
                 #name="ngModel"
                 class="form-control" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="searchItems()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="goToNew()">
          <span class="margin-right-7 create-new-span">Create New Item</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-item *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [itemId]="selectedItemId"></crm-add-item>
<crm-item-price *ngIf="itemPriceMode" (closeEvent)="closeAdding($event)" [itemId]="selectedItemId" [priceType]="selectedPriceType"></crm-item-price>
<crm-item-businessunit *ngIf="itemBusinessunitMode" (closeEvent)="closeAdding($event)" [itemId]="selectedItemId"></crm-item-businessunit>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Items Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="items" [responsive]="true" [resizableColumns]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn style="padding: 0.25em 0.5em; font-size: 13px;" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr *ngIf="items.length>0 && displayMode==2">
                <!--<td>
                  <strong>{{item.id}}</strong>
                </td>-->
                <td>{{ item.name }}</td>
                <td>{{ item.itemBrandName  }}</td>
                <td><a href="javascript:;" (click)="getItemPrices(item,itemPriceType.PublicPrice)">{{ item.publicPrice }} </a></td>
                <td><a href="javascript:;" (click)="getItemPrices(item,itemPriceType.XFactorPrice)">{{ item.xFactorPrice }} </a></td>
                <td><a href="javascript:;" (click)="getItemPrices(item,itemPriceType.TenderPrice)"> {{ item.tenderPrice }}</a></td>
                <td><a href="javascript:;" (click)="getItemPrices(item,itemPriceType.ExportPrice)">{{ item.exportPrice }} </a></td>
                <td><a href="javascript:;" (click)="getItemPrices(item,itemPriceType.AveragePrice)"> {{ item.averagePrice }}</a></td>
                <td>{{ item.itemFormatName  }}</td>
                <td>{{ item.itemTypeName  }}</td>
                <td>
                  <a *ngIf="item.businessUnitName" href="javascript:;" (click)="getBusinessUnit(item)"> {{ item.businessUnitName }}</a>
                  <a *ngIf="!item.businessUnitName" class="" href="javascript:;" (click)="getBusinessUnit(item)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>

                <td>{{ item.isActive == true ? 'Active' : 'InActive'  }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(item.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDeleteItem(item.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

              </tr>
              <tr *ngIf="items.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>


        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2 cursor-pointer left-15">
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Items Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-items #provisionItems></crm-provision-items>
    </div>
  </div>
</div>
