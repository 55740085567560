import {Injectable} from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BusinessUnit} from "../models/lookups/business-unit.model";

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  constructor(private readonly http: HttpClient,
    private readonly configuration: ConfigurationService) { }


  getAllUsersMini(activeOnly = false) {
    return this.http.get(`${this.configuration.apiAddress}/api/users/getAllUsersMini?activeOnly=${activeOnly}`);
  }

  getAllUsersMiniReport(disabledUserDate = undefined) {
    let myParams = new HttpParams()
      .set('disabledUserDate', disabledUserDate == undefined ? "" : disabledUserDate.toDateString());
    return this.http.get(`${this.configuration.apiAddress}/api/users/getAllUsersMiniReport`, { params: myParams });

  }
  getUsersByBusinessUnit(businessUnitId) {

    return this.http.get(`${this.configuration.apiAddress}/api/users/getUsersByBusinessUnit?businessUnitId=` + businessUnitId);
  }
  getUsersByBusinessUnitType(businessUnitTypeId) {

    return this.http.get(`${this.configuration.apiAddress}/api/users/getUsersByBusinessUnitType?businessUnitTypeId=` + businessUnitTypeId);
  }
  getUsersByBusinessUnits(businessUnits: BusinessUnit[]) {
    return this.http.post(`${this.configuration.apiAddress}/api/users/getAllUsersMiniByBusinessIds`, businessUnits);
  }

  getUsersByManager() {
    return this.http.get(`${this.configuration.apiAddress}/api/users/usersByManager`);
  }
  getManagers() {
    return this.http.get(`${this.configuration.apiAddress}/api/users/getManagers`);
  }
}
