import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AccountEndpoint } from './account-endpoint.service';
import { AuthService } from './auth.service';
import { User } from '../models/user.model';
import { Role } from '../models/role.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from '../models/user-edit.model';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { ConfigurationService } from './configuration.service';




export type RolesChangedOperation = "add" | "delete" | "modify";
export type RolesChangedEventArg = { roles: Role[] | string[], operation: RolesChangedOperation };



@Injectable()
export class AccountService {

  public static readonly roleAddedOperation: RolesChangedOperation = "add";
  public static readonly roleDeletedOperation: RolesChangedOperation = "delete";
  public static readonly roleModifiedOperation: RolesChangedOperation = "modify";

  private _rolesChanged = new Subject<RolesChangedEventArg>();


  constructor(private readonly router: Router,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly configurations: ConfigurationService,
    private readonly accountEndpoint: AccountEndpoint,
    private readonly configuration: ConfigurationService) {

  }


  getUser(userId?: string) {
    return this.accountEndpoint.getUserEndpoint<User>(userId);
  }

  getUserAndRoles(userId?: string) {

    return forkJoin(
      this.accountEndpoint.getUserEndpoint<User>(userId),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  getUsers(page?: number, pageSize?: number) {

    return this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize);
  }

  getUsersAndRoles(page?: number, pageSize?: number) {

    return forkJoin(
      this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
      this.accountEndpoint.getRolesEndpoint<Role[]>());
  }

  getRole(roleId: string) {
    return this.accountEndpoint.getRoleEndpoint(roleId);
  }


  updateUser(user: User) {
    if (user.id) {
      return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
    }
    else {
      return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName);
        //.pipe<User>(
        //mergeMap(foundUser => {
        //  user.id = foundUser.id;
        //  return this.accountEndpoint.getUpdateUserEndpoint(user, user.id)
        //}));
    }
  }


  newUser(user: User) {
    return this.accountEndpoint.getNewUserEndpoint<User>(user);
  }


  getUserPreferences() {
    return this.accountEndpoint.getUserPreferencesEndpoint<string>();
  }

  updateUserPreferences(configuration: string) {
    return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
  }


  deleteUser(userId: string): Observable<User> {
    return this.accountEndpoint.getDeleteUserEndpoint<User>(<string>userId).pipe<User>(
        tap(data => this.onRolesUserCountChanged(data.roles)));

    
  }


  unblockUser(userId: string) {
    return this.accountEndpoint.getUnblockUserEndpoint(userId);
  }

  resetPassword(userId: string) {
    let myParams = new HttpParams()
      .set("userId", userId.toString());    
    return this.http.get(`${this.configurations.apiAddress}/api/Users/resetPassword`, { params: myParams });
  }

  userHasPermission(permissionValue: PermissionValues): boolean {
    return this.permissions.some(p => p == permissionValue);
  }


  refreshLoggedInUser() {
    return this.authService.refreshLogin();
  }




  getRoles(page?: number, pageSize?: number) {

    return this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize);
  }

  getAllRoles() {
    return this.http.get(`${this.configuration.apiAddress}/api/account/getAllRoles`);
  }

  getRolesAndPermissions(page?: number, pageSize?: number) {

    return forkJoin(
      this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize),
      this.accountEndpoint.getPermissionsEndpoint<Permission[]>());
  }


  updateRole(role: Role) {
    if (role.id) {
      return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id).pipe(
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
    else {
      return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(role.name).pipe(
        mergeMap(foundRole => {
          role.id = foundRole.id;
          return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id)
        }),
        tap(data => this.onRolesChanged([role], AccountService.roleModifiedOperation)));
    }
  }


  newRole(role: Role) {
    return this.accountEndpoint.getNewRoleEndpoint<Role>(role).pipe<Role>(
      tap(data => this.onRolesChanged([role], AccountService.roleAddedOperation)));
  }


  deleteRole(roleOrRoleId: number | Role): Observable<Role> {

    if (typeof roleOrRoleId === 'number' || roleOrRoleId instanceof String) {
      return this.accountEndpoint.getDeleteRoleEndpoint<Role>(<number>roleOrRoleId).pipe<Role>(
        tap(data => this.onRolesChanged([data], AccountService.roleDeletedOperation)));
    }
    else {

      if (roleOrRoleId.id) {
        return this.deleteRole(roleOrRoleId.id);
      }
      else {
        return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(roleOrRoleId.name);
          //.pipe<Role>(
         // mergeMap(role => this.deleteRole(role.id)));
      }
    }
  }

  getPermissions() {

    return this.accountEndpoint.getPermissionsEndpoint<Permission[]>();
  }


  private onRolesChanged(roles: Role[] | string[], op: RolesChangedOperation) {
    this._rolesChanged.next({ roles: roles, operation: op });
  }


  onRolesUserCountChanged(roles: Role[] | string[]) {
    return this.onRolesChanged(roles, AccountService.roleModifiedOperation);
  }


  getRolesChangedEvent(): Observable<RolesChangedEventArg> {
    return this._rolesChanged.asObservable();
  }

  downloadCsvTemplate(): Observable<Blob> {

    return this.http.get(this.configurations.apiAddress + "/api/account/downloadCSVTemplate", { responseType: "blob" });

  }

  uploadCsv(formData) {
return this.http.post(`${this.configurations.apiAddress}/api/account/uploadCsvFile`,formData);
    

  }
  provisionUsers(templateUrl) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/account/provisionFromCsv/${templateUrl}`,null, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }
  get permissions(): PermissionValues[] {
    return this.authService.userPermissions;
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  getLogo() {

    return this.http.get(this.configurations.apiAddress + "/api/Users/getLogoByType" , { responseType: 'text' });
  }
}
