import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { HcoService } from "../../hco/hco.service";



@Component({
  selector: 'crm-provision-hcp-hco',
  templateUrl: './provision-hcp-hco.component.html',
  styleUrls: ['./provision-hcp-hco.component.css']
})
export class ProvisionHcpHcoComponent implements OnInit {

  error: boolean;
  progress: number;
  message: string;
  loading: boolean;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  constructor(private readonly hcoService: HcoService) { }

  ngOnInit() {
  }
  downloadCSVTemplate() {
    this.hcoService.downloadHcpHcosCSVTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "HcpHcosTemplate.csv";
      anchor.href = url;
      anchor.click();
    },
      err => { console.log(err); });
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.loading = true;
    this.error = false;
    this.message = "Loading .....";
    this.hcoService.provisionHcpHcosFromCSV(formData).subscribe(result => {
        this.loading = false;
        let res = result as any;
        if (res.isError) {
          this.error = true;
          this.message = "uploaded with error";
          this.downloadCSVTemplatErrorFile();
        }
        else
          this.message = "uploaded success";
      });
  }

  downloadCSVTemplatErrorFile() {
    this.hcoService.downloadHcpHcosErrorFile().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "Error.csv";
      anchor.href = url;
      anchor.click();

    },
      err => {
        console.log(err);
      });
  }
}
