<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Sales File' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Sales File' | translate}}</h1>
  </div>
</div>

<ul id="uidemo-tabs-default-demo" class="nav nav-tabs">
  <li class="active">
    <a href="#uidemo-tabs-default-demo-targ" (click)="onChangeSalesMode('target')" data-toggle="tab">{{'Target' |translate}}</a>
  </li>
  <li class="">
    <a href="#uidemo-tabs-default-demo-sales" (click)="onChangeSalesMode('sales')" data-toggle="tab">{{'Sales' |translate}}</a>
  </li>
  <li class="">
    <a href="#uidemo-tabs-default-demo-ph" (click)="onChangeSalesMode('pharmacy')" data-toggle="tab">{{'Pharmacy' |translate}}</a>
  </li>

</ul>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{salesType}}{{' File Upload' | translate}}</span>
  </div>
  <div class="tab-content tab-content-bordered" style="border:none !important;">
    <div class="tab-pane fade active in" id="uidemo-tabs-default-demo-targ">
      <div class="panel-body padding-sm-hr">
        <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
          <div class="row">
            <div class="col-sm-3">
              <label class="control-label">{{'Target File type' | translate}} </label><br />
              <span class="ui-fluid">
                <p-dropdown [options]="fileTypes"
                            styleClass="wid100"
                            placeholder="{{'Target File type' | translate}}"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [(ngModel)]="fileType"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>
            </div>
            <div class="col-sm-3">
              <label class="control-label">{{'Date' | translate}} </label>
              <input type="text"
                     name="d"
                     #d="ngModel"
                     [(ngModel)]="selectedDate"
                     required="required"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }" class="form-control" autocomplete="off">

            </div>
            <div class="col-sm-2">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="applyDistributorPri" [(ngModel)]="applyDistributorPrice">
                  {{'Distributor Price' | translate}}
                </label>
              </div>
            </div>
            <div class="col-sm-2 margin-top-20  left-15 cursor-pointer" (click)="downloadCSVTemplate()">
              <span class="margin-right-7 filter-span">Download</span>
              <i class="fa fa-download" aria-hidden="true"></i>
            </div>
            <div class="col-sm-2 margin-top-20 left-7 cursor-pointer" (click)="uploadFile()">
              <span class="margin-right-7 create-new-span">Upload</span>
              <i class="fa fa-upload"></i>
              <input #file type="file" id="choosefile" (change)="upload(file.files)" style="display:none" />
            </div>
            <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
              {{progress}}%
            </span>

            <span style="font-weight:bold;color:green;" *ngIf="message">
              {{message}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="uidemo-tabs-default-demo-sales">
      <div class="panel-body padding-sm-hr">
        <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
          <div class="row">
            <div class="col-sm-3">
              <label class="control-label">{{'Distributor' | translate}} </label><br />
              <span class="ui-fluid">
                <p-dropdown [options]="fileTypes"
                            styleClass="wid100"
                            placeholder="{{'Distributor' | translate}}"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [(ngModel)]="fileType"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>
            </div>
            <div class="col-sm-3">
              <label class="control-label">{{'Date' | translate}} </label>
              <input type="text"
                     name="d"
                     #d="ngModel"
                     [(ngModel)]="selectedDate"
                     required="required"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }" class="form-control" autocomplete="off">

            </div>
            <div class="col-sm-2">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="applyDistributorPri" [(ngModel)]="applyDistributorPrice">
                  {{'Distributor Price' | translate}}
                </label>
              </div>
            </div>
            <div class="col-sm-2 margin-top-20  left-15 cursor-pointer" (click)="downloadDistributorCSVTemplate()">
              <span class="margin-right-7 filter-span">Download</span>
              <i class="fa fa-download" aria-hidden="true"></i>
            </div>
            <div class="col-sm-2 margin-top-20 left-7 cursor-pointer" (click)="uploadDistributorFile()">
              <span class="margin-right-7 create-new-span">Upload</span>
              <i class="fa fa-upload"></i>
              <input #fileDistributor type="file" id="chooseDistributorfile" (change)="uploadDistributor(fileDistributor.files)" style="display:none" />
            </div>
            <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
              {{progress}}%
            </span>

            <span style="font-weight:bold;color:green;" *ngIf="message">
              {{message}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="uidemo-tabs-default-demo-ph">
      <div class="panel-body padding-sm-hr">
        <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
          <div class="row">
            <div class="col-sm-3">
              <label class="control-label">{{'Distributor' | translate}} </label><br />
              <span class="ui-fluid">
                <p-dropdown [options]="fileTypes"
                            styleClass="wid100"
                            placeholder="{{'Distributor' | translate}}"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [(ngModel)]="fileType"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>
            </div>
            <div class="col-sm-3">
              <label class="control-label">{{'Date' | translate}} </label>
              <input type="text"
                     name="d"
                     #d="ngModel"
                     [(ngModel)]="selectedDate"
                     required="required"
                     #dpYMD="bsDatepicker"
                     bsDatepicker
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' }" class="form-control" autocomplete="off">

            </div>
            <div class="col-sm-2">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="applyDistributorPri" [(ngModel)]="applyDistributorPrice">
                  {{'Distributor Price' | translate}}
                </label>
              </div>
            </div>
            <div class="col-sm-2 margin-top-20  left-15 cursor-pointer" (click)="downloadPharmacySalesCSVTemplate()">
              <span class="margin-right-7 filter-span">Download</span>
              <i class="fa fa-download" aria-hidden="true"></i>
            </div>
            <div class="col-sm-2 margin-top-20 left-7 cursor-pointer" (click)="uploadPharmacyFile()">
              <span class="margin-right-7 create-new-span">Upload</span>
              <i class="fa fa-upload"></i>
              <input #filePharmacy type="file" id="choosePharmacyfile" (change)="uploadPharmacy(filePharmacy.files)" style="display:none" />
            </div>
            <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
              {{progress}}%
            </span>

            <span style="font-weight:bold;color:green;" *ngIf="message">
              {{message}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{salesType}}{{' File Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-table #dt [value]="filesData" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr *ngIf="filesData.length>0">

                <td> {{ item.fileName }}</td>
                <td>{{item.distributor ? item.distributor.name : item.saleFileTypeName}}</td>
                <td> {{ item.insertedDate  | date :'dd/MM/yyyy'}} </td>
                <td> {{ item.creationDate  | date :'dd/MM/yyyy hh:mm a'}} </td>
                <td><a href="javascript:;" (click)="confirmDelete(item.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

              </tr>
              <tr *ngIf="filesData.length==0">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>

            </ng-template>
          </p-table>

        </div>

      </div>
    </div>
  </div>
</div>



<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
