<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-10 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'BusinessUnitBricks' | translate}}</h1>
  </div>
</div>
<div class="panel panel-success widget-support-tickets">
  <div class="panel-body padding-sm-hr">

    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row padding-sm-vr">
        <div class="col-sm-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>{{'Name' | translate}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="businessUnits.length>0 && displayMode==2">
              <tr *ngFor="let item of businessUnits">
                <td>{{ item.name }}</td>
                <td>
                  <div>
                    <a class="btn btn-link btn-success btn-xs" href="javascript:;" (click)="manageBusinessUnitBricks(item.id)"><i class="fa fa-plus-o" aria-hidden="true"></i> {{'ManageBusinessUnitBricks' | translate}}</a>
                  </div>
                </td>
              </tr>

            </tbody>
            <tbody *ngIf="businessUnits.length==0 && displayMode==2">
              <tr>
                <td colspan="6">{{'NoDataFound' | translate}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="displayMode==1">
              <tr>
                <td colspan="3">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>

                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="row" *ngIf="displayMode==3">
        <div class="col-sm-12">
          <div class="alert alert-danger alert-dark">
            <button type="button" class="close" data-dismiss="alert">×</button>
            {{'ServerError' |translate}}.
          </div>
        </div>

      </div>

    </div>


  </div>
</div>

