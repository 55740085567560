import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { Cycle } from '../../../models/cycle.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupsService } from '../../../services/lookups.service';
import { CycleService } from '../cycle.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import Success = MessageSeverity.Success;



@Component({
  selector: 'crm-add-cycle',
  templateUrl: './add-cycle.component.html',
  styleUrls: ['./add-cycle.component.css']
})
export class AddCycleComponent implements OnInit {

  minStartDate: Date = new Date();
  displayMode: DisplayModes = DisplayModes.Success;
  cycle: Cycle = new Cycle(true);

  searchText: string = "";
  cycleId: number;
  isSaving: boolean = false;
  en: any;
  public logo = "";
  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private cycleService: CycleService,
    private translateService: AppTranslationService,
    private router: Router) {
    this.route.params.subscribe(params => {
      console.log(params)
      this.cycleId = params["cycleId"];
      if (this.cycleId != undefined)
        this.getDetails(this.cycleId);
    });
  }

  ngOnInit() {

    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy'
    };
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.isSaving = true;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.cycleService.save(this.cycle).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.isSaving = false;

        this.cycle.id = result as number;

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), Success);
        this.next();
      }, error => {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.Error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

        this.displayMode = DisplayModes.Error;
      });
    }
  }



  back() {
    this.router.navigate(['cycles']);
  }

  next() {
    this.router.navigate(['cycle/bu-specialities', this.cycle.id]);

  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.cycleService.getById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.cycle = response as Cycle;
    }, error => {
      this.alertService.showStickyMessage("Load Data Error", "The below errors occured whilst fetch data from server:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }

}
