import { Component, OnInit } from '@angular/core';
import { coaching } from '../../../models/lookups/coaching.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { CoachingService } from '../coaching.service';

@Component({
  selector: 'crm-add-coaching',
  templateUrl: './add-coaching.component.html',
  styleUrls: ['./add-coaching.component.css']
})
export class AddCoachingComponent implements OnInit {
  coaching: coaching = new coaching();

  coachingId: number;
  displayMode: DisplayModes = DisplayModes.Success;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private coachingService: CoachingService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.coachingId = params["id"];
      if (this.coachingId != undefined)
        this.getDetails(this.coachingId);
    });
  }
  save(form) {
    if (!form.invalid) {

      // Start Loading and alert Saving message
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

      // get a save method from activity type service and carry its result in result
      this.coachingService.Save(this.coaching).subscribe(result => {

        // End Loading and alert Success message
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;

        // check if it exsiting item to create new one 
        if (this.coaching.id == 0)
          this.coaching = new coaching();
        else
          this.router.navigate(['coachings']);

        // alert that Save successfully
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {

        // End Loading and alert error message
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.router.navigate(['coachings']);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;

    // get a GetById method from activity type service and carry its result in response
    this.coachingService.GetById(id).subscribe(response => {

      // Display Success message
      this.displayMode = DisplayModes.Success;
      this.coaching = response as coaching;
    }, err => {
      // Display erorr message
      this.displayMode = DisplayModes.Error;
    });
  }
}
