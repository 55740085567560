import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class OrganizationTypeService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {

  }

  Save(organizationType) {
    return this.http.post(this.configurations.apiAddress + "/api/OrganizationType/save", organizationType);
  }

  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/OrganizationType/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/OrganizationType/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/OrganizationType/getById/' + id);
  }

}
