import { Component, OnInit, ViewChild } from "@angular/core";
import { SpecialityService as SpecialityService } from "../speciality.service";
import { HttpEventType } from "@angular/common/http";
import { ModalDirective } from "ngx-bootstrap";

@Component({
  selector: "crm-provision-specialities",
  templateUrl: "./provision-specialities.component.html",
  styleUrls: ["./provision-specialities.component.css"]
})
export class ProvisionSpecialitiesComponent implements OnInit {
  progress: number;
  message: string;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  constructor(private readonly spcialitiyService: SpecialityService) { }

  ngOnInit() {
  }

  downloadCSVTemplate() {
    this.spcialitiyService.downloadCsvTemplate().subscribe(response => {

        const blob = new Blob([response], { type: "text/csv" });
        console.log(blob);
        const url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = "SpcialityTemplate.csv";
        anchor.href = url;
        anchor.click();
        //window.open(url);
        console.log(url);
        console.log(response);

      },
      err => { console.log(err); });
  }
  
  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.spcialitiyService.uploadCsvTemplate(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = "uploaded success";
        //this.provisionModal.hide();
      }
    });
  }
}
