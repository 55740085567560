import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VacationTypeService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  Save(holiday) {
    return this.http.post(this.configurations.apiAddress + "/api/VacationType/save", holiday);
  }

  Search(searchText: string, pageSize: number, pageNumber: number, isVac: boolean) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("isVac", isVac.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/VacationType/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/VacationType/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/VacationType/getById/' + id);
  }


  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/VacationType/downloadCSVTemplate", { responseType: "blob" });
  }

  uploadCsvTemplate(formData, isVac) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/VacationType/uploadCSVTemplate?isVac=` + isVac, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }
}
