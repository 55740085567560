import { Component, OnInit, ViewChild } from '@angular/core';
import { SalesFileService } from '../services/sales-file.service';
import { HttpEventType } from '@angular/common/http';
import { AppConfigService } from '../../app.config';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ModalDirective } from 'ngx-bootstrap';
import { SalesFileType } from '../../enums/salesFileType';

@Component({
  selector: 'crm-sales-file',
  templateUrl: './sales-file.component.html',
  styleUrls: ['./sales-file.component.css']
})
export class SalesFileComponent implements OnInit {
  columns: any[] = [];
  progress: number;
  message: string;
  error: boolean = false;
  fileTypes: any[] = [];
  fileType: any;
  selectedDate: any;
  files: any[] = [];
  filesData: any[] = [];
  pageSize: number = this.appConfig.settings.pageSize;
  public logo = require("../../../app/assets/images/demo/Icon_Sales.png");
  //isSales: boolean = true;
  salesType: SalesFileType = SalesFileType.target;
  selectedFileId;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  applyDistributorPrice = true;
  constructor(private translateService: AppTranslationService, private alertService: AlertService,
    private readonly appConfig: AppConfigService, private salesFileService: SalesFileService) { }

  ngOnInit() {
    this.getFilesType();
    this.getSalesFileData();

    this.columns = [

      { field: 'name', header: 'Name' },
      { field: 'type', header: 'Type' },
      { field: 'insertDate', header: 'Insert Date' },
      { field: 'creationDate', header: 'Creation Date' }
    ];
  }

  getFilesType() {
    this.salesFileService.getAllSalesFileTypeTypes().subscribe(response => {
      debugger;
      let result = response as any[];
      this.fileTypes = result.filter(x => x.name.indexOf('Target') > -1);
    }, err => {
      console.log(err);
    });
  }

  getDitributors() {
    this.salesFileService.getDitributors().subscribe(response => {
      let result = response as any[];
      this.fileTypes = result;
    }, err => {
      console.log(err);
    });
  }

  getSalesFileData() {
    this.salesFileService.getSalesFileData().subscribe(response => {
      let result = response as any[];
      this.filesData = result;
    }, err => {
      console.log(err);
    });
  }

  getSalesFileDistributorsData() {
    this.salesFileService.getSalesFileDistributorsData().subscribe(response => {
      let result = response as any[];
      this.filesData = result;
    }, err => {
      console.log(err);
    });
  }

  getPharmacySalesFileData() {
    this.salesFileService.getPharmacySalesFileData().subscribe(response => {
      let result = response as any[];
      this.filesData = result;
    }, err => {
      console.log(err);
    });
  }

  downloadCSVTemplate() {
    if (!this.fileType || !this.selectedDate || this.message == 'loading')
      return;

    this.salesFileService.downloadCsvTemplate(this.fileType.id).subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      if (this.fileType.id == 1)
        anchor.download = "IbnSina.csv";
      else if (this.fileType.id == 2)
        anchor.download = "POS.csv";
      else if (this.fileType.id == 3)
        anchor.download = "UCP.csv";
      else if (this.fileType.id == 4)
        anchor.download = "EgyDrug.csv";
      else if (this.fileType.id == 5)
        anchor.download = "Dirct.csv";
      else if (this.fileType.id == 6)
        anchor.download = "Target.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }

  uploadFile() {
    if (!this.fileType || !this.selectedDate || this.message == 'loading')
      return;
    document.getElementById('choosefile').click();
  }


  confirmDelete(itemId) {
    this.selectedFileId = itemId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(itemId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  delete() {
    this.hideDeleteModal();
    this.salesFileService.delete(this.selectedFileId).subscribe(response => {
      if (response == false) {
        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
      }
      else {
        this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
        if (this.salesType == SalesFileType.target)
          this.getSalesFileData();
        else if (this.salesType == SalesFileType.sales)
          this.getSalesFileDistributorsData();
        else if (this.salesType == SalesFileType.pharmacy)
          this.getPharmacySalesFileData();
      }

    }, err => {

      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }

  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);
    this.message = 'loading';

    this.salesFileService.provision(formData, this.fileType.id, this.selectedDate, this.applyDistributorPrice).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = "uploaded success";
        this.getSalesFileData();
      }
    });


    //if (this.fileType.id == 1) {
    //  return;
    //  this.message = 'loading';

    //  this.salesFileService.provisionIbnSina(formData).subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress)
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = "uploaded success";
    //    }
    //  });
    //}

    //if (this.fileType.id == 2) {
    //  return;
    //  this.message = 'loading';
    //  this.salesFileService.provisionPOS(formData).subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress)
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = "uploaded success";
    //    }
    //  });
    //}

    //if (this.fileType.id == 3) {
    //  return;
    //  this.message = 'loading';
    //  this.salesFileService.provisionUCP(formData).subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress)
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = "uploaded success";
    //    }
    //  });
    //}

    //if (this.fileType.id == 4) {
    //  return;
    //  this.message = 'loading';
    //  this.salesFileService.provisionEgyDrug(formData).subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress)
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = "uploaded success";
    //    }
    //  });
    //}

    //if (this.fileType.id == 5) {
    //  return;
    //  this.message = 'loading';
    //  this.salesFileService.provisionDirect(formData).subscribe(event => {
    //    if (event.type === HttpEventType.UploadProgress)
    //      this.progress = Math.round(100 * event.loaded / event.total);
    //    else if (event.type === HttpEventType.Response) {
    //      this.message = "uploaded success";
    //    }
    //  });
    //}

  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  onChangeSalesMode(salesType) {
    this.message = "";
    this.selectedDate = undefined;
    //this.isSales = isSales;
    if (salesType == 'target') {
      this.salesType = SalesFileType.target;
      this.getFilesType();
      this.getSalesFileData();
    }
    else if (salesType == 'sales') {
      this.salesType = SalesFileType.sales;
      this.getDitributors();
      this.getSalesFileDistributorsData();
    }
    else if (salesType == 'pharmacy') {
      this.salesType = SalesFileType.pharmacy;
      this.getDitributors();
      this.getPharmacySalesFileData();
    }
  }

  downloadDistributorCSVTemplate() {
    this.salesFileService.downloadDistributorSalesCSVTemplate().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "DistributorSalesTemplate.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);
    },
      err => { console.log(err); });
  }

  uploadDistributorFile() {
    if (!this.fileType || !this.selectedDate || this.message == 'loading')
      return;
    document.getElementById('chooseDistributorfile').click();
  }

  uploadDistributor(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.error = false;
    this.message = "Loading .....";
    this.salesFileService.uploadDistributorSalesCSVTemplate(formData, this.fileType.id, this.selectedDate, this.applyDistributorPrice).subscribe(result => {
      let res = result as any;
      if (res.isError) {
        this.error = true;
        this.message = "uploaded with error";
        this.downloadCSVTemplatErrorFile();
      }
      else {
        this.message = "uploaded success";
      }
      this.getSalesFileDistributorsData();
    });
  }

  downloadCSVTemplatErrorFile() {
    this.salesFileService.downloadDistributorSalesErrorFile().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "Error.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);
    },
      err => {
        console.log(err);
      });
  }


  downloadPharmacySalesCSVTemplate() {
    this.salesFileService.downloadPharmacySalesCSVTemplate().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "PharmacySalesTemplate.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);
    },
      err => { console.log(err); });
  }


  uploadPharmacyFile() {
    if (!this.fileType || !this.selectedDate || this.message == 'loading')
      return;
    document.getElementById('choosePharmacyfile').click();
  }

  uploadPharmacy(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);

    this.error = false;
    this.message = "Loading .....";
    this.salesFileService.uploadPharmacySalesCSVTemplate(formData, this.fileType.id, this.selectedDate, this.applyDistributorPrice).subscribe(result => {
      let res = result as any;
      if (res.isError) {
        this.error = true;
        this.message = "uploaded with error";
        this.downloadPharmacySalesErrorFile();
      }
      else {
        this.message = "uploaded success";
      }
      this.getPharmacySalesFileData();
    });
  }

  downloadPharmacySalesErrorFile() {
    this.salesFileService.downloadPharmacySalesErrorFile().subscribe(response => {
      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "Error.csv";
      anchor.href = url;
      anchor.click();
      console.log(url);
      console.log(response);
    },
      err => {
        console.log(err);
      });
  }

}
