<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'mainMenu.coachings' | translate}}</h1>


  </div>
</div>
<div class="panel panel-success widget-support-tickets">
  <div class="panel-body padding-sm-hr">

    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row padding-sm-vr">
        <div class="col-sm-6">
          <label class="control-label">{{'SearchText' | translate}} </label>
          <input type="text"
                 name="searchText"
                 [(ngModel)]="searchText"
                 placeholder="{{'SearchText' |translate}}"
                 #name="ngModel"
                 class="form-control" />

        </div>
        <div class="col-sm-6">

          <div style="padding-top:25px;" class="pull-right">
            <button class="btn btn-labeled btn-warning " type="button" (click)="searchcoaching()"><span class="btn-label icon fa fa-search"></span>{{'Search' | translate}}</button>
            <button class="btn btn-labeled btn-primary" (click)="new()" type="button"><span class="btn-label icon fa fa-plus"></span>{{'New' | translate}}</button>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="coachings" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <th width="5%">#</th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="25%"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-coaching>
              <tr *ngIf="coachings.length>0 && displayMode==2">
                <td>
                  <strong>{{coaching.id}}</strong>
                </td>
                <td>{{ coaching.name }}</td>              
                <td>
                  <div>
                    <a class="btn btn-labeled btn-primary btn-xs" href="javascript:;" (click)="gotoEdit(coaching.id)"><i class="btn-label fa fa-pencil-square-o" aria-hidden="true"></i> Edit</a>
                    <a class="btn btn-labeled btn-danger btn-xs" href="javascript:;" (click)="confirmDelete(coaching.id)"><i class="btn-label fa fa-trash-o" aria-hidden="true"></i>Delete</a>
                  </div>
                </td>
              </tr>
              <tr *ngIf="coachings.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>
