import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayModes } from '../../models/enums';
import { AppConfigService } from '../../app.config';
import { Router } from '@angular/router';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Representation } from '../models/representation';
import { RepresentationApiService } from '../services/representation-api.service';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'crm-representation',
  templateUrl: './representation.component.html',
  styleUrls: ['./representation.component.css']
})
export class RepresentationComponent implements OnInit {
  representations: Representation[];
  displayMode: DisplayModes;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Representations.png");

  selectedRepresentationsId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly representationService: RepresentationApiService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) {
    this.pageSize = this.appConfig.settings.pageSize;
    this.displayMode = DisplayModes.Success;
  }

  ngOnInit() {
    this.getAll();
  }


  getAll() {
    this.displayMode = DisplayModes.Loading;
    this.representationService.getAll().subscribe(response => {
      this.representations = response as Representation[];
      this.displayMode = DisplayModes.Success;
    }, error => {
      this.displayMode = DisplayModes.Error;
    });
  }
  gotoNew() {
    this.addNew = true;
    //this.router.navigate(['representation/add']);
  }

  gotoEdit(itemId) {
    this.selectedRepresentationsId = itemId;
    this.addNew = true;
    //this.router.navigate(['representation/edit', itemId]);
  }

  confirmDeleteItem(item) {
    this.selectedRepresentationsId = item;
    this.deleteModal.show();
    //this.alertService.showDialog(`are you sure you want to delete representation, ${item.name}!`, DialogType.Confirm,
    //  () => this.delete(item),
    //  () => this.alertService.showMessage(`Representation delete`, `delete representation ${item.name}, canceled!`, MessageSeverity.Default));

  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.alertService.startLoadingMessage(`Representation delete`, `deleting representation, ${this.selectedRepresentationsId.name}! ....`);

    this.representationService.delete(this.selectedRepresentationsId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.stopLoadingMessage();

      this.alertService.showMessage("Representation delete", `${this.selectedRepresentationsId.name} deleted successfully!`, MessageSeverity.Success);
      this.getAll();
    }, err => {
      this.displayMode = DisplayModes.Success;
      this.alertService.stopLoadingMessage();

      this.alertService.showMessage(`Representation delete`, `delete representation, ${this.selectedRepresentationsId.name} failed please try again later!`, MessageSeverity.Error);

    });
  }

  closeAdding(event) {
    this.selectedRepresentationsId = undefined;
    this.addNew = false;
    if (event) {
      this.getAll();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
