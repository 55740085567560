import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ItemService } from '../item.service';
import { SaveItemPriceComponent } from '../save-item-price/save-item-price.component';

@Component({
  selector: 'crm-item-price',
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.css']
})
export class ItemPriceComponent implements OnInit {
  displayMode: DisplayModes = DisplayModes.Success;
  itemPrices: any[] = [];
  selectedItemPriceId;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() priceType: number;
  @Input() itemId: number;

  @ViewChild('itemPriceModal')
  itemPriceModal: ModalDirective;

  @ViewChild('itemPriceEditor')
  itemPriceEditor: SaveItemPriceComponent;

  @ViewChild('deleteItemPriceModal')
  deleteItemPriceModal: ModalDirective;

  constructor(private readonly itemService: ItemService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getItemPrices();
  }

  ngAfterViewInit() {
    this.itemPriceEditor.changesCallback = () => {
      this.getItemPrices();
      this.itemPriceModal.hide();
    };
  }

  getItemPrices() {
    this.displayMode = DisplayModes.Loading;
    this.itemService.getItemPrices(this.itemId, this.priceType).subscribe(response => {
      this.itemPrices = response as any[];
      this.displayMode = DisplayModes.Success;
    }, err => {
      console.log(err);
      this.displayMode = DisplayModes.Error;
    });
  }

  confirmDelete(itemPriceId) {
    this.selectedItemPriceId = itemPriceId;
    this.deleteItemPriceModal.show();
  }

  deleteItemPrice() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemService.deleteItemPrice(this.selectedItemPriceId).subscribe(() => {
      this.selectedItemPriceId = undefined;
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);
      this.getItemPrices();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  hideDeleteModal() {
    this.deleteItemPriceModal.hide();
  }

  back() {
    this.closeEvent.emit(true);
  }

  addNew() {
    this.itemPriceEditor.new(this.itemId, this.priceType);
    this.itemPriceModal.show();
  }


  edit(itemPrice) {
    this.itemPriceEditor.edit(itemPrice);
    this.itemPriceModal.show();
  }

  onEditorModalHidden() {
  }

}
