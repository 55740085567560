import { Injectable, Injector } from '@angular/core';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AppTranslationService } from '../../services/app-translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { DBkeys } from '../../services/db-Keys';
import { Cycle } from '../../models/cycle.model';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CycleService extends EndpointFactory {
  headers = new HttpHeaders();
  culture: string = "en";

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) {
    super(http, configurations, injector);

   
    this.culture = this.configurations.language;
  }

  getAll() {
    return this.http.get(`${this.configurations.apiAddress}/api/cycle/getAll`, { headers: this.headers });
  }

  search(searchText: string, startDate: Date, endDate: Date, pageSize: number, pageNumber: number) {
    
    let myParams = new HttpParams()
      .set('startDate', startDate != undefined ? startDate.toDateString() : "")
      .set('endDate', endDate != undefined? endDate.toDateString():"")
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());

  

    return this.http.get(`${this.configurations.apiAddress}/api/cycle/search`, {  params: myParams });
  }

  save(cycle: Cycle) {
    return this.http.post(`${this.configurations.apiAddress}/api/cycle/saveCycle`, cycle);
  }

  
  getById(id: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/cycle/getById/${id}`);
  }
  delete(id: number) {
    return this.http.delete(`${this.configurations.apiAddress}/api/cycle/delete/${id}`);
  }

  getCycleBusinessUnits(cycleId: number) {
    let url = `${this.configurations.apiAddress}/api/cycle/businessUnits`;
   
      url = `${url}/${cycleId}`;
    return this.http.get(url);
  }

  getCycleBricks(cycleId: number) {
    let url = `${this.configurations.apiAddress}/api/cycleBricks/getCycleBricks`;
   
      url = `${url}/${cycleId}`;
    return this.http.get(url);
  }

  saveCycleBusinessUnits(saveCycleBusinessUnit:any) {
    //let model = { cycleBUSpecialityDtos: buSpecialities, cycleId: cycleId }
    return this.http.post(`${this.configurations.apiAddress}/api/cycle/saveCycleBusinessUnits`, saveCycleBusinessUnit);
  }

  saveCycleBricks(cycleId, selectedBricks) {
    let model = { cycleId: cycleId, selectedBricks: selectedBricks };
    return this.http.post(`${this.configurations.apiAddress}/api/cycleBricks/saveCycleBricks`, model).pipe(retry(5));
  }
}
