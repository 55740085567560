import { OrganizationJobStructure } from "../../../models/lookups/organizationJobStructure.model";

export class UserJob {
  constructor() {
    this.isCurrent = true;
    this.applyList = true;
    this.startDate = new Date();
  }

  id: number;
  userId: number;
  jobId: number;
  startDate: Date;
  endDate: Date;
  isCurrent: boolean;
  applyList: boolean;
  userName: string;
  jobName: string;

  job: OrganizationJobStructure;
}
