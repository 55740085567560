import { ActivityCostCenter } from "./activity-cost-center.model";
import { ActivityCostType } from "./activity-cost-type.model";
import { ActivityGroup } from "./activity-group.model";

export class activityType {
  constructor() {
    this.activityCostCenter = [];
    this.activityCostType = [];
    this.activityGroup = new ActivityGroup();
    this.activityTypeApproval = [];
  }
  id: number;
  name: string;
  leadTime: number;
  confirmedDeadline: number;

  activityGroup: ActivityGroup;

  costCenters: ActivityTypeCostCenter[];
  activityCostCenter: ActivityCostCenter[];

  costTypes: ActivityTypeCostType[];
  activityCostType: ActivityCostType[];

  activityGroupId?: number;

  activityTypeApproval: ActivityTypeApproval[];
}


export class ActivityTypeApproval {
  id: number;
  activityTypeId: number;
  stepLevel: number;
  activityApprovalActorTypeData: any;
  activityApprovalActorType: ActivityApprovalActorType;
  activityApprovalActorValueData: any;
  activityApprovalActorValue: number;
  showRoles: boolean;
  showJobs: boolean;
  showUsers: boolean;
}

export enum ActivityApprovalActorType {
  User = 1,
  Role = 2,
  Job = 3
}

export class ActivityTypeCostCenter {
  activityCostCenterId: number;
}

export class ActivityTypeCostType {
  activityCostTypeId: number;
}
