import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { BusinessUnitTypeService } from '../business-unit-type.service';
import { BusinessUnitType } from '../../models/business-unit-type';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { DisplayModes } from '../../../models/enums';
import { AppTranslationService } from '../../../services/app-translation.service';



@Component({
  selector: 'crm-save-business-unit-type',
  templateUrl: './save-business-unit-type.component.html',
  styleUrls: ['./save-business-unit-type.component.css']
})
export class SaveBusinessUnitTypeComponent implements OnInit {

  businessUnitType: BusinessUnitType = new BusinessUnitType();

  displayMode: DisplayModes = DisplayModes.Success;
  //businessUnitTypeId: number;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() businessUnitTypeId: number;

  constructor(private readonly businessUnitTypeService: BusinessUnitTypeService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {

    if (this.businessUnitTypeId != undefined)
      this.getDetails(this.businessUnitTypeId);

  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.businessUnitTypeService.SaveBusinessUnitType(this.businessUnitType).subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.businessUnitType.id == 0)
          this.businessUnitType = new BusinessUnitType();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }


  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.businessUnitTypeService.GetBusinessUnitTypeDetails(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.businessUnitType = response as BusinessUnitType;
    }, err => {
      this.displayMode = DisplayModes.Error;

    });
  }


}

