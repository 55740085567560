import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { Cycle } from '../../../models/cycle.model';
import { CycleService } from '../cycle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CycleBusinessUnit } from '../models/cycle-buspeciality';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AppConfigService } from '../../../app.config';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Specialty } from '../../../models/lookups/speciality.model';
import { BricksService } from '../../../settings/services/bricks.service';
import { Brick } from '../../../settings/models/brick.model';
import { BusinessUnitService } from '../../../lookups/business-unit/business-unit.service';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';

@Component({
  selector: 'crm-cycle-buspeciality',
  templateUrl: './cycle-buspeciality.component.html',
  styleUrls: ['./cycle-buspeciality.component.css']
})
export class CycleBUSpecialityComponent implements OnInit {
  cycleId: number;
  pageSize: number = this.appConfig.settings.pageSize;
  currentPage: number = 1;
  cycle: Cycle = new Cycle(true);
  cycleBusinessUnits: CycleBusinessUnit[] = [];
  businessUnits: BusinessUnit[] = [];
  bricks: Brick[] = [];
  selectedBusinessUnit: BusinessUnit = null;
  specialities: Specialty[] = [];
  isSaving: boolean = false;
  isLoading: boolean = false;
  public logo = "";
  //  pagedItems: Array<CycleBUSpeciality> = new Array<CycleBUSpeciality>();
  displayMode: DisplayModes = DisplayModes.Success;
  constructor(private readonly cycleService: CycleService,
    private readonly route: ActivatedRoute,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService,
    private readonly brickService: BricksService,
    private readonly appConfig:AppConfigService,
    private readonly businessUnitService: BusinessUnitService,
    private router: Router) {
    this.route.params.subscribe(params => {
      console.log(params)
      this.cycleId = params["cycleId"];
      if (this.cycleId != undefined)
        this.getDetails(this.cycleId);
      this.getCycleBusinessUnits(this.cycleId);
    });
  }

  ngOnInit() {
    this.getBusinessUnitsRootLevel();
    this.getBricksRootLevel();
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.isLoading = true;
    this.cycleService.getById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.cycle = response as Cycle;
      this.isLoading = false;
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.isLoading = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

    });
  }
  getCycleBusinessUnits(id) {
    this.displayMode = DisplayModes.Loading;
    this.cycleService.getCycleBusinessUnits(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      let result = response;
      this.cycleBusinessUnits = result as CycleBusinessUnit[];


      // this.pagedItems = this.buSpecialities.slice(this.currentPage-1, this.pageSize);
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }
  getBusinessUnitsRootLevel() {
    this.isLoading = true;
    this.businessUnitService.getBusinessUnitsRootLevel().subscribe(response => {
      this.businessUnits = response as BusinessUnit[];
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }
   addBusinessUnit() {
    let buCycle = this.cycleBusinessUnits.find(x => x.businessUnitId == this.selectedBusinessUnit.id);
    if (buCycle !== null && buCycle !== undefined) {
      this.alertService.showMessage("Select Business Unit Error", `${this.selectedBusinessUnit.name} added before can't add it twice!`, MessageSeverity.Error);
      return;
    }
     this.getBusinessUnitSpecialities(this.selectedBusinessUnit);
    
   

  }
  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.isSaving = true;
      let saveModel = { cycleId: this.cycleId, cycleBusinessUnits: this.cycleBusinessUnits };
      this.cycleService.saveCycleBusinessUnits(saveModel).subscribe(result => {
        this.isSaving = false;
        this.displayMode = DisplayModes.Success;
      }, error => {
        this.isSaving = false;
        this.displayMode = DisplayModes.Error;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
      });
    }


  }

  back() {
    this.router.navigate(['add-cycle', this.cycleId]);
  }

  next() {
    this.router.navigate(['cycle/bricks', this.cycleId]);
  }

  getBusinessUnitSpecialities(businessUnit: BusinessUnit) {
    this.isLoading = true;
    this.businessUnitService.getBusinessUnitSpecialities(businessUnit.id).subscribe(response => {
      this.specialities = response as Specialty[];
      this.isLoading = false;
      this.cycleBusinessUnits.push({
        id: 0,
        bricks: [],
        specialities: this.specialities,
        selectedSpecialities: [],
        businessUnitId: this.selectedBusinessUnit.id,
        businessUnitName: this.selectedBusinessUnit.name,
        cycleId: this.cycleId,
        cycleName: this.cycle.name
      });
      this.selectedBusinessUnit = null;
    }, error => {
      this.isLoading = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }
  getBricksRootLevel() {
    this.isLoading = true;
    this.brickService.getBricksRootLevel().subscribe(response => {
      this.bricks = response as Brick[];
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

    });
  }
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
}
