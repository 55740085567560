<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Cycles' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Cycles' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Cycles Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="searchText"
                 [(ngModel)]="search.searchText"
                 placeholder="{{'settings.header.SearchText' |translate}}"
                 #name="ngModel"
                 class="form-control" />

        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="openFilterModal()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="newCycle()">
          <span class="margin-right-7 create-new-span">Create New Cycle</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Cycles Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="cycles" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align:center" class="table-edit">{{'Name' | translate}}</th>
                <th style="text-align:center" class="table-edit">{{'settings.header.StartDate' | translate}}</th>
                <th style="text-align:center" class="table-edit">{{'settings.header.EndDate' | translate}}</th>
                <th style="text-align:center" class="table-edit">{{'settings.header.ExpectedVisits' | translate}}</th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
                <th width="10%" style="text-align:center" class="table-edit">BU Specialities</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr *ngIf="cycles.length>0 && displayMode==2">
                <td>{{ item.name }}</td>
                <td>{{ item.startDate |date }}</td>
                <td>{{ item.endDate |date}}</td>
                <td>{{ item.expectedVisits  }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(item.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDelete(item.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
                <td>
                  <a class="" href="javascript:;" (click)="buSpecialities(item.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
              </tr>
              <tr *ngIf="cycles.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #filterModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title">{{'Filter' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">
        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{'settings.header.SearchText' | translate}} </label>
            <input type="text"
                   name="searchText"
                   [(ngModel)]="search.searchText"
                   placeholder="{{'settings.header.SearchText' |translate}}"
                   #name="ngModel"
                   class="form-control" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{'settings.header.DateFrom' | translate}} </label>
            <div class="input-group date">
              <input type="text"
                     class="form-control"
                     name="startDatePicker"
                     #startDatePicker="ngModel"
                     [(ngModel)]="search.startDate"
                     required="required"
                     placeholder="yyyy-mm-dd"
                     [maxDate]="search.endDate"
                     #d="bsDatepicker"
                     bsDatepicker
                     autocomplete="off"
                     [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">

              <span class="input-group-btn date-label">
                <button class="btn date-btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
              </span>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="control-label">{{'settings.header.DateTo' | translate}} </label>
            <div class="input-group date">
              <input type="text"
                     class="form-control"
                     name="endDatePicker"
                     #endDatePicker="ngModel"
                     [(ngModel)]="search.endDate"
                     [minDate]="search.startDate"
                     required="required"
                     #d2="bsDatepicker"
                     placeholder="yyyy-mm-dd"
                     bsDatepicker
                     autocomplete="off"
                     [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD'}">

              <span class="input-group-btn date-label">
                <button class="btn date-btn" (click)="d2.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideFilterModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="searchFilterModal()">{{'Search' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteCycle()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>



