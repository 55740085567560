import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserJob } from '../../../models/user-job.model';
import { DisplayModes } from '../../../../../models/enums';
import { OrganizationJobStructure } from '../../../../../models/lookups/organizationJobStructure.model';
import { UserJobService } from '../../../../../services/user-job.service';
import { AlertService, MessageSeverity } from '../../../../../services/alert.service';
import { AppTranslationService } from '../../../../../services/app-translation.service';
import { OrganizationJobStructureService } from '../../../../../lookups/organization-job-structure/organization-job-structure.service';

@Component({
  selector: 'crm-save-user-job',
  templateUrl: './save-user-job.component.html',
  styleUrls: ['./save-user-job.component.css']
})
export class SaveUserJobComponent implements OnInit {
  userJob: UserJob = new UserJob();
  jobs: OrganizationJobStructure[] = [];
  userJobs: UserJob[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  selectedJob: OrganizationJobStructure;
  startDate: any;
  endDate: any;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  userId: number;

  @ViewChild('endDatePicker')
  private endDatePicker;
  

  constructor(private readonly jobStructureService: OrganizationJobStructureService,
    private readonly userJobService: UserJobService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService, ) { }

  ngOnInit() {
    this.getAllJobs();
  }
  
  save(form) {
    debugger;
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.userJob.userId =Number(this.userId);
      this.userJob.jobId = this.userJob.job.id;
      this.userJob.startDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day,12,0,0);
      if (this.endDate !== undefined)
        this.userJob.endDate = new Date(this.endDate.year, this.endDate.month-1, this.endDate.day,12,0,0);
      this.userJobService.saveUserJob(this.userJob).subscribe(response => {
        this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);

        this.changesSavedCallback();
      }, () => {
          this.displayMode = DisplayModes.Error;
          this.alertService.showMessage("", this.translateService.getTranslation("FialedToSave"), MessageSeverity.Error);
          this.changesFailedCallback();
      });
    }
  }


  getAllJobs() {
    this.displayMode = DisplayModes.Loading

    this.jobStructureService.GetAllOrgJobStructure().subscribe(response => {
      this.jobs = response as OrganizationJobStructure[];
      this.jobs = this.jobs.filter(x => x.isActive);
      this.displayMode = DisplayModes.Success

    }, () => {
        this.displayMode = DisplayModes.Error;
        this.alertService.stopLoadingMessage();

    });
  }

  editJob(job: UserJob,userJobs:UserJob[]) {
    
    Object.assign(this.userJob, job);

    this.userJobs = userJobs;
    let sDate = new Date(this.userJob.startDate);
    this.startDate = {
      year: sDate.getFullYear(),
      month: sDate.getMonth()+1,
      day: sDate.getDate()
    };

    if (this.userJob.endDate !== null) {
      this.endDate = {
        year: new Date(this.userJob.endDate).getFullYear(),
        month: new Date(this.userJob.endDate).getMonth()+1,
        day: new Date(this.userJob.endDate).getDate()
      }
    }

  }

  newJob(userId: number,userJobs:UserJob[]) {
    this.userJob = new UserJob();
    this.startDate = {}
    this.endDate = {}
    this.userJobs = userJobs;
    this.userJob.userId = userId;
  }

  close() {
    this.changesCancelledCallback();
  }
}
