import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayModes } from '../../models/enums';
import { Page } from '../../models/page.model';
import { Cycle } from '../../models/cycle.model';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupsService } from '../../services/lookups.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { CycleService } from './cycle.service';
import Default = MessageSeverity.Default;
import Success = MessageSeverity.Success;
import Error = MessageSeverity.Error;
import Confirm = DialogType.Confirm;
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'crm-cycle',
  templateUrl: './cycle.component.html',
  styleUrls: ['./cycle.component.css']
})
export class CycleComponent implements OnInit {

  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;
  countryId: number;
  displayMode: DisplayModes = DisplayModes.Success;

  cycles: Cycle[] = [];

  public logo = "";
  @ViewChild('filterModal')
  filterModal: ModalDirective;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  selectedCycleId;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private cycleService: CycleService,
    private translateService: AppTranslationService,
    private router: Router) {
  }
  ngOnInit() {
    this.searchCycles();
  }

  searchCycles() {

    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.cycleService.search(this.search.searchText, this.search.startDate, this.search.endDate, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      let result = response as Page;
      this.cycles = result.items as Cycle[];
      this.totalItems = result.totalItems;

    }, error => {
      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Fetch Data Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);

      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchCycles();
  }

  newCycle() {
    this.router.navigate(['add-cycle']);
  }


  gotoEdit(cycleId) {
    this.router.navigate(['add-cycle', cycleId]);
  }

  confirmDelete(cycleId) {
    this.selectedCycleId = cycleId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), Confirm,
    //  () => this.deleteCycle(cycleId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", Default));

  }

  deleteCycle() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.cycleService.delete(this.selectedCycleId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), Success);

      this.searchCycles();
    }, error => {
      this.displayMode = DisplayModes.Success;

      this.alertService.stopLoadingMessage();
      this.alertService.showStickyMessage("Save Error", "The below errors occured whilst fetching data:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);


    });
  }

  buSpecialities(cycleId) {
    this.router.navigate(['cycle/bu-specialities', cycleId]);

  }

  openFilterModal() {
    this.filterModal.show();
  }

  hideFilterModal() {
    this.search.searchText = "";
    this.search.startDate = undefined;
    this.search.endDate = undefined;
    this.filterModal.hide();
  }

  searchFilterModal() {
    this.filterModal.hide();
    this.searchCycles();
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
