import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { ConfigurationService } from '../../services/configuration.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { Specialty } from '../../models/lookups/speciality.model';

@Injectable({
  providedIn: 'root'
})
export class SpecialityService extends EndpointFactory{

  
  culture: string = "ar";

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) {
    super(http, configurations, injector);

    
    this.culture = this.configurations.language;
  }


  Search(searchText: string, parentId: number) {
    let myParams = new HttpParams()
      .set('parentId', parentId == undefined ? "0" : parentId.toString())

      .set('searchText', searchText == undefined ? "" : searchText);
      //.set("pageNumber", pageNumber.toString())
      //.set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/speciality/search', {  params: myParams });
  }

  Save(speciality: Specialty) {
    return this.http.post(this.configurations.apiAddress + '/api/speciality/save', speciality);
  }

  

  GetAllSpecialities(searchText: string) {
    return this.http.get(this.configurations.apiAddress + '/api/speciality/getAll/' + searchText);
  }
  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/speciality/getById/' + id);
  }
  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/speciality/delete/' + id);
  }

  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/speciality/downloadCSVTemplate", { responseType: "blob" });

  }

  uploadCsvTemplate(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/speciality/uploadCSVTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }
}
