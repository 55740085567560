import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ItemService } from '../item.service';
import { SaveItemBusinessunitComponent } from '../save-item-businessunit/save-item-businessunit.component';

@Component({
  selector: 'crm-item-businessunit',
  templateUrl: './item-businessunit.component.html',
  styleUrls: ['./item-businessunit.component.css']
})
export class ItemBusinessunitComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  itemBusinessUnits: any[] = [];
  selectedItemBusinessUnitId;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemId: number;

  @ViewChild('itemBusinessUnitModal')
  itemBusinessUnitModal: ModalDirective;

  @ViewChild('itemBusinessUnitEditor')
  itemBusinessUnitEditor: SaveItemBusinessunitComponent;

  @ViewChild('deleteItemBusinessUnitModal')
  deleteItemBusinessUnitModal: ModalDirective;

  constructor(private readonly itemService: ItemService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getItemBusinessUnits();
  }

  ngAfterViewInit() {
    this.itemBusinessUnitEditor.changesCallback = () => {
      this.getItemBusinessUnits();
      this.itemBusinessUnitModal.hide();
    };
  }

  getItemBusinessUnits() {
    this.displayMode = DisplayModes.Loading;
    this.itemService.getItemBusinessUnits(this.itemId).subscribe(response => {
      this.itemBusinessUnits = response as any[];
      this.displayMode = DisplayModes.Success;
    }, err => {
      console.log(err);
      this.displayMode = DisplayModes.Error;
    });
  }

  confirmDelete(itemBusinessUnitId) {
    this.selectedItemBusinessUnitId = itemBusinessUnitId;
    this.deleteItemBusinessUnitModal.show();
  }

  deleteItemBusinessUnit() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemService.deleteItemBusinessUnit(this.selectedItemBusinessUnitId).subscribe(() => {
      this.selectedItemBusinessUnitId = undefined;
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);
      this.getItemBusinessUnits();
    }, (error) => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  hideDeleteModal() {
    this.deleteItemBusinessUnitModal.hide();
  }

  back() {
    this.closeEvent.emit(true);
  }

  addNew() {
    this.itemBusinessUnitEditor.new(this.itemId);
    this.itemBusinessUnitModal.show();
  }


  edit(itemBusinessUnit) {
    this.itemBusinessUnitEditor.edit(itemBusinessUnit);
    this.itemBusinessUnitModal.show();
  }

  onEditorModalHidden() {
    this.itemBusinessUnitModal.hide();
  }


}
