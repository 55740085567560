import { Component, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Job } from '../../models/job.model';

import { JobService } from '../job.service';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';


@Component({
  selector: 'crm-save-job',
  templateUrl: './save-job.component.html',
  styleUrls: ['./save-job.component.css']
})
export class SaveJobComponent implements OnInit {

  displayMode: DisplayModes;
  job: Job;
  jobs: Job[] = new Array<Job>();
  searchText: string;
  //jobId: number;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() jobId: number;
  @ViewChild("parentId")
  private parentId;

  @ViewChild("parentsSelector")
  private parentsSelector;

  constructor(private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly jobService: JobService,
    private readonly translateService: AppTranslationService,
    private readonly router: Router) {
    this.displayMode = DisplayModes.Success;
    this.job = new Job();

    this.searchText = "";
  }

  ngOnInit() {
    this.getAllJobs();

    if (this.jobId != undefined)
      this.getJobDetails(this.jobId);
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.jobService.saveJob(this.job).subscribe((response) => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.job.id === 0) {
          this.job = new Job();
          this.closeEvent.emit(true);
          this.getAllJobs();
        }
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, error => {
        this.alertService.stopLoadingMessage();
          this.displayMode = DisplayModes.Error;
          this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.Error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getJobDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.jobService.getJobById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.job = response as Job;
    }, error => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showStickyMessage("Save Error", "The below errors occured whilst retrieving data:", MessageSeverity.Error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  getAllJobs() {
    this.displayMode = DisplayModes.Loading;
    this.jobService.getAllJobs().subscribe(response => {
      const results = response;
      this.jobs = results as Job[];

      this.displayMode = DisplayModes.Success;
   //   setTimeout(() => this.parentsSelector.refresh());
    }, error => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showStickyMessage("Save Error", "The below errors occured whilst retrieving data:", MessageSeverity.Error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

}
