import { Component, OnInit } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { HelperMethodsService } from '../../../services/helper-methods.service';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { ItemService } from '../item.service';

@Component({
  selector: 'crm-save-item-businessunit',
  templateUrl: './save-item-businessunit.component.html',
  styleUrls: ['./save-item-businessunit.component.css']
})
export class SaveItemBusinessunitComponent implements OnInit {

  businessUnits: BusinessUnit[] = [];
  itemBusinessunit: any = {};
  displayMode: DisplayModes = DisplayModes.Success;
  startDate: any;
  endDate: any;
  public changesCallback: () => void;

  constructor(private readonly itemService: ItemService,
    private readonly alertService: AlertService, private readonly businessUnitService: BusinessUnitService,
    private readonly helperMethods: HelperMethodsService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getBusinessUnits();
  }

  close() {
    this.changesCallback();
  }

  new(itemId: number) {
    this.itemBusinessunit = {};
    this.startDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1
    };
    this.endDate = {
      year: new Date().getFullYear()+1,
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 10
    };
    this.itemBusinessunit.itemId = itemId;
    this.itemBusinessunit.isCurrent = false;
  }

  edit(itemBusinessunit) {
    this.itemBusinessunit = itemBusinessunit;
    let sDate = new Date(this.itemBusinessunit.startDate);
    this.startDate = {
      year: sDate.getFullYear(),
      month: sDate.getMonth() + 1,
      day: sDate.getDate()
    };

    if (itemBusinessunit.businessUnitId)
      this.itemBusinessunit.businessUnit = this.businessUnits.find(x => x.id == itemBusinessunit.businessUnitId);

    if (this.itemBusinessunit.endDate !== null) {
      this.endDate = {
        year: new Date(this.itemBusinessunit.endDate).getFullYear(),
        month: new Date(this.itemBusinessunit.endDate).getMonth() + 1,
        day: new Date(this.itemBusinessunit.endDate).getDate()
      }
    }

  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

      this.itemBusinessunit.startDate = this.helperMethods.convertDateToUTC(new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day + 1));

      if (this.endDate !== undefined)
        this.itemBusinessunit.endDate = this.helperMethods.convertDateToUTC(new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day + 1));

      if (this.itemBusinessunit.businessUnit)
        this.itemBusinessunit.businessUnitId = this.itemBusinessunit.businessUnit.id;

      this.itemService.saveItemBusinessUnit(this.itemBusinessunit).subscribe(response => {
        this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
        this.changesCallback();
      }, (error) => {
        this.displayMode = DisplayModes.Error;
        this.alertService.showMessage("", this.translateService.getTranslation("FialedToSave"), MessageSeverity.Error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
        this.changesCallback();
      });
    }
  }

  getBusinessUnits() {
    this.businessUnitService.getBusinessUnitsRootLevel().subscribe(response => {
      this.businessUnits = response as BusinessUnit[];
    }, error => { });
  }

}
