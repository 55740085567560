<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!businessUnitId">Create {{'pageHeader.businessUnits' | translate}}</span>
    <span class="panel-title" *ngIf="businessUnitId">Edit {{'pageHeader.businessUnits' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!businessUnitId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="businessUnit.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnitSpecialities.dirty || businessUnitSpecialities.touched || f.submitted)  && businessUnitSpecialities.valid, 'has-error' : (businessUnitSpecialities.dirty || businessUnitSpecialities.touched || f.submitted)  && businessUnitSpecialities.invalid}">
              <label class="control-label">{{'Parent Speciality' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Business Unit Specialities'"
                                       [data]="specialities"
                                       name="businessUnitSpecialities"
                                       #businessUnitSpecialities="ngModel"
                                       [(ngModel)]="businessUnit.businessUnitSpecialities"
                                       [settings]="dropdownSettings"
                                       (onSelect)="onItemSelect($event)"
                                       (onSelectAll)="onSelectAll($event)">
              </ng-multiselect-dropdown>


              <div *ngIf="(businessUnitSpecialities.dirty || businessUnitSpecialities.touched || f.submitted)  && businessUnitSpecialities.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnitTypeId.dirty || businessUnitTypeId.touched || f.submitted)  && businessUnitTypeId.valid, 'has-error' : (businessUnitTypeId.dirty || businessUnitTypeId.touched || f.submitted)  && businessUnitTypeId.invalid}">
              <label class="control-label">{{'businessUnits.businessUnitType' | translate}} </label><br />
              <span class="ui-fluid">
                <p-dropdown [options]="businessUnitTypes"
                            name="businessUnitTypeId" #businessUnitTypeId="ngModel"
                            styleClass="wid100"
                            placeholder="Business Unit Type"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [styleClass]="form-control"
                            [(ngModel)]="businessUnit.businessUnitType"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>
              <div *ngIf="(businessUnitTypeId.dirty || businessUnitTypeId.touched || f.submitted)  && businessUnitTypeId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (parentId.dirty || parentId.touched || f.submitted)  && parentId.valid, 'has-error' : (parentId.dirty || parentId.touched || f.submitted)  && parentId.invalid}">
              <label class="control-label">{{'businessUnits.parentBusinessUnit' | translate}} </label><br />


              <span class="ui-fluid">

                <p-dropdown [options]="businessUnits"
                            name="parentId" #parentId="ngModel"
                            styleClass="wid100"
                            placeholder="Parent Business Unit"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            [styleClass]="form-control"
                            [(ngModel)]="businessUnit.parentBusinessUnit"
                            [virtualScroll]="true" itemSize="10"
                            [filter]="true" optionLabel="name"></p-dropdown>

              </span>

            </div>
          </div>

        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (active.dirty || active.touched || f.submitted)  && active.valid, 'has-error' : (active.dirty || active.touched || f.submitted)  && active.invalid}">
              <label class="control-label">{{'Active' | translate}} </label>

              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" #active="ngModel" name="active" [(ngModel)]="businessUnit.active" (change)="onChangeIsActive()">{{'Active' | translate}}  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="!businessUnit.active">
            <div [ngClass]="{'has-success': (endDatePicker.dirty || endDatePicker.touched)  && endDatePicker.valid, 'has-error' : (endDatePicker.dirty || endDatePicker.touched)  && endDatePicker.invalid}">
              <label class="control-label">{{'End Date' | translate}} </label>
              <div class="input-group date">
                <input class="form-control" placeholder="yyyy-mm-dd"
                       name="endDatePicker" #endDatePicker="ngModel" [(ngModel)]="endDate" ngbDatepicker #d="ngbDatepicker">
                <span class="input-group-btn">
                  <button class="btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                </span>
              </div>
            </div>
          </div>
        </div>




        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>
