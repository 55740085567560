import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayModes } from '../../../models/enums';
import { ItemFormat } from '../../../models/lookups/itemFormat.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ItemFormatService } from '../item-format.service';

@Component({
  selector: 'crm-add-item-format',
  templateUrl: './add-item-format.component.html',
  styleUrls: ['./add-item-format.component.css']
})
export class AddItemFormatComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  itemFormat: ItemFormat = new ItemFormat();
  //itemFormatId: number;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemFormatId: number;
  constructor(private readonly itemFormatService: ItemFormatService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.itemFormatId != undefined)
      this.getItemFormat(this.itemFormatId);
  }

  getItemFormat(itemFormatId) {
    this.displayMode = DisplayModes.Loading;
    this.itemFormatService.getById(itemFormatId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.itemFormat = responce as ItemFormat;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item fromat');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.itemFormatService.save(this.itemFormat).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
