import { Component, OnInit, ViewChild } from '@angular/core';

import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ActivatedRoute, Router } from '@angular/router';
import { SpecialityService } from './speciality.service';
import { ProvisionSpecialitiesComponent } from "./provision-specialities/provision-specialities.component";
import { ModalDirective } from "ngx-bootstrap";
import { DialogType, MessageSeverity, AlertService } from '../../services/alert.service';
import { DisplayModes } from '../../models/enums';
import { Page } from '../../models/page.model';
import { AppTranslationService } from '../../services/app-translation.service';
import { LookupsService } from '../../services/lookups.service';
import { Specialty } from '../../models/lookups/speciality.model';
import { AppConfigService } from '../../app.config';



@Component({
  selector: 'crm-speciality',
  templateUrl: './speciality.component.html',
  styleUrls: ['./speciality.component.css']
})
export class SpecialityComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  countryId: number;
  displayMode: DisplayModes = DisplayModes.Success;

  specialities: Specialty[] = [];
  public logo = require("../../../app/assets/images/demo/Icon_Specialities.png");
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Specialities List :',
    useBom: true,
    noDownload: false,
    headers: ["ID", "Name","ParentName"]
  };

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  @ViewChild("provisionSpecialities")
  provisionSpecialities: ProvisionSpecialitiesComponent;


  selectedSpecialityId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private lookupsService: LookupsService,
    private readonly appConfig: AppConfigService,
    private specialityService: SpecialityService,
    private translateService: AppTranslationService,
    private router: Router) {
  }

  ngOnInit() {
    this.searchSpecialities();
    this.columns = [

      { field: 'name', header: 'Name' },
      { field: 'parentName', header: 'Parent Name' }
    ];
  }

  searchSpecialities() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.specialityService.Search(this.search.searchText, this.search.parentId).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      let result = response as Page;
      this.specialities = result.items;
      this.totalItems = result.totalItems;

    }, err => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchSpecialities();
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['specialty']);
  }


  gotoEdit(specialityId) {
    this.selectedSpecialityId = specialityId;
    this.addNew = true;
    //this.router.navigate(['specialty', specialityId]);
  }

  confirmDelete(specialityId) {
    this.selectedSpecialityId = specialityId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(specialityId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.specialityService.Delete(this.selectedSpecialityId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);

      this.searchSpecialities();
    }, err => {
      this.displayMode = DisplayModes.Success;

        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }
  
  showProvisionModal() {
    this.provisionModal.show();
  }

  downloadCSV() {
    //this.dtHolidays : JSONDATA , HolidayList : CSV file Name, this.csvOptions : file options
    const csvData = this.specialities.map(o => {
      return { id: o.id, name: o.name, parentName: o.parentName};

    });
    new AngularCsv(csvData, "SpecialitiesList", this.csvOptions);
  }

  onProvisionModalHidden() {

  }

  closeAdding(event) {
    this.selectedSpecialityId = undefined;
    this.addNew = false;
    if (event) {
      this.searchSpecialities();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }


}
