import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BrickLevelService {

  constructor(private readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }


  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/brickLevel/search', { params: myParams });
  }

  getAllBrickLevels() {
    return this.http.get(this.configurations.apiAddress + '/api/brickLevel/getAllBrickLeveles');
  }

  SaveBrickLevel(brickLevel) {
    return this.http.post(this.configurations.apiAddress + '/api/brickLevel/saveBrickLevel', brickLevel);
  }

  GetBrickLevelDetails(brickLevelId) {
    return this.http.get(this.configurations.apiAddress + '/api/brickLevel/getBrickLevelDetails/' + brickLevelId);
  }

  DeleteBrickLevel(brickLevelId) {
    return this.http.delete(this.configurations.apiAddress + '/api/brickLevel/deleteBrickLevel/' + brickLevelId);
  }

}
