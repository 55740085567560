import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { UserBricksService } from '../user-bricks.service';

@Component({
  selector: 'crm-add-user-bricks',
  templateUrl: './add-user-bricks.component.html',
  styleUrls: ['./add-user-bricks.component.css']
})
export class AddUserBricksComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() userId: number;
  bricks: any[] = [];
  selectedBricks: any[] = [];
  pageSize = 10;
  searchText;
  constructor(private readonly userBricksService: UserBricksService,
    private readonly alertService: AlertService, private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getAvilableBricks();
  }

  getAvilableBricks() {
    this.userBricksService.getAvilableBricks(this.userId).subscribe(response => {
      this.bricks = response as any[];
    });
  }

  searchBricks() {
    return this.bricks.filter(x => !this.searchText || x.name.indexOf(this.searchText) > -1);
  }

  back() {
    this.closeEvent.emit(true);
  }

  save() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
    let data = {
      userId: this.userId,
      bricksIds: this.selectedBricks.map(x => Number(x))
    }
    this.userBricksService.addUserBricks(data).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("", `Bricks Added.`, MessageSeverity.Success);
      this.closeEvent.emit(true);
    }, () => {
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToSave"), MessageSeverity.Error);
    });
  }

}
