import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationJobStructureService } from './organization-job-structure.service';
import { Router } from '@angular/router';
import { DisplayModes } from '../../models/enums';
import { OrganizationJobStructure } from '../../models/lookups/organizationJobStructure.model';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AppConfigService } from '../../app.config';
import { Page } from '../../models/page.model';
import { AppConsts } from '../../../shared/app-consts';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from 'ngx-bootstrap';
import { ProvisionOrganizationJobStructureComponent } from './provision-organization-job-structure/provision-organization-job-structure.component';
import { TreeNode } from 'primeng/api';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { BusinessUnitService } from '../business-unit/business-unit.service';
import { BusinessUnit } from '../../models/lookups/business-unit.model';
import { ProvisionOrgJobStrTargetDatesComponent } from './provision-org-job-str-target-dates/provision-org-job-str-target-dates.component';


@Component({
  selector: 'crm-organization-job-structure',
  templateUrl: './organization-job-structure.component.html',
  styleUrls: ['./organization-job-structure.component.css']
})
export class OrganizationJobStructureComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 10;
  displayMode: DisplayModes = DisplayModes.Success;

  orgJobStrs: TreeNode[] = [];
  public logo = require("../../../app/assets/images/demo/Icon_Organization Job Structure.png");

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;


  @ViewChild("provisionTargetDateModal")
  provisionTargetDateModal: ModalDirective;

  @ViewChild("provisionOrgJobStructure")
  provisionOrgJobStructure: ProvisionOrganizationJobStructureComponent;

  @ViewChild("provisionOrgJobStructureTargetDates")
  provisionOrgJobStructureTargetDates: ProvisionOrgJobStrTargetDatesComponent;

  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  @ViewChild('filterModal')
  filterModal: ModalDirective;

  addNew: boolean = false;
  selectedOrgId;

  updateModel: boolean = false;

  @ViewChild('orgJobStrBusinessunitsModal')
  orgJobStrBusinessunitsModal: ModalDirective;
  businessUnits: BusinessUnit[] = [];
  selectedBu: any[] = [];
  orgJobStrBusinessunitDatesMode: boolean = false;
  orgJobStrTargetDatesMode: boolean = false;
  targetType: string = "";

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Organization Job Structure List :',
    useBom: true,
    noDownload: false,
    headers: ["ID", "Name", "AM Target", "PM Target", "PH Target", "Apply Sales", "User Name", "Business Unit Name", "Job Name", "Direct Manager Name", "Direct Manager Job","Active"]
  };

  constructor(private alertService: AlertService,
    private orgJobStrService: OrganizationJobStructureService, private businessUnitService: BusinessUnitService,
    private translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private router: Router) {
    this.pageSize = AppConsts.pageSize;

    //this.searchOrgJobStrs();

  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  ngOnInit() {
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'userName', header: 'User Name' },
      { field: 'businessUnitName', header: 'Business Unit Name' },
      { field: 'jobName', header: 'Job Name' },
      { field: 'directManagerName', header: 'Direct Manager Name' },
      { field: 'directManagerJob', header: 'Direct Manager Job' }
    ];


  }

  loadTreeTableData(event) {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.orgJobStrService.getAllTree(this.search.searchText, this.search.directManager, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as any;
      this.orgJobStrs = result.items as TreeNode[];
      this.orgJobStrs.forEach(x => { x.expanded = true; x.children.forEach(y => y.expanded = true) });
      this.totalItems = result.totalItems;
      this.displayMode = DisplayModes.Success;
      this.orgJobStrs.forEach((i) => {

        {
          i.expanded = true;
          if (i.data.userName && i.data.userName.length > 10) {
            i.data.userNameTrimmed = i.data.userName.substring(0, 10) + '...';
          }
          else i.data.userNameTrimmed = i.data.userName;
        }
        i.children.forEach(y => {
          y.expanded = true;
          if (y.data.userName && y.data.userName.length > 10) {
            y.data.userNameTrimmed = y.data.userName.substring(0, 10) + '...';
          }
          else y.data.userNameTrimmed = y.data.userName;
          y.children.forEach(z => {
            z.expanded = true;
            if (z.data.userName && z.data.userName.length > 10) {
              z.data.userNameTrimmed = z.data.userName.substring(0, 10) + '...';
            }
            else z.data.userNameTrimmed = z.data.userName;
          })
        })

      })
    }, (error) => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }
  onNodeExpand(event) {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    const ojs = event.node as TreeNode;
    this.orgJobStrService.getChilds(ojs.data.id).subscribe(response => {
      ojs.children = response as TreeNode[];
      ojs.children.forEach((i) => {
        { if (i.data.userName && i.data.userName.length > 10) { i.data.userNameTrimmed = i.data.userName.substring(0, 10) + '...'; } else i.data.userNameTrimmed = i.data.userName; }

      })
      this.orgJobStrs = [...this.orgJobStrs];
      this.alertService.stopLoadingMessage();
    });
  }



  pageChanged(event) {
    this.currentPage = event.page;
    this.loadTreeTableData(event);
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['add-org-job-str']);
  }


  gotoEdit(orgJobStrId) {
    this.selectedOrgId = orgJobStrId;
    this.addNew = true;
    //this.router.navigate(['add-org-job-str', orgJobStrId]);
  }

  confirmDeleteOrgJobStr(orgJobStrId) {
    this.selectedOrgId = orgJobStrId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(orgJobStrId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.orgJobStrService.Delete(this.selectedOrgId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);

      this.loadTreeTableData(null);
    }, err => {
      this.displayMode = DisplayModes.Success;
      let errorMessage = Utilities.findHttpResponseMessage("errorDescription", err);

      if (errorMessage)
        this.alertService.showStickyMessage("Unable to delete", errorMessage, MessageSeverity.Error, err);
      else
        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }
  onProvisionModalHidden() {
    this.loadTreeTableData(null);
  }

  showProvisionModal() {
    this.updateModel = false;
    this.provisionModal.show();
  }

  showUpdatenModal() {
    this.updateModel = true;
    this.provisionModal.show();
  }

  downloadCSV() {
    this.displayMode = DisplayModes.Loading;
    let buTypeId = 0;
    this.orgJobStrService.getAllTree(this.search.searchText, this.search.directManager, this.currentPage).subscribe(response => {
      debugger;
      let allOJS = response as any;
      let mainList: any[] = [];
      //mainList = this.addChildren(mainList, allOJS.items);
      //allOJS.items.forEach(x => {
      //  test.push(x.data);
      //  if (x.children)
      //    x.children.forEach(y => {
      //      test.push(y.data);
      //      if (y.children)
      //        y.children.forEach(z => {
      //          test.push(z.data);
      //          if (z.children)
      //            y.children.forEach(f => {
      //              test.push(f.data);
      //            })
      //        })
      //    })
      //})
      allOJS.items.forEach(item => {
        mainList.push(item.data);
        this.addChildren(mainList, item)
      })
      const csvData = mainList.map(o => {
        return {
          id: o.id, name: o.name, amTarget: o.targetAm, pmTarget: o.targetPm, phTarget: o.pharmacyTarget, applySales: o.applySales, userName: o.userName, businessUnitName: o.businessUnitName,
          jobName: o.jobName, directManagerName: o.directManagerName, directManagerJob: o.directManagerJob, active: o.isActive

        };
      });
      new AngularCsv(csvData, "OrganizationJobStructure", this.csvOptions);
      this.displayMode = DisplayModes.Success;

    }, err => { });

  }

  addChildren(mainList, item) {
    console.log(mainList)
    if (item.children)
      item.children.forEach(y => {
        console.log(mainList)
        mainList.push(y.data);
        this.addChildren(mainList, y);
      })
    return mainList;
  }

  closeAdding(event) {
    this.selectedOrgId = undefined;
    this.addNew = false;
    this.orgJobStrBusinessunitDatesMode = false;
    this.orgJobStrTargetDatesMode = false;
    this.targetType = "";
    if (event) {
      this.loadTreeTableData(null);
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  hideOrgJobStrBusinessunitsModal() {
    this.selectedOrgId = undefined;
    this.orgJobStrBusinessunitsModal.hide();
  }

  showOrgJobStrBusinessunitsModal(orgJobStrId) {
    this.selectedOrgId = orgJobStrId;
    this.orgJobStrBusinessunitsModal.show();
    this.businessUnitService.getAllBusinessUnits().subscribe(response => {
      const results = response;
      this.businessUnits = results as BusinessUnit[];

    }, () => {
      //this.displayMode = DisplayModes.Error;
    });
    this.orgJobStrService.getOrgJobStrBusinessunits(this.selectedOrgId).subscribe(response => {
      const results = response;
      this.selectedBu = results as any[];

    }, () => {
      //this.displayMode = DisplayModes.Error;
    });


  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  saveOrgJobStrBu() {

    this.displayMode = DisplayModes.Loading;
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

    this.orgJobStrService.saveOrgJobStrBusinessunits(this.selectedOrgId, this.selectedBu).subscribe(() => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      this.hideOrgJobStrBusinessunitsModal();
      this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
    }, () => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });

  }

  getBusinessUnitDates(job) {
    this.selectedOrgId = job.id;
    this.orgJobStrBusinessunitDatesMode = true;
  }

  getTargetDates(job, targetType) {
    this.selectedOrgId = job.id;
    this.targetType = targetType;
    this.orgJobStrTargetDatesMode = true;
  }

  getTargetDatesProvision() {
    this.provisionTargetDateModal.show();
  }

  onProvisionTargetDateModalHidden() {
    this.loadTreeTableData(null);
  }

  openFilterModal() {
    this.filterModal.show();
  }

  hideFilterModal() {
    this.filterModal.hide();
  }

  searchFilterModal() {
    this.hideFilterModal();
    this.loadTreeTableData(null);
  }

}
