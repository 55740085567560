import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { OrganizationJobStructureService } from '../organization-job-structure.service';
import { SaveOrgJobStrTargetDatesComponent } from '../save-org-job-str-target-dates/save-org-job-str-target-dates.component';

@Component({
  selector: 'crm-org-job-str-target-dates',
  templateUrl: './org-job-str-target-dates.component.html',
  styleUrls: ['./org-job-str-target-dates.component.css']
})
export class OrgJobStrTargetDatesComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  orgJobStrTargetDates: any[] = [];
  selectedOrgJobStrTargetDatesId;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() orgJobStrId: number;
  @Input() targetType: string;

  @ViewChild('orgJobStrTargetDatesModal')
  orgJobStrTargetDatesModal: ModalDirective;

  @ViewChild('orgJobStrTargettDatesEditor')
  orgJobStrTargetDatesEditor: SaveOrgJobStrTargetDatesComponent;

  @ViewChild('deleteOrgJobStrTargetDatesModal')
  deleteOrgJobStrTargetDatesModal: ModalDirective;

  constructor(private readonly organizationJobStructureService: OrganizationJobStructureService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.getOrgJobStrTargetDates();
  }

  ngAfterViewInit() {
    this.orgJobStrTargetDatesEditor.changesCallback = () => {
      this.getOrgJobStrTargetDates();
      this.orgJobStrTargetDatesModal.hide();
    };
  }

  getOrgJobStrTargetDates() {
    this.displayMode = DisplayModes.Loading;
    this.organizationJobStructureService.getOrganizationJobStructureTargetDates(this.orgJobStrId, this.targetType).subscribe(response => {
      this.orgJobStrTargetDates = response as any[];
      this.displayMode = DisplayModes.Success;
    }, err => {
      console.log(err);
      this.displayMode = DisplayModes.Error;
    });
  }

  confirmDelete(orgJobStrTargetDatesId) {
    this.selectedOrgJobStrTargetDatesId = orgJobStrTargetDatesId;
    this.deleteOrgJobStrTargetDatesModal.show();
  }

  deleteOrganizationJobStructureTargetDates() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.organizationJobStructureService.deleteOrganizationJobStructureTargetDates(this.selectedOrgJobStrTargetDatesId).subscribe(() => {
      this.selectedOrgJobStrTargetDatesId = undefined;
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);
      this.getOrgJobStrTargetDates();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  hideDeleteModal() {
    this.deleteOrgJobStrTargetDatesModal.hide();
  }

  back() {
    this.closeEvent.emit(true);
  }

  addNew() {
    this.orgJobStrTargetDatesEditor.new(this.orgJobStrId, this.targetType);
    this.orgJobStrTargetDatesModal.show();
  }


  edit(orgJobStrTarget) {
    this.orgJobStrTargetDatesEditor.edit(orgJobStrTarget);
    this.orgJobStrTargetDatesModal.show();
  }

  onEditorModalHidden() {
    this.orgJobStrTargetDatesModal.hide();
  }


}
