import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ModalModule, PaginationModule, TooltipModule, PopoverModule } from "ngx-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ChartsModule } from "ng2-charts";
import { AppRoutingModule } from "./app-routing.module";
import { AppErrorHandler } from "./app-error.handler";
import { AppTitleService } from "./services/app-title.service";
import { AppTranslationService } from "./services/app-translation.service";
import { ConfigurationService } from "./services/configuration.service";
import { AlertService } from "./services/alert.service";
import { LocalStoreManager } from "./services/local-store-manager.service";
import { EndpointFactory } from "./services/endpoint-factory.service";
import { NotificationService } from "./services/notification.service";
import { NotificationEndpoint } from "./services/notification-endpoint.service";
import { AccountService } from "./services/account.service";
import { AccountEndpoint } from "./services/account-endpoint.service";

import { EqualValidator } from "./directives/equal-validator.directive";
import { LastElementDirective } from "./directives/last-element.directive";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { BootstrapTabDirective } from "./directives/bootstrap-tab.directive";
import { BootstrapToggleDirective } from "./directives/bootstrap-toggle.directive";
import { BootstrapDatepickerDirective } from "./directives/bootstrap-datepicker.directive";
import { GroupByPipe } from "./pipes/group-by.pipe";
import { ToastyModule } from "ng2-toasty";
import { AppComponent } from "./components/app.component";
import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { BannerDemoComponent } from "./components/controls/banner-demo.component";
import { TodoDemoComponent } from "./components/controls/todo-demo.component";
import { StatisticsDemoComponent } from "./components/controls/statistics-demo.component";
import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { SearchBoxComponent } from "./components/controls/search-box.component";
import { UserInfoComponent } from "./components/controls/user-info.component";
import { UserPreferencesComponent } from "./components/controls/user-preferences.component";
import { UsersManagementComponent } from "./components/settings/users-management/users-management.component";
import { RolesManagementComponent } from "./components/settings/roles-management/roles-management.component";
import { LookupsService } from "./services/lookups.service";
import { MainMenuComponent } from "./components/layout/main-menu/main-menu.component";
import { AccordionModule } from 'primeng/accordion';
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { CycleComponent } from "./components/cycle/cycle.component";
import { AddCycleComponent } from "./components/cycle/add-cycle/add-cycle.component";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { CycleBUSpecialityComponent as CycleBUSpecialityComponent } from "./components/cycle/cycle-buspeciality/cycle-buspeciality.component";
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserJobsComponent } from './components/settings/users-management/user-jobs/user-jobs.component';
import { SaveUserJobComponent } from './components/settings/users-management/user-jobs/save-user-job/save-user-job.component';
import { UserEditorComponent } from './components/settings/users-management/user-editor/user-editor.component';
import { ProvisionUsersComponent } from './components/settings/users-management/provision-users/provision-users.component';
import { RoleEditorComponent } from "./components/settings/roles-management/role-editor/role-editor.component";
import { ServerErrorComponent } from './components/server-error/server-error.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';
import { FormWizardModule } from 'angular2-wizard';
import { ArchwizardModule } from 'angular-archwizard';
import { CycleBricksComponent } from './components/cycle/cycle-bricks/cycle-bricks.component';
import { TopSharedModule } from "./top-shared/top-shared.module";

//PrimeNG

import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeModule } from 'primeng/tree';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';

import { FilterPipe } from "./lookups/business-unit-bricks/add-business-unit-bricks/pipe";
import { CountryComponent } from "./lookups/country/country.component";
import { HCPComponent } from "./lookups/hcp/hcp.component";
import { AddHCPComponent } from "./lookups/hcp/add-hcp/add-hcp.component";
import { ItemTypeComponent } from "./lookups/item-type/item-type.component";
import { AddItemTypeComponent } from "./lookups/item-type/add-item-type/add-item-type.component";
import { OrganizationTypeComponent } from "./lookups/organization-type/organization-type.component";
import { AddOrganizationTypeComponent } from "./lookups/organization-type/add-organization-type/add-organization-type.component";
import { SpecialityComponent } from "./lookups/speciality/speciality.component";
import { AddBusinessUnitBricksComponent } from "./lookups/business-unit-bricks/add-business-unit-bricks/add-business-unit-bricks.component";
import { AddOrganizationJobStructureComponent } from "./lookups/organization-job-structure/add-organization-job-structure/add-organization-job-structure.component";
import { OrganizationJobStructureComponent } from "./lookups/organization-job-structure/organization-job-structure.component";
import { ProvisionBusinessUnitsComponent } from "./lookups/business-unit/provision-business-units/provision-business-units.component";
import { ManageBusinessUnitBricksComponent } from "./lookups/business-unit-bricks/manage-business-unit-bricks/manage-business-unit-bricks.component";
import { SaveBusinessUnitComponent } from "./lookups/business-unit/save-business-unit/save-business-unit.component";
import { BusinessUnitComponent } from "./lookups/business-unit/business-unit.component";
import { BusinessUnitBricksComponent } from "./lookups/business-unit-bricks/business-unit-bricks.component";
import { SaveSpecialityComponent } from "./lookups/speciality/save-speciality/save-speciality.component";
import { ProvisionSpecialitiesComponent } from "./lookups/speciality/provision-specialities/provision-specialities.component";
import { ItemComponent } from "./lookups/item/item.component";
import { AddItemComponent } from "./lookups/item/add-item/add-item.component";
import { ItemPriceComponent } from './lookups/item/item-price/item-price.component';
import { SaveItemPriceComponent } from './lookups/item/save-item-price/save-item-price.component';
import { SaveItemBusinessunitComponent } from './lookups/item/save-item-businessunit/save-item-businessunit.component';
import { ItemBusinessunitComponent } from './lookups/item/item-businessunit/item-businessunit.component';
import { ProvisionItemsComponent } from "./lookups/item/provision-items/provision-items.component";
import { BusinessUnitTypeComponent } from "./lookups/business-unit-type/business-unit-type.component";
import { SaveBusinessUnitTypeComponent } from "./lookups/business-unit-type/save-business-unit-type/save-business-unit-type.component";
import { LookupsModule } from "./lookups/lookups.module";
import { WorkingDaysComponent } from "./lookups/working-days/working-days.component";
import { holidayComponent } from "./lookups/holiday/holiday.component";
import { AddHolidayComponent } from "./lookups/holiday/add-holiday/add-holiday.component";
import { ReportViewerModule } from 'ngx-ssrs-reportviewer';
import { ProvisionOrganizationJobStructureComponent } from "./lookups/organization-job-structure/provision-organization-job-structure/provision-organization-job-structure.component";
import { ProvisionOrgJobStrTargetDatesComponent } from './lookups/organization-job-structure/provision-org-job-str-target-dates/provision-org-job-str-target-dates.component';
import { SharedModule } from "./shared-lazy/shared/shared.module";
import { ProvisionBusinessUnitBricksComponent } from './lookups/business-unit-bricks/provision-business-unit-bricks/provision-business-unit-bricks.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { AppConfigService } from "./app.config";
import { PharmacyWorkingDaysComponent } from './lookups/pharmacy-working-days/pharmacy-working-days.component';
import { OrgJobStrBusinessUnitDatesComponent } from './lookups/organization-job-structure/org-job-str-business-unit-dates/org-job-str-business-unit-dates.component';
import { SaveOrgJobStrBusinessUnitDatesComponent } from './lookups/organization-job-structure/save-org-job-str-business-unit-dates/save-org-job-str-business-unit-dates.component';
import { OrgJobStrTargetDatesComponent } from './lookups/organization-job-structure/org-job-str-target-dates/org-job-str-target-dates.component';
import { SaveOrgJobStrTargetDatesComponent } from './lookups/organization-job-structure/save-org-job-str-target-dates/save-org-job-str-target-dates.component';
import { AddHcoHcpComponent } from "./settings/components/hco-hcps/add-hco-hcp/add-hco-hcp.component";
import { ProvisionHcpHcoComponent } from "./settings/components/hco-hcps/provision-hcp-hco/provision-hcp-hco.component";
import { UserBricksComponent } from './user-bricks/user-bricks.component';
import { AddUserBricksComponent } from './user-bricks/add-user-bricks/add-user-bricks.component';

export function initializeApp(configuration: ConfigurationService) {
  return () => configuration.loadAppConfig();
}
@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxDatatableModule,
    ToastyModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
    PaginationModule.forRoot(),
    SharedModule,
    TopSharedModule,
    LookupsModule,
    NgbModule,
    FileUploadModule,
    ArchwizardModule,
    NgSlimScrollModule,
    //PrimNG Modules
    TableModule,
    TreeModule,
    FormWizardModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    CalendarModule,
    AppRoutingModule,
    ChartsModule,
    ReportViewerModule,
    AccordionModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
  ],
  declarations: [
    FilterPipe,
    AppComponent,
    LoginComponent,
    HomeComponent,
    SettingsComponent,
    UsersManagementComponent,
    UserInfoComponent,
    UserPreferencesComponent,
    RolesManagementComponent,
    RoleEditorComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    SearchBoxComponent,
    StatisticsDemoComponent,
    TodoDemoComponent,
    BannerDemoComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    BootstrapDatepickerDirective,
    GroupByPipe,
    CountryComponent,
    AddHcoHcpComponent,
    ProvisionHcpHcoComponent,
    //BootstrapSelectDirective,
    HCPComponent,

    MainMenuComponent,

    AddHCPComponent,
    ItemTypeComponent,
    AddItemTypeComponent,
    // hcpClassComponent,
    CycleComponent,
    AddCycleComponent,
    OrganizationTypeComponent,
    AddOrganizationTypeComponent,
    SpecialityComponent,
    SaveSpecialityComponent,
    BusinessUnitComponent,
    SaveBusinessUnitComponent,
    CycleBUSpecialityComponent,

    UserProfileComponent,
    ProvisionSpecialitiesComponent,
    ItemComponent,
    AddItemComponent,
    ItemPriceComponent,
    SaveItemPriceComponent,
    SaveItemBusinessunitComponent,
    ItemBusinessunitComponent,
    ProvisionItemsComponent,
    ProvisionBusinessUnitsComponent,
    OrganizationJobStructureComponent,
    ProvisionOrganizationJobStructureComponent,
    AddOrganizationJobStructureComponent,
    UserJobsComponent,
    SaveUserJobComponent,
    UserEditorComponent,
    ProvisionUsersComponent,
    ServerErrorComponent,
    UnAuthorizedComponent,
    CycleBricksComponent,
    BusinessUnitTypeComponent,
    SaveBusinessUnitTypeComponent,
    BusinessUnitBricksComponent,
    ManageBusinessUnitBricksComponent,
    AddBusinessUnitBricksComponent,
    WorkingDaysComponent,
    holidayComponent,
    AddHolidayComponent,
    ProvisionBusinessUnitBricksComponent,
    PharmacyWorkingDaysComponent,
    OrgJobStrBusinessUnitDatesComponent,
    SaveOrgJobStrBusinessUnitDatesComponent,
    OrgJobStrTargetDatesComponent,
    SaveOrgJobStrTargetDatesComponent,
    ProvisionOrgJobStrTargetDatesComponent,
    UserBricksComponent,
    AddUserBricksComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationService], multi: true
    },
    AlertService,
    AppConfigService,
    TokenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true

    },
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true

    },
    AppTitleService,
    LookupsService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    EndpointFactory,
    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: false
      }
    },
  ],
  bootstrap: [AppComponent],

})
export class AppModule {

}

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}
