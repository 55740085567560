import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayModes, ActivityCostCenterType } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ActivityCostCenterService } from '../activity-cost-center.service';
import { ActivityCostCenter } from '../../../models/lookups/activity-cost-center.model';
import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { ItemBrandService } from '../../item-brand/item-brand.service';
import { ItemBrand } from '../../../models/lookups/item-brand.model';

@Component({
  selector: 'crm-add-activity-cost-center',
  templateUrl: './add-activity-cost-center.component.html',
  styleUrls: ['./add-activity-cost-center.component.css']
})
export class AddActivityCostCenterComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  activityCostCenter: ActivityCostCenter = new ActivityCostCenter();
  businessUnitsLines: any[] = [];
  businessUnits: any[] = [];
  itemBrands: any[] = [];
  buLinesSelected: any[] = [];
  brandsSelected: any[] = [];
  busSelected: any[] = [];

  buLinesSelectedIds: any[] = [];
  brandsSelectedIds: any[] = [];
  busSelectedIds: any[] = [];
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() activityCostCenterId: number;
  @Output() showProvision = new EventEmitter<boolean>();

  showBuLines: boolean = false;
  showBrands: boolean = false;
  showBu: boolean = false;

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  constructor(private readonly activityCostCenterService: ActivityCostCenterService, private readonly itemBrandService: ItemBrandService,
    private readonly route: ActivatedRoute, private readonly businessUnitService: BusinessUnitService, private cd: ChangeDetectorRef,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    this.getBusinessUnitsRootLevel();
    if (this.activityCostCenterId != undefined)
      this.getActivityCostCenter(this.activityCostCenterId);
    else {
      //this.getBusinessUnitsRootLevel();
      this.showBuLines = true;
      this.showBrands = true;
      this.showBu = true;
    }
  }

  getActivityCostCenter(activityCostCenterId) {
    this.displayMode = DisplayModes.Loading;
    this.activityCostCenterService.getById(activityCostCenterId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.activityCostCenter = responce as ActivityCostCenter;
      debugger;
      if (this.activityCostCenter && this.activityCostCenter.activityCostCenterDetails
        && this.activityCostCenter.activityCostCenterDetails != null && this.activityCostCenter.activityCostCenterDetails.length > 0) {
        this.activityCostCenter.activityCostCenterDetails.forEach(x => {
          if (x.type == ActivityCostCenterType.BusinessUnitLineId)
            this.buLinesSelected.push(x.item)
          else if (x.type == ActivityCostCenterType.ItemBrandId)
            this.brandsSelected.push(x.item)
          else if (x.type == ActivityCostCenterType.BusinessUnitTerritoryId)
            this.busSelected.push(x.item)
        });
      }



      //if (this.businessUnitsLines && this.businessUnitsLines != null && this.businessUnitsLines.length &&
      //  this.buLinesSelectedIds && this.buLinesSelectedIds != null && this.buLinesSelectedIds.length > 0) {
      //  this.buLinesSelectedIds.forEach(x => {
      //    let bu = this.businessUnitsLines.find(y => y.id == x)
      //    if (bu)
      //      this.buLinesSelected.push(bu);
      //  });
      //}

      this.buLinesSelected.forEach(x => {
        this.getChildrensBusinessUnit(x.id, true);
        this.getAllItemBrands(x.id, true);
      })

    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Activity Cost Center');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;
      this.activityCostCenter.activityCostCenterDetails = [];
      if (this.buLinesSelected && this.buLinesSelected != null && this.buLinesSelected.length > 0) {
        this.buLinesSelected.forEach(x => this.activityCostCenter.activityCostCenterDetails.push({ value: x.id, type: ActivityCostCenterType.BusinessUnitLineId, item: null }));
      }

      if (this.brandsSelected && this.brandsSelected != null && this.brandsSelected.length > 0) {
        this.brandsSelected.forEach(x => this.activityCostCenter.activityCostCenterDetails.push({ value: x.id, type: ActivityCostCenterType.ItemBrandId, item: null }));
      }

      if (this.busSelected && this.busSelected != null && this.busSelected.length > 0) {
        this.busSelected.forEach(x => this.activityCostCenter.activityCostCenterDetails.push({ value: x.id, type: ActivityCostCenterType.BusinessUnitTerritoryId, item: null }));
      }

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.activityCostCenterService.save(this.activityCostCenter).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getBusinessUnitsRootLevel() {
    this.businessUnitService.getBusinessUnitsRootLevel().subscribe(response => {
      this.businessUnitsLines = response as any[];
      //if (this.buLinesSelectedIds && this.buLinesSelectedIds != null && this.buLinesSelectedIds.length > 0) {
      //  this.buLinesSelectedIds.forEach(x => {
      //    let bu = this.businessUnitsLines.find(y => y.id == x)
      //    if (bu)
      //      this.buLinesSelected.push(bu);
      //  });
      //  this.showBuLines = true;
      //}
    }, error => { });
  }

  getAllItemBrands(businessUnitId, editMode) {
    this.showBrands = !editMode;
    this.cd.detectChanges();
    this.itemBrandService.getAllByBusinessUnitId(businessUnitId).subscribe(response => {
      let result = response as any[];
      result.forEach(x => x.buLineId = businessUnitId);
      this.itemBrands = this.itemBrands.concat(result);
      //if (editMode) {
      //  if (this.brandsSelectedIds && this.brandsSelectedIds != null && this.brandsSelectedIds.length > 0) {
      //    this.brandsSelectedIds.forEach(x => {
      //      let br = this.itemBrands.find(y => y.id == x)
      //      let brExist = this.brandsSelected.find(y => y.id == x)
      //      if (br&&!brExist)
      //        this.brandsSelected.push(br);
      //    });
      //  }
      //  this.showBrands = true;
      //  console.log('brand')
      //  console.log(this.brandsSelected)
      //}
    }, error => { });
  }

  getChildrensBusinessUnit(businessUnitId, editMode) {
    this.showBu = !editMode;
    this.cd.detectChanges();
    this.businessUnitService.getChildrens(businessUnitId).subscribe(response => {
      let result = response as any[];
      result.forEach(x => x.buLineId = businessUnitId);
      this.businessUnits = this.businessUnits.concat(result);
      //if (editMode) {
      //  if (this.busSelectedIds && this.busSelectedIds != null && this.busSelectedIds.length > 0) {
      //    this.busSelectedIds.forEach(x => {
      //      let bu = this.businessUnits.find(y => y.id == x)
      //      let buExist = this.busSelected.find(y => y.id == x)
      //      if (bu && !buExist)
      //        this.busSelected.push(bu);
      //    });
      //  }
      //  this.showBu = true;
      //  console.log('bu')
      //  console.log(this.busSelected)
      //}
    }, error => { });
  }


  onBuLineChanged(event, selected) {
    if (selected) {
      this.getChildrensBusinessUnit(event.id, false);
      this.getAllItemBrands(event.id, false);
    }
    else {
      let unselectedBrands = this.itemBrands.filter(x => x.buLineId == event.id).map(({ id }) => id);
      this.brandsSelected = this.brandsSelected.filter(x => unselectedBrands.indexOf(x.id) == -1);
      this.itemBrands = this.itemBrands.filter(x => x.buLineId != event.id);


      let unselectedbusinessUnits = this.businessUnits.filter(x => x.buLineId == event.id).map(({ id }) => id);
      this.busSelected = this.busSelected.filter(x => unselectedbusinessUnits.indexOf(x.id) == -1);
      this.businessUnits = this.businessUnits.filter(x => x.buLineId != event.id);
    }
    console.log(event)
    console.log(this.buLinesSelected)


  }

  onBrandsChanged(event) {
    console.log(event)
  }

  onBushanged(event) {
    console.log(event)
  }


  showProvisionModal() {
    this.showProvision.emit(true);
  }



}
