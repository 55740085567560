import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { UserJob } from '../components/settings/models/user-job.model';

@Injectable({
  providedIn: 'root'
})
export class UserJobService {

  constructor(private readonly http: HttpClient,
    private readonly configuration: ConfigurationService) { }

  getUserJobs(userId: number) {
    return this.http.get(`${this.configuration.apiAddress}/api/userJob/getUserJobs/${userId}`);
  }

  saveUserJob(userJob: UserJob) {
    return this.http.post(`${this.configuration.apiAddress}/api/userJob/saveUserJob`, userJob);
  }

  deleteUserJob(userJobId: number) {
    return this.http.delete(`${this.configuration.apiAddress}/api/userJob/deleteUserJob/${userJobId}`);
  }

}
