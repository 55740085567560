import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Reaction } from '../../models/lookups/Reaction';
import { ReactionsApiService } from '../services/reactions-api.service';
import { Permission } from '../../models/permission.model';

@Component({
  selector: 'crm-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.css']
})
export class ReactionComponent implements OnInit {
  reactions: Reaction[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = "";

  selectedReactioId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly reactionsApiService: ReactionsApiService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getReactions();
  }

  getReactions() {
    this.displayMode = DisplayModes.Loading;
    this.reactionsApiService.search(this.searchText).subscribe(responce => {
      this.reactions = responce as Reaction[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get reactions');
        console.log(err);
      })
  }


  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedReactioId = id;
    this.addNew = true;
  }

  confirmDelete(itemFormat) {
    this.selectedReactioId = itemFormat;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.reactionsApiService.delete(this.selectedReactioId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.reactions.indexOf(this.selectedReactioId);
      this.reactions.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete item fromat:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  closeAdding(event) {
    this.selectedReactioId = undefined;
    this.addNew = false;
    if (event) {
      this.getReactions();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  get canManagReaction() {
    return this.accountService.userHasPermission(Permission.manageReactionPermission);
  }

}
