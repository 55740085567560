import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayModes } from '../../../models/enums';
import { ActivityCostType } from '../../../models/lookups/activity-cost-type.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ActivityCostTypeService } from '../activity-cost-type.service';

@Component({
  selector: 'crm-add-activity-cost-type',
  templateUrl: './add-activity-cost-type.component.html',
  styleUrls: ['./add-activity-cost-type.component.css']
})
export class AddActivityCostTypeComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  activityCostType: ActivityCostType = new ActivityCostType();

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() activityCostTypeId: number;
  constructor(private readonly activityCostTypeService: ActivityCostTypeService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService) { }

  ngOnInit() {
    if (this.activityCostTypeId != undefined)
      this.getActivityCostType(this.activityCostTypeId);
  }

  getActivityCostType(activityCostTypeId) {
    this.displayMode = DisplayModes.Loading;
    this.activityCostTypeService.getById(activityCostTypeId).subscribe(responce => {
      this.displayMode = DisplayModes.Success;
      this.activityCostType = responce as ActivityCostType;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Activity CostType');
        console.log(err);
      });
  }

  save(form) {
    if (!form.invalid) {

      this.displayMode = DisplayModes.Loading;

      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.activityCostTypeService.save(this.activityCostType).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        this.back();
        this.alertService.showMessage("", this.translateService.getTranslation("clm.savedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

}
