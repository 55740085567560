import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { ItemBrand } from '../../models/lookups/item-brand.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ItemBrandService } from './item-brand.service';

@Component({
  selector: 'crm-item-brand',
  templateUrl: './item-brand.component.html',
  styleUrls: ['./item-brand.component.css']
})
export class ItemBrandComponent implements OnInit {

  itemBrands: ItemBrand[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedItemBrandId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly itemBrandService: ItemBrandService,
    private accountService: AccountService) { }


  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getItemBrands();
  }

  getItemBrands() {
    this.displayMode = DisplayModes.Loading;
    this.itemBrandService.search(this.searchText).subscribe(responce => {
      this.itemBrands = responce as ItemBrand[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get item brands');
        console.log(err);
      })
  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedItemBrandId = id;
    this.addNew = true;
  }

  confirmDelete(itemBrand) {
    this.selectedItemBrandId = itemBrand;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemBrandService.delete(this.selectedItemBrandId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.itemBrands.indexOf(this.selectedItemBrandId);
      this.itemBrands.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete item fromat:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageItemBrands() {
    ///////////////////////
    return this.accountService.userHasPermission(Permission.manageItemBrandsPermission);
    //////////////////////
  }

  closeAdding(event) {
    this.selectedItemBrandId = undefined;
    this.addNew = false;
    if (event) {
      this.getItemBrands();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }


}
