export class Job {

  constructor() {
    this.id = 0;
    this.active = true;
  }

  id: number;
  name: string;
  parentId: number;
  parentName: string;
  active: boolean;
}
