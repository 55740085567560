<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'settings.header.RolesManagement' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'settings.header.RolesManagement' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.RolesManagement' | translate}} {{'Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input (input)="onSearchChanged()" type="text" [(ngModel)]="searchText" class="form-control" placeholder="{{'roles.management.Search' | translate}}" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="onSearchChanged()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" *ngIf="canManageRoles" (click)="newRole()">
          <span class="margin-right-7 create-new-span">Create New Role</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.RolesManagement' | translate}} {{'Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-table #dt [value]="rows" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->

                <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="20%" *ngIf="canManageRoles">Edit</th>
                <th width="20%" *ngIf="canManageRoles">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
              <tr *ngIf="rows.length>0 && displayMode===2">
                <!--<td>

                  <strong>{{role.index}}</strong>

                </td>-->
                <td>



                  {{role.name}}

                </td>
                <td>{{role.description}}</td>
                <td> {{role.usersCount}}</td>

                <td *ngIf="canManageRoles">
                  <a class="" href="javascript:;" (click)="editRole(role)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td *ngIf="canManageRoles"><a href="javascript:;" (click)="deleteRole(role)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="rows.length===0 && displayMode===2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode===1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>

                  </div>

                </td>
              </tr>
            </ng-template>



          </p-table>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteRoleHelper()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>










<div class="modal fade" bsModal #editorModal="bs-modal" (onHidden)="onEditorModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="!canManageRoles" class="modal-title pull-left"><i class="fa fa-shield"></i> {{'roles.management.RoleDetails' | translate:editingRoleName}}</h4>
        <h4 *ngIf="canManageRoles" class="modal-title pull-left"><i class="fa fa-shield"></i> {{editingRoleName ? ('roles.management.EditRole' | translate:editingRoleName) : ('roles.management.NewRole' | translate)}}</h4>
        <button type="button" class="close pull-right" title="Close" (click)="editorModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <role-editor #roleEditor></role-editor>
      </div>
    </div>
  </div>
</div>

