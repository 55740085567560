import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrganizationJobStructureService } from '../organization-job-structure.service';
import { ActivatedRoute, Router } from '@angular/router';

import { BusinessUnitService } from '../../business-unit/business-unit.service';
import { OrganizationJobStructure } from '../../../models/lookups/organizationJobStructure.model';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { Job } from '../../../models/lookups/job.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { SettingService } from '../../../settings/components/settings/setting.service';
import { ActivityGroupService } from '../../activity-group/activity-group.service';
import { ActivityGroup } from '../../../models/lookups/activity-group.model';
import { ItemType } from '../../../models/lookups/itemType.model';
import { ItemTypeService } from '../../item-type/item-type.service';


@Component({
  selector: 'crm-add-organization-job-structure',
  templateUrl: './add-organization-job-structure.component.html',
  styleUrls: ['./add-organization-job-structure.component.css']
})
export class AddOrganizationJobStructureComponent implements OnInit {

  orgJobStr: OrganizationJobStructure = new OrganizationJobStructure();
  businessUnit: BusinessUnit = new BusinessUnit(true);
  businessUnits: BusinessUnit[] = [];

  activityGroups: ActivityGroup[] = [];
  activityGroup: ActivityGroup = new ActivityGroup();

  itemTypes: ItemType[] = [];
  itemType: ItemType = new ItemType();
  job: Job = new Job();
  jobs: Job[] = [];
  endDate: any;
  isActiveChanged: boolean = false;
  directManager: OrganizationJobStructure = new OrganizationJobStructure();
  directManagers: OrganizationJobStructure[] = [];

  displayMode: DisplayModes = DisplayModes.Success;
  //orgJobStrId: number;
  @ViewChild('businessUnitelector')
  private businessUnitSelector;

  @ViewChild('jobSelector')
  private jobSelector;

  @ViewChild('directManagerSelector')
  private directManagerSelector;


  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() orgJobStrId: number;
  applyingPharmaciesModule: string;

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  constructor(private readonly orgJobStrService: OrganizationJobStructureService,
    private readonly route: ActivatedRoute, private readonly activityGroupService: ActivityGroupService,
    private readonly router: Router, private itemTypeService: ItemTypeService,
    private readonly alertService: AlertService, private readonly settingService: SettingService,
    private readonly businessUnitService: BusinessUnitService,
    private readonly translateService: AppTranslationService) { }

  ngOnInit() {
    this.endDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    if (this.orgJobStrId) {
      this.displayMode = DisplayModes.Loading;
    }

    this.settingService.getAllSettings().subscribe(response => {
      this.applyingPharmaciesModule = response.find(x => { return x.key == "applyingPharmaciesModule"; }).value;
    });

    setTimeout(() => {
      this.getAllOrgJobStructure();
      this.getAllBussinessUnit();
      this.getAllJobs();
      this.getActivityGroups();
      this.getItemTypes();
      if (this.orgJobStrId) {
        this.getDetails(this.orgJobStrId);
      }
    }, 1000);
  }

  getAllOrgJobStructure() {
    // this.displayMode = DisplayModes.Loading;
    this.orgJobStrService.GetAllOrgJobStructure().subscribe(response => {
      const results = response;
      this.directManagers = results as OrganizationJobStructure[];
      if (this.orgJobStr)
        this.orgJobStr.directManager = this.directManagers.filter(x => x.id == this.orgJobStr.directManagerId)[0];
      // this.displayMode = DisplayModes.Success;
      if (this.directManagerSelector)
        setTimeout(() => this.directManagerSelector.refresh());
    }, () => {
      //this.displayMode = DisplayModes.Error;
    });
  }

  getActivityGroups() {
    this.activityGroupService.search("").subscribe(responce => {
      this.activityGroups = responce as ActivityGroup[];

      if (this.orgJobStr &&
        this.orgJobStr.activityGroups && this.activityGroups && this.activityGroups.length > 0 && this.orgJobStr.activityGroups.length > 0) {
        this.orgJobStr.orgActivityGroups = [];
        this.orgJobStr.activityGroups.forEach(x => this.orgJobStr.orgActivityGroups.push(this.activityGroups.find(y => y.id == x.activityGroupId)))
      }
    },
      err => {
        console.log('error in get Activity Group');
        console.log(err);
      })
  }

  getItemTypes() {
    this.itemTypeService.Search("", 100, 1).subscribe(responce => {
      let result = responce as any;

      this.itemTypes = result.items as ItemType[];

      if (this.orgJobStr &&
        this.orgJobStr.itemTypes && this.itemTypes && this.itemTypes.length > 0 && this.orgJobStr.itemTypes.length > 0) {
        this.orgJobStr.orgItemTypes = [];
        this.orgJobStr.itemTypes.forEach(x => this.orgJobStr.orgItemTypes.push(this.itemTypes.find(y => y.id == x.itemTypeId)))
      }
    },
      err => {
        console.log('error in get Item Types');
        console.log(err);
      })
  }

  getAllBussinessUnit() {
    // this.displayMode = DisplayModes.Loading;
    this.businessUnitService.getAllBusinessUnits().subscribe(response => {
      const results = response;
      this.businessUnits = results as BusinessUnit[];
      if (this.orgJobStr)
        this.orgJobStr.businessUnit = this.businessUnits.filter(x => x.id == this.orgJobStr.businessUnitId)[0];
      // this.displayMode = DisplayModes.Success;
      if (this.businessUnitSelector)
        setTimeout(() => this.businessUnitSelector.refresh());
    }, () => {
      //this.displayMode = DisplayModes.Error;
    });
  }

  getAllJobs() {
    //this.displayMode = DisplayModes.Loading;
    this.orgJobStrService.GetAllJobs().subscribe(response => {
      const results = response;
      this.jobs = results as Job[];
      if (this.orgJobStr)
        this.orgJobStr.job = this.jobs.filter(x => x.id == this.orgJobStr.jobId)[0];
      //this.displayMode = DisplayModes.Success;
      if (this.jobSelector)
        setTimeout(() => this.jobSelector.refresh());
    }, () => {
      //this.displayMode = DisplayModes.Error;
    });
  }

  save(form) {
    if (!form.invalid) {
      if (!this.orgJobStr.isActive) {
        this.orgJobStr.endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 12, 0, 0);
      }
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      console.log(this.orgJobStr)
      if (this.orgJobStr.businessUnit !== undefined)
        this.orgJobStr.businessUnitId = this.orgJobStr.businessUnit.id;
      if (this.orgJobStr.job !== undefined)
        this.orgJobStr.jobId = this.orgJobStr.job.id;

      if (this.orgJobStr.directManager !== undefined)
        this.orgJobStr.directManagerId = this.orgJobStr.directManager.id;

      this.orgJobStr.activityGroups = [];
      if (this.orgJobStr.orgActivityGroups !== null && this.orgJobStr.orgActivityGroups !== undefined && this.orgJobStr.orgActivityGroups.length > 0) {
        this.orgJobStr.orgActivityGroups.forEach(x => this.orgJobStr.activityGroups.push({ activityGroupId: x.id }))
      }

      this.orgJobStr.itemTypes = [];
      if (this.orgJobStr.orgItemTypes !== null && this.orgJobStr.orgItemTypes !== undefined && this.orgJobStr.orgItemTypes.length > 0) {
        this.orgJobStr.orgItemTypes.forEach(x => this.orgJobStr.itemTypes.push({ itemTypeId: x.id }))
      }

      this.orgJobStrService.Save(this.orgJobStr).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.orgJobStr.id === 0)
          this.closeEvent.emit(true);
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  onChangeIsActive() {
    this.isActiveChanged = true;
  }

  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.orgJobStrService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.orgJobStr = response as OrganizationJobStructure;
      if (this.orgJobStr.endDate) {
        let eDate = new Date(this.orgJobStr.endDate);
        this.endDate = {
          year: eDate.getFullYear(),
          month: eDate.getMonth() + 1,
          day: eDate.getDate()
        };
      }

      if (this.businessUnitSelector)
        setTimeout(() => this.businessUnitSelector.refresh());
      if (this.jobSelector)
        setTimeout(() => this.jobSelector.refresh());
      if (this.directManagerSelector)
        setTimeout(() => this.directManagerSelector.refresh());
      if (this.jobs)
        this.orgJobStr.job = this.jobs.filter(x => x.id == this.orgJobStr.jobId)[0];
      if (this.businessUnits)
        this.orgJobStr.businessUnit = this.businessUnits.filter(x => x.id == this.orgJobStr.businessUnitId)[0];
      if (this.directManagers)
        this.orgJobStr.directManager = this.directManagers.filter(x => x.id == this.orgJobStr.directManagerId)[0];

      if (this.orgJobStr.activityGroups && this.activityGroups && this.activityGroups.length > 0 && this.orgJobStr.activityGroups.length > 0) {
        this.orgJobStr.orgActivityGroups = [];
        this.orgJobStr.activityGroups.forEach(x => this.orgJobStr.orgActivityGroups.push(this.activityGroups.find(y => y.id == x.activityGroupId)))
      }

      if (this.orgJobStr.itemTypes && this.itemTypes && this.itemTypes.length > 0 && this.orgJobStr.itemTypes.length > 0) {
        this.orgJobStr.orgItemTypes = [];
        this.orgJobStr.itemTypes.forEach(x => this.orgJobStr.orgItemTypes.push(this.itemTypes.find(y => y.id == x.itemTypeId)))
      }

    }, () => {
      this.displayMode = DisplayModes.Error;

    });
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }
}
