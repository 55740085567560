
<ul id="uidemo-tabs-default-demo" class="nav nav-tabs">
  <li class="active">
    <a href="#uidemo-tabs-default-demo-home" data-toggle="tab">{{'DownloadTemplate' |translate}}</a>
  </li>
  <li class="">
    <a href="#uidemo-tabs-default-demo-profile" data-toggle="tab">{{'UploadTemplate' |translate}}</a>
  </li>

</ul>

<div class="tab-content tab-content-bordered">
  <div class="tab-pane fade active in" id="uidemo-tabs-default-demo-home">
    <div class="image-upload cursor-pointer margin-top-5 margin-left-44" (click)="downloadTemplate()">
      <label for="file-download">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </label>
    </div>

  </div>
  <div class="tab-pane fade" id="uidemo-tabs-default-demo-profile">

    <div class="image-upload cursor-pointer margin-top-5 margin-left-44">
      <label for="file-upload">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </label>

      <input id="file-upload" #file type="file" (change)="upload(file.files)" />
    </div>
    <!--<p-fileUpload name="file"  customUpload="true" (uploadHandler)="upload($event.files)"></p-fileUpload>-->
    <br />
    <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress <= 100">
      {{progress}}%
    </span>

    <span style="font-weight:bold;color:green;" *ngIf="message">
      {{message}}
    </span>
    <button class="btn  btn-save" *ngIf="templateUrl" type="button" (click)="provisionUsers()">{{'Save' | translate}}</button>

  </div>

</div>

