<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title">{{'Business Unit Bricks' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">
          <div class="col-sm-8">
          </div>
          <div class="col-sm-2 cursor-pointer left-15">
            <span class="margin-right-7 edit-profile-span" (click)="addNewBusinessUnitBrick()">New</span>
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
          </div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>


        <div class="row padding-sm-vr">
          <div class="col-sm-12">

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="table-edit">{{'Name' | translate}}</th>
                  <th class="table-edit">Delete</th>
                </tr>
              </thead>
              <tbody *ngIf="businessUnitBricks.length>0 && displayMode==2">
                <tr *ngFor="let item of businessUnitBricks">
                  <td>{{ item.brickName }}</td>
                  <td><a href="javascript:;" (click)="confirmDeleteHCOHCP(item.id)" *ngIf="!item.endDate"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

                </tr>

              </tbody>
              <tbody *ngIf="businessUnitBricks.length==0 && displayMode==2">
                <tr>
                  <td colspan="6">{{'NoDataFound' | translate}}</td>
                </tr>
              </tbody>
              <tbody *ngIf="displayMode==1">
                <tr>
                  <td colspan="3">
                    <div class="row col-sm-12 padding-sm-hr">
                      <div class="container text-center">
                        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                      </div>

                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>




        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">
        <div class="col-sm-12">
          <div [ngClass]="{'has-success': (startDatePicker.dirty || startDatePicker.touched)  && startDatePicker.valid, 'has-error' : (startDatePicker.dirty || startDatePicker.touched)  && startDatePicker.invalid}">
            <label class="control-label">{{'End Date' | translate}} </label>


            <div class="input-group date">
              <input class="form-control" placeholder="yyyy-mm-dd" required
                     name="startDatePicker" #startDatePicker="ngModel" [(ngModel)]="endDate" ngbDatepicker #d="ngbDatepicker">
              <span class="input-group-btn">
                <button class="btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
              </span>

            </div>


            <div *ngIf="(startDatePicker.dirty || startDatePicker.touched)  && startDatePicker.invalid" class="jquery-validate-error help-block">
              {{'RequiredField' | translate}}
            </div>
          </div>
        </div>
        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteBusinessUnitBrickById()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="modal-sizes-1" bsModal #addBusinessUnitBrickModal="bs-modal" (onHidden)="onAddBusinessUnitBrickModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Add Business Unit Bricks'|translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="addBusinessUnitBrickModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-add-business-unit-bricks #addBusinessUnitBrick businessUnitId="{{businessUnitId}}" (closeModalEvent)="onCloseAddBusinessUnitBrickModal()"></crm-add-business-unit-bricks>
    </div>
  </div>
</div>


<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Business Units Bricks Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-business-unit-bricks #provisionBusinessUnitBricks></crm-provision-business-unit-bricks>
    </div>
  </div>
</div>
