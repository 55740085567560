import { Injectable, Injector } from '@angular/core';
import { HttpParams, HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppTranslationService } from './app-translation.service';
import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
@Injectable({
  providedIn: 'root'
})
export class AttachementFileApiService {

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) { }

  upload(formData, folderName) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/attachementFile/upload/${folderName}`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  uploadContent(formData, folderName) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/attachementFile/uploadContent/${folderName}`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  delete(fileId) {
    return this.http.post(`${this.configurations.apiAddress}/api/attachementFile/deleteFile/${fileId}`, null);
  }
}
