import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DisplayModes } from '../../../../models/enums';
import { UserJobService } from '../../../../services/user-job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserJob } from '../../models/user-job.model';
import { ModalDirective } from 'ngx-bootstrap';
import { SaveUserJobComponent } from './save-user-job/save-user-job.component';
import { AlertService, DialogType, MessageSeverity } from '../../../../services/alert.service';
import { AppTranslationService } from '../../../../services/app-translation.service';

@Component({
  selector: 'crm-user-jobs',
  templateUrl: './user-jobs.component.html',
  styleUrls: ['./user-jobs.component.css']
})
export class UserJobsComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  //userId: number;
  userJobs: UserJob[] = [];

  @ViewChild('userJobModal')
  userJobModal: ModalDirective;

  @ViewChild('userJobEditor')
  userJobEditor: SaveUserJobComponent;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() userId: number;

  selectedJobId;
  @ViewChild('deleteJobModal')
  deleteJobModal: ModalDirective;
  constructor(private readonly userJobService: UserJobService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) {
   
  }

  ngOnInit() {
    this.getUserJobs();
  }

  ngAfterViewInit() {

    this.userJobEditor.changesSavedCallback = () => {
      this.getUserJobs();
      this.userJobModal.hide();
    };

    this.userJobEditor.changesCancelledCallback = () => {

      this.userJobModal.hide();
    };

    this.userJobEditor.changesFailedCallback = () => {
      this.userJobModal.hide();

    };
  }
  back() {
    this.closeEvent.emit(true);


  }

  
  getUserJobs() {
    this.displayMode = DisplayModes.Loading;
    this.userJobService.getUserJobs(this.userId).subscribe(response => {
      this.userJobs = response as UserJob[];
      this.displayMode = DisplayModes.Success;
    }, err => {
        console.log(err);
        this.displayMode = DisplayModes.Error;
    });
  }

  newUserJob() {
    this.userJobEditor.newJob(this.userId, this.userJobs);
    this.userJobModal.show();
  }

  onEditorModalHidden() {
    
  }

  deleteJob(job) {

  }
  editJob(job) {
    debugger;
    this.userJobEditor.editJob(job, this.userJobs);
    this.userJobModal.show();

  }

  confirmDeleteUserJob(userJobId) {
    this.selectedJobId = userJobId;
    this.deleteJobModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteUserJob(userJobId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  deleteUserJob() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.userJobService.deleteUserJob(this.selectedJobId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Default);

      this.getUserJobs();
    }, () => {
      this.displayMode = DisplayModes.Success;

        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);

    });
  }

  hideDeleteModal() {
    this.deleteJobModal.hide();
  }
}
