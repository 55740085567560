import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class BusinessUnitTypeService {

  constructor(private readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }


  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/businessUnitType/search', { params: myParams });
  }

  GetAllTypes() {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnitType/getAllTypes');
  }

  SaveBusinessUnitType(businessUnitType) {
    return this.http.post(this.configurations.apiAddress + '/api/businessUnitType/saveBusinessUnitType', businessUnitType);
  }

  GetBusinessUnitTypeDetails(businessUnitTypeId) {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnitType/getBusinessUnitTypeDetails/' + businessUnitTypeId);
  }

  DeleteBusinessUnitType(businessUnitTypeId) {
    return this.http.delete(this.configurations.apiAddress + '/api/businessUnitType/deleteBusinessUnitType/' + businessUnitTypeId);
  }
}
