import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../../services/configuration.service';
import { Observable, of } from 'rxjs';
import { Setting } from '../../models/setting.model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  public responseCache = new Map();
  constructor(private http: HttpClient,
    private configurations: ConfigurationService) {
  }

  search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/Settings/getSettings', { params: myParams });
  }

  getSettingValueTypes() {
    return this.http.get(this.configurations.apiAddress + '/api/Settings/getSettingValueTypes');
  }

  saveSetting(setting) {
    return this.http.post(this.configurations.apiAddress + '/api/Settings/saveSetting', setting);
  }

  getSettingDetails(settingId) {
    return this.http.get(this.configurations.apiAddress + '/api/Settings/getSettingDetails/' + settingId);
  }

  delete(settingId) {
    return this.http.delete(this.configurations.apiAddress + '/api/Settings/delete/' + settingId);
  }

  getAllSettings(): Observable<Setting[]>{
    const URL = `${this.configurations.apiAddress}/api/settings/getAllSettings`;
    const settingsFromCache = this.responseCache.get(URL);
    if (settingsFromCache) {
      return of(settingsFromCache);
    }
    const response = this.http.get<Setting[]>(URL);
    response.subscribe(settings => this.responseCache.set(URL, settings));
    return response;
   
  }

  getAllSettingsByURL(url): Observable<Setting[]> {
    const URL = `${url}/api/settings/getAllSettings`;
    const settingsFromCache = this.responseCache.get(URL);
    if (settingsFromCache) {
      return of(settingsFromCache);
    }
    const response = this.http.get<Setting[]>(URL);
    response.subscribe(settings => this.responseCache.set(URL, settings));
    return response;

  }

  getServerTime() {
    return this.http.get(this.configurations.apiAddress + '/api/Values/getServerTime');
  }
}
