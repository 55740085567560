import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { activityType, ActivityTypeApproval, ActivityApprovalActorType } from '../../../models/lookups/activityType.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../../services/app-translation.service';
import { ActivityTypeService } from '../activity-type.service';
import { ActivityGroup } from '../../../models/lookups/activity-group.model';
import { ActivityCostCenter } from '../../../models/lookups/activity-cost-center.model';
import { ActivityCostCenterService } from '../../activity-cost-center/activity-cost-center.service';
import { ActivityGroupService } from '../../activity-group/activity-group.service';
import { UserMini } from '../../../models/user-mini.model';
import { UsersApiService } from '../../../services/users-api.service';
import { AccountService } from '../../../services/account.service';
import { Role } from '../../../models/role.model';
import { forkJoin } from 'rxjs';
import { ActivityCostTypeService } from '../../activity-cost-type/activity-cost-type.service';
import { ActivityCostType } from '../../../models/lookups/activity-cost-type.model';

@Component({
  selector: 'crm-add-activity-type',
  templateUrl: './add-activity-type.component.html',
  styleUrls: ['./add-activity-type.component.css']
})
export class AddActivityTypeComponent implements OnInit {

  activitytype: activityType = new activityType();

  activityGroups: ActivityGroup[] = [];
  activityCostCenters: ActivityCostCenter[] = [];
  activityCostTypes: ActivityCostType[] = [];
  /*activityTypeId: number;*/
  displayMode: DisplayModes = DisplayModes.Success;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() activityTypeId: number;

  activityApprovalActorTypes = ActivityApprovalActorType;
  activityApprovalActorkeys: any[] = [];


  users: UserMini[] = [];
  roles: Role[] = [];
  jobs: any[] = [{ id: 1, name: 'Direct Manager' }, { id: 2, name: 'Direct Direct Manager' }]

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false
  };

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private activityTypeService: ActivityTypeService, private activityCostCenterService: ActivityCostCenterService,
    private translateService: AppTranslationService, private activityGroupService: ActivityGroupService,
    private router: Router, private usersService: UsersApiService, private accountService: AccountService,
    private activityCostTypeService: ActivityCostTypeService  ) { }

  ngOnInit() {
    let keys = Object.keys(ActivityApprovalActorType).filter(f => !isNaN(Number(f)));
    for (let i = 1; i <= keys.length; i++) {
      this.activityApprovalActorkeys.push({ name: this.activityApprovalActorTypes[i], value: i });
    }
    this.displayMode = DisplayModes.Loading;
    forkJoin(this.activityGroupService.search(""),
      this.activityCostCenterService.search(""),
      this.accountService.getAllRoles(),
      this.usersService.getAllUsersMiniReport(),
      this.activityCostTypeService.getAll())
      .subscribe(([response1, response2, response3, response4, responce5]) => {
        this.displayMode = DisplayModes.Success;
        this.activityGroups = response1 as ActivityGroup[];
        this.activityCostCenters = response2 as ActivityCostCenter[];
        this.roles = response3 as Role[];
        this.users = response4 as UserMini[];
        this.activityCostTypes = responce5 as ActivityCostType[];
        if (this.activityTypeId != undefined)
          this.getDetails(this.activityTypeId);
      }, error => {
        this.displayMode = DisplayModes.Success;
        this.alertService.showStickyMessage("Load Data Error", "The below errors occured whilst fetch data from server:", MessageSeverity.Error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
      });

   
  }
  save(form) {
    if (!form.invalid && this.activitytype.activityGroup && this.activitytype.activityGroup != null && this.activitytype.activityCostCenter && this.activitytype.activityCostCenter != null) {

      if (this.activitytype.activityGroup !== null && this.activitytype.activityGroup !== undefined)
        this.activitytype.activityGroupId = this.activitytype.activityGroup.id;

      if (this.activitytype.activityCostCenter !== null && this.activitytype.activityCostCenter !== undefined && this.activitytype.activityCostCenter.length > 0) {
        this.activitytype.costCenters = [];
        this.activitytype.activityCostCenter.forEach(x => this.activitytype.costCenters.push({ activityCostCenterId: x.id }))
      }

      if (this.activitytype.activityCostType !== null && this.activitytype.activityCostType !== undefined && this.activitytype.activityCostType.length > 0) {
        this.activitytype.costTypes = [];
        this.activitytype.activityCostType.forEach(x => this.activitytype.costTypes.push({ activityCostTypeId: x.id }))
      }

      if (this.activitytype.activityTypeApproval !== null && this.activitytype.activityTypeApproval !== undefined && this.activitytype.activityTypeApproval.length > 0) {
        this.activitytype.activityTypeApproval.forEach(x => x.activityApprovalActorValue = x.activityApprovalActorValueData.id)
      }

      console.log(this.activitytype);

      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));

      this.activityTypeService.Save(this.activitytype).subscribe(result => {

        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;

        if (this.activitytype.id == 0)
          this.activitytype = new activityType();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {

        // End Loading and alert error message
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(false);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.activityTypeService.GetById(id).subscribe(response => {

      this.displayMode = DisplayModes.Success;
      this.activitytype = response as activityType;

      if (this.activitytype.activityGroupId && this.activityGroups && this.activityGroups.length > 0) {
        this.activitytype.activityGroup = this.activityGroups.find(x => x.id == this.activitytype.activityGroupId);
      }

      if (this.activitytype.costCenters && this.activityCostCenters && this.activityCostCenters.length > 0 && this.activitytype.costCenters.length>0) {
        this.activitytype.activityCostCenter = [];
        this.activitytype.costCenters.forEach(x => this.activitytype.activityCostCenter.push(this.activityCostCenters.find(y => y.id == x.activityCostCenterId)))
      }

      if (this.activitytype.costTypes && this.activityCostTypes && this.activityCostTypes.length > 0 && this.activitytype.costTypes.length > 0) {
        this.activitytype.activityCostType = [];
        this.activitytype.costTypes.forEach(x => this.activitytype.activityCostType.push(this.activityCostTypes.find(y => y.id == x.activityCostTypeId)))
      }
     
      if (this.activitytype.activityTypeApproval !== null && this.activitytype.activityTypeApproval !== undefined && this.activitytype.activityTypeApproval.length > 0) {
        this.activitytype.activityTypeApproval.forEach(x => {
       
          if (x.activityApprovalActorType == ActivityApprovalActorType.User) {
            x.showUsers = true;
            x.activityApprovalActorValueData = this.users.find(y => y.id == x.activityApprovalActorValue);
          }
          else if (x.activityApprovalActorType == ActivityApprovalActorType.Role) {
            x.showRoles = true;
            x.activityApprovalActorValueData = this.roles.find(y => y.id == x.activityApprovalActorValue);
          }
          else if (x.activityApprovalActorType == ActivityApprovalActorType.Job) {
            x.showJobs = true;
            x.activityApprovalActorValueData = this.jobs.find(y => y.id == x.activityApprovalActorValue);
          }
          x.activityApprovalActorTypeData = this.activityApprovalActorkeys.find(y => y.value == x.activityApprovalActorType)
        })
      }

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }


  addNewLevel() {
    if (!this.activitytype.activityTypeApproval)
      this.activitytype.activityTypeApproval = [];
    this.activitytype.activityTypeApproval.push(new ActivityTypeApproval());
  }

  onChangeApprovalActorType(level: ActivityTypeApproval) {
    if (level.activityApprovalActorTypeData.value == ActivityApprovalActorType.User) {
      level.showRoles = false;
      level.showJobs = false;
      level.showUsers = true;
      level.activityApprovalActorValueData = null;
      level.activityApprovalActorType = ActivityApprovalActorType.User;
    }
    else if (level.activityApprovalActorTypeData.value == ActivityApprovalActorType.Role) {
      level.showRoles = true;
      level.showJobs = false;
      level.showUsers = false;
      level.activityApprovalActorValueData = null;
      level.activityApprovalActorType = ActivityApprovalActorType.Role;
    }
    else if (level.activityApprovalActorTypeData.value == ActivityApprovalActorType.Job) {
      level.showRoles = false;
      level.showJobs = true;
      level.showUsers = false;
      level.activityApprovalActorValueData = null;
      level.activityApprovalActorType = ActivityApprovalActorType.Job;
    }


  }

  removeLevel(index) {
    this.activitytype.activityTypeApproval.splice(index, 1);
  }


}
