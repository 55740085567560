
<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">Edit Shared Brick</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>

      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">

        <div class="row padding-xs-vr" *ngFor="let bus of result; let i = index">
          <div class="col-sm-6">
            <label class="control-label">{{'Business Unit Name' | translate}} </label>
            <input class="form-control" type="text" placeholder="{{'Business Unit Name' | translate}}"
                   name="bu{{i}}" #bu="ngModel" disabled
                   [(ngModel)]="bus.businessUnitName">
          </div>
          <div class="col-sm-6">
            <label class="control-label">{{'Percent' | translate}} </label>
            <input class="form-control" type="number" placeholder="{{'Percent' | translate}}"
                   name="percent{{i}}" #percent="ngModel"
                   [(ngModel)]="bus.percent">
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">

          <div *ngIf="showError" class="jquery-validate-error help-block">
            Total percent must be 100
          </div>
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




