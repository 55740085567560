import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { ActivityCostType } from '../../models/lookups/activity-cost-type.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ActivityCostTypeService } from './activity-cost-type.service';

@Component({
  selector: 'crm-activity-cost-type',
  templateUrl: './activity-cost-type.component.html',
  styleUrls: ['./activity-cost-type.component.css']
})
export class ActivityCostTypeComponent implements OnInit {

  activityCostTypes: ActivityCostType[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedActivityCostTypeId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly activityCostTypeService: ActivityCostTypeService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getActivityCostTypes();
  }

  getActivityCostTypes() {
    this.displayMode = DisplayModes.Loading;
    this.activityCostTypeService.search(this.searchText).subscribe(responce => {
      this.activityCostTypes = responce as ActivityCostType[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Activity Cost Type');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedActivityCostTypeId = id;
    this.addNew = true;
  }

  confirmDelete(activityCostType) {
    this.selectedActivityCostTypeId = activityCostType;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.activityCostTypeService.delete(this.selectedActivityCostTypeId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.activityCostTypes.indexOf(this.selectedActivityCostTypeId);
      this.activityCostTypes.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete activity CostType:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageActivityCostTypePermission() {
    return this.accountService.userHasPermission(Permission.manageActivityCostTypePermission);
  }

  closeAdding(event) {
    this.selectedActivityCostTypeId = undefined;
    this.addNew = false;
    if (event) {
      this.getActivityCostTypes();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
