import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HCPClassService } from '../hcpClass.service';
import { hcpClass } from '../../../models/lookups/hcpClass.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';


@Component({
  selector: 'crm-add-hcpClass',
  templateUrl: './add-hcpClass.component.html',
  styleUrls: ['./add-hcpClass.component.css']
})
export class AddhcpClassComponent implements OnInit {
  hcpclass: hcpClass = new hcpClass();

  hcpClassid: number;
  displayMode: DisplayModes = DisplayModes.Success;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() hcpClassId: number;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private hcpClassService: HCPClassService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
 
    this.hcpClassid = this.hcpClassId;
    if (this.hcpClassid != undefined)
      this.getDetails(this.hcpClassid);
    //this.route.params.subscribe(params => {
    //  this.hcpClassid = params["id"];
    //  if (this.hcpClassid != undefined)
    //    this.getDetails(this.hcpClassid);
    //});
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.hcpClassService.Save(this.hcpclass).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.hcpclass.id == 0)
          this.hcpclass = new hcpClass();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    //this.router.navigate(['hcp-classes']);
    this.closeEvent.emit(false);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.hcpClassService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.hcpclass = response as hcpClass;
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

}
