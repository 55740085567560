import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VacationTypeService } from './vacation-type.service';
import { AppConfigService } from '../../app.config';
import { VacationType } from '../../models/lookups/vacationType.model';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from 'ngx-bootstrap';
import { ProvisionVacationTypeComponent } from './provision-vacation-type/provision-vacation-type.component';

@Component({
  selector: 'crm-vacation-type',
  templateUrl: './vacation-type.component.html',
  styleUrls: ['./vacation-type.component.css']
})

export class VacationTypeComponent implements OnInit {

  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  vacationTypes: VacationType[] = [];
  vacationTypeId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  isVac: boolean = false;
  @ViewChild("provisionModal")
  provisionModal: ModalDirective;
  public vacLogo = require("../../../app/assets/images/demo/Icon_Vacations Type.png");
  public nonFiledLogo = require("../../../app/assets/images/demo/Icon_Non Field Activities Type.png");

  @ViewChild("provisionVacationTypes")
  provisionVacationTypes: ProvisionVacationTypeComponent;

  selectedVacId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private alertService: AlertService,
    private vacationTypeService: VacationTypeService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.isVac = this.router.url.indexOf('vacation') > -1;

    this.searchvacationTypes();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }
  new() {
    this.addNew = true;
    //if (this.isVac)
    //  this.router.navigate(['add-vacation-type']);
    //else
    //  this.router.navigate(['add-non-field-type']);

  }
  searchvacationTypes() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.vacationTypeService.Search(this.searchText, this.pageSize, this.currentPage, this.isVac).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;

      this.vacationTypes = response as VacationType[];
      //this.totalItems = result.totalItems;
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve vacation types from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchvacationTypes();
  }

  gotoEdit(id) {
    this.selectedVacId = id;
    this.addNew = true;
    //if (this.isVac)
    //  this.router.navigate(['add-vacation-type', id]);
    //else
    //  this.router.navigate(['add-non-field-type', id]);

  }

  confirmDelete(vacationTypeId) {
    this.selectedVacId = vacationTypeId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deletevacationType(vacationTypeId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  deletevacationType() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.vacationTypeService.Delete(this.selectedVacId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
      this.searchvacationTypes();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  showProvisionModal() {
    this.provisionModal.show();
  }

  onProvisionModalHidden() {
  }

  closeAdding(event) {
    this.selectedVacId = undefined;
    this.addNew = false;
    if (event) {
      this.searchvacationTypes();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
