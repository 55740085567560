import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { build$ } from 'protractor/built/element';
import { ConfigurationService } from '../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UserBricksService {

  constructor(private readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }

  addUserBricks(userBricks) {
    return this.http.post(`${this.configurations.apiAddress}/api/UserBricks/addUserBricks`, userBricks);
  }

  deleteUserBrick(userBrickId) {
    return this.http.delete(`${this.configurations.apiAddress}/api/UserBricks/deleteUserBrick/${userBrickId}`);
  }

  getUserBricks(userId) {
    return this.http.get(`${this.configurations.apiAddress}/api/UserBricks/getUserBricks/${userId}`);
  }

  getAvilableBricks(userId) {
    return this.http.get(`${this.configurations.apiAddress}/api/UserBricks/getAvilableBricks/${userId}`);
  }


}
