<ul class="breadcrumb breadcrumb-page">
  <div class="breadcrumb-label text-light-gray">{{'YouAreHere' | translate}}: </div>
  <li><a href="#">{{'User' | translate}}</a></li>
  <li class="active"><a href="#">{{'settings.header.UserProfile' | translate}}</a></li>
</ul>
<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'settings.header.UserProfile' | translate}}</h1>


  </div>
</div>
<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title"><i class="panel-title-icon fa fa-bullhorn"></i>{{'settings.header.UserProfile' | translate}}</span>

  </div>

  <div class="panel-body padding-sm-hr">



    <form *ngIf="formResetToggle" [attr.autocomplete]="isGeneralEditor ? NaN : null" class="form-horizontal" name="userInfoForm" #f="ngForm" novalidate
          (ngSubmit)="f.form.valid ? save() :

          (!userName.valid && showErrorAlert('User name is required', 'Please enter a user name (minimum of 2 and maximum of 200 characters)'));

          (userPassword && !userPassword.valid && showErrorAlert('Password is required', 'Please enter the current password'));

          (email.errors?.required && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
          (email.errors?.pattern && showErrorAlert('Invalid Email', 'Please enter a valid email address'));

          (isChangePassword && isEditingSelf && !currentPassword.valid && showErrorAlert('Current password is required', 'Please enter the current password'));

          ((isChangePassword || isNewUser) && !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)'));

          ((isChangePassword || isNewUser) && newPassword.valid && confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password'));
          ((isChangePassword || isNewUser) && newPassword.valid && confirmPassword.errors?.validateEqual && showErrorAlert('Passwword mismatch', 'New password and comfirmation password do not match'));

          (canAssignRoles && !roles.valid && showErrorAlert('Roles is required', 'Please select a minimum of 1 role'));">

      <!--<div class="form-group">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="jobTitle-{{uniqueId}}">{{'users.editor.JobTitle' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p class="form-control-static">{{user.jobTitle}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <input autofocus type="text" attr.id="jobTitle-{{uniqueId}}" name="jobTitle" placeholder="Enter Job Title" class="form-control" [(ngModel)]="userEdit.jobTitle" />
        </div>
      </div>-->

      <div class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div class="form-group has-feedback">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="userName-{{uniqueId}}">{{'users.editor.UserName' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p class="form-control-static">{{user.userName}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && userName.valid, 'has-error' : f.submitted && !userName.valid}">
          <input type="text" disabled attr.id="userName-{{uniqueId}}" name="userName" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter user name"
                 class="form-control" [(ngModel)]="userEdit.userName" #userName="ngModel" required minlength="2" maxlength="200" />
          <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': userName.valid, 'glyphicon-remove' : !userName.valid}"></span>
          <span *ngIf="showValidationErrors && f.submitted && !userName.valid" class="errorMessage">
            {{'users.editor.UserNameRequired' | translate}}
          </span>
        </div>
      </div>

      <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName" class="row">
        <div class="col-md-12">
          <hr class="password-separator-hr" />
        </div>
      </div>
      <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName" class="form-group has-feedback">
        <label class="control-label col-md-2" for="userPassword-{{uniqueId}}">{{'users.editor.Password' | translate}}</label>
        <div class="col-md-10" [ngClass]="{'has-success': f.submitted && userPassword.valid, 'has-error' : f.submitted && !userPassword.valid}">
          <input type="password" attr.id="userPassword-{{uniqueId}}" name="userPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter password"
                 class="form-control" [(ngModel)]="userEdit.currentPassword" #userPassword="ngModel" required />
          <small class="hint-sm">{{'users.editor.PasswordHint' | translate}}</small>
          <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': userPassword.valid, 'glyphicon-remove' : !userPassword.valid}"></span>
          <span *ngIf="showValidationErrors && f.submitted && !userPassword.valid" class="errorMessage">
            {{'users.editor.CurrentPasswordRequired' | translate}}
          </span>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div class="form-group has-feedback">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="email-{{uniqueId}}">{{'users.editor.Email' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p class="form-control-static">{{user.email}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
          <input disabled type="text" attr.id="email-{{uniqueId}}" name="email" placeholder="Enter email address" class="form-control" [(ngModel)]="userEdit.email" #email="ngModel"
                 required maxlength="200" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" />
          <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': email.valid, 'glyphicon-remove' : !email.valid}"></span>
          <span *ngIf="showValidationErrors && f.submitted && email.errors?.required" class="errorMessage">
            {{'users.editor.EmailRequired' | translate}}
          </span>
          <span *ngIf="showValidationErrors && f.submitted && email.errors?.pattern" class="errorMessage">
            {{'users.editor.InvalidEmail' | translate}}
          </span>
        </div>
      </div>

      <div *ngIf="isEditMode" class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div *ngIf="isEditMode" class="form-group">
        <label for="newPassword-{{uniqueId}}" class="control-label col-md-2">{{'users.editor.Password' | translate}}</label>
        <div *ngIf="!isChangePassword && !isNewUser" class="col-md-10">
          <button type="button" (click)="changePassword()" class="btn btn-link">{{'users.editor.ChangePassword' | translate}}</button>
        </div>

        <div *ngIf="isChangePassword || isNewUser" class="col-md-10">
          <div class="password-well well well-sm">
            <div *ngIf="isEditingSelf" class="form-group has-feedback">
              <label class="control-label col-md-3" for="currentPassword-{{uniqueId}}">{{'users.editor.CurrentPassword' | translate}}</label>
              <div class="col-md-9" [ngClass]="{'has-success': f.submitted && currentPassword.valid, 'has-error' : f.submitted && !currentPassword.valid}">
                <input type="password" attr.id="currentPassword-{{uniqueId}}" name="currentPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter current password" class="form-control" [(ngModel)]="userEdit.currentPassword" #currentPassword="ngModel"
                       required />
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': currentPassword.valid, 'glyphicon-remove' : !currentPassword.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && !currentPassword.valid" class="errorMessage">
                  {{'users.editor.CurrentPasswordRequired' | translate}}
                </span>
              </div>
            </div>

            <div *ngIf="!isNewUser" class="row">
              <div class="col-md-12">
                <hr class="password-separator-hr" />
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="control-label col-md-3" for="newPassword-{{uniqueId}}">{{'users.editor.NewPassword' | translate}}</label>
              <div class="col-md-9" [ngClass]="{'has-success': f.submitted && newPassword.valid, 'has-error' : f.submitted && !newPassword.valid}">
                <input type="password" attr.id="newPassword-{{uniqueId}}" name="newPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Enter new password" class="form-control" [(ngModel)]="userEdit.newPassword" #newPassword="ngModel"
                       required minlength="6" validateEqual="confirmPassword" reverse="true" />
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': newPassword.valid, 'glyphicon-remove' : !newPassword.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && !newPassword.valid" class="errorMessage">
                  {{'users.editor.NewPasswordRequired' | translate}}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr class="password-separator-hr" />
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="control-label col-md-3" for="confirmPassword-{{uniqueId}}">{{'users.editor.ConfirmPassword' | translate}}</label>
              <div class="col-md-9" [ngClass]="{'has-success': f.submitted && confirmPassword.valid, 'has-error' : f.submitted && !confirmPassword.valid}">
                <input type="password" attr.id="confirmPassword-{{uniqueId}}" name="confirmPassword" [attr.autocomplete]="isGeneralEditor ? new-password : null" placeholder="Confirm new password" class="form-control" [(ngModel)]="userEdit.confirmPassword" #confirmPassword="ngModel"
                       required validateEqual="newPassword" />
                <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': confirmPassword.valid, 'glyphicon-remove' : !confirmPassword.valid}"></span>
                <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.required" class="errorMessage">
                  {{'users.editor.ConfirmationPasswordRequired' | translate}}
                </span>
                <span *ngIf="showValidationErrors && f.submitted && confirmPassword.errors?.validateEqual && !confirmPassword.errors?.required" class="errorMessage">
                  {{'users.editor.PasswordMismatch' | translate}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--******************************************************************************************************-->

      <div *ngIf="!isEditMode || canAssignRoles" class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div *ngIf="!isEditMode || canAssignRoles" class="form-group has-feedback">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="roles-user-info">{{'users.editor.Roles' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p ngPreserveWhitespaces class="form-control-static">
            <span *ngFor="let role of user.roles">
              <span title='{{getRoleByName(role)?.description}}' class="badge">{{role}}</span>
            </span>
          </p>
        </div>
        <div *ngIf="isEditMode && canAssignRoles" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly" [ngClass]="{'has-success': f.submitted && roles.valid, 'has-error' : f.submitted && !roles.valid}">
          <select id="roles-user-info" name="roles" [(ngModel)]="userEdit.roles" #roles="ngModel" #rolesSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect required multiple
                  data-live-search="true" data-actions-box="false" data-live-search-placeholder="Search..." title="Select Roles">
            <option *ngFor="let role of allRoles" attr.data-content="<span title='{{role.description}}' class='badge'>{{role.name}}</span>" attr.value="{{role.name}}">
              {{role.name}}
            </option>
          </select>
          <span *ngIf="showValidationErrors && f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': roles.valid, 'glyphicon-remove' : !roles.valid}"></span>
          <span *ngIf="showValidationErrors && f.submitted && !roles.valid" class="errorMessage">
            {{'users.editor.RoleRequired' | translate}}
          </span>
        </div>
      </div>

      <!--******************************************************************************************************-->

      <div class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div class="form-group">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="fullName-{{uniqueId}}">{{'users.editor.FullName' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p class="form-control-static">{{user.fullName}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <input type="text" disabled attr.id="fullName-{{uniqueId}}" name="fullName" placeholder="Enter full name" class="form-control" [(ngModel)]="userEdit.fullName" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr [class.separator-hr]="!isEditMode" [class.edit-separator-hr]="isEditMode" />
        </div>
      </div>

      <div class="form-group">
        <label [class.col-md-3]="isViewOnly" [class.col-md-2]="!isViewOnly" class="control-label" for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber' | translate}}</label>
        <div *ngIf="!isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <p class="form-control-static">{{user.phoneNumber}}</p>
        </div>
        <div *ngIf="isEditMode" [class.col-md-9]="isViewOnly" [class.col-md-10]="!isViewOnly">
          <input disabled type="text" attr.id="phoneNumber-{{uniqueId}}" name="phoneNumber" placeholder="Enter phone number" class="form-control" [(ngModel)]="userEdit.phoneNumber" />
        </div>
      </div>

      <div *ngIf="!isViewOnly" class="row">
        <div class="col-md-12">
          <hr [class.last-separator-hr]="!isEditMode" [class.edit-last-separator-hr]="isEditMode" />
        </div>
      </div>

      <div *ngIf="!isViewOnly" class="form-group">
        <div class="col-sm-5">
          <div *ngIf="isGeneralEditor && isEditMode" class="pull-left">
            <div class="checkbox user-enabled">
              <label>
                <input type="checkbox" name="isEnabled" [(ngModel)]="userEdit.isEnabled">
                {{'users.editor.Enabled' | translate}}
              </label>
            </div>
            <button *ngIf="userEdit.isLockedOut" type="button" (click)="unlockUser()" class="btn btn-warning unblock-user" [disabled]="isSaving"><i class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
          </div>
        </div>
        <div class="col-sm-7">
          <div ngPreserveWhitespaces class="pull-right">
            <button *ngIf="!isEditMode && isGeneralEditor" type="button" (click)="close()" class="btn btn-default"><i class='fa fa-close'></i> {{'users.editor.Close' | translate}}</button>
            <button *ngIf="!isEditMode && !isGeneralEditor" type="button" (click)="edit()" class="btn btn-default"><i class='fa fa-edit'></i> {{'users.editor.Edit' | translate}}</button>
            <button *ngIf="isEditMode" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-primary" [disabled]="isSaving">
              <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Save' | translate)}}
            </button>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </form>

  </div>
</div>
