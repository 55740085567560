import { Component, OnInit } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { WorkingDaysService } from './working-days.service';
import { AppConfigService } from '../../app.config';
import { workingdays } from '../../models/lookups/workingDays.model';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { Utilities } from '../../services/utilities';

@Component({
  selector: 'working-days',
  templateUrl: './working-days.component.html',
  styleUrls: ['./working-days.component.css']
})
export class WorkingDaysComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  workingDays: workingdays[] = [];
  itemTypeId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = require("../../../app/assets/images/demo/Icon_Working Days.png");

  constructor(private alertService: AlertService,
    private workingDaysService: WorkingDaysService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchItemtypes();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }
  new() {
    this.router.navigate(['add-item-type']);
  }
  searchItemtypes() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.workingDaysService.Search(this.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;

      this.workingDays = result.items;
      this.totalItems = result.totalItems;
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve item types from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchItemtypes();
  }

  gotoEdit(itemTypeId) {
    this.router.navigate(['add-item-type', itemTypeId]);
  }

  DoAmCheck(id) {  
    this.workingDaysService.AmCheck(id).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);    
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  DoPmCheck(id) {  
    this.workingDaysService.PmCheck(id).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);     
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  confirmDelete(itemTypeId) {
    this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
      () => this.deleteItemType(itemTypeId),
      () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  deleteItemType(itemTypeId) {
    this.displayMode = DisplayModes.Loading;
    this.workingDaysService.Delete(itemTypeId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
      this.searchItemtypes();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }
}
