<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!specialityId">Create {{'Speciality' | translate}}</span>
    <span class="panel-title" *ngIf="specialityId">Edit {{'Speciality' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!specialityId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="specialty.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>


          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (parentId.dirty || parentId.touched || f.submitted)  && parentId.valid, 'has-error' : (parentId.dirty || parentId.touched || f.submitted)  && parentId.invalid}">
              <label class="control-label">{{'Parent Speciality' | translate}} </label>


              <select id="parentId" name="parentId" [(ngModel)]="specialty.parentId" #parentId="ngModel" #specialitiesSelector="bootstrap-select" class="selectpicker form-control" bootstrapSelect
                      data-live-search="true" data-actions-box="false" data-live-search-placeholder="Search..." title="Select Speciality">
                <option *ngFor="let item of specialities" attr.data-content="<span title='{{item.name}}' class='badge'>{{item.name }}</span>" attr.value="{{item.id}}">
                  {{item.name}}
                </option>
              </select>
              <div *ngIf="(parentId.dirty || parentId.touched || f.submitted)  && parentId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (active.dirty || active.touched || f.submitted)  && active.valid, 'has-error' : (active.dirty || active.touched || f.submitted)  && active.invalid}">
              <label class="control-label">{{'Active' | translate}} </label>

              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" #active="ngModel" name="active" [(ngModel)]="specialty.active">{{'Active' | translate}}  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




