import { ActivityCostCenterType } from "../enums";

export class ActivityCostCenter {
  constructor() {
    this.activityCostCenterDetails = [];
  }
  id: number;
  name: string;
  amount: number;
  reserved: number;
  consumed: number;
  activityCostCenterId: number;
  activityCostCenterDetails: ActivityCostCenterDetails[];
}

export class ActivityCostCenterDetails {
 
  value: number;
  type: ActivityCostCenterType;
  item: any;

}
