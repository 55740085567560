import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})

export class CoachingService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  Save(coaching) {
    return this.http.post(this.configurations.apiAddress + "/api/Coaching/save", coaching);
  }

  Search(searchText: string, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/Coaching/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/Coaching/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/Coaching/getById/' + id);
  }
}
