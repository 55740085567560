export class HCP {

  constructor() {
    this.active = true;
    this.id = 0;
  }
  id: number;
  Id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nickName: string;
  fullName: string;
  specialityId: number;
  hcpClassId: number;

  active: boolean;
  selected: boolean;


  specialityName: string;
  hcpClassName: string;

  email: string;
  phone: string;
  otp: string;

  trimmedName: string;
  trimmedEmail: string;

}
