<div class="form-horizontal">
  <div class="form-group">
    <label for="refreshButton" class="col-sm-3 control-label">{{'preferences.ReloadPreferences' | translate}} </label>
    <div class="col-sm-4">
      <button id="refreshButton" class="btn btn-default" (click)="reloadFromServer()">
        <i class="fa fa-refresh"></i>
      </button>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.ReloadPreferencesHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label for="language" class="col-sm-3 control-label">{{'preferences.Language' | translate}} </label>
    <div class="col-sm-4">
      <select id="language" [(ngModel)]="configurations.language" #languageSelector="bootstrap-select" bootstrapSelect class="selectpicker form-control">
        <option data-subtext="(Default)" value="en">{{'preferences.English' | translate}}</option>
        <option value="fr">{{'preferences.French' | translate}}</option>
        <option value="de">{{'preferences.German' | translate}}</option>
        <option value="pt">{{'preferences.Portuguese' | translate}}</option>
        <option value="ar">{{'preferences.Arabic' | translate}}</option>
        <option value="ko">{{'preferences.Korean' | translate}}</option>
      </select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.LanguageHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label for="homePage" class="col-sm-3 control-label">{{'preferences.HomePage' | translate}} </label>
    <div class="col-sm-4">
      <select id="homePage" [(ngModel)]="configurations.homeUrl" #homePageSelector="bootstrap-select" bootstrapSelect class="selectpicker form-control">
        <option data-icon="fa fa-tachometer" data-subtext="(Default)" value="/">{{'preferences.Dashboard' | translate}}</option>
        <option *ngIf="canViewCustomers" data-icon="fa fa-handshake-o" value="/customers">{{'preferences.Customers' | translate}}</option>
        <option *ngIf="canViewProducts" data-icon="fa fa-truck" value="/products">{{'preferences.Products' | translate}}</option>
        <option *ngIf="canViewOrders" data-icon="fa fa-shopping-cart" value="/orders">{{'preferences.Orders' | translate}}</option>
        <option data-icon="fa fa-info-circle" value="/about">{{'preferences.About' | translate}}</option>
        <option data-icon="fa fa-cog" value="/settings">{{'preferences.Settings' | translate}}</option>
      </select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.HomePageHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label for="defaultTheme" class="col-sm-3 control-label">{{'preferences.Theme' | translate}} </label>
    <div *ngIf="themeSelectorToggle" class="col-sm-4">
      <select id="defaultTheme" [(ngModel)]="configurations.theme" bootstrapSelect class="selectpicker form-control">
        <option attr.data-content="{{'preferences.DefaultColor' | translate}}">Default</option>
        <option attr.data-content="{{'preferences.RedColor' | translate}}">Red</option>
        <option attr.data-content="{{'preferences.OrangeColor' | translate}}">Orange</option>
        <option attr.data-content="{{'preferences.GreenColor' | translate}}">Green</option>
        <option attr.data-content="{{'preferences.GrayColor' | translate}}">Gray</option>
        <option attr.data-content="{{'preferences.BlackColor' | translate}}">Black</option>
      </select>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.ThemeHint' | translate}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <hr class="separator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label" for="dashboardStatistics">{{'preferences.DashboardStatistics' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardStatistics" [(ngModel)]="configurations.showDashboardStatistics" class="form-control" type="checkbox" bootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.DashboardStatisticsHint' | translate}}</p>
    </div>
  </div>
  <!--  -->

  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label" for="dashboardTodo">{{'preferences.DashboardNotifications' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardNotifications" [(ngModel)]="configurations.showDashboardNotifications" class="form-control" type="checkbox" bootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.DashboardNotificationsHint' | translate}}</p>
    </div>
  </div>
  <!--  -->

  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label" for="dashboardTodo">{{'preferences.DashboardTodo' | translate}} </label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardTodo" [(ngModel)]="configurations.showDashboardTodo" class="form-control" type="checkbox" bootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.DashboardTodoHint' | translate}}</p>
    </div>
  </div>
  <!--  -->


  <div class="row">
    <div class="col-md-12">
      <hr class="subseparator-hr" />
    </div>
  </div>

  <div class="form-group">
    <label class="col-sm-3 control-label" for="dashboardBanner">{{'preferences.DashboardBanner' | translate}}</label>
    <div class="col-sm-4">
      <div class="checkbox">
        <input name="dashboardBanner" [(ngModel)]="configurations.showDashboardBanner" class="form-control" type="checkbox" bootstrapToggle data-size="small">
      </div>
    </div>
    <div class="col-sm-5">
      <p class="form-control-static text-muted small">{{'preferences.DashboardBannerHint' | translate}}</p>
    </div>
  </div>
  <!--  -->



  <div class="row">
    <div class="col-md-12">
      <hr class="last-separator-hr" />
    </div>
  </div>
  <div class="form-group">
    <div class="col-sm-3">

    </div>
    <div class="col-sm-9">
      <div class="row pull-left">
        <div class="col-sm-6 col-reset-default">
          <button type="button" (click)="resetDefault()" class="btn btn-success">
            <i class='fa fa-circle-o-notch'></i> {{'preferences.ResetDefault' | translate}}
          </button>
        </div>
        <div class="col-sm-6 col-set-default">
          <button type="button" (click)="setAsDefault()" class="btn btn-primary">
            <i class='fa fa-hdd-o'></i> {{'preferences.SetDefault' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
