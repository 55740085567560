import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayModes } from '../../../models/enums';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Representation } from '../../models/representation';
import { RepresentationApiService } from '../../services/representation-api.service';

@Component({
  selector: 'crm-save-representations',
  templateUrl: './save-representations.component.html',
  styleUrls: ['./save-representations.component.css']
})
export class SaveRepresentationsComponent implements OnInit {
  item: Representation = new Representation();
  displayMode: DisplayModes;
  //itemId: number;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemId: number;
  constructor(private readonly representationService: RepresentationApiService,
    private readonly translateService: AppTranslationService,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly router: Router) { }

  ngOnInit() {
    this.displayMode = DisplayModes.Success;
    if (this.itemId != undefined)
      this.getDetails(this.itemId);
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.representationService.save(this.item).subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.item.id == 0)
          this.item = new Representation();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }


  back() {
    this.closeEvent.emit(true);
  }


  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.representationService.getById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.item = response as Representation;

    }, err => {
      this.displayMode = DisplayModes.Error;

    });
  }
}
