import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DisplayModes } from "../../models/enums";
import { AppConfigService } from "../../app.config";
import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { LookupsService } from "../../services/lookups.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { Page } from "../../models/page.model";
import { OrganizationTypeService } from "./organization-type.service";
import { OrganizationType } from "../../models/lookups/organizationType.model";
import { ModalDirective } from "ngx-bootstrap";
import { SettingService } from "../../settings/components/settings/setting.service";
import { Setting } from "../../settings/models/setting.model";


@Component({
  selector: "crm-organization-type",
  templateUrl: "./organization-type.component.html",
  styleUrls: ["./organization-type.component.css"]
})

export class OrganizationTypeComponent implements OnInit {
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  countryId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  applyAmPmOrganizationType: string;
  //organizationTypes = new Array<any>();
  organizationTypes: OrganizationType[] = [];
  public logo = require("../../../app/assets/images/demo/Icon_Organization.png");
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  addNewMode: boolean = false;
  selectedOrgTypeId;
  constructor(
    private readonly alertService: AlertService, private readonly settingService: SettingService,
    private readonly route: ActivatedRoute,
    private readonly lookupsService: LookupsService,
    private readonly translateService: AppTranslationService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly organizationTypeService : OrganizationTypeService
  ) { }

  ngOnInit() {
   this.getSettings();
   this.searchOrganizationTypes();
  }
  newOrganizationType() {
    this.addNewMode = true;
    //this.router.navigate(["organization-type"]);
  }

  getSettings() {

    this.settingService.getAllSettings().subscribe(response => {
      let settings = response as Setting[];
      this.applyAmPmOrganizationType = settings.find(x => { return x.key == "applyAmPmOrganizationType"; }).value;
    });
  }

  searchOrganizationTypes() {

    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.lookupsService.searchOrganizationType(this.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      const result = response as Page;
      this.organizationTypes = result.items;
      this.totalItems = result.totalItems;
    }, () => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }
  pageChanged(event) {
    this.currentPage = event.page;
    this.searchOrganizationTypes();
  }

  gotoEdit(organizationTypeId) {
    this.selectedOrgTypeId = organizationTypeId;
    this.addNewMode = true;
    //this.router.navigate(['organization-type', organizationTypeId]);
  }

  confirmDelete(organizationTypeId) {
    this.selectedOrgTypeId = organizationTypeId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteOrganizationType(organizationTypeId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }
  deleteOrganizationType() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.organizationTypeService.Delete(this.selectedOrgTypeId).subscribe(result => {
      this.displayMode = DisplayModes.Success;
      this.searchOrganizationTypes();
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  showAddNew() {
    this.addNewMode = true;
  }

  closeAdding(event) {
    this.selectedOrgTypeId = undefined;
    this.addNewMode = false;
    if (event) {
      this.searchOrganizationTypes();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
