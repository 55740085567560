import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PaginationModule } from 'ngx-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableSortPipe } from '../../pipes/table-sort.pipe';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [TableSortPipe],
  imports: [
    CommonModule,
    AutoCompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    PaginationModule.forRoot()
  ],
  exports: [CommonModule, TranslateModule]
})
export class SharedModule {
  constructor(private translate: TranslateService) {

    translate.addLangs(["en", "ar"]);
    translate.setDefaultLang('en');

    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
  }
}
