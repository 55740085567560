import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Observable } from 'rxjs';
import { GiveawayItem } from '../models/giveaway-item.model';

@Injectable({
  providedIn: 'root'
})
export class GiveawayItemService {

  constructor(protected readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }

  getAll(): Observable<GiveawayItem[]> {
    return this.http.get<GiveawayItem[]>(`${this.configurations.apiAddress}/api/giveawayItem/getAll`);

  }

  save(model) {
    return this.http.post(`${this.configurations.apiAddress}/api/giveawayItem/save`, model);
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/giveawayItem/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/giveawayItem/getById/' + id);
  }

  getAllGiveawayItemTypes() {
    return this.http.get(this.configurations.apiAddress + '/api/giveawayItem/getAllGiveawayItemTypes');
  }
}
