import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppTranslationService } from '../../services/app-translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';

@Injectable({
  providedIn: 'root'
})
export class RepresentationApiService  {

  constructor(protected http: HttpClient,
    private readonly translateService: AppTranslationService,
    protected readonly configurations: ConfigurationService,
    private readonly injector: Injector,
    private readonly localStorage: LocalStoreManager) {
  }

  getAll() {
    return this.http.get(`${this.configurations.apiAddress}/api/representations/getAllRepresentations`);
  }

  save(model) {
    return this.http.post(`${this.configurations.apiAddress}/api/representations/save`, model);
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/representations/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/representations/getById/' + id);
  }
}
