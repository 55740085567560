import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})
export class HCPClassService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {

  }

  Save(itemType) {
    return this.http.post(this.configurations.apiAddress + "/api/HCPClass/save", itemType);
  }

  Search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText);
    return this.http.get(this.configurations.apiAddress + '/api/HCPClass/search', { params: myParams });
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/HCPClass/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/HCPClass/getById/' + id);
  }

}
