import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessUnitType } from '../models/business-unit-type';

import { BusinessUnitTypeService } from './business-unit-type.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { ModalDirective } from 'ngx-bootstrap';






@Component({
  selector: 'crm-business-unit-type',
  templateUrl: './business-unit-type.component.html',
  styleUrls: ['./business-unit-type.component.css']
})
export class BusinessUnitTypeComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  displayMode: DisplayModes = DisplayModes.Success;
  businessUnitTypes: BusinessUnitType[] = [];

  selectedBusinessUnitTypeId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  public logo = require("../../../app/assets/images/demo/Icon_Business Units Types.png");

  constructor(private readonly businessUnitTypeService: BusinessUnitTypeService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService) {

  }

  ngOnInit() {
    this.searchBusinessUnitTypes();

    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'order', header: 'Order' }
    ];
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['business-unit-type']);
  }

  goToEdit(businessUnitTypeId) {
    this.selectedBusinessUnitTypeId = businessUnitTypeId;
    this.addNew = true;
    //this.router.navigate(["business-unit-type", businessUnitTypeId]);
  }

  confirmDelete(businessUnitTypeId) {
    this.selectedBusinessUnitTypeId = businessUnitTypeId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.delete(businessUnitTypeId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.businessUnitTypeService.DeleteBusinessUnitType(this.selectedBusinessUnitTypeId).subscribe(() => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("DeletedSuccessfully"), MessageSeverity.Success);
      this.searchBusinessUnitTypes();
    }, () => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }

  searchBusinessUnitTypes() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.businessUnitTypeService.Search(this.search.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      let result = response as Page;
      this.businessUnitTypes = result.items;
      this.totalItems = result.totalItems;
    }, err => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchBusinessUnitTypes();
  }

  closeAdding(event) {
    this.selectedBusinessUnitTypeId = undefined;
    this.addNew = false;
    if (event) {
      this.searchBusinessUnitTypes();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}

