import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { BusinessUnitService } from '../business-unit.service';

import { SpecialityService } from '../../speciality/speciality.service';

import { ActivatedRoute, Router } from '@angular/router';


import { BusinessUnitType } from '../../models/business-unit-type';
import { BusinessUnitTypeService } from '../../services/business-unit-type.service';
import { BusinessUnit } from '../../../models/lookups/business-unit.model';
import { DisplayModes } from '../../../models/enums';
import { Specialty } from '../../../models/lookups/speciality.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';

@Component({
  selector: 'crm-save-business-unit',
  templateUrl: './save-business-unit.component.html',
  styleUrls: ['./save-business-unit.component.css']
})
export class SaveBusinessUnitComponent implements OnInit {
  displayMode: DisplayModes = DisplayModes.Success;

  businessUnit: BusinessUnit = new BusinessUnit(true);
  businessUnits: BusinessUnit[] = [];
  specialities: Specialty[] = [];
  businessUnitTypes: any[] = [];
  //businessUnitId: number;
  parentBusinessUnit: BusinessUnit = null;
  isActiveChanged: boolean = false;
  endDate: any;

  @ViewChild("businessUnitTypeId")
  private businessUnitTypeId;

  @ViewChild("businessUnitTypesSelector")
  private businessUnitTypesSelector;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() businessUnitId: number;

  constructor(private readonly businessUnitService: BusinessUnitService,
    private readonly alertService: AlertService,
    private readonly route: ActivatedRoute,
    private readonly specialityService: SpecialityService,
    private readonly translateService: AppTranslationService,
    private readonly businessUnitTypeService: BusinessUnitTypeService,
    private readonly router: Router) { }


  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };


  ngOnInit() {
    this.endDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.getSpecialities();
    this.getAllBusinessUnits();

    this.getAllBusinessUnitTypes();
    if (this.businessUnitId != undefined)
      this.getDetails(this.businessUnitId);
  }

  getSpecialities() {
    this.displayMode = DisplayModes.Loading;
    this.specialityService.GetAllSpecialities("").subscribe(response => {
      let results = response;
      this.specialities = results as Specialty[];
      this.displayMode = DisplayModes.Success;
      // setTimeout(() => this.specialitiesSelector.refresh());
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }
  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      if (!this.businessUnit.active) {
        this.businessUnit.endDate = new Date(this.endDate.year, this.endDate.month - 1, this.endDate.day, 12, 0, 0);
      }
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      if (this.businessUnit.businessUnitType !== undefined && this.businessUnit.businessUnitType !== null)
        this.businessUnit.businessUnitTypeId = this.businessUnit.businessUnitType.id;

      this.businessUnit.parentId = null;
      if (this.businessUnit.parentBusinessUnit !== null && this.businessUnit.parentBusinessUnit !== undefined)
        this.businessUnit.parentId = this.businessUnit.parentBusinessUnit.id;
      this.businessUnitService.Save(this.businessUnit).subscribe(() => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.businessUnit.id == 0)
          this.closeEvent.emit(true);
        else
          this.closeEvent.emit(true);
        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, () => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }

  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.businessUnitService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.businessUnit = response as BusinessUnit;
      if (this.businessUnit.endDate) {
        let eDate = new Date(this.businessUnit.endDate);
        this.endDate = {
          year: eDate.getFullYear(),
          month: eDate.getMonth() + 1,
          day: eDate.getDate()
        };
    }
       
  }, () => {
  this.displayMode = DisplayModes.Error;

});
  }
onItemSelect(item: any) {
  console.log(item);
}
onSelectAll(items: any) {
  console.log(items);
}

getAllBusinessUnits() {
  this.businessUnitService.getAllBusinessUnits().subscribe(response => {
    let result = response as BusinessUnit[];
    this.businessUnits = result;
  }, err => {
    console.log(err);
  });
}

getAllBusinessUnitTypes() {
  this.businessUnitTypeService.getAllTypes().subscribe(response => {
    let result = response as BusinessUnitType[];
    this.businessUnitTypes = result;
  }, err => {
    console.log(err);
  });
}

options = {
  multiple: false,
  theme: 'bootstrap',
  cssImport: true,
  closeOnSelect: true
}

showProvisionModal() {
  this.showProvision.emit(true);
}

onChangeIsActive() {
  this.isActiveChanged = true;
}
}
