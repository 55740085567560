<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!orgJobStrId">Create {{'Organization Job Structure' | translate}}</span>
    <span class="panel-title" *ngIf="orgJobStrId">Edit {{'Organization Job Structure' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr" *ngIf="!orgJobStrId">
          <div class="col-sm-8">
            <span>Enter the Date Manually or Update Sheet</span>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-2 cursor-pointer left-6">
            <span class="margin-right-7 edit-profile-span" (click)="showProvisionModal()">Provision</span>
            <i class="fa fa-upload" aria-hidden="true"></i>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="orgJobStr.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (directManagerId.dirty || directManagerId.touched || f.submitted)  && directManagerId.valid, 'has-error' : (directManagerId.dirty || directManagerId.touched || f.submitted)  && directManagerId.invalid}">
              <label class="control-label">{{'settings.header.DirectManager' | translate}} </label>

              <span class="ui-fluid">
                <p-dropdown [options]="directManagers"
                            styleClass="wid100"
                            placeholder=" {{'organizationJobStructure.SelectDirectManager'| translate}}"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="directManagerId" #directManagerId="ngModel"
                            [(ngModel)]="orgJobStr.directManager" baseZIndex="9999"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(directManagerId.dirty || directManagerId.touched || f.submitted)  && directManagerId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.valid, 'has-error' : (businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid}">
              <label class="control-label">{{'settings.header.BusinessUnit' | translate}} </label>

              <span class="ui-fluid">
                <p-dropdown [options]="businessUnits"
                            styleClass="wid100"
                            [disabled]="orgJobStrId"
                            placeholder="Business Unit"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="businessUnitId" #businessUnitId="ngModel"
                            required="required" [(ngModel)]="orgJobStr.businessUnit"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>
              <div *ngIf="(businessUnitId.dirty || businessUnitId.touched || f.submitted)  && businessUnitId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>

            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (jobId.dirty || jobId.touched || f.submitted)  && jobId.valid, 'has-error' : (jobId.dirty || jobId.touched || f.submitted)  && jobId.invalid}">
              <label class="control-label">{{'Job' | translate}} </label>
              <span class="ui-fluid">
                <p-dropdown [options]="jobs"
                            styleClass="wid100"
                            placeholder="Business Unit"
                            emptyFilterMessage="No results found"
                            showClear="true"
                            name="jobId" #jobId="ngModel"
                            required="required" [(ngModel)]="orgJobStr.job" scrollHeight="100px"
                            [filter]="true" optionLabel="name"></p-dropdown>
              </span>


              <div *ngIf="(jobId.dirty || jobId.touched || f.submitted)  && jobId.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>

            </div>
          </div>
        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">

            <div class="pull-left">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="isEnabled" [(ngModel)]="orgJobStr.isActive" (change)="onChangeIsActive()">
                  Active
                </label>
              </div>
            </div>

          </div>
          <div class="col-sm-6" *ngIf="!orgJobStr.isActive">
            <div [ngClass]="{'has-success': (endDatePicker.dirty || endDatePicker.touched)  && endDatePicker.valid, 'has-error' : (endDatePicker.dirty || endDatePicker.touched)  && endDatePicker.invalid}">
              <label class="control-label">{{'End Date' | translate}} </label>
              <div class="input-group date">
                <input class="form-control" placeholder="yyyy-mm-dd"
                       name="endDatePicker" #endDatePicker="ngModel" [(ngModel)]="endDate" ngbDatepicker #d="ngbDatepicker">
                <span class="input-group-btn">
                  <button class="btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (TargetAm.dirty || TargetAm.touched || f.submitted)  && TargetAm.valid, 'has-error' : (TargetAm.dirty || TargetAm.touched || f.submitted)  && TargetAm.invalid}">
              <label class="control-label">{{'Target Am' | translate}} </label>
              <input type="number"
                     [disabled]="orgJobStrId"
                     name="TargetAm"
                     [(ngModel)]="orgJobStr.targetAm"
                     #TargetAm="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(TargetAm.dirty || TargetAm.touched || f.submitted)  && TargetAm.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (TargetPm.dirty || TargetPm.touched || f.submitted)  && TargetPm.valid, 'has-error' : (TargetPm.dirty || TargetPm.touched || f.submitted)  && TargetPm.invalid}">
              <label class="control-label">{{'Target Pm' | translate}} </label>
              <input type="number"
                     name="TargetPm"
                     [disabled]="orgJobStrId"
                     [(ngModel)]="orgJobStr.targetPm"
                     #TargetPm="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(TargetPm.dirty || TargetPm.touched || f.submitted)  && TargetPm.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (specificTargetAm.dirty || specificTargetAm.touched || f.submitted)  && specificTargetAm.valid, 'has-error' : (specificTargetAm.dirty || specificTargetAm.touched || f.submitted)  && specificTargetAm.invalid}">
              <label class="control-label">{{'Specific Target Am' | translate}} </label>
              <input type="number"
                     name="specificTargetAm"
                     [(ngModel)]="orgJobStr.specificTargetAm"
                     #specificTargetAm="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(specificTargetAm.dirty || specificTargetAm.touched || f.submitted)  && specificTargetAm.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6" >
            <div [ngClass]="{'has-success': (specificTargetPm.dirty || specificTargetPm.touched || f.submitted)  && specificTargetPm.valid, 'has-error' : (specificTargetPm.dirty || specificTargetPm.touched || f.submitted)  && specificTargetPm.invalid}">
              <label class="control-label">{{'Specific Target Pm' | translate}} </label>
              <input type="number"
                     name="specificTargetPm"
                     [(ngModel)]="orgJobStr.specificTargetPm"
                     #specificTargetPm="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(specificTargetPm.dirty || specificTargetPm.touched || f.submitted)  && specificTargetPm.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>
        <div class="row padding-sm-vr">
          <div class="col-sm-6" *ngIf="activityGroups && activityGroups.length>0">
            <div [ngClass]="{'has-success': (activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.valid, 'has-error' : (activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.invalid}">

              <label class="control-label">{{'Activity Group' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Activity Group'"
                                       [data]="activityGroups"
                                       name="activityGrou" #activityGrou="ngModel"
                                       [(ngModel)]="orgJobStr.orgActivityGroups"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>

              <div *ngIf="(activityGrou.dirty || activityGrou.touched || f.submitted)  && activityGrou.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="itemTypes && itemTypes.length>0">
            <div [ngClass]="{'has-success': (itemTy.dirty || itemTy.touched || f.submitted)  && itemTy.valid, 'has-error' : (itemTy.dirty || itemTy.touched || f.submitted)  && itemTy.invalid}">

              <label class="control-label">{{'Item Categories' | translate}} </label>
              <ng-multiselect-dropdown [placeholder]="'Item Category'"
                                       [data]="itemTypes"
                                       name="itemTy" #itemTy="ngModel"
                                       [(ngModel)]="orgJobStr.orgItemTypes"
                                       [settings]="dropdownSettings">
              </ng-multiselect-dropdown>

              <div *ngIf="(itemTy.dirty || itemTy.touched || f.submitted)  && itemTy.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>
        <div class="row padding-sm-vr">

          <div class="col-sm-6">
            <div class="pull-left">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="sales" [(ngModel)]="orgJobStr.applySales">
                  {{'Apply Sales' | translate}}
                </label>
              </div>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-left">
              <div class="checkbox user-enabled">
                <label>
                  <input type="checkbox" name="fieldTrainer" [(ngModel)]="orgJobStr.isFieldTrainer">
                  {{'Field Trainer' | translate}}
                </label>
              </div>

            </div>
          </div>
          <div class="col-sm-6" *ngIf="applyingPharmaciesModule">
            <div [ngClass]="{'has-success': (pharmacyTarget.dirty || pharmacyTarget.touched || f.submitted)  && pharmacyTarget.valid, 'has-error' : (pharmacyTarget.dirty || pharmacyTarget.touched || f.submitted)  && pharmacyTarget.invalid}">
              <label class="control-label">{{'Pharmacy Target' | translate}} </label>
              <input type="number"
                     name="pharmacyTarget"
                     [disabled]="orgJobStrId"
                     [(ngModel)]="orgJobStr.pharmacyTarget"
                     #pharmacyTarget="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(pharmacyTarget.dirty || pharmacyTarget.touched || f.submitted)  && pharmacyTarget.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>
        </div>


        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

        </div>
      </form>
    </div>
  </div>

</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>
