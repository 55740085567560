<ul class="breadcrumb breadcrumb-page">
  <div class="breadcrumb-label text-light-gray">{{'YouAreHere' | translate}}: </div>
  <li><a href="#">{{'Configurations' | translate}}</a></li>
  <li class="active"><a href="#">{{'settings.header.coachings' | translate}}</a></li>
</ul>
<div class="page-header">

  <div class="row">
    <!-- Page header, center on small screens -->
    <h1 class="col-xs-12 col-sm-10 text-center text-left-sm"><i class="fa fa-dashboard page-header-icon"></i>&nbsp;&nbsp;{{'settings.header.coachings' | translate}}</h1>
  </div>
</div>
<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==1">
  <div class="container text-center">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
  </div>

</div>
<form #f="ngForm" *ngIf="displayMode==2"
      novalidate (ngSubmit)="save(f)">
  <div class="col-sm-12">
    <div class="panel">
      <div class="panel-heading">
        <span class="panel-title"><i class="panel-title-icon fa fa-bullhorn"></i>{{'settings.header.coachings' | translate}}</span>
      </div>
      <div class="panel-body tab-content-padding">
        <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
          <div class="row padding-sm-vr">
            <div class="col-sm-6">
              <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
                <label class="control-label">{{'Name' | translate}} </label>
                <input type="text"
                       name="name"
                       [(ngModel)]="coaching.name"
                       #name="ngModel"
                       required="required"
                       class="form-control" />
                <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                  {{'RequiredField' | translate}}
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div [ngClass]="{'has-success': (leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.valid, 'has-error' : (leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.invalid}">
                <label class="control-label">{{'leadtime' | translate}} </label>
                <input type="text"
                       name="leadTime"
                       [(ngModel)]="coaching.leadTime"
                       #leadTime="ngModel"
                       required="required"
                       class="form-control" />
                <div *ngIf="(leadTime.dirty || leadTime.touched || f.submitted)  && leadTime.invalid" class="jquery-validate-error help-block">
                  {{'RequiredField' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="panel-footer">
            <div class="row" style="float:right;">

              <div class="col-sm-12">
                <button class="btn btn-labeled btn-default" type="button" (click)="back()"><span class="btn-label icon fa fa-backward"></span>{{'Back' | translate}}</button>&nbsp;
                <button class="btn btn-labeled btn-success " type="submit"><span class="btn-label icon fa fa-save"></span>{{'Save' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>
