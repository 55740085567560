import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { ActivityGroup } from '../../models/lookups/activity-group.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ActivityGroupService } from './activity-group.service';

@Component({
  selector: 'crm-activity-group',
  templateUrl: './activity-group.component.html',
  styleUrls: ['./activity-group.component.css']
})
export class ActivityGroupComponent implements OnInit {

  activityGroups: ActivityGroup[] = [];
  displayMode: DisplayModes = DisplayModes.Success;
  searchText: string;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedActivityGroupId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly translateService: AppTranslationService,
    private readonly appConfig: AppConfigService,
    private readonly activityGroupService:ActivityGroupService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.pageSize = this.appConfig.settings.pageSize;
    this.getActivityGroups();
  }

  getActivityGroups() {
    this.displayMode = DisplayModes.Loading;
    this.activityGroupService.search(this.searchText).subscribe(responce => {
      this.activityGroups = responce as ActivityGroup[];
      this.displayMode = DisplayModes.Success;
    },
      err => {
        this.displayMode = DisplayModes.Error;
        console.log('error in get Activity Group');
        console.log(err);
      })

  }

  gotoNew() {
    this.addNew = true;
  }

  edit(id) {
    this.selectedActivityGroupId = id;
    this.addNew = true;
  }

  confirmDelete(activityGroup) {
    this.selectedActivityGroupId = activityGroup;
    this.deleteModal.show();
  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.activityGroupService.delete(this.selectedActivityGroupId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.activityGroups.indexOf(this.selectedActivityGroupId);
      this.activityGroups.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete activity group:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  get canManageActivityGroupPermission() {
    return this.accountService.userHasPermission(Permission.manageActivityGroupPermission);
  }

  closeAdding(event) {
    this.selectedActivityGroupId = undefined;
    this.addNew = false;
    if (event) {
      this.getActivityGroups();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
