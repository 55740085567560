import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayModes } from '../../models/enums';
import { AppConfigService } from '../../app.config';
import { GiveawayItemService } from '../services/giveaway-item.service';
import { GiveawayItem } from '../models/giveaway-item.model';
import { Router } from '@angular/router';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'crm-giveaway-items',
  templateUrl: './giveaway-items.component.html',
  styleUrls: ['./giveaway-items.component.css']
})
export class GiveawayItemsComponent implements OnInit {
  giveawayItems: GiveawayItem[];
  displayMode: DisplayModes;
  pageSize: number;
  public logo = require("../../../app/assets/images/demo/Icon_Giveaway Items.png");

  selectedGiveawayItemsId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly giveawayItemService: GiveawayItemService,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly appConfig: AppConfigService,
    private readonly translateService: AppTranslationService) {
    this.pageSize = this.appConfig.settings.pageSize;
    this.displayMode = DisplayModes.Success;
  }

  ngOnInit() {
    this.getAll();
  }


  getAll() {
    this.displayMode = DisplayModes.Loading;
    this.giveawayItemService.getAll().subscribe(response => {
      this.giveawayItems = response;
      this.displayMode = DisplayModes.Success;
    }, error => {
        this.displayMode = DisplayModes.Error;
    });
  }
  gotoNew() {
    this.addNew = true;
    //this.router.navigate(['giveaway-items/add']);
  }

  gotoEdit(itemId) {
    this.selectedGiveawayItemsId = itemId;
    this.addNew = true;
    //this.router.navigate(['giveaway-items/edit', itemId]);
  }

  confirmDeleteItem(item) {
    this.selectedGiveawayItemsId = item;
    this.deleteModal.show();
    //this.alertService.showDialog(`are you sure you want to delete giveaway item, ${item.name}!`, DialogType.Confirm,
    //  () => this.delete(item),
    //  () => this.alertService.showMessage(`Giveaway item delete`, `delete giveaway item ${item.name}, canceled!`, MessageSeverity.Default));

  }

  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.alertService.startLoadingMessage(`Giveaway item delete`, `deleting giveaway item, ${this.selectedGiveawayItemsId.name}! ....`);

    this.giveawayItemService.delete(this.selectedGiveawayItemsId.id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.stopLoadingMessage();

      this.alertService.showMessage("Giveaway item delete", `${this.selectedGiveawayItemsId.name} deleted successfully!`, MessageSeverity.Success);
      this.getAll();
    }, err => {
      this.displayMode = DisplayModes.Success;
        this.alertService.stopLoadingMessage();

      this.alertService.showMessage(`Giveaway item delete`, `delete giveaway item, ${this.selectedGiveawayItemsId.name} failed please try again later!`, MessageSeverity.Error);

    });
  }

  closeAdding(event) {
    this.selectedGiveawayItemsId = undefined;
    this.addNew = false;
    if (event) {
      this.getAll();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
