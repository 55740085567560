import { Component, OnInit } from '@angular/core';

import { BusinessUnitBricksService } from './business-unit-bricks.service';



import { ActivatedRoute, Router } from "@angular/router";

import { ManageBusinessUnitBricksComponent } from "./manage-business-unit-bricks/manage-business-unit-bricks.component";
import { DisplayModes } from '../../models/enums';
import { BusinessUnit } from '../../models/lookups/business-unit.model';
import { AlertService } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'crm-business-unit-bricks',
  templateUrl: './business-unit-bricks.component.html',
  styleUrls: ['./business-unit-bricks.component.css']
})
export class BusinessUnitBricksComponent implements OnInit {

  displayMode: DisplayModes = DisplayModes.Success;
  businessUnits: BusinessUnit[] = [];

  constructor(private businessUnitBricksService: BusinessUnitBricksService,
    private readonly router: Router,
    private alertService: AlertService,
    private translateService: AppTranslationService) { }

  ngOnInit() {
    this.getBusinessUnits();
  }

  getBusinessUnits() {
    this.businessUnitBricksService.getAllBusinessUnitLastLevel().subscribe(response => {

      let results = response;
      this.businessUnits = results as BusinessUnit[];
      this.displayMode = DisplayModes.Success;

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  manageBusinessUnitBricks(id) {
    this.router.navigate(["manage-business-unit-bricks", id]);
  }

}
