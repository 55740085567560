import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SalesFileService {

  constructor(private readonly http: HttpClient,
    private readonly configurations: ConfigurationService) { }


  getAllSalesFileTypeTypes() {
    return this.http.get(`${this.configurations.apiAddress}/api/SalesFile/getSalesFileType`);
  }
  getSalesFileData() {
    return this.http.get(`${this.configurations.apiAddress}/api/SalesFile/getSalesFileData`);
  }
  provisionIbnSina(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/uploadIbnSinaTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  provisionPOS(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/uploadPOSTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  provisionUCP(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/uploadUCPTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  provisionEgyDrug(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/uploadEgyDrugTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  provisionDirect(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/uploadDirectTemplate`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  provision(formData, typeId, selectedDate, applyDistributorPrice) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/SalesFile/upload?type=` + typeId + `&date=` + selectedDate.toDateString() + `&applyDistributorPrice=` + applyDistributorPrice, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  downloadCsvTemplate(typeId): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/SalesFile/downloadCSVTemplate?type=" + typeId, { responseType: "blob" });
  }

  delete(id) {
    return this.http.delete(this.configurations.apiAddress + '/api/SalesFile/delete/' + id);
  }


  getDitributors() {
    return this.http.get(`${this.configurations.apiAddress}/api/Distributor/search`);
  }
  getSalesFileDistributorsData() {
    return this.http.get(`${this.configurations.apiAddress}/api/SalesFile/getSalesFileDistributorsData`);
  }

  downloadDistributorSalesCSVTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/SalesFile/downloadDistributorSalesCSVTemplate", { responseType: "blob" });
  }

  downloadDistributorSalesErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/SalesFile/downloadDistributorSalesErrorFile", { responseType: "blob" });
  }

  uploadDistributorSalesCSVTemplate(formData, distributorId, selectedDate, applyDistributorPrice) {
    return this.http.post(this.configurations.apiAddress + "/api/SalesFile/uploadDistributorSalesCSVTemplate?distributorId=" + distributorId + "&date=" + selectedDate.toDateString() + `&applyDistributorPrice=` + applyDistributorPrice, formData);
  }

  getPharmacySalesFileData() {
    return this.http.get(`${this.configurations.apiAddress}/api/SalesFile/getPharmacySalesFileData`);
  }

  downloadPharmacySalesCSVTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/SalesFile/downloadPharmacySalesCSVTemplate", { responseType: "blob" });
  }

  downloadPharmacySalesErrorFile(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/SalesFile/downloadPharmacySalesErrorFile", { responseType: "blob" });
  }

  uploadPharmacySalesCSVTemplate(formData, distributorId, selectedDate, applyDistributorPrice) {
    return this.http.post(this.configurations.apiAddress + "/api/SalesFile/uploadPharmacySalesCSVTemplate?distributorId=" + distributorId + "&date=" + selectedDate.toDateString() + `&applyDistributorPrice=` + applyDistributorPrice, formData);
  }

}
