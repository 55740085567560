<div class="container">
  <header class="pageHeader">
    <h3><i class="fa fa-exclamation-circle fa-lg page-caption" aria-hidden="true"></i> UnAuthorized</h3>
  </header>

  <div [@fadeInOut] class="vertical-center-flex">
    <div class="center-block">
      <div class="icon-container"><i class='fa fa-exclamation-circle'></i> 403</div>
      <div class="row col-sm-12 padding-sm-hr">
        <div class="alert alert-danger alert-dark">
          <button type="button" class="close" data-dismiss="alert">×</button>
          You don't have permission to access this page
        </div>
      </div>

      <div><a class="btn btn-primary" routerLink="/"><i class='fa fa-home'></i> Home</a></div>
    </div>
  </div>
</div>
