<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'settings.header.UsersManagements' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'settings.header.UsersManagements' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.UsersManagements' | translate}} {{'Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-3">
          <input type="text" (input)="onSearchChanged($event)" [(ngModel)]="searchText" class="form-control" placeholder="{{'users.management.Search' | translate}}">
        </div>
        <div class="col-sm-3">
          <span class="ui-fluid">
            <p-dropdown [options]="displayFilter"
                        name="displayFil" #displayFil="ngModel"
                        styleClass="wid100"
                        placeholder="Filter"
                        [(ngModel)]="display"
                        [virtualScroll]="true" itemSize="3"
                        optionLabel="label"
                        (onChange)="onChangeActiveFilter()"></p-dropdown>

          </span>
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer">
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="newUser()" *ngIf="canManageUsers">
          <span class="margin-right-7 create-new-span">Create New User</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-user-editor *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [userId]="selectedUserId"></crm-user-editor>
<crm-user-jobs *ngIf="jobMode" (closeEvent)="closeAdding($event)" [userId]="selectedUserId"></crm-user-jobs>


<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'settings.header.UsersManagements' | translate}} {{'Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-table #dt [value]="rows" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize" [resizableColumns]="true">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageUsers">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageUsers">Delete</th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageUsers">Reset Password</th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageUsers">Jobs</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
              <tr *ngIf="rows.length>0 && displayMode===2">
                <!--<td>
    <strong>{{user.index}}</strong>
  </td>-->
                <td class="ui-resizable-column">
                  <span [class.locked-out]="user.isLockedOut" [class.user-disabled]="!user.isEnabled">
                    <i *ngIf="user.isLockedOut" class="fa fa-exclamation-triangle"> </i>
                    <i *ngIf="!user.isEnabled" class="fa fa-exclamation"> </i>

                    {{user.userName}}
                  </span>
                </td>
                <td class="ui-resizable-column">{{user.businessUnitName}}</td>
                <td class="ui-resizable-column">{{user.jobName}}</td>
                <td class="ui-resizable-column">{{user.email}}</td>
                <td class="ui-resizable-column"> <span class="user-role badge" *ngFor="let role of user.roles">{{role.name}}</span></td>
                <td class="ui-resizable-column">{{user.lastWebLoginDate | date}}</td>
                <td class="ui-resizable-column">{{user.lastMobileLoginDate | date}}</td>

                <td *ngIf="canManageUsers">
                  <a class="" href="javascript:;" (click)="editUser(user)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td *ngIf="canManageUsers"><a href="javascript:;" (click)="deleteUser(user)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

                <td *ngIf="canManageUsers">
                  <a class="" href="javascript:;" (click)="resetPassword(user.id)"><i class="fa fa-refresh fa-lg font-size-20"></i></a>
                </td>
                <td *ngIf="canManageUsers"><a href="javascript:;" (click)="userJobs(user)"><i class="fa fa-briefcase  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="rows.length===0 && displayMode===2">
                <td colspan="6">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="6">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>

                  </div>

                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2 cursor-pointer left-15">
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="deleteUserHelper()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Users Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <crm-provision-users #provisionUsers></crm-provision-users>
    </div>
  </div>
</div>

