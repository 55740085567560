import { Component, OnInit, ViewChild } from '@angular/core';
import { BusinessUnitService } from '../business-unit.service';
import { HttpEventType } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'crm-provision-business-units',
  templateUrl: './provision-business-units.component.html',
  styleUrls: ['./provision-business-units.component.css']
})
export class ProvisionBusinessUnitsComponent implements OnInit {


  progress: number;
  message: string;

  @ViewChild("provisionModal")
  provisionModal: ModalDirective;

  constructor(private businessUnitService: BusinessUnitService) { }

  ngOnInit() {
  }

  
  downloadTemplate() {
    this.businessUnitService.downloadCsvTemplate().subscribe(response => {

      const blob = new Blob([response], { type: "text/csv" });
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = "BusinessUnits.csv";
      anchor.href = url;
      anchor.click();
      //window.open(url);
      console.log(url);
      console.log(response);

    },
      err => { console.log(err); });
  }
  upload(files) {
    if (files.length === 0)
      return;

    const formData = new FormData();

    for (let file of files)
      formData.append(file.name, file);



    this.businessUnitService.provisionBusinessUnits(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = "uploaded success";
        //this.provisionModal.hide();
      }
    });
  }

}
