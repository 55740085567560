import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BusinessUnitBricksService } from '../business-unit-bricks.service';
import { FilterPipe } from './pipe'
import { BusinessUnitBricks } from '../../../models/lookups/business-unit-bricks.model';
import { Brick } from '../../../settings/models/brick.model';
import { DisplayModes } from '../../../models/enums';
import { BrickLevel } from '../../../settings/models/brick-level.model';
import { BrickLevelService } from '../../../settings/services/brick-level.service';
import { BricksService } from '../../../settings/services/bricks.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
@Component({
  selector: 'crm-add-business-unit-bricks',
  templateUrl: './add-business-unit-bricks.component.html',
  styleUrls: ['./add-business-unit-bricks.component.css']
})
export class AddBusinessUnitBricksComponent implements OnInit {

  //@ViewChild(ModalDirective) modal: ModalDirective;

  @Input() businessUnitId: number;
  @Output() closeModalEvent = new EventEmitter<boolean>();

  bricks: Brick[] = [];
  businessUnitBricks: BusinessUnitBricks[] = [];
  brickLevel: BrickLevel = new BrickLevel();
  brickLevels: BrickLevel[] = [];
  search: string = '';
  displayMode: DisplayModes = DisplayModes.Success;
  startDate: any;
  constructor(private bricksService: BricksService, private brickLevelService: BrickLevelService, private businessUnitBricksService: BusinessUnitBricksService, private alertService: AlertService,
    private translateService: AppTranslationService,) {
  }

  ngOnInit() {
    this.startDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };
    this.getBrickLevels();
  }

  getBricks() {
    this.businessUnitBricksService.getAllBricksLeafLevel().subscribe(response => {

      let results = response;
      this.bricks = results as Brick[];

      if (this.businessUnitId != undefined)
        this.getBusinessUnitBricks(this.businessUnitId);
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  getBusinessUnitBricks(businessUnitId) {
    this.businessUnitBricksService.getBusinessUnitBricksByBusinessUnitd(businessUnitId).subscribe(response => {
      let results = response;
      this.businessUnitBricks = results as BusinessUnitBricks[];
      let busBr = this.businessUnitBricks

      if (this.businessUnitBricks) {
        this.bricks.forEach(function (brick) {
          if (busBr.find(x => x.brickId == brick.id))
            brick.selected = true;
        })
        this.bricks = this.bricks.filter(x => !x.selected);
      }
      this.displayMode = DisplayModes.Success;

    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  save() {
    this.displayMode = DisplayModes.Loading;

    let selectedBricks: any[] = [];
    this.bricks.forEach(function (brick) { if (brick.selected) selectedBricks.push(brick.id); })

    if (this.startDate !== undefined)
      this.startDate = new Date(this.startDate.year, this.startDate.month - 1, this.startDate.day, 12, 0, 0);

    this.businessUnitBricksService.addBusinessUnitBricks(this.businessUnitId, selectedBricks, this.startDate).subscribe(response => {

      this.displayMode = DisplayModes.Success;
      if (response == true) {
        this.alertService.showMessage("", this.translateService.getTranslation("Successfully"), MessageSeverity.Success);

        // reset data
        //if (this.businessUnitId != undefined)
        //  this.getBusinessUnitBricks(this.businessUnitId);
        this.brickLevel = new BrickLevel();
        this.startDate = {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate()
        };
        this.closeModalEvent.emit();
      }
      else {
        this.alertService.showMessage("Failed to delete", "This date is already exist in some if this bricks.", MessageSeverity.Error);
      }


    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  getBrickLevels() {
    this.brickLevelService.getAllBrickLevels().subscribe(response => {
      this.brickLevels = response as BrickLevel[];

    }, err => {
      console.log(err);
    });
  }

  onChangeBrickLevel() {
    this.bricksService.getBricksByBrickLevel(this.brickLevel.id).subscribe(response => {

      let results = response;
      this.bricks = results as Brick[];

      if (this.businessUnitId != undefined)
        this.getBusinessUnitBricks(this.businessUnitId);
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

}
