import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], name): any {
    console.log('name', name);

    return name
      ? items.filter(item => item.name.toLowerCase().indexOf(name.toLowerCase()) != -1)
      : items;
  }
}
