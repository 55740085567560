

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    
    <span class="panel-title" *ngIf="!hcpClassid">{{'settings.header.hcpClass' | translate}}</span>
    <span class="panel-title" *ngIf="hcpClassid">Edit {{'settings.header.hcpClass' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center"  *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">
        <div class="row padding-sm-vr">

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="hcpclass.name"
                     #name="ngModel" placeholder="{{'Name' | translate}}"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (visitsPerPlan.dirty || visitsPerPlan.touched || f.submitted)  && visitsPerPlan.valid, 'has-error' : (visitsPerPlan.dirty || visitsPerPlan.touched || f.submitted)  && visitsPerPlan.invalid}">
              <label class="control-label">{{'visitsPerPlan' | translate}} </label>
              <input type="number"
                     name="visitsPerPlan"
                     [(ngModel)]="hcpclass.visitsPerPlan"
                     #visitsPerPlan="ngModel"
                     placeholder="{{'visitsPerPlan' | translate}}"
                     required="required"
                     class="form-control" />
              <div *ngIf="(visitsPerPlan.dirty || visitsPerPlan.touched || f.submitted)  && visitsPerPlan.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit" *ngIf="!hcpClassid">{{'Create' | translate}}</button>
          <button class="btn  btn-save" type="submit" *ngIf="hcpClassid">{{'Save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>




<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




