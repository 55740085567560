export class Specialty {

  constructor(_active: boolean) {

    this.active = _active;
  }
  id: number;
  name: string;
  parentId: number;
  parentName: string;
  active: boolean;

  createdBy: string;
  creationDate: Date;
  lastModifiedBy: string;
  lastModificationDate: Date;
}
