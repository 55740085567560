<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Specialities' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Specialities' | translate}}</h1>
  </div>
</div>

<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Specialities Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="name"
                 [(ngModel)]="search.searchText"
                 placeholder="{{'Name' |translate}}"
                 #name="ngModel"
                 class="form-control" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="searchSpecialities()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="goToNew()">
          <span class="margin-right-7 create-new-span">Create New Speciality</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-save-speciality *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [specialityId]="selectedSpecialityId"></crm-save-speciality>


<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Specialities Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="specialities" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="text-align:center" class="table-edit">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-speciality>
              <tr *ngIf="specialities.length>0 && displayMode==2">
                <!--<td>
                  <strong>{{speciality.id}}</strong>
                </td>-->
                <td>{{ speciality.name }}</td>
                <td>{{ speciality.parentName }}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(speciality.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDelete(speciality.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="specialities.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2 cursor-pointer left-15">
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Speciality Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <crm-provision-specialities #provisionSpecialities></crm-provision-specialities>
    </div>
  </div>
</div>

