<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">

    <span class="panel-title" *ngIf="!businessUnitTypeId">Create {{'BusinessUnitTypes' | translate}}</span>
    <span class="panel-title" *ngIf="businessUnitTypeId">Edit {{'BusinessUnitTypes' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="container text-center" *ngIf="displayMode==1">
        <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
      </div>
      <form #f="ngForm" *ngIf="displayMode==2"
            novalidate (ngSubmit)="save(f)">

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (name.dirty || name.touched || f.submitted)  && name.valid, 'has-error' : (name.dirty || name.touched || f.submitted)  && name.invalid}">
              <label class="control-label">{{'Name' | translate}} </label>
              <input type="text"
                     name="name"
                     [(ngModel)]="businessUnitType.name"
                     #name="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(name.dirty || name.touched || f.submitted)  && name.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (order.dirty || order.touched || f.submitted)  && order.valid, 'has-error' : (order.dirty || order.touched || f.submitted)  && order.invalid}">
              <label class="control-label">{{'Order' | translate}} </label>
              <input type="number"
                     name="order"
                     [(ngModel)]="businessUnitType.order"
                     #order="ngModel"
                     required="required"
                     class="form-control" />
              <div *ngIf="(order.dirty || order.touched || f.submitted)  && order.invalid" class="jquery-validate-error help-block">
                {{'RequiredField' | translate}}
              </div>
            </div>
          </div>

        </div>

        <div class="row padding-sm-vr">
          <div class="col-sm-6">
            <div [ngClass]="{'has-success': (active.dirty || active.touched || f.submitted)  && active.valid, 'has-error' : (active.dirty || active.touched || f.submitted)  && active.invalid}">
              <label class="control-label">{{'Active' | translate}} </label>

              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" #active="ngModel" name="active" [(ngModel)]="businessUnitType.active">{{'Active' | translate}}  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-footer margin-left-20 margin-top-20 margin-left-37">
          <button class="btn  btn-cancel" type="button" (click)="back()">{{'Cancel' | translate}}</button>&nbsp;
          <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>
        </div>

      </form>
    </div>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>




