<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'Item Brands' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'Item Brands' | translate}}</h1>
  </div>
</div>


<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Item Brands Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <input type="text"
                 name="brandName"
                 [(ngModel)]="searchText"
                 placeholder="{{'clm.name' |translate}}"
                 #consentName="ngModel"
                 autocomplete="off"
                 class="form-control" />
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="getItemBrands()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-8 cursor-pointer" *ngIf="canManageItemBrands" (click)="gotoNew()">
          <span class="margin-right-7 create-new-span">Create New Item Brand</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-add-item-brand *ngIf="addNew" (closeEvent)="closeAdding($event)" [itemBrandId]="selectedItemBrandId"></crm-add-item-brand>

<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'Item Brands Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">
          <p-table #dt [value]="itemBrands" [responsive]="true" sortMode="multiple" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <!--<th width="5%">#</th>-->
                <th style="text-align:center" [pSortableColumn]="name" class="table-edit">
                  {{'clm.name'| translate}}
                  <p-sortIcon [field]="name" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageItemBrands">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit" *ngIf="canManageItemBrands">Delete</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-brand>
              <tr *ngIf="itemBrands.length>0 && displayMode==2">
                <td>{{ brand.name }}</td>
                <td *ngIf="canManageItemBrands">
                  <a class="" href="javascript:;" (click)="edit(brand.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td *ngIf="canManageItemBrands"><a href="javascript:;" (click)="confirmDelete(brand)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>
              </tr>
              <tr *ngIf="itemBrands.length==0 && displayMode==2">
                <td colspan="5">{{'NoDataFound' | translate}}</td>
              </tr>
              <tr *ngIf="displayMode==1">
                <td colspan="5">
                  <div class="row col-sm-12 padding-sm-hr">
                    <div class="container text-center">
                      <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="displayMode==3">
                <td colspan="5">
                  <div class="alert alert-danger alert-dark">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    {{'ServerError' |translate}}.
                  </div>
                </td>

              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


