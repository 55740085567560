<script>

  init.push(function () {
    var $div = $('<div id="signin-demo" class="hidden-xs"><div>PAGE BACKGROUND</div></div>'),
      bgs = ['../assets/images/demo/bg_login.svg', '../assets/demo/signin-bg-2.jpg', '../assets/demo/signin-bg-3.jpg',
        '../assets/demo/signin-bg-4.jpg', '../assets/demo/signin-bg-5.jpg', '../assets/demo/signin-bg-6.jpg',
        '../assets/demo/signin-bg-7.jpg', '../assets/demo/signin-bg-8.jpg', '../assets/demo/signin-bg-9.jpg'];
    for (var i = 0, l = bgs.length; i < l; i++) $div.append($('<img src="' + bgs[i] + '">'));
    $div.find('img').click(function () {
      var img = new Image();
      img.onload = function () {
        $('#page-signin-bg > img').attr('src', img.src);
        $(window).resize();
      }
      img.src = $(this).attr('src');
    });
    $('body').append($div);
  });
</script>

<!-- Page background -->
<div id="page-signin-bg">
  <!-- Background overlay -->
  <div class="overlay"></div>
  <!-- Replace this with your bg image -->
  <img [src]="loginPic" alt="" class="login-logo">
</div>
<!-- / Page background -->
<!-- Container -->
<div class="signin-container">

  <!-- Left side -->
  <div class="signin-info">
    <a class="logo" >
      <img [src]="logo" alt="" >&nbsp;
    </a>

  </div>
  <!-- / Left side -->
  <!-- Right side -->
  <div class="signin-form">

    <div class="form-login-logo">

    </div>
    <!-- Form -->
    <form *ngIf="formResetToggle" class="form-horizontal login" name="loginForm" #f="ngForm" novalidate
          (ngSubmit)="f.form.valid ? login() :
                          (!email.valid && showErrorAlert('Email is required', 'Please enter a valid email'));
                          (!password.valid && showErrorAlert('Password is required', 'Please enter a valid password'))">
      <div class="signin-text">
        <span>

          {{'SigninYourAccount' | translate}}
        </span>
      </div> <!-- / .signin-text -->
      <div class="row">
        <div class="form-group w-icon" [ngClass]="{'has-success': f.submitted && email.valid, 'has-error' : f.submitted && !email.valid}">
          <input type="text" name="signin_username"
                 id="username_id" class="form-control input-lg input-login"
                 placeholder="{{'app.loginName' | translate}}"
                 [(ngModel)]="userLogin.email" #email="ngModel" required>
          <span class="fa fa-user signin-form-icon"></span>

        </div> <!-- / Username -->
        <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': email.valid, 'glyphicon-remove' : !email.valid}" aria-hidden="true"></span>
        <span class="errorMessage" *ngIf="f.submitted && !email.valid">
          {{'validations.loginNameRequired' | translate}}
        </span>
      </div>
      <div class="row padding-sm-vr" style="margin-top: 5%;">
        <div class="form-group w-icon" [ngClass]="{'has-success': f.submitted && password.valid, 'has-error' : f.submitted && !password.valid}">
          <input type="password" name="signin_password"
                 id="password_id" class="form-control input-lg input-login"
                 placeholder="{{'Password' | translate}}"
                 [(ngModel)]="userLogin.password" #password="ngModel" required>
          <span class="fa fa-lock signin-form-icon"></span>


        </div> <!-- / Password -->
        <span *ngIf="f.submitted" class="glyphicon form-control-feedback" [ngClass]="{'glyphicon-ok ': password.valid, 'glyphicon-remove' : !password.valid}" aria-hidden="true"></span>
        <span class="errorMessage" *ngIf="f.submitted && !password.valid">
          {{'validations.passwordRequired' | translate}}
        </span>
      </div>
      <div class="form-group" style="margin-top: 5%;">

        <div class="checkbox">
          <label><input class="input-remember-me" type="checkbox" name="rememberMe" [(ngModel)]="userLogin.rememberMe">{{'app.rememberMe' | translate}}  </label>
        </div>

      </div>
      <div class="form-actions">
        <button type="submit" value="SIGN IN" class="signin-btn bg-primary" [disabled]="isLoading">
          <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> <span *ngIf="isLoading">{{ 'Logging' |translate }}</span> <span *ngIf="!isLoading">{{'app.login' | translate}}</span>
        </button>
        <!-- <a href="#" class="forgot-password" id="forgot-password-link">Forgot your password?</a>-->
      </div> <!-- / .form-actions -->
    </form>
    <!-- / Form -->
    <!-- Password reset form -->
    <div class="password-reset-form" id="password-reset-form">
      <div class="header">
        <div class="signin-text">
          <span>Password reset</span>
          <div class="close">&times;</div>
        </div> <!-- / .signin-text -->
      </div> <!-- / .header -->
      <!-- Form -->
      <form action="index.html" id="password-reset-form_id">
        <div class="form-group w-icon">
          <input type="text" name="password_reset_email" id="p_email_id" class="form-control input-lg" placeholder="Enter your email">
          <span class="fa fa-envelope signin-form-icon"></span>
        </div> <!-- / Email -->

        <div class="form-actions">
          <input type="submit" value="SEND PASSWORD RESET LINK" class="signin-btn bg-primary">
        </div> <!-- / .form-actions -->
      </form>
      <!-- / Form -->
    </div>
    <!-- / Password reset form -->
  </div>
  <!-- Right side -->
</div>
<!-- / Container -->


<script type="text/javascript">
  // Resize BG
  init.push(function () {
    var $ph = $('#page-signin-bg'),
      $img = $ph.find('> img');

    $(window).on('resize', function () {
      $img.attr('style', '');
      if ($img.height() < $ph.height()) {
        $img.css({
          height: '100%',
          width: 'auto'
        });
      }
    });
  });

  // Show/Hide password reset form on click
  init.push(function () {
    $('#forgot-password-link').click(function () {
      $('#password-reset-form').fadeIn(400);
      return false;
    });
    $('#password-reset-form .close').click(function () {
      $('#password-reset-form').fadeOut(400);
      return false;
    });
  });

  

  window.PixelAdmin.start(init);
</script>

