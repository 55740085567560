import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { ItemTypeService } from '../item-type.service';
import { ItemType } from '../../../models/lookups/itemType.model';
import { DisplayModes } from '../../../models/enums';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';




@Component({
  selector: 'crm-add-item-type',
  templateUrl: './add-item-type.component.html',
  styleUrls: ['./add-item-type.component.css']
})
export class AddItemTypeComponent implements OnInit {
  itemType: ItemType = new ItemType();

  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() itemTypeid: number;
  displayMode: DisplayModes = DisplayModes.Success;

  constructor(private alertService: AlertService,
    private route: ActivatedRoute,
    private itemTypeService: ItemTypeService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    if (this.itemTypeid != undefined)
      this.getDetails(this.itemTypeid);
  }

  save(form) {
    if (!form.invalid) {
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("", this.translateService.getTranslation("Saving"));
      this.itemTypeService.Save(this.itemType).subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Success;
        if (this.itemType.id == 0)
          this.itemType = new ItemType();
        else
          this.closeEvent.emit(true);

        this.alertService.showMessage("", this.translateService.getTranslation("SavedSuccessfully"), MessageSeverity.Success);
      }, err => {
        this.alertService.stopLoadingMessage();
        this.displayMode = DisplayModes.Error;
      });
    }
  }

  back() {
    this.closeEvent.emit(true);
  }
  getDetails(id) {
    this.displayMode = DisplayModes.Loading;
    this.itemTypeService.GetById(id).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.itemType = response as ItemType;
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

}
