import { Component, OnInit, ViewChild } from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { ItemTypeService } from './item-type.service';
import { AppConfigService } from '../../app.config';
import { ItemType } from '../../models/lookups/itemType.model';
import { DisplayModes } from '../../models/enums';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from 'ngx-bootstrap';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';




@Component({
  selector: 'crm-item-type',
  templateUrl: './item-type.component.html',
  styleUrls: ['./item-type.component.css']
})
export class ItemTypeComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  itemTypes: ItemType[] = [];
  itemTypeId: number;
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = require("../../../app/assets/images/demo/Icon_Items Format.png");

  selectedItemTypeId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;

  constructor(private alertService: AlertService,
    private itemTypeService: ItemTypeService,
    private route: ActivatedRoute, private accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchItemtypes();
    this.columns = [
      { field: 'name', header: 'Name' }
    ];
  }
  new() {
    this.addNew = true;
  }
  edit(id) {
    this.selectedItemTypeId = id;
    this.addNew = true;
  }
  searchItemtypes() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    this.itemTypeService.Search(this.searchText, this.pageSize, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;

      this.itemTypes = result.items;
      this.totalItems = result.totalItems;
    }, error => {

      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve item types from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchItemtypes();
  }


  confirmDelete(itemTypeId) {
    this.selectedItemTypeId = itemTypeId;
    this.deleteModal.show();
   

  }
  delete() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.itemTypeService.Delete(this.selectedItemTypeId).subscribe(response => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("", this.translateService.getTranslation("clm.deletedSuccessfully"), MessageSeverity.Success);

      let index = this.itemTypes.indexOf(this.selectedItemTypeId);
      this.itemTypes.splice(index, 1);
    }, error => {
      this.displayMode = DisplayModes.Error;
      this.alertService.showStickyMessage("Save Error", "The below errors occured while delete item fromat:", MessageSeverity.Error, error);
      this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
    });
  }

  closeAdding(event) {
    this.selectedItemTypeId = undefined;
    this.addNew = false;
    if (event) {
      this.searchItemtypes();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

  get canManageItemTypes() {
    return this.accountService.userHasPermission(Permission.manageItemTypesPermission);
  }
}

