import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapSelectDirective } from '../directives/bootstrap-select.directive';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { FormWizardModule } from 'angular2-wizard';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TreeTableModule } from 'primeng/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ExpenseComponent } from '../expenses/expense/expense.component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { RatingModule } from 'primeng/rating';

@NgModule({
  declarations: [BootstrapSelectDirective, ExpenseComponent],
  imports: [
    TranslateModule.forChild({}),
    CommonModule,
    FormsModule,
    TreeTableModule,
    TableModule,
    TreeModule,
    FormWizardModule,
    CheckboxModule,
    RadioButtonModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgbModule,
    AutoCompleteModule,
    DropdownModule,
    UiSwitchModule,
    AccordionModule,
    CardModule,
    RatingModule,
    NgxDropzoneModule,
    DataTablesModule
  ],
  exports: [BootstrapSelectDirective,
    TreeTableModule,
    AccordionModule,
    CardModule,
    RatingModule,
    FormsModule,
    NgbModule,
    TableModule,
    NgMultiSelectDropDownModule,
    AutoCompleteModule,
    DropdownModule,
    TreeModule,
    UiSwitchModule,
    BsDatepickerModule,
    NgxDropzoneModule, DataTablesModule]
})
export class TopSharedModule { }
