import { Component, OnInit, ViewChild } from '@angular/core';

import { Job } from '../models/job.model';

import { ActivatedRoute, Router } from '@angular/router';

import { JobService } from './job.service';
import { AppConfigService } from '../../app.config';
import { DisplayModes } from '../../models/enums';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Page } from '../../models/page.model';
import { AppConsts } from '../../../shared/app-consts';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'crm-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {

  searchText: string;
  search: any = {};
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = AppConsts.pageSize;
  countryId: number;
  displayMode: DisplayModes = DisplayModes.Success;

  jobs: Job[] = [];
  public logo = require("../../../app/assets/images/demo/Icon_Jobs.png");

  selectedJobId;
  addNew: boolean = false;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  constructor(private readonly alertService: AlertService,
    private readonly appConfig: AppConfigService,
    private readonly jobService: JobService,
    private readonly translateService: AppTranslationService,
    private readonly router: Router) {
  }

  ngOnInit() {
    this.searchJobs();

  }

  searchJobs() {
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;
    this.jobService.searchJobs(this.search.searchText, this.search.parentId, this.currentPage).subscribe(response => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;
      let result = response as Page;
      this.jobs = result.items;
      this.totalItems = result.totalItems;

    }, err => {
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;
    });
  }

  pageChanged(event) {
    this.currentPage = event.page;
    this.searchJobs();
  }

  goToNew() {
    this.addNew = true;
    //this.router.navigate(['jobs/Add']);
  }


  gotoEdit(jobId) {
    this.selectedJobId = jobId;
    this.addNew = true;
    //this.router.navigate(['jobs/edit', jobId]);
  }

  confirmDeleteJob(job: Job) {
    this.selectedJobId = job;
    this.deleteModal.show();
    //this.alertService.showDialog(`Are you sure you want to delete ${job.name}`, DialogType.Confirm,
    //  () => this.deleteJob(job),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));
  }

  deleteJob() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;
    this.jobService.deleteJob(this.selectedJobId.id).subscribe((response) => {
      this.displayMode = DisplayModes.Success;
      this.alertService.showMessage("Delete", `Job ${this.selectedJobId.name} deleted successfully!`, MessageSeverity.Success);

      //this.searchJobs();
    }, err => {
        this.displayMode = DisplayModes.Success;
        this.alertService.showMessage("", this.translateService.getTranslation("FailedToDelete"), MessageSeverity.Error);
    });
  }


  closeAdding(event) {
    this.selectedJobId = undefined;
    this.addNew = false;
    if (event) {
      this.searchJobs();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }
}
