<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==1">
  <div class="container text-center">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>


<!--<form #f="ngForm" *ngIf="displayMode==2"
      novalidate (ngSubmit)="save(f)">
  <div class="col-sm-12">

  </div>

</form>-->
<div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">
  <div class="row">
    <div class="col-sm-6">
      <label class="control-label">{{'Brick Level' | translate}} </label><br />
      <span class="ui-fluid">
        <p-dropdown [options]="brickLevels"
                    styleClass="wid100"
                    placeholder="Brick Level"
                    emptyFilterMessage="No results found"
                    (onChange)="onChangeBrickLevel()"
                    name="brickLevelId" #brickLevelId="ngModel"
                    [(ngModel)]="brickLevel"
                    [filter]="true" optionLabel="name"></p-dropdown>
      </span>
    </div>
    <div class="col-sm-6">
      <div [ngClass]="{'has-success': (startDatePicker.dirty || startDatePicker.touched)  && startDatePicker.valid, 'has-error' : (startDatePicker.dirty || startDatePicker.touched)  && startDatePicker.invalid}">
        <label class="control-label">{{'Start Date' | translate}} </label>


        <div class="input-group date">
          <input class="form-control" placeholder="yyyy-mm-dd" 
                 name="startDatePicker" #startDatePicker="ngModel" [(ngModel)]="startDate" ngbDatepicker #d="ngbDatepicker">
          <span class="input-group-btn">
            <button class="btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
          </span>

        </div>

      </div>
    </div>
  </div>

  <div class="row" style="margin-top:15px" *ngIf="brickLevel && brickLevel.id>0">
    <div class="col-sm-9">
      <input type="text" id="Search" name="search" class="form-control" placeholder="Search" [(ngModel)]="search" />
    </div>
    <div class="col-sm-3">
      <button class="btn  btn-save" type="button" (click)="save()">{{'Save' | translate}}</button>
    </div>
  </div>

  <div class="row">

    <div class="col-sm-6">
      <div class="custom-control custom-checkbox" *ngFor="let brick of bricks | filter : search; let i = index ">
        <input style="margin-right:10px" type="checkbox" class="custom-control-input" [(ngModel)]="brick.selected" name="selected_{{brick.id}}" id="{{brick.id}}">
        <label class="custom-control-label" for="{{brick.id}}">{{brick.name}}</label>
      </div>
    </div>
  </div>

</div>



