import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { Reaction } from '../../models/lookups/Reaction';



@Injectable({
  providedIn: 'root'
})
export class ReactionsApiService{

  constructor(protected http: HttpClient,
    private readonly configurations: ConfigurationService) {
    
  }

  getAll() {
    return this.http.get(`${this.configurations.apiAddress}/api/reactions/getAll`);
  }

  save(reaction: Reaction) {
    return this.http.post(this.configurations.apiAddress + "/api/reactions/save", reaction);
  }

  search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/reactions/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/reactions/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/reactions/getById/' + id);
  }


}
