import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperMethodsService {

  constructor() { }

  convertDateToUTC(date: Date) {

    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  }

  
  convertDateToCalendarDate(date: Date) {
    let d = new Date(date);
    return {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };

    
  }

  convertCalendarToDate(date: any) :Date{
   return new Date(date.year, date.month - 1, date.day + 1)
  }

  getArrayOfDays(start: Date, end: Date): Date[] {
   let days = new Array<Date>();
    while (start <= end) {
      days.push(this.convertDateToUTC(start));
      start.setDate(start.getDate() + 1);

    }

    return days;
  }

   getDateUTC(date: Date) {

    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  }

}
