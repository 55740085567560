
import { Injectable } from "@angular/core";

import { TranslateService, TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import "rxjs/add/observable/of";


@Injectable()
export class AppTranslationService {

  readonly defaultLanguage = "en";
  private onLanguageChanged = new Subject<string>();
  languageChanged$ = this.onLanguageChanged.asObservable();

  constructor(private translate: TranslateService) {

    this.setDefaultLanguage(this.defaultLanguage);
  }


  addLanguages(lang: string[]) {
    this.translate.addLangs(lang);
  }


  setDefaultLanguage(lang: string) {
    this.translate.setDefaultLang(lang);
  }

  getDefaultLanguage() {
    return this.translate.defaultLang;
  }

  getBrowserLanguage() {
    return this.translate.getBrowserLang();
  }


  useBrowserLanguage(): string | void {
    let browserLang = this.getBrowserLanguage();

    if (browserLang.match(/en/)) {
      this.changeLanguage(browserLang);
      return browserLang;
    }
    return browserLang;
  }

  changeLanguage(language: string = "en") {

    if (!language)
      language = this.translate.defaultLang;

    if (language !== this.translate.currentLang) {
      setTimeout(() => {
        this.translate.use(language);
        this.onLanguageChanged.next(language);
      });
    }

    return language;
  }


  getTranslation(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translate.instant(key, interpolateParams);
  }


  getTranslationAsync(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
    return this.translate.get(key, interpolateParams);
  }

}




//export class TranslateLanguageLoader implements TranslateLoader {

//  public getTranslation(lang: string): any {

//    //Note Dynamic require(variable) will not work. Require is always at compile time

//    switch (lang) {
//      case "en":
//        return of(require("../assets/locale/en.json"));
      
//      default:
//    }
//  }
//}

//@Injectable()
//export class TranslateLanguageLoader implements TranslateLoader {
//  constructor(private http: HttpClient,) { }
//  getTranslation(lang: string): Observable<any> {
//    var apiAddress = AppConfig.settings.apiServer.baseUrl+ "/api/translations?lang=" + lang;
//    let contentHeader = new Headers({ "Content-Type": "application/json" });
//    return Observable.create(observer => {
//      this.http.get(apiAddress).subscribe((res: Response) => {
//        observer.next(res);
//        observer.complete();
//      },
//        error => {
//          //  failed to retrieve from api, switch to local
//          this.http.get("/src/app/assets/i18n/" + lang + ".json").subscribe((res: Response) => {
//            observer.next(res.json());
//            observer.complete();
//          })
//        }
//      );
//    });
//  }
//}
export class TranslateLanguageLoader implements TranslateLoader {

  /**
   * Gets the translations from webpack
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {

    //Note Dynamic require(variable) will not work. Require is always at compile time

    switch (lang) {
      case "en":
        return Observable.of(require("../assets/i18n/en.json"));

      //case "ar":
      //  return Observable.of(require("../assets/locale/ar.json"));

      default:
    }
    return Observable.of(require("../assets/i18n/en.json"));
  }
}
