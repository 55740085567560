import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Utilities } from '../../../../services/utilities';
import { User } from '../../../../models/user.model';
import { Permission } from '../../../../models/permission.model';
import { UserEdit } from '../../../../models/user-edit.model';
import { Role } from '../../../../models/role.model';

import { MessageSeverity, AlertService } from '../../../../services/alert.service';

import { AccountService } from '../../../../services/account.service';
import { DisplayModes } from '../../../../models/enums';
import { OrganizationJobStructure } from '../../../../models/lookups/organizationJobStructure.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationJobStructureService } from '../../../../lookups/organization-job-structure/organization-job-structure.service';
import { HelperMethodsService } from '../../../../services/helper-methods.service';

@Component({
  selector: 'crm-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.css']
})
export class UserEditorComponent implements OnInit {
  private isEditMode = true;
  private isNewUser = false;
  private isSaving = false;
  private isChangePassword = false;
  private isEditingSelf = false;
  private showValidationErrors = false;
  private editingUserName: string;
  private uniqueId: string = Utilities.uniqueId();
  private user: User = new User();
  private userEdit: User = new User();
  private allRoles: Role[] = [];
  //private userId: number;
  private jobs: OrganizationJobStructure[] = [];
  private hiringdate: any;
  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  displayMode: DisplayModes = DisplayModes.Success;


  @Input()
  isViewOnly: boolean = false;

  @Input()
  isGeneralEditor = false;





  @ViewChild('f')
  private form;

  //ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild('userName')
  private userName;

  @ViewChild('userPassword')
  private userPassword;

  @ViewChild('email')
  private email;

  @ViewChild('currentPassword')
  private currentPassword;

  @ViewChild('newPassword')
  private newPassword;

  @ViewChild('confirmPassword')
  private confirmPassword;

  @ViewChild('roles')
  private roles;

  @ViewChild('rolesSelector')
  private rolesSelector;

  @ViewChild('jobId')
  private jobId;

  @ViewChild('jobsSelector')
  private jobsSelector;

  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() showProvision = new EventEmitter<boolean>();
  @Input() userId: number;
  constructor(private readonly alertService: AlertService, private readonly helperMethods: HelperMethodsService,

    private readonly accountService: AccountService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly jobStructureService: OrganizationJobStructureService) {
  }

  ngOnInit() {

    this.getAllJobs();
    this.loadData();
    //const current = new Date();

    //this.hiringdate = {
    //  year: current.getFullYear(),
    //  month: current.getMonth() + 1,
    //  day: current.getDate()
    //};
    if (this.userId === undefined)
      this.newUser();
    else
      this.loadUserData();

  }



  private loadUserData() {
    this.alertService.startLoadingMessage();


    this.accountService.getUser(this.userId.toString()).subscribe(user => this.onCurrentUserDataLoadSuccessful(user, user.roles.map(x => new Role(x as any))), error => this.onCurrentUserDataLoadFailed(error));

  }


  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.userEdit = user;
    if (this.userEdit.hiringDate)
      this.hiringdate = this.helperMethods.convertDateToCalendarDate(this.userEdit.hiringDate);

    this.editUser(user);
    // this.allRoles = roles;
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    //this.alertService.showStickyMessage("Load Error", `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
    //  MessageSeverity.Error, error);

    this.user = new User();
  }



  private getRoleByName(name: string) {
    return this.allRoles.find((r) => r.name == name)
  }



  private showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.Error);
  }


  public deletePasswordFromUser(user: UserEdit | User) {
    let userEdit = <UserEdit>user;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }


  private edit() {
    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
    }
    else {
      if (!this.userEdit)
        this.userEdit = new UserEdit();

      this.isEditingSelf = this.accountService.currentUser ? this.userEdit.id == this.accountService.currentUser.id : false;
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;
  }


  private save(form) {
    if (this.hiringdate)
      //this.userEdit.hiringDate = this.helperMethods.convertCalendarToDate(this.hiringdate);
      this.userEdit.hiringDate = new Date(this.hiringdate.year, this.hiringdate.month - 1, this.hiringdate.day, 12, 0, 0);
    if (form.valid) {
      this.isSaving = true;
      this.displayMode = DisplayModes.Loading;
      this.alertService.startLoadingMessage("Saving changes...");



      if (this.isNewUser) {
        if (this.userEdit.job !== undefined)
          this.userEdit.jobId = this.userEdit.job.id;
        this.accountService.newUser(this.userEdit).subscribe(user => {
          this.saveSuccessHelper(user);
          if (user.jobLinkedToOtherUser) {
            this.alertService.showStickyMessage("Job Warning", "Job linked to other user", MessageSeverity.Warn);
          }
        }, error => this.saveFailedHelper(error));
      }
      else {
        this.accountService.updateUser(this.userEdit).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
      }
    }

  }



  private saveSuccessHelper(user?: User) {

    this.isSaving = false;
    this.displayMode = DisplayModes.Success;
    this.alertService.stopLoadingMessage();
    //this.userEdit = new UserEdit();
    //this.resetForm(true);
    if (this.isNewUser)
      this.alertService.showMessage("Success", `User \"${this.user.userName}\" was created successfully`, MessageSeverity.Success);
    //else if (!this.isEditingSelf) {
    else
      this.alertService.showMessage("Success", `Changes to user \"${this.user.userName}\" was saved successfully`, MessageSeverity.Success);
    //this.router.navigate(['users-management']);
    //}

  }


  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Save Error", "The below errors occured whilst saving your changes:", MessageSeverity.Error, error);
    //this.alertService.showStickyMessage(error, null, MessageSeverity.Error);


  }

  getAllJobs() {
    this.displayMode = DisplayModes.Loading

    this.jobStructureService.GetAllOrgJobStructure().subscribe(response => {
      this.jobs = response as OrganizationJobStructure[];
      this.displayMode = DisplayModes.Success;

      if (this.jobs && this.userEdit.job)
        this.userEdit.job = this.jobs.filter(x => x.id == this.userEdit.job.id)[0];

    }, () => {
      this.displayMode = DisplayModes.Error;
      this.alertService.stopLoadingMessage();

    });
  }

  private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {

    let rolesAdded = this.isNewUser ? editedUser.roles : editedUser.roles.filter(role => currentUser.roles.indexOf(role) == -1);
    let rolesRemoved = this.isNewUser ? [] : currentUser.roles.filter(role => editedUser.roles.indexOf(role) == -1);

    let modifiedRoles = rolesAdded.concat(rolesRemoved);

    if (modifiedRoles.length)
      setTimeout(() => this.accountService.onRolesUserCountChanged(modifiedRoles));
  }



  private cancel() {
    if (this.isGeneralEditor)
      this.userEdit = this.user = new UserEdit();
    else
      this.userEdit = new UserEdit();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage("Cancelled", "Operation cancelled by user", MessageSeverity.Default);
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor)
      this.isEditMode = false;

    if (this.changesCancelledCallback)
      this.changesCancelledCallback();
  }


  private close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback)
      this.changesSavedCallback();
  }





  private unlockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage("Unblocking user...");


    this.accountService.unblockUser(this.userEdit.id)
      .subscribe(response => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage("Success", "User has been successfully unblocked", MessageSeverity.Success);
      },
        error => {
          this.isSaving = false;
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage("Unblock Error", "The below errors occured whilst unblocking the user:", MessageSeverity.Error, error);
          this.alertService.showStickyMessage(error, null, MessageSeverity.Error);
        });
  }


  resetForm(replace = false) {
    this.isChangePassword = false;

    if (!replace) {
      this.form.reset();
    }
    else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }


  newUser() {
    debugger;
    this.isGeneralEditor = true;
    this.isNewUser = true;

    //    this.allRoles = [...allRoles];
    this.editingUserName = null;
    this.user = this.userEdit = new UserEdit();
    this.userEdit.isEnabled = true;
    this.edit();

    return this.userEdit;
  }

  loadData() {
    this.alertService.startLoadingMessage();


    if (this.canViewRoles) {
      this.accountService.getRoles().subscribe(results => this.onDataLoadSuccessful(results), error => this.onDataLoadFailed(error));
    }

  }

  onDataLoadSuccessful(roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.allRoles = roles;
  }


  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.Error, error);
  }



  editUser(user: User) {
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;


      this.editingUserName = user.userName;
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      if (this.jobs && user.job)
        this.userEdit.job = this.jobs.filter(x => x.id == user.job.id)[0];
      this.edit();

      return this.userEdit;
    }
    else {
      return this.newUser();
    }
  }


  displayUser(user: User, allRoles?: Role[]) {
    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setRoles(user, allRoles);

    this.isEditMode = false;
  }
  back() {
    this.closeEvent.emit(true);

  }


  private setRoles(user: User, allRoles?: Role[]) {

    //this.allRoles = allRoles ? [...allRoles] : [];

    //if (user.roles) {
    //  for (let ur of user.roles) {
    //    if (!this.allRoles.some(r => r.name == ur))
    //      this.allRoles.unshift(new Role(ur));
    //  }
    //}

    //if (allRoles == null || this.allRoles.length != allRoles.length)
    //  setTimeout(() => this.rolesSelector.refresh());
  }



  get canViewAllRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(Permission.assignRolesPermission);
  }


  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission)
  }

  get canManageUsers() {
    return this.accountService.userHasPermission(Permission.manageUsersPermission);
  }

  showProvisionModal() {
    this.showProvision.emit(true);
  }

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
}
