import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { ActivityGroup } from '../../models/lookups/activity-group.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityGroupService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {
  }

  save(activityGroup: ActivityGroup) {
    return this.http.post(this.configurations.apiAddress + "/api/ActivityGroup/save", activityGroup);
  }

  search(searchText: string) {
    let myParams = new HttpParams()
      .set('searchText', searchText == undefined ? "" : searchText)
    return this.http.get(this.configurations.apiAddress + '/api/ActivityGroup/search', { params: myParams });
  }

  delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/ActivityGroup/delete/' + id);
  }

  getById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityGroup/getById/' + id);
  }

  getAll() {
    return this.http.get(this.configurations.apiAddress + '/api/ActivityGroup/getAll/');
  }
}
