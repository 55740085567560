import { Component, OnInit, ViewChild } from '@angular/core';
import { DisplayModes } from '../../models/enums';
import { AppConfigService } from '../../app.config';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService } from '../../services/app-translation.service';
import { ActivityTypeService } from './activity-type.service';
import { activityType } from '../../models/lookups/activityType.model';
import { Utilities } from '../../services/utilities';
import { ModalDirective } from "ngx-bootstrap";


@Component({
  selector: 'crm-activity-type',
  templateUrl: './activity-type.component.html',
  styleUrls: ['./activity-type.component.css']
})
export class ActivityTypeComponent implements OnInit {
  columns: any[] = [];
  searchText: string;
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = this.appConfig.settings.pageSize;
  activityTypes: activityType[] = [];
  activityTypeId: number;

  //instance from an enum to display either sucess,loading,error or saving
  displayMode: DisplayModes = DisplayModes.Success;
  public logo = "";
  @ViewChild('filterModal')
  filterModal: ModalDirective;
  @ViewChild('deleteModal')
  deleteModal: ModalDirective;
  addNew: boolean = false;
  selectedTypeId;
  constructor(private alertService: AlertService,
    private activityTypeService: ActivityTypeService,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private translateService: AppTranslationService,
    private router: Router) { }

  ngOnInit() {
    this.searchActivityType();
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'leadTime', header: 'Lead Time' }
    ];
  }
  new() {
    this.addNew = true;
    //this.router.navigate(['add-activity-type']);
  }
  searchActivityType() {

    // Start Loading and alert loading message
    this.alertService.startLoadingMessage("", this.translateService.getTranslation("Searching"));
    this.displayMode = DisplayModes.Loading;

    // get a search method from activity type service and carry its result in response
    this.activityTypeService.Search(this.searchText).subscribe(response => {

      // Stop Loading and alert Success message
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Success;

      // assign value of response as an array to result variable
      let result = response as Array<any>;

      this.activityTypes = result;
    }, error => {

      // Stop Loading and alert Error message
      this.alertService.stopLoadingMessage();
      this.displayMode = DisplayModes.Error;

      this.alertService.showStickyMessage("Load Error", `Unable to retrieve item types from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
        MessageSeverity.Error, error);
    });
  }
  pageChanged(event) {
    this.currentPage = event.page;
    this.searchActivityType();
  }

  // Edit Activity Type by Id 
  gotoEdit(activityTypeId) {
    this.selectedTypeId = activityTypeId;
    this.addNew = true;
    //this.router.navigate(['add-activity-type', activityTypeId]);
  }

  // Delete ActivityType by Id
  deleteActivityType() {
    this.hideDeleteModal();
    this.displayMode = DisplayModes.Loading;

    // get a Delete method from activity type service and carry its result in result
    this.activityTypeService.Delete(this.selectedTypeId).subscribe(result => {

      this.displayMode = DisplayModes.Success;
      this.searchActivityType();
    }, err => {
      this.displayMode = DisplayModes.Error;
    });
  }

  // Confirm Delete Method
  confirmDelete(activityTypeId) {
    this.selectedTypeId = activityTypeId;
    this.deleteModal.show();
    //this.alertService.showDialog(this.translateService.getTranslation("DeleteConfirmationMessage"), DialogType.Confirm,
    //  () => this.deleteActivityType(activityTypeId),
    //  () => this.alertService.showMessage(this.translateService.getTranslation("OperationCancelled"), "", MessageSeverity.Default));

  }

  openFilterModal() {
    this.filterModal.show();
  }

  showAddNew() {
    this.addNew = true;
  }


  hideFilterModal() {
    this.filterModal.hide();
  }

  searchFilterModal() {
    this.filterModal.hide();
    this.searchActivityType();
  }

  closeAdding(event) {
    this.selectedTypeId = undefined;
    this.addNew = false;
    if (event) {
      this.searchActivityType();
    }
  }

  hideDeleteModal() {
    this.deleteModal.hide();
  }

}
