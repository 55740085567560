import { Injectable, Injector } from '@angular/core';
import { HttpParams, HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../../services/endpoint-factory.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { BusinessUnit } from '../../models/lookups/business-unit.model';


@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService extends EndpointFactory {
  culture: string = "en";

  constructor(protected http: HttpClient,
    private translateService: AppTranslationService,
    protected configurations: ConfigurationService, injector: Injector,
    private localStorage: LocalStoreManager) {
    super(http, configurations, injector);


    this.culture = this.configurations.language;
  }


  Search(searchText: string, parentId: number, pageSize: number, pageNumber: number) {
    let myParams = new HttpParams()


      .set('searchText', searchText == undefined ? "" : searchText)
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString());
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/search', { params: myParams });
  }

  Save(businessUnit: BusinessUnit) {
    return this.http.post(this.configurations.apiAddress + '/api/businessUnit/save', businessUnit);
  }



  GetAllSpecialities(searchText: string) {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/getAll/' + searchText);
  }
  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/getById/' + id);
  }
  Delete(id: number) {
    return this.http.delete(`${this.configurations.apiAddress}/api/businessUnit/delete/${id}`);
  }

  provisionBusinessUnits(formData) {
    const uploadReq = new HttpRequest("POST", this.configurations.apiAddress + `/api/businessUnit/provisionFromCsv`, formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);

  }

  getAllBusinessUnitsTree(typeId) {
    let myParams = new HttpParams()
      .set('typeId', typeId == undefined ? "0" : typeId);

    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getAllBusinessUnitsTree`, { params: myParams });
  }

  getAllBusinessUnits() {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getAllBusinessUnits`); 1
  }

  getUserBussinessUnit() {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getCurrentUserBusinessUnits`);
  }

  getBusinessUnitsByType(type: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getBusinessUnitsByType/${type}`);
  }
  downloadCsvTemplate(): Observable<Blob> {
    return this.http.get(this.configurations.apiAddress + "/api/businessUnit/downloadCSVTemplate", { responseType: "blob" });
  }

  getBusinessUnitsRootLevel(activeOnly: boolean = true) {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getBusinessUnitsRootLevel?activeOnly=${activeOnly}`);
  }

  getBusinessUnitSpecialities(businessUnitId: number) {
    return this.http.get(`${this.configurations.apiAddress}/api/businessUnit/getBusinessUnitSpecialities/${businessUnitId}`);
  }

  getChildrens(businessUnitId: number) {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/getChildrens/' + businessUnitId);
  }

  getListOfSharedBricks(businessUnitId = undefined) {
    let myParams = new HttpParams()
      .set('businessUnitId', businessUnitId == undefined ? "0" : businessUnitId);
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/getListOfSharedBricks', { params: myParams });
  }

  getBrickBusinessUnits(brickId, buLineId) {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/getBrickBusinessUnits/' + brickId + '/' + buLineId);
  }

  saveSharedBricksPercentage(brickId, sharedBricks) {
    return this.http.post(this.configurations.apiAddress + '/api/businessUnit/saveSharedBricksPercentage/' + brickId, sharedBricks);
  }

  adjustSharedBricksPercent() {
    return this.http.get(this.configurations.apiAddress + '/api/businessUnit/adjustSharedBricksPercent');
  }
}
