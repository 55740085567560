
<form #f="ngForm" *ngIf="displayMode==2"
      novalidate (ngSubmit)="save(f)">

  <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
    <div class="row padding-sm-vr">
      <div class="col-sm-12">
        <div [ngClass]="{'has-success': (job.dirty || job.touched || f.submitted)  && job.valid, 'has-error' : (job.dirty || job.touched || f.submitted)  && job.invalid}">
          <label class="control-label">{{'User Job' | translate}} </label>
          <span class="ui-fluid">
            <p-dropdown [options]="jobs"
                        styleClass="wid100"
                        placeholder="User Job"
                        emptyFilterMessage="No results found"
                        showClear="true"
                        name="job" #job="ngModel"
                        required="required" [(ngModel)]="userJob.job"
                        [filter]="true" optionLabel="name"></p-dropdown>
          </span>
          <div *ngIf="(job.dirty || job.touched || f.submitted)  && job.invalid" class="jquery-validate-error help-block">
            {{'RequiredField' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="row padding-sm-vr">
      <div class="col-sm-6">
        <div [ngClass]="{'has-success': (startDatePicker.dirty || startDatePicker.touched || f.submitted)  && startDatePicker.valid, 'has-error' : (startDatePicker.dirty || startDatePicker.touched || f.submitted)  && startDatePicker.invalid}">
          <label class="control-label">{{'Start Date' | translate}} </label>


          <div class="input-group date">
            <input class="form-control" placeholder="yyyy-mm-dd" required
                   name="startDatePicker" #startDatePicker="ngModel" [(ngModel)]="startDate" ngbDatepicker #d="ngbDatepicker">
            <span class="input-group-btn">
              <button class="btn" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
            </span>

          </div>


          <div *ngIf="(startDatePicker.dirty || startDatePicker.touched || f.submitted)  && startDatePicker.invalid" class="jquery-validate-error help-block">
            {{'RequiredField' | translate}}
          </div>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="!userJob.isCurrent">
        <div [ngClass]="{'has-success': (endDatePicker.dirty || endDatePicker.touched || f.submitted)  && endDatePicker.valid, 'has-error' : (endDatePicker.dirty || endDatePicker.touched || f.submitted)  && endDatePicker.invalid}">
          <label class="control-label">{{'endDate' | translate}} </label>


          <div class="input-group date" id="bs-datepicker-component">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   [required]="userJob.isCurrent===false"
                   name="endDatePicker" #endDatePicker="ngModel"
                   [(ngModel)]="endDate" ngbDatepicker #d2="ngbDatepicker">

            <span class="input-group-btn">
              <button class="btn" (click)="d2.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </span>

          </div>



          <div *ngIf="(endDatePicker.dirty || endDatePicker.touched || f.submitted)  && endDatePicker.invalid" class="jquery-validate-error help-block">
            {{'RequiredField' | translate}}
          </div>
        </div>
      </div>

    </div>
    <div class="row padding-sm-vr">
      <div class="col-sm-6">
        <div [ngClass]="{'has-success': (isCurrent.dirty || isCurrent.touched || f.submitted)  && isCurrent.valid, 'has-error' : (isCurrent.dirty || isCurrent.touched || f.submitted)  && isCurrent.invalid}">
          <label class="control-label">{{'Current' | translate}} </label>

          <div class="form-group">
            <div class="checkbox">
              <label><input type="checkbox" #isCurrent="ngModel" name="isCurrent" [(ngModel)]="userJob.isCurrent">{{'Is Current' | translate}}  </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div [ngClass]="{'has-success': (applyList.dirty || applyList.touched || f.submitted)  && applyList.valid, 'has-error' : (applyList.dirty || applyList.touched || f.submitted)  && applyList.invalid}">
          <label class="control-label">{{'Apply List' | translate}} </label>

          <div class="form-group">
            <div class="checkbox">
              <label><input type="checkbox" #applyList="ngModel" name="applyList" [(ngModel)]="userJob.applyList">{{'Apply List' | translate}}  </label>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div class="panel-footer margin-left-20 margin-top-20 margin-left-20">
      <button class="btn  btn-cancel" type="button" (click)="close()">{{'Cancel' | translate}}</button>&nbsp;
      <button class="btn  btn-save" type="submit">{{'Save' | translate}}</button>

    </div>


  </div>

</form>
<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==3">
  <div class="alert alert-danger alert-dark">
    <button type="button" class="close" data-dismiss="alert">×</button>
    {{'ServerError' |translate}}.
  </div>
</div>

<div class="row col-sm-12 padding-sm-hr" *ngIf="displayMode==1">
  <div class="container text-center">
    <i class="fa fa-spinner fa-spin" style="font-size:48px;color:darkseagreen"></i> <p>{{'Loading' | translate}}</p>
  </div>

</div>
<script>
  init.push(function () {
    // Javascript code here
    debugger;
    $('#bs-datepicker-component').datepicker();
  });
</script>

