import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';


@Injectable({
  providedIn: 'root'
})

export class PharmacyWorkingDaysService {

  constructor(protected http: HttpClient,
    protected configurations: ConfigurationService) {

  }

  Save(id: number) {
    return this.http.post(this.configurations.apiAddress + "/api/PharmacyWorkingDay/save/", id);
  }

  Search() {
    return this.http.get(this.configurations.apiAddress + '/api/PharmacyWorkingDay/getAll');
  }

  Delete(id: number) {
    return this.http.delete(this.configurations.apiAddress + '/api/PharmacyWorkingDay/delete/' + id);
  }

  GetById(id: number) {
    return this.http.get(this.configurations.apiAddress + '/api/PharmacyWorkingDay/getById/' + id);
  }

}
