<ul class="breadcrumb breadcrumb-page">
  <li><a href="#">{{'Home' | translate}}</a></li>
  <li class="active">{{'pageHeader.businessUnits' | translate}}</li>
</ul>

<div class="page-header">
  <div class="row">
    <h1 class="col-xs-12 col-sm-4 text-center text-left-sm"><img [src]="logo" class="img-icon" />&nbsp;&nbsp;{{'pageHeader.businessUnits' | translate}}</h1>
  </div>
</div>


<div class="panel panel-default widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'pageHeader.businessUnits' | translate}} {{'Search' | translate}}</span>
  </div>

  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-6">
          <span class="ui-fluid">
            <p-dropdown [options]="businessUnitTypes"
                        (onChange)="searchBusinessUnits()"
                        styleClass="wid100"
                        placeholder="{{'businessUnits.businessUnitType' | translate}}"
                        emptyFilterMessage="No results found"
                        showClear="true"
                        [(ngModel)]="businessUnitType"
                        [filter]="true" optionLabel="name"></p-dropdown>

          </span>
        </div>
        <div class="col-sm-3 margin-top-10 cursor-pointer" (click)="searchBusinessUnits()">
          <span class="margin-right-7 filter-span">Filter</span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </div>
        <div class="col-sm-3 margin-top-10 left-10 cursor-pointer" (click)="goToNew()">
          <span class="margin-right-7 create-new-span">Create New BU</span>
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<crm-save-business-unit *ngIf="addNew" (closeEvent)="closeAdding($event)" (showProvision)="showProvisionModal($event)" [businessUnitId]="selectedBusinessUnitId"></crm-save-business-unit>

<crm-manage-business-unit-bricks *ngIf="manageMode" (closeEvent)="closeAdding($event)" [businessUnitId]="selectedBusinessUnitId"></crm-manage-business-unit-bricks>


<div class="panel panel-success widget-support-tickets">
  <div class="panel-heading">
    <span class="panel-title">{{'pageHeader.businessUnits' | translate}} {{'Search Result' | translate}}</span>
  </div>
  <div class="panel-body padding-sm-hr">
    <div class="form-group no-margin-hr panel-padding-h no-padding-t no-border-t">
      <div class="row">
        <div class="col-sm-12">

          <p-treeTable [value]="businessUnits" [resizableColumns]="true" [paginator]="true" [rows]="pageSize">
            <ng-template pTemplate="header">
              <tr>
                <th ttResizableColumn [ttSortableColumn]="'name'" style="text-align:center" class="table-edit">
                  {{'businessUnits.name' |translate}}
                  <p-treeTableSortIcon [field]="'name'"></p-treeTableSortIcon>
                </th>
                <th ttResizableColumn style="text-align:center" class="table-edit">{{'businessUnits.businessUnitType' | translate}}</th>

                <th width="10%" style="text-align:center" class="table-edit">Edit</th>
                <th width="10%" style="text-align:center" class="table-edit">Delete</th>
                <th width="10%" style="text-align:center" class="table-edit">{{'businessUnits.bricks' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
              <tr [ttRow]="rowNode">
                <td>
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>

                  <span [class.user-disabled]="!rowData.active">
                    <i *ngIf="!rowData.active" class="fa fa-exclamation"> </i>

                    {{rowData.name}}
                  </span>

                 
                </td>
                <td>{{rowData.businessUnitTypeName}}</td>
                <td>
                  <a class="" href="javascript:;" (click)="gotoEdit(rowData.id)"><i class="fa fa-edit fa-lg font-size-20"></i></a>
                </td>
                <td><a href="javascript:;" (click)="confirmDelete(rowData.id)"><i class="fa fa-trash-o  fa-lg font-size-20"></i></a></td>

                <td><a *ngIf="rowData.businessUnitTypeIsLeafLevel" href="javascript:;" (click)="manageBusinessUnitBricks(rowData.id)"><i class="fa fa-comments  fa-lg font-size-20"></i></a></td>

              </tr>
            </ng-template>
          </p-treeTable>



        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-8">
  </div>
  <div class="col-sm-2 cursor-pointer left-15">
  </div>
  <div class="col-sm-2 cursor-pointer left-7" (click)="downloadCSV()">
    <span class="margin-right-7 edit-profile-span">Export Sheet</span>
    <i class="fa fa-download" aria-hidden="true"></i>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="delete-title">{{'Do you Want to Delete the File?' | translate}}</h1>
      </div>
      <div class="row panel-body padding-sm-hr padding-top-0 padding-bottom-0">

        <div class="panel-footer margin-left-20 margin-top-20">
          <button type="button" (click)="hideDeleteModal()" class="btn btn-cancel">{{'users.editor.Cancel' | translate}}</button>
          <button type="button" class="btn btn-save" (click)="delete()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="modal-sizes-1" bsModal #provisionModal="bs-modal" (onHidden)="onProvisionModalHidden()" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog  modal-lg">
    <div class="panel padding-2">
      <div>
        <h1 class="filter-title pull-left width-90">{{'Upload Business Units Result' | translate}}</h1>
        <button type="button" class="close pull-right" title="Close" (click)="provisionModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <crm-provision-business-units #provisionBusinessUnits></crm-provision-business-units>
    </div>
  </div>
</div>


